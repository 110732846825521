import { FC } from 'react';

import { Skeleton, Tag } from 'antd';
import { ColumnType } from 'antd/es/table';

export type TTagColumn<T> = ColumnType<T> & Omit<TTagCellProps, 'tag'>;

export interface TTagCellProps {
  tag: string;
  loading?: boolean;
}

const setColorFromStatus = (orderStatus: string) => {
  switch (orderStatus) {
    case 'PENDING':
      return 'gray';
    case 'PAID':
      return 'black';
    case 'PARTIALLY PAID':
      return 'black';
    case 'PURCHASED':
      return 'purple';
    case 'PARTIALLY PURCHASED':
      return 'purple';
    case 'SHIPPED':
      return 'green';
    case 'PARTIALLY SHIPPED':
      return 'green';
    case 'DELIVERED':
      return 'blue';
    case 'PARTIALLY DELIVERED':
      return 'blue';
    case 'CANCELLED':
      return 'red';
    case 'PARTIALLY REFUNDED':
      return 'yellow';
    case 'REFUNDED':
      return 'yellow';
    case 'RETURN STARTED':
      return 'orange';
    case 'RETURN COMPLETED':
      return 'orange';
    case 'ACTIVE':
      return 'green';
    case 'DRAFT':
      return 'default';
    case 'ARCHIVE':
      return 'warning';
    default:
      return 'gray';
  }
};

const TagCell: FC<TTagCellProps> = ({ tag, loading }) => {
  if (loading) {
    return <Skeleton.Input active />;
  }

  const settedColor = setColorFromStatus(tag);

  return <Tag color={settedColor}>{tag}</Tag>;
};

export const tag = <T,>({
  dataIndex = 'title',
  key = 'title',
  width = '10%',
  loading,
  ...props
}: TTagColumn<T>): ColumnType<T> => ({
  dataIndex: dataIndex,
  key: key,
  width: width,
  render: (tag: string) => <TagCell tag={tag} loading={loading} />,
  ...props,
});
