import { AxiosResponse } from 'axios';
import { IPage, IPatchPageParams } from 'common/types/page';
import { EPages } from 'common/types/page/enums';

import { $host } from './index';

export const getPage = async (page: EPages) => {
  const {
    data: { data },
  } = await $host.get<AxiosResponse<IPage>>(`admin/pages/${page}`);

  return data;
};

export const patchPage = async (params: IPatchPageParams) => {
  const {
    data: { data },
  } = await $host.patch<AxiosResponse<IPage>>(`admin/pages`, params);

  return data;
};
