import { getAllSubcategories, deleteById } from 'http/categoryApi';
import { getProductAutocompleteByType } from 'http/productApi';

import {
  IAutocompleteTypeParams,
  ISubcategory,
  ISubcategoryParams,
} from 'common/types';
import { ProductAutocompleteTypeEnum } from 'common/types/enums';
import { notify } from 'common/utils';
import { computed, makeAutoObservable, observable } from 'mobx';
import { FiltersModel, TParams } from 'store/filters-model';

import { SubcategoryModel } from '../../store/subcategory-model';

export class SubcategoryViewModel {
  _loading = false;
  _subcategories: SubcategoryModel[] = [];
  _count = 0;
  _filters: FiltersModel;
  _brandOptions: IAutocompleteTypeParams[] = [];
  _selectedBrand: IAutocompleteTypeParams | null;
  _searchBrandValue: string;

  get loading() {
    return this._loading;
  }

  set loading(value: boolean) {
    this._loading = value;
  }

  set search(value: string) {
    this._filters.search = value;

    this.fetchSubcategories();
  }

  get subcategories() {
    return this._subcategories;
  }

  set subcategories(subcategories: SubcategoryModel[]) {
    this._subcategories = subcategories;
  }

  get brandOptions() {
    return this._searchBrandValue
      ? this._brandOptions.filter((option) =>
          option.value
            .toLowerCase()
            .includes(this._searchBrandValue.toLowerCase()),
        )
      : this._brandOptions;
  }

  get searchBrandValue() {
    return this._searchBrandValue;
  }

  set searchBrandValue(value: string) {
    this._searchBrandValue = value;
  }

  get selectedBrand() {
    return this._selectedBrand;
  }

  set selectedBrand(brand: IAutocompleteTypeParams | null) {
    this._selectedBrand = brand;
    this._filters.page = 1;

    if (brand) {
      this._filters.params = {
        ...this._filters.params,
        brandTitle: brand.value,
        brandId: String(brand.id),
      };

      this.fetchSubcategories();
    }
  }

  get skeleton() {
    return new Array(5).fill(new SubcategoryModel());
  }

  get count() {
    return this._count;
  }

  removeSubcategory = async (id: number) => {
    const updatedSubcategories = this._subcategories.filter(
      (subcategory) => subcategory.id !== id,
    );
    const result = await deleteById(id);

    if (result) {
      this._subcategories = updatedSubcategories;
      notify(
        <div>
          Category <strong>{result.title}</strong> was removed succesfully!
        </div>,
      );
    }
  };

  fetchSubcategories = async () => {
    const { page, pageSize, search, sort, sortBy } = this._filters;

    const params: ISubcategoryParams = {
      page: page,
      pageSize: pageSize,
      sort: sort,
      sortBy: sortBy,
    };

    if (search) {
      params.title = search;
    }

    if (this.selectedBrand) {
      params.brandId = String(this.selectedBrand.id);
    }

    this.loading = true;

    try {
      const { count, items, page } = await getAllSubcategories(params);

      this._brandOptions = await getProductAutocompleteByType(
        ProductAutocompleteTypeEnum.brands,
      );

      this._subcategories = items.map(
        (subcategory: ISubcategory) =>
          new SubcategoryModel(subcategory.id, subcategory),
      );

      this._count = count;
      this._filters.page = page;

      return items;
    } catch (e) {
      notify(`Error, reason:${e}`, 'error');
    } finally {
      this.loading = false;
    }
  };

  constructor(params?: TParams) {
    this._filters = new FiltersModel(this.fetchSubcategories, params);

    this._searchBrandValue = params?.brandTitle || '';
    this._selectedBrand = params?.brandId
      ? { value: params.brandTitle, id: Number(params.brandId) }
      : null;

    this.fetchSubcategories();

    makeAutoObservable(this, {
      _loading: observable,
      _subcategories: observable,
      _selectedBrand: observable,
      _searchBrandValue: observable,
      _count: observable,

      loading: computed,
    });
  }
}
