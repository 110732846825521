export enum ESuppliers {
  NONE = '',
  ALIEXPRESS = 'Aliexpress',
  EBAY = 'Ebay',
  WEN = 'Wen',
}

export enum EShipmentReasons {
  NONE = '',
  PRIMARY = 'SHIPMENT_PRIMARY',
  PARCEL_LOST = 'SHIPMENT_PARCEL_LOST',
  ITEM_DAMAGED = 'SHIPMENT_ITEM_DAMAGED',
  ITEM_DID_NOT_FIT = 'SHIPMENT_ITEM_DID_NOT_FIT',
  PARCEL_RETURNED = 'SHIPMENT_PARCEL_RETURNED',
  WRONG_ITEM = 'SHIPMENT_WRONG_ITEM',
}
