import { FC, useEffect, useState } from 'react';

import {
  Modal,
  InputNumber,
  Select,
  Typography,
  Button,
  Popconfirm,
} from 'antd';
import { TOption } from 'common/types';
import { observer } from 'mobx-react-lite';

import styles from './refund-modal.module.scss';

export interface IRefundModalProps {
  open: boolean;
  responsibleOptions: TOption[];
  reasonOptions: TOption[];
  isLoading?: boolean;
  onOk: (sum: number, responsible: string, reason: string) => Promise<void>;
  onCancel: () => void;
}

export const RefundModal: FC<IRefundModalProps> = observer(
  ({ open, responsibleOptions, reasonOptions, isLoading, onOk, onCancel }) => {
    const [sum, setSum] = useState(0);
    const [responsible, setResponsible] = useState('');
    const [reason, setReason] = useState('');

    const isValid = !!(sum && responsible && reason);

    useEffect(() => {
      if (open) {
        setSum(0);
        setResponsible('');
        setReason('');
      }
    }, [open]);

    return (
      <Modal
        open={open}
        title="Start a refund"
        destroyOnClose
        footer={
          <>
            <Button onClick={onCancel}>Cancel</Button>
            <Popconfirm
              title="This action will decrease Grand total AND Total paid values of the order, are you sure?"
              onConfirm={() => onOk(sum, responsible, reason)}
            >
              <Button type="primary" disabled={!isValid} loading={isLoading}>
                OK
              </Button>
            </Popconfirm>
          </>
        }
        onCancel={onCancel}
      >
        <Typography.Title level={5} className={styles.warning}>
          Don’t make a custom refund for a specific item. Use purchase block or
          returns block.
        </Typography.Title>
        <div className={styles.content}>
          <div className={styles.field}>
            <Typography.Text>Sum to refund</Typography.Text>
            <InputNumber
              value={sum}
              min={0}
              prefix="$"
              className={styles.input}
              onChange={(e) => setSum(Number(e))}
            />
          </div>
          <div className={styles.field}>
            <Typography.Text>Responsible</Typography.Text>
            <Select
              value={responsible}
              options={responsibleOptions}
              className={styles.select}
              onChange={(e) => setResponsible(e)}
            />
          </div>
          <div className={styles.field}>
            <Typography.Text>Reason</Typography.Text>
            <Select
              value={reason}
              options={reasonOptions}
              className={styles.select}
              onChange={(e) => setReason(e)}
            />
          </div>
        </div>
      </Modal>
    );
  },
);
