import { FC } from 'react';

import { Spin } from 'antd';

import styles from './section.module.scss';

export interface ISectionItem {
  label: string;
  value?: string | number;
}

export interface ISectionProps {
  titles: [string, string];
  items: ISectionItem[];
  prefix?: string;
  isLoading?: boolean;
}

export const Section: FC<ISectionProps> = ({
  titles,
  items,
  prefix,
  isLoading,
}) => (
  <div className={styles.container}>
    <div className={styles.header}>
      <span>{titles[0]}</span>
      <span>{titles[1]}</span>
    </div>
    <div className={styles.content}>
      {isLoading ? (
        <Spin />
      ) : (
        <div className={styles.items}>
          {items.map((item) => (
            <div key={item.label} className={styles.item}>
              <span>{item.label}</span>
              <span className={styles.value}>{`${prefix ?? ''} ${
                item.value ?? 0
              }`}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  </div>
);
