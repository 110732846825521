import { FC } from 'react';

import { RightOutlined } from '@ant-design/icons';
import { Button, Divider, Typography } from 'antd';
import cn from 'clsx';
import { useCurrentUserStore } from 'common/providers/store-provider';
import { EReturnShipmentStatuses } from 'common/types/return-shipment/enums';
import { ConfirmButton } from 'components/ConfirmButton';
import { observer } from 'mobx-react-lite';
import { ReturnViewModel } from 'pages/NewOrder/view-models/return-view-model';

import { EditReturnModal } from '../EditReturnModal';

import { ReturnComment } from './ReturnComment';
import { ReturnDetails } from './ReturnDetails';

import styles from './return.module.scss';

export interface IReturn {
  model: ReturnViewModel;
  disabled: boolean;
  onDelete: (id: number) => Promise<void>;
}

export const Return: FC<IReturn> = observer(({ model, disabled, onDelete }) => {
  const { isSupplier } = useCurrentUserStore();

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.column}>
          <Button
            size="small"
            shape="circle"
            icon={<RightOutlined />}
            disabled={isSupplier}
            className={cn({ [styles.rotated]: model.isExpanded })}
            onClick={model.onToggleExpanded}
          />
          <Typography.Text strong>Return # {model.id}</Typography.Text>
        </div>
        <div className={styles.images}>
          {model.itemsModelsView.map((item) => (
            <img
              key={item.id}
              src={item.photo.url}
              alt={item.photo.key}
              className={styles.image}
            />
          ))}
        </div>
        <div className={styles.column}>
          <Typography.Text strong>Date: </Typography.Text>
          <Typography.Text>{model.createdAtView}</Typography.Text>
        </div>
        <div className={styles.buttons}>
          <Button onClick={model.onEditModalOpen} disabled={isSupplier}>
            Items
          </Button>
          <ConfirmButton
            confirm={{ title: 'This action cannot be undone, are you sure?' }}
            disabled={disabled || model.resolved || isSupplier}
            isLoading={model.isLoading}
            onConfirm={() => model.handleReturnDelete(onDelete)}
          >
            Delete
          </ConfirmButton>
          <ConfirmButton
            confirm={{
              title: `The client will get 70% of the order amount,
            returns: $${model.costOfEasyReturnView}`,
            }}
            isLoading={model.isLoading}
            disabled={disabled || model.resolved || isSupplier}
            button={{
              className: cn({
                [styles.highlighted]:
                  model.status ===
                  EReturnShipmentStatuses.RETURN_EASY_RETURN_FINISHED,
              }),
            }}
            onConfirm={() => model.onFinishReturn(true)}
          >
            Easy Finish
          </ConfirmButton>
          <ConfirmButton
            confirm={{
              title: `The client will get 100% of the order amount,
            returns: $${model.costOfReturnView}`,
            }}
            isLoading={model.isLoading}
            disabled={disabled || model.resolved || isSupplier}
            button={{
              className: cn({
                [styles.highlighted]:
                  model.status ===
                  EReturnShipmentStatuses.RETURN_STANDARD_RETURN_FINISHED,
              }),
            }}
            onConfirm={() => model.onFinishReturn()}
          >
            Finish
          </ConfirmButton>
        </div>
      </div>
      {model.isExpanded && (
        <div className={styles.details}>
          <ReturnDetails model={model} disabled={disabled} />
          <Divider />
          <ReturnComment model={model} />
        </div>
      )}
      <EditReturnModal
        open={model.isEditModalOpen}
        orderItems={model.root?.root?.order?.items ?? []}
        itemStats={model.root?.root?.order?.itemStats ?? []}
        returnShipment={model.returnShipment}
        isLoading={model.isLoading}
        onOk={model.onReturnItemsUpdate}
        onCancel={model.onEditModalClose}
      />
    </div>
  );
});
