import { getSupplierInvoicePDF } from 'http/supplierInvoiceApi';

import { downloadFile } from 'common/utils';

export interface IInvoice {
  id: number;
  createdAt: string;
  total: number;
}

export class InvoiceModel {
  _invoice: IInvoice;

  get id() {
    return this._invoice.id;
  }

  get createdAt() {
    return this._invoice.createdAt;
  }

  get total() {
    return this._invoice.total;
  }

  async getInvoicePDF() {
    const pdf = await getSupplierInvoicePDF(this._invoice.id);

    downloadFile(pdf, `invoice-#${this.id}.pdf`);
  }

  constructor(invoice: IInvoice) {
    this._invoice = invoice;
  }
}
