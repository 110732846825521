import { AxiosResponse } from 'axios';
import {
  IShortOrder,
  IDataWithPagination,
  IDetailedOrder,
  IOrderStatusOption,
  IPageParams,
  IRefundParams,
  TEditableUserDataParams,
} from 'common/types';
import { EPaymentCancelledStatuses } from 'common/types/order/enums';
import { TPurchaseItems } from 'common/types/purchase';

import { $host } from './index';

const headers = {
  'Content-Type': 'multipart/form-data',
};

// TODO: Remove redundant after New Order page is applied
export const getOrdersWithFilters = async (params: IPageParams) => {
  const {
    data: { data },
  } = await $host.get('admin/orders', { params });

  return data;
};

export const getOrders = async (params?: IPageParams) => {
  const {
    data: { data },
  } = await $host.get<AxiosResponse<IDataWithPagination<IShortOrder>>>(
    'admin/orders',
    {
      params: { ...{ page: 1, pageSize: 50 }, ...params },
    },
  );

  return data;
};

export const getOrder = async (id: number) => {
  const { data } = await $host.get<AxiosResponse<IDetailedOrder>>(
    `admin/orders/${id}`,
  );

  return data;
};

export const cancelOrderById = async (
  id: IDetailedOrder['id'],
  params: { reason: EPaymentCancelledStatuses | string },
) => {
  const {
    data: { data },
  } = await $host.delete<AxiosResponse<IDetailedOrder>>(`admin/orders/${id}`, {
    params,
  });

  return data;
};

export const createOrder = async (params: FormData) => {
  const { data } = await $host.post<AxiosResponse<IDetailedOrder>>(
    'admin/orders',
    params,
    {
      headers,
    },
  );

  return data.data;
};

export const editOrder = async (id: IDetailedOrder['id'], params: FormData) => {
  const { data } = await $host.put<AxiosResponse<IDetailedOrder>>(
    `admin/orders/${id}`,
    params,
    {
      headers,
    },
  );

  return data.data;
};

export const changeOrderManager = async (
  orderId: number,
  managerEmail: string,
  managerId?: number,
) => {
  const { data } = await $host.patch<AxiosResponse<IShortOrder>>(
    `admin/orders/${orderId}/responsible`,
    null,
    {
      params: {
        id: managerId,
        email: managerEmail,
      },
    },
  );

  return data.data;
};

export const changeOrderComment = async (orderId: number, comment: string) => {
  const { data } = await $host.put<AxiosResponse<IShortOrder>>(
    `admin/orders/${orderId}/comment`,
    {
      comment: comment,
    },
  );

  return data.data;
};

export const changeOrderStatus = async (
  orderId: number,
  status: string | string[],
  statusType: string,
) => {
  const { data } = await $host.put<AxiosResponse<IShortOrder>>(
    `admin/orders/${orderId}/status`,
    null,
    {
      params: {
        [statusType]: status,
      },
    },
  );

  return data.data;
};

export const changeOrderFees = async (orderId: number, fees: number) => {
  const { data } = await $host.put<AxiosResponse<IDetailedOrder>>(
    `admin/orders/${orderId}/fees`,
    null,
    {
      params: {
        fees,
      },
    },
  );

  return data.data;
};

export const createRefund = async (id: number, params: IRefundParams) => {
  const {
    data: { data },
  } = await $host.post<AxiosResponse<IDetailedOrder>>(
    `admin/orders/${id}/refund`,
    params,
  );

  return data;
};

export const createInvoice = async (
  id: number,
  params: { items: TPurchaseItems },
) => {
  const {
    data: { data },
  } = await $host.post<AxiosResponse<IDetailedOrder>>(
    `admin/orders/${id}/invoice`,
    params,
  );

  return data;
};

export const changeUserData = async (
  id: number,
  params: TEditableUserDataParams,
) => {
  const {
    data: { data },
  } = await $host.put<AxiosResponse<IDetailedOrder>>(
    `admin/orders/${id}/user-data`,
    params,
  );

  return data;
};

export const getOrderStatusOptions = async () => {
  const { data } = await $host.get<AxiosResponse<IOrderStatusOption[]>>(
    '/admin/orders/filter-autocomplete',
  );

  return data;
};

export const recalculateOrderCost = async (orderId: number) => {
  const { data } = await $host.post(
    `/admin/orders/${orderId}/recalculate-cost`,
  );

  return data;
};
