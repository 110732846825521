import {
  createPromocode,
  deleteById,
  editPromocode,
  getAllPromocodes,
} from 'http/promocodeApi';

import { TTablePaginationParams } from 'common/types';
import { TPromoParams } from 'common/types/promocode';
import { notify } from 'common/utils';
import { computed, makeAutoObservable, observable } from 'mobx';
import { FiltersModel } from 'store/filters-model';
import { PromocodeModel } from 'store/promocode-model';

export class PromopcodeViewModel {
  _loading = false;
  _promocodes: PromocodeModel[] = [];
  _filters: FiltersModel = new FiltersModel();
  _count = 0;

  get loading() {
    return this._loading;
  }

  set loading(value: boolean) {
    this._loading = value;
  }

  get promocodes() {
    return this._promocodes;
  }

  set promocodes(promocodes: PromocodeModel[]) {
    this._promocodes = promocodes;
  }

  get skeleton() {
    return new Array(8).fill(new PromocodeModel());
  }

  get count() {
    return this._count;
  }

  fetchPromocodes = async () => {
    this.loading = true;

    const { count, promos } = await getAllPromocodes();

    this._promocodes = promos.map(
      (promocode) => new PromocodeModel(promocode.id, promocode),
    );
    this._count = count;

    this.loading = false;
  };

  removePromocode = async (id: number) => {
    this.loading = true;

    const updatedPromocodes = this._promocodes.filter(
      (promocode) => promocode.id !== id,
    );

    try {
      const result = await deleteById(id);

      if (result) {
        this.promocodes = updatedPromocodes;

        notify(
          <div>
            Promocode <strong>{result.promocode}</strong> was removed
            succesfully!
          </div>,
        );
      }
    } catch (error) {
      notify('Failed to remove Promocode, see console for details', 'error');

      console.error('Error:', error);
    } finally {
      this.loading = false;
    }
  };

  async createPromocode(params: TPromoParams) {
    this.loading = true;

    try {
      const newPromocode = await createPromocode(params);

      if (newPromocode) {
        this.promocodes = [
          ...this.promocodes,
          new PromocodeModel(newPromocode.id, newPromocode),
        ];

        notify(
          <span>
            Promocode <strong>'{newPromocode.promocode}'</strong> was created
            succesfully!
          </span>,
        );
      }
    } catch (error) {
      notify('Failed to create Promocode, see console for details', 'error');

      console.error('Error:', error);
    } finally {
      this.loading = false;
    }
  }

  async changePromocode(promoId: number, params: TPromoParams) {
    this.loading = true;

    try {
      const newPromocode = await editPromocode(promoId, params);

      if (newPromocode) {
        this._promocodes = this.promocodes.map((el) => {
          if (el.id === newPromocode.id) {
            return new PromocodeModel(newPromocode.id, newPromocode);
          }

          return el;
        });

        notify(
          <span>
            Promocode <strong>'{newPromocode.promocode}'</strong> was changed
            succesfully!
          </span>,
        );
      }
    } catch (error) {
      notify('Failed to change Promocode, see console for details', 'error');

      console.error('Error:', error);
    } finally {
      this.loading = false;
    }
  }

  async refetchPromocodes(pagination: TTablePaginationParams) {
    const { current } = pagination;
    const { pageSize } = this._filters;

    const params = {
      page: current ? current : 1,
      pageSize: pageSize,
    };

    this.loading = true;

    try {
      const { count, promos } = await getAllPromocodes(params);

      this._promocodes = promos.map(
        (promocode) => new PromocodeModel(promocode.id, promocode),
      );
      this._count = count;

      return promos;
    } catch (e) {
      notify(`Error, reason:${e}`, 'error');
    } finally {
      this.loading = false;
    }
  }

  constructor() {
    this.fetchPromocodes();

    makeAutoObservable(this, {
      _loading: observable,
      _promocodes: observable,
      _count: observable,

      loading: computed,
    });
  }
}
