import { changeShipmentVariations, editShipment } from 'http/shipments';

import {
  IServerError,
  IShipment,
  IShipmentItemWithControlAttrs,
} from 'common/types';
import {
  cents2dollars,
  combineString,
  dollars2cents,
  notify,
  parseToLongDate,
  reduceOrderItems,
  removeStatusPrefix,
  upperUndescoreToRegularCase,
} from 'common/utils';
import { makeAutoObservable } from 'mobx';

import { ShipmentItemModel } from '../models/shipment-item-model';

import { ShipmentsViewModel } from './shipments-view-model';

export class ShipmentViewModel {
  _root: ShipmentsViewModel | null = null;
  _isLoading = false;
  _isExpanded = false;
  _isEditing = false;
  _isEditModalOpen = false;
  _shipment: IShipment;

  get root() {
    return this._root;
  }

  get orderViewModel() {
    return this.root?.root?.root;
  }

  get isLoading() {
    return this._isLoading;
  }

  set isLoading(isLoading: boolean) {
    this._isLoading = isLoading;
  }

  get isExpanded() {
    return this._isExpanded;
  }

  set isExpanded(isExpanded: boolean) {
    this._isExpanded = isExpanded;
  }

  get isEditing() {
    return this._isEditing;
  }

  set isEditing(isEditing: boolean) {
    this._isEditing = isEditing;
  }

  get isEditModalOpen() {
    return this._isEditModalOpen;
  }

  set isEditModalOpen(isOpen: boolean) {
    this._isEditModalOpen = isOpen;
  }

  get shipment() {
    return this._shipment;
  }

  set shipment(shipment: IShipment) {
    this._shipment = shipment;
  }

  get id() {
    return this.shipment.id;
  }

  get createdAt() {
    return this.shipment.createdAt;
  }

  get createdAtView() {
    return parseToLongDate(this.shipment.createdAt);
  }

  get items() {
    return this.shipment.items;
  }

  get itemsModels() {
    return this.items.map((item) => new ShipmentItemModel(item));
  }

  get itemsModelsView() {
    return this.itemsModels.slice(0, 5);
  }

  get supplier() {
    return this.shipment.supplier ?? { id: 0, name: '' };
  }

  get supplierView() {
    return this.supplier.user
      ? combineString([
          this.supplier.user.firstName,
          this.supplier.user.lastName,
        ])
      : this.supplier.name;
  }

  get reason() {
    return this.shipment.reason;
  }

  get reasonView() {
    return upperUndescoreToRegularCase(
      removeStatusPrefix(this.shipment.reason, 'SHIPMENT_'),
    );
  }

  get trackingNumber() {
    return this.shipment.trackingNumber;
  }

  get trackingStatus() {
    return this.shipment.trackingStatus;
  }

  get supId() {
    return this.shipment.supId;
  }

  get costOfPurchase() {
    return this.shipment.costOfPurchase;
  }

  get costOfPurchaseView() {
    return cents2dollars(this.shipment.costOfPurchase);
  }

  get costOfShipping() {
    return this.shipment.costOfShipping;
  }

  get costOfShippingView() {
    return cents2dollars(this.shipment.costOfShipping);
  }

  get comment() {
    return this.shipment.comment;
  }

  onToggleExpanded = () => {
    this.isExpanded = !this.isExpanded;
  };

  onToggleEditing = () => {
    this.isEditing = !this.isEditing;
  };

  onEditModalOpen = () => {
    this.isEditModalOpen = true;
  };

  onEditModalClose = () => {
    this.isEditModalOpen = false;
  };

  onShipmentUpdate = async (values: Record<string, any>) => {
    try {
      this.isLoading = true;

      const formattedParams = {
        ...values,
        costOfPurchase: dollars2cents(values.costOfPurchase),
        costOfShipping: dollars2cents(values.costOfShipping),
      };

      await editShipment(this.id, formattedParams);
      this.orderViewModel && (await this.orderViewModel.syncOrderData());

      this.isEditing = false;
    } catch (e) {
      const error = e as IServerError;

      notify(error.response.data.message, 'error');
    } finally {
      this.isLoading = false;
    }
  };

  onShipmentItemsUpdate = async (
    shipmentItems: IShipmentItemWithControlAttrs[],
  ) => {
    try {
      this.isLoading = true;

      const formattedItems = reduceOrderItems(shipmentItems);

      await changeShipmentVariations(this.id, {
        items: formattedItems,
      });

      this.orderViewModel && (await this.orderViewModel.syncOrderData());
      this.isEditModalOpen = false;
    } catch (e) {
      const error = e as IServerError;

      notify(error.response.data.message, 'error');
    } finally {
      this.isLoading = false;
    }
  };

  handleShipmentDelete = async (onDelete: (id: number) => Promise<void>) => {
    try {
      this.isLoading = true;

      await onDelete(this.id);
    } catch (e) {
      const error = e as IServerError;

      notify(error.response.data.message, 'error');
    } finally {
      this.isLoading = false;
    }
  };

  constructor(root: ShipmentsViewModel | null, shipment: IShipment) {
    if (root) {
      this._root = root;
    }

    this._shipment = shipment;

    makeAutoObservable(this);
  }
}
