// TODO: handle this mess
import { ChangeEvent, FC, useEffect, useState } from 'react';

import { Button, Input, Modal, Select } from 'antd';
import { defaultPhoto, shipmentReasonDefaultOptions } from 'common/constants';
import { TOption } from 'common/types';
import { EShipmentReasons, ESuppliers } from 'common/types/shipment/enums';
import { observer } from 'mobx-react-lite';
import { PurchaseViewModel } from 'pages/NewOrder/view-models/purchase-view-model';

import { IPurchaseWithSelected } from '../../../types';
import { PackageModalItem } from '../../PackageModalItem';

import styles from './add-shipment-modal.module.scss';

interface IAddShipmentModalProps {
  open: boolean;
  purchases: PurchaseViewModel[];
  supplierOptions: TOption[];
  isLoading?: boolean;
  onOk: (
    supplier: ESuppliers,
    reason: EShipmentReasons,
    supId: string,
    costOfPurchase: number,
    costOfShipping: number,
    shipments: IPurchaseWithSelected[],
  ) => Promise<void>;
  onCancel: () => void;
}

const getFormattedPurchases = (
  purchases: PurchaseViewModel[],
): IPurchaseWithSelected[] =>
  purchases.map((purchase) => ({
    ...purchase.purchase,
    id: purchase.id,
    productKit: purchase.productKit,
    title: purchase.title,
    quantity: purchase.quantity,
    selectedQuantity: 1,
    selected: false,
  }));

const getShipmentModalItemProps = (purchase: IPurchaseWithSelected) => {
  const title = purchase.productKit
    ? `${purchase.title} - KIT №${purchase.productKit.id}`
    : purchase.title;

  const photo =
    purchase.variation.content.photos?.find(
      (photo) => photo.id === purchase.variation.content.mainPhotoId,
    ) || defaultPhoto;

  const disabled = { input: !purchase.selected };

  return { title, photo, disabled };
};

export const AddShipmentModal: FC<IAddShipmentModalProps> = observer(
  ({ open, purchases, supplierOptions, isLoading, onOk, onCancel }) => {
    const [supplier, setSupplier] = useState<ESuppliers | null>(null);
    const [shipmentReason, setShipmentReason] =
      useState<EShipmentReasons | null>(null);
    const [supId, setSupId] = useState('');
    const [costOfPurchase, setCostOfPurchase] = useState('');
    const [costOfShipping, setCostOfShipping] = useState('');

    const [formattedPurchases, setFormattedPurchases] = useState<
      IPurchaseWithSelected[]
    >([]);

    const isCostOfPurchaseValid =
      !isNaN(+costOfPurchase) && +costOfPurchase >= 0;

    const isCostOfShippingValid =
      !isNaN(+costOfShipping) && +costOfShipping >= 0;

    const canAdd = !(
      formattedPurchases.filter((purchase) => purchase.selected).length &&
      !!supplier &&
      !!shipmentReason &&
      !!isCostOfPurchaseValid &&
      !!isCostOfShippingValid
    );

    const onSupplierChange = (supplier: ESuppliers) => setSupplier(supplier);
    const onSupIdChange = (e: ChangeEvent<HTMLInputElement>) =>
      setSupId(e.target.value);

    const onShipmentReasonChange = (reason: EShipmentReasons) =>
      setShipmentReason(reason);

    const onCostOfPurchaseChange = (e: ChangeEvent<HTMLInputElement>) =>
      setCostOfPurchase(e.target.value);

    const onCostOfShippingChange = (e: ChangeEvent<HTMLInputElement>) =>
      setCostOfShipping(e.target.value);

    const toggleSelectAll = (selected: boolean) => {
      const selectedProduct = formattedPurchases.map((purchase) => ({
        ...purchase,
        selected: selected,
        selectedQuantity: selected ? purchase.quantity : 1,
      }));

      setFormattedPurchases(selectedProduct);
    };

    const onSelectedChange = (id: number) => {
      const updatedPurchases = formattedPurchases.map((purchase) => {
        if (purchase.id === id) {
          return { ...purchase, selected: !purchase.selected };
        }

        return purchase;
      });

      setFormattedPurchases(updatedPurchases);
    };

    const onQuantityChange = (id: number, value: number | null) => {
      const updatedPurchases = formattedPurchases.map((purchase) => {
        if (purchase.id === id) {
          return { ...purchase, selectedQuantity: Number(value) };
        }

        return purchase;
      });

      setFormattedPurchases(updatedPurchases);
    };

    const handleAdd = async () => {
      const selected = formattedPurchases.filter((el) => el.selected);

      await onOk(
        supplier as ESuppliers,
        shipmentReason as EShipmentReasons,
        supId,
        +costOfPurchase || 0,
        +costOfShipping || 0,
        selected,
      );
    };

    useEffect(() => {
      if (purchases.length && open) {
        const formattedValues = getFormattedPurchases(purchases);

        setSupplier(null);
        setShipmentReason(null);
        setSupId('');
        setCostOfPurchase('');
        setCostOfShipping('');
        setFormattedPurchases(formattedValues);
      }
    }, [purchases, open]);

    return (
      <Modal
        open={open}
        title="Add new Shipment"
        okButtonProps={{ disabled: canAdd, loading: isLoading }}
        onOk={handleAdd}
        onCancel={onCancel}
      >
        <div className={styles.selectorWrapper}>
          {!!formattedPurchases.filter((el) => !el.selected).length && (
            <Button onClick={() => toggleSelectAll(true)}>Select all</Button>
          )}
          {formattedPurchases.filter((el) => el.selected).length ===
            formattedPurchases.length && (
            <Button onClick={() => toggleSelectAll(false)}>Unselect all</Button>
          )}
          <Select
            placeholder="Supplier"
            options={supplierOptions}
            value={supplier}
            onChange={onSupplierChange}
          />
          <Select
            placeholder="Reason"
            options={shipmentReasonDefaultOptions}
            value={shipmentReason}
            onChange={onShipmentReasonChange}
          />
          <Input
            value={supId}
            placeholder="Supplier id"
            className={styles.input}
            onChange={onSupIdChange}
          />
          <Input
            value={costOfPurchase}
            placeholder="Cost of purchase"
            prefix="$"
            className={styles.input}
            onChange={onCostOfPurchaseChange}
          />
          <Input
            value={costOfShipping}
            placeholder="Cost of shipping"
            prefix="$"
            className={styles.input}
            onChange={onCostOfShippingChange}
          />
        </div>
        <div className={styles.productList}>
          {formattedPurchases.map((purchase) => {
            const { title, photo, disabled } =
              getShipmentModalItemProps(purchase);

            return (
              <PackageModalItem
                key={purchase.id}
                title={title}
                photo={photo}
                selected={purchase.selected}
                quantity={purchase.selectedQuantity}
                min={1}
                max={purchase.quantity}
                disabled={disabled}
                onSelect={() => onSelectedChange(purchase.id)}
                onChange={(value) => onQuantityChange(purchase.id, value)}
              />
            );
          })}
        </div>
      </Modal>
    );
  },
);
