import { FC, useState } from 'react';

import { RightOutlined } from '@ant-design/icons';
import { Button, Divider, Popconfirm } from 'antd';
import clsx from 'clsx';
import { IReturnShipmentItemWithControlAttrs } from 'common/types';
import { EReturnShipmentStatuses } from 'common/types/return-shipment/enums';
import { cents2dollars, parseDate, useModal } from 'common/utils';
import { observer } from 'mobx-react-lite';
import { ReturnShipmentModel } from 'store/return-shipment-model';

import { OrderExpandedViewModel } from '../../../order-expanded-view-model';
import { EditReturnShipmentModal } from '../EditReturnShipmentModal';

import { CommentSection } from './CommentSection';
import { InfoSection } from './InfoSection';

import styles from './return-item.module.scss';

interface IReturnItemProps {
  returnShipment: ReturnShipmentModel;
  model: OrderExpandedViewModel;
}

const ButtonWithConfirm: FC<{
  warnText: string;
  buttonText: string;
  highlighted?: boolean;
  disabled?: boolean;
  handleAction: () => void;
}> = ({ warnText, buttonText, handleAction, highlighted, disabled }) => {
  if (highlighted && disabled) {
    return (
      <Button className={styles.highlighted} disabled={disabled}>
        {buttonText}
      </Button>
    );
  }

  return (
    <Popconfirm
      placement="left"
      destroyTooltipOnHide
      rootClassName={styles.actions}
      title={<p className={styles.popconfirmMessage}>{warnText}</p>}
      disabled={disabled}
      onConfirm={handleAction}
    >
      <Button disabled={disabled}>{buttonText}</Button>
    </Popconfirm>
  );
};

export const ReturnShipment: FC<IReturnItemProps> = observer(
  ({ returnShipment, model }) => {
    const [openItemModal, handleItemModalOpen, handleItemModalClose] =
      useModal(false);
    const [isExpanded, setExpanded] = useState(false);

    const isCancelled = model.order.isCancelled;
    const isOngoingDispute = model.order.isOngoingDispute;
    const isResolved = returnShipment.resolved;
    const status = returnShipment.status;

    const easyCostOfReturn = cents2dollars(
      Math.round(returnShipment.costOfReturn * 0.7),
    );
    const costOfReturn = cents2dollars(Math.round(returnShipment.costOfReturn));

    const handleManageItems = () => {
      handleItemModalOpen();
    };

    const toggleExpanded = () => {
      setExpanded(!isExpanded);
    };

    const handleEasyFinish = () => {
      model.finishReturn(returnShipment.id, true);
    };

    const handleFinish = () => {
      model.finishReturn(returnShipment.id);
    };

    const handleDelete = () => {
      model.deleteReturn(returnShipment.id);
    };

    const handleChange = (
      returnItems: IReturnShipmentItemWithControlAttrs[],
    ) => {
      model.changeReturn(
        returnShipment.id,
        returnItems,
        returnShipment.responsible,
        returnShipment.reason,
      );
    };

    return (
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.column}>
            <div className={styles.row}>
              <div className={styles.info}>
                <RightOutlined
                  className={clsx(styles.icon, {
                    [styles.isExpanded]: isExpanded,
                    [styles.isNotExpanded]: !isExpanded,
                  })}
                  onClick={toggleExpanded}
                />
                <span className={styles.title}>
                  Return # {returnShipment.id}
                </span>
                <div className={styles.items}>
                  {returnShipment.items.map((item) => (
                    <img
                      key={item.photo.id}
                      src={item.photo.url}
                      alt={item.photo.key}
                      className={styles.item}
                    />
                  ))}
                </div>
              </div>
              <div className={styles.controls}>
                <div>
                  <span className={styles.date}>Creation date: </span>
                  <span>{parseDate(returnShipment.createdAt)}</span>
                </div>
                <Button
                  onClick={handleManageItems}
                  disabled={isCancelled || isOngoingDispute}
                >
                  Items
                </Button>
                <ButtonWithConfirm
                  warnText={`The client will get 70% of the order amount,
                          returns: $${easyCostOfReturn}`}
                  highlighted={
                    status ===
                    EReturnShipmentStatuses.RETURN_EASY_RETURN_FINISHED
                  }
                  disabled={isCancelled || isResolved || isOngoingDispute}
                  handleAction={handleEasyFinish}
                  buttonText="Easy finish"
                />
                <ButtonWithConfirm
                  warnText={`The client will get 100% of the order amount,
                        returns: $${costOfReturn}`}
                  highlighted={
                    status ===
                    EReturnShipmentStatuses.RETURN_STANDARD_RETURN_FINISHED
                  }
                  disabled={isCancelled || isResolved || isOngoingDispute}
                  handleAction={handleFinish}
                  buttonText="Finish"
                />
                <ButtonWithConfirm
                  warnText="This action cannot be undone, you're sure?"
                  disabled={isCancelled || isResolved || isOngoingDispute}
                  handleAction={handleDelete}
                  buttonText="Delete"
                />
              </div>
            </div>
          </div>
        </div>
        {isExpanded && (
          <div className={styles.expandable}>
            <InfoSection
              returnItem={returnShipment}
              disabled={isCancelled || isResolved || isOngoingDispute}
              supplierOptions={model.supplierOptions}
              responsibleOptions={model.defaultResponsibleOptions}
              handleEditReturn={model.changeReturnFields}
            />
            <Divider />
            <CommentSection
              returnItem={returnShipment}
              disabled={isCancelled || isOngoingDispute}
              handleEditComment={model.editReturnComment}
            />
          </div>
        )}
        <EditReturnShipmentModal
          openEditModal={openItemModal}
          handleEditModalClose={handleItemModalClose}
          orderItems={model.order.items}
          itemStats={model.itemStats}
          returnShipment={returnShipment}
          handleChange={handleChange}
        />
      </div>
    );
  },
);
