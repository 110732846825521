import { changeOrderFees } from 'http/orderApi';

import { ILongOrder, IServerError } from 'common/types';
import { cents2dollars, dollars2cents, notify } from 'common/utils';
import { makeAutoObservable } from 'mobx';

import { ExpandedOrderViewModel } from './expanded-order-view-model';

export interface ISummary {
  items: number;
  fees: number;
  discount: number;
  refunded: number;
  subtotal: number;
  shipping: number;
  grandTotal: number;
  totalPaid: number;
}

export class SummaryViewModel {
  _root: ExpandedOrderViewModel | null = null;
  _isLoading = false;
  _summary: ISummary;
  _fees: number;

  get root() {
    return this._root;
  }

  get orderViewModel() {
    return this.root?.root;
  }

  get orderModel() {
    return this.root?.order;
  }

  get disabled() {
    return !!this.root?.order?.disabled;
  }

  get isLoading() {
    return this._isLoading;
  }

  set isLoading(isLoading: boolean) {
    this._isLoading = isLoading;
  }

  get summary() {
    return this._summary;
  }

  set summary(summary: ISummary) {
    this._summary = summary;
  }

  get fees() {
    return this._fees;
  }

  set fees(fees: number) {
    this._fees = fees;
  }

  syncOrderData = (order: ILongOrder) => {
    this.summary = this.getSummary(order);
    this.fees = this.summary.fees;
  };

  getSummary = (order: ILongOrder) => {
    const items = order.items.reduce(
      (acc: number, el) => (acc = acc + el.quantity),
      0,
    );

    return {
      items: items,
      fees: cents2dollars(order?.efficiency?.fees ?? 0),
      discount: cents2dollars(order.discount),
      refunded: cents2dollars(order.refunded),
      subtotal: cents2dollars(order.totalItemFinal),
      shipping: cents2dollars(order.shippingFinal),
      grandTotal: cents2dollars(order.totalPriceFinal),
      totalPaid: cents2dollars(order.amountPaid),
    };
  };

  onFeesChange = (fees: number) => (this.fees = fees);

  onFeesUpdate = async (fees: number) => {
    const orderId = this.orderViewModel?.shortOrder.id;

    if (!orderId) {
      return;
    }

    try {
      this.isLoading = true;

      const order = await changeOrderFees(orderId, dollars2cents(+fees));

      this.orderViewModel && (await this.orderViewModel.syncOrderData());

      this.summary = this.getSummary(order as ILongOrder);
    } catch (e) {
      const error = e as IServerError;

      notify(error?.response?.data?.message, 'error');
    } finally {
      this.isLoading = false;
    }
  };

  constructor(root: ExpandedOrderViewModel | null, order: ILongOrder) {
    if (root) {
      this._root = root;
    }

    this._summary = this.getSummary(order);
    this._fees = this._summary.fees;

    makeAutoObservable(this);
  }
}
