import { FC } from 'react';

import { Select, Skeleton, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { SortOrder } from 'antd/es/table/interface';
import cn from 'clsx';
import { disputeOptions, todoOptions } from 'common/constants';
import {
  IBrand,
  IShortOrder,
  TTablePaginationParams,
  OrderTableColumn,
} from 'common/types';
import { EReturnStatusEnum } from 'common/types/order/enums';
import {
  cents2dollars,
  removeStatusPrefix,
  sortByDate,
  upperUndescoreToRegularCase,
} from 'common/utils';
import { Table, text } from 'components/Table';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';

import { OrderTableRowViewModel } from './order-table-row-view-model';
import { OrderViewModel } from './order-view-model';
import { OrderExpandedContent } from './OrderExpandedContent';
import TableFilter from './TableFilter';

import styles from './styles.module.scss';

const dropdownStyle = { minWidth: '220px' };

const skeletonPlaceholder = (
  loading: boolean,
  value: number | string,
  convert = true,
) => {
  if (loading) {
    return (
      <Skeleton active paragraph={{ rows: 1 }} className={styles.skeleton} />
    );
  }

  if (typeof value === 'number') {
    return <span>{convert ? cents2dollars(value) : value.toFixed(2)}</span>;
  }
  return <span>{value}</span>;
};

export interface IOrderProps {
  model: OrderViewModel;
}

const Order: FC<IOrderProps> = observer(({ model }) => {
  const handleChangeManager = async (
    orderId: OrderTableColumn['id'],
    managerEmail: string,
  ) => {
    model.changeManager(orderId, managerEmail);
  };

  const handleChangeStatus = async (
    status: string,
    orderId: OrderTableColumn['id'],
    statusType: string,
  ) => {
    model.changeOrderStatus(status, orderId, statusType);
  };

  const handleChangeMultiple = (
    values: string[],
    orderId: OrderTableColumn['id'],
    statusType: string,
  ) => {
    model.changeOrderStatus(values, orderId, statusType);
  };

  const handleChangeTable = (pagination: TTablePaginationParams) => {
    const { current } = pagination;
    model.filters.page = current;

    model.refetchOrders();
  };

  const onSearch = (value: string) => {
    model.filters.search = value;
    model.filters.page = 1;

    model.refetchOrders();
  };

  const onSearchEmail = (value: string) => {
    model.filters.buyerEmail = value;
    model.filters.page = 1;

    model.refetchOrders();
  };

  const handleStatusFilterChange = (values: string[]) => {
    model.statusFilter = values;
    model.filters.page = 1;

    model.refetchOrders();
  };

  const handleDateFilterChange = (value: string) => {
    model.filters.setSortOptions('createdAt', value);
    model.filters.page = 1;

    model.refetchOrders();
  };

  const columns: ColumnsType<OrderTableRowViewModel> = [
    text({
      width: '120px',
      title: 'DATE',
      dataIndex: ['order', 'createdAt'],
      defaultSortOrder: 'descend' as SortOrder | undefined,
      sorter: sortByDate<keyof IBrand['updatedAt']>('updatedAt'),
      loading: model.loading,
      key: 'createdAt',
    }),
    text({
      width: '220px',
      title: 'MANAGER',
      dataIndex: ['order', 'manager'],
      defaultSortOrder: 'descend' as SortOrder | undefined,
      loading: model.loading,
      key: 'manager',
      render: (manager: IShortOrder['manager'], record) =>
        model.loading ? (
          skeletonPlaceholder(model.loading, 'manager')
        ) : (
          <Select
            defaultValue={manager?.email}
            options={model.managerOptions}
            size="small"
            placeholder="Select manager"
            className={styles.select}
            disabled={record.order.isCancelled}
            allowClear
            onChange={(value) => handleChangeManager(record.order.id, value)}
          />
        ),
    }),
    text({
      width: '220px',
      title: 'TO DO',
      dataIndex: ['order', 'todo'],
      defaultSortOrder: 'descend' as SortOrder | undefined,
      loading: model.loading,
      key: 'todo',
      render: (todo: IShortOrder['todo'], record) => {
        return model.loading ? (
          skeletonPlaceholder(model.loading, 'todo')
        ) : (
          <Select
            mode="multiple"
            size="small"
            placeholder="Select event"
            defaultValue={todo}
            onChange={(e) => handleChangeMultiple(e, record.order.id, 'todo')}
            options={todoOptions}
            disabled={record.order.isCancelled}
            className={styles.select}
          />
        );
      },
    }),
    text({
      width: '120px',
      title: 'ORDER №',
      dataIndex: ['order', 'publicId'],
      defaultSortOrder: 'descend' as SortOrder | undefined,
      loading: model.loading,
      key: 'orderNumber',
    }),
    text({
      width: '120px',
      title: 'BUYER',
      dataIndex: ['order', 'buyer'],
      defaultSortOrder: 'descend' as SortOrder | undefined,
      loading: model.loading,
      key: 'buyer',
    }),
    text({
      width: '100px',
      title: 'TOTAL',
      dataIndex: ['order', 'totalPriceFinal'],
      defaultSortOrder: 'descend' as SortOrder | undefined,
      loading: model.loading,
      key: 'totalPriceFinal',
      render: (total: IShortOrder['totalPriceFinal']) =>
        skeletonPlaceholder(model.loading, total),
    }),
    text({
      width: '240px',
      title: 'PAYMENT',
      dataIndex: ['order', 'payment'],
      defaultSortOrder: 'descend' as SortOrder | undefined,
      loading: model.loading,
      key: 'payment',
      render: (payment: IShortOrder['payment']) =>
        model.loading ? (
          skeletonPlaceholder(model.loading, payment ?? '')
        ) : (
          <Tag
            color={payment?.match(/(CANCELLED)/i) ? 'red' : '#adda81'}
            className={styles.tag}
          >
            {upperUndescoreToRegularCase(
              removeStatusPrefix(payment ?? '', 'PAYMENT_'),
            )}
          </Tag>
        ),
    }),
    text({
      width: '240px',
      title: 'REFUND',
      dataIndex: ['order', 'refund'],
      defaultSortOrder: 'descend' as SortOrder | undefined,
      loading: model.loading,
      key: 'refund',
      render: (refunds: IShortOrder['refund']) => {
        return model.loading ? (
          skeletonPlaceholder(model.loading, 'refund')
        ) : (
          <div className={styles.refundWrapper}>
            {refunds.map((refund) => (
              <Tag color="#c8453c" className={styles.tag}>
                {upperUndescoreToRegularCase(
                  removeStatusPrefix(refund, 'REFUND_'),
                )}
              </Tag>
            ))}
          </div>
        );
      },
    }),
    text({
      width: '150px',
      title: 'PURCHASING',
      dataIndex: ['order', 'purchasing'],
      defaultSortOrder: 'descend' as SortOrder | undefined,
      loading: model.loading,
      key: 'purchasing',
      render: (purchasing: IShortOrder['purchasing']) =>
        model.loading ? (
          skeletonPlaceholder(model.loading, purchasing ?? '')
        ) : (
          <Tag color="#B6D7A8" className={styles.tag}>
            {upperUndescoreToRegularCase(
              removeStatusPrefix(purchasing ?? '', 'PURCHASE_'),
            )}
          </Tag>
        ),
    }),
    text({
      width: '150px',
      title: 'SENDING',
      dataIndex: ['order', 'sending'],
      defaultSortOrder: 'descend' as SortOrder | undefined,
      loading: model.loading,
      key: 'sending',
      render: (sending: IShortOrder['sending']) =>
        model.loading ? (
          skeletonPlaceholder(model.loading, sending ?? '')
        ) : (
          <Tag color="blue" className={styles.tag}>
            {upperUndescoreToRegularCase(
              removeStatusPrefix(sending ?? '', 'SENDING_'),
            )}
          </Tag>
        ),
    }),
    text({
      width: '150px',
      title: 'DELIVERY',
      dataIndex: ['order', 'delivery'],
      defaultSortOrder: 'descend' as SortOrder | undefined,
      loading: model.loading,
      key: 'delivery',
      render: (delivery: IShortOrder['delivery']) =>
        model.loading ? (
          skeletonPlaceholder(model.loading, String(delivery))
        ) : (
          <Tag color="#BA55D3" className={styles.tag}>
            {upperUndescoreToRegularCase(
              delivery ? removeStatusPrefix(delivery, 'DELIVERY_') : 'None',
            )}
          </Tag>
        ),
    }),
    text({
      width: '240px',
      title: 'RETURN',
      dataIndex: ['order', 'return'],
      defaultSortOrder: 'descend' as SortOrder | undefined,
      loading: model.loading,
      key: 'return',
      render: (returnStatus: EReturnStatusEnum) =>
        model.loading ? (
          skeletonPlaceholder(model.loading, String(returnStatus))
        ) : (
          <Tag color="gold" className={styles.tag}>
            {upperUndescoreToRegularCase(
              removeStatusPrefix(returnStatus, 'RETURN_'),
            )}
          </Tag>
        ),
    }),
    text({
      width: '240px',
      title: 'DISPUTE',
      dataIndex: ['order', 'dispute'],
      defaultSortOrder: 'descend' as SortOrder | undefined,
      loading: model.loading,
      key: 'dispute',
      render: (dispute: IShortOrder['dispute'], record) => {
        return model.loading ? (
          skeletonPlaceholder(model.loading, dispute)
        ) : (
          <div className={cn(styles.disputeWrapper)}>
            <Select
              size="small"
              defaultValue={upperUndescoreToRegularCase(
                removeStatusPrefix(dispute, 'DISPUTE_'),
              )}
              dropdownStyle={dropdownStyle}
              onChange={(e) =>
                handleChangeStatus(e, record.order.id, 'disputeStatus')
              }
              options={disputeOptions}
              className={styles.select}
            />
          </div>
        );
      },
    }),
    text({
      width: '150px',
      title: 'RESHIPMENTS',
      dataIndex: ['order', 'reshipments'],
      defaultSortOrder: 'descend' as SortOrder | undefined,
      loading: model.loading,
      key: 'reshipments',
      render: (reshipments: IShortOrder['reshipments']) =>
        model.loading
          ? skeletonPlaceholder(model.loading, String(reshipments))
          : reshipments?.map((el) => (
              <Tag color="#8d8d8d" className={styles.tag}>
                {upperUndescoreToRegularCase(
                  removeStatusPrefix(el, 'SHIPMENT_'),
                )}
              </Tag>
            )),
    }),
    text({
      width: '150px',
      title: 'SUPPLIER',
      dataIndex: ['order', 'supplier'],
      defaultSortOrder: 'descend' as SortOrder | undefined,
      loading: model.loading,
      key: 'supplier',
      render: (supplier: IShortOrder['supplier']) =>
        model.loading
          ? skeletonPlaceholder(model.loading, String(supplier))
          : supplier?.map((el) => (
              <Tag color="peru" className={styles.tag}>
                {upperUndescoreToRegularCase(el)}
              </Tag>
            )),
    }),
  ];

  // subscribe to deep changes
  toJS(model.orderTableRowViewModels);

  return (
    <>
      <TableFilter
        isLoading={model.loading}
        getOrderStatusOptions={model.getOrderStatusOptions}
        onSearch={onSearch}
        onSearchEmail={onSearchEmail}
        onStatusChange={handleStatusFilterChange}
        onDateChange={handleDateFilterChange}
      />
      <Table
        bordered
        pagination={{
          total: model.filters.count,
          disabled: model.loading,
          current: model.filters.page,
        }}
        loading={model.loading}
        skeleton={model.skeleton}
        data={model.orderTableRowViewModels}
        columns={columns}
        expandable={{
          expandedRowRender: (record: OrderTableRowViewModel) => (
            <OrderExpandedContent
              key={record.order.id}
              id={record.order.id}
              model={record}
            />
          ),
        }}
        onChange={handleChangeTable}
      />
    </>
  );
});

export const OrderPage = () => {
  const model = new OrderViewModel();

  return <Order model={model} />;
};
