import { FC } from 'react';

import { Modal } from 'antd';
import { Swiper } from 'components/Swiper';
import { observer } from 'mobx-react-lite';

import { TPhoto } from '../../../common/types/common-types';
import { ZoomImage } from '../../../components/ZoomImage';
import { ReviewViewModel } from '../review-view-model';

import styles from './review-modal.module.scss';

interface IReviewModalProps {
  isOpen: boolean;
  model: ReviewViewModel;
  closeModal: () => void;
}

export const ReviewModal: FC<IReviewModalProps> = observer(
  ({ isOpen, model, closeModal }) => {
    const { reviewPhoto } = model;

    const setSelectedPhoto = (photo: TPhoto) => {
      reviewPhoto.selectedPhoto = photo;
    };

    return (
      <Modal
        className={styles.modal}
        open={isOpen}
        onCancel={closeModal}
        width="70%"
        title={reviewPhoto.title}
        footer={null}
      >
        {reviewPhoto.photos.length > 1 ? (
          <Swiper
            alt="product"
            photos={reviewPhoto.photos}
            selectedPhoto={reviewPhoto.selectedPhoto}
            setSelectedPhoto={setSelectedPhoto}
          />
        ) : (
          <div className={styles.imageWrapper}>
            <ZoomImage alt="product" src={reviewPhoto.selectedPhoto.url} />
          </div>
        )}
      </Modal>
    );
  },
);
