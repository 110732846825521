import { deleteVariationAttribute } from 'http/variationApi';

import { FC, useState } from 'react';

import { notify } from 'common/utils';
import { TVariationItem } from 'components/VariantsCard/Variation';
import { ProductControllerViewModel } from 'pages/Product/ProductController/product-controller-view-model';
import { useFormContext } from 'react-hook-form';

import EditableVariation from './EditableVariation';
import ShowableVariation from './ShowableVariation';

import styles from './styles.module.scss';

interface IDoneVariationProps {
  title: TVariationItem['type'];
  values: string[];
  model: ProductControllerViewModel;
}

const DoneVariation: FC<IDoneVariationProps> = ({ title, values, model }) => {
  const [isEdit, setEdit] = useState<boolean>(false);
  const [isLoading, setLoading] = useState(false);
  const { watch, reset } = useFormContext();

  const allFormFields = watch();

  const toggleEdit = () => {
    setEdit((edit) => !edit);
  };

  const handleDelete = async () => {
    setLoading(true);

    try {
      await deleteVariationAttribute(model.product.id, [title]);

      await model.product.fetchProduct(model.product.id, true);

      reset({
        ...allFormFields,
        variations: {
          ...allFormFields.variations,
          [title]: [],
        },
      });

      notify('Variations list was deleted!', 'success');
    } catch (e) {
      notify('Failed to delete variant', 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.main}>
      {!isEdit && (
        <ShowableVariation
          name={title}
          values={values}
          isLoading={isLoading}
          handleDelete={handleDelete}
          toggleEdit={toggleEdit}
        />
      )}
      {isEdit && (
        <EditableVariation
          name={title}
          toggleEdit={toggleEdit}
          model={model}
          values={values}
        />
      )}
    </div>
  );
};

export default DoneVariation;
