import { FC } from 'react';

import { observer } from 'mobx-react-lite';
import { ReturnViewModel } from 'pages/NewOrder/view-models/return-view-model';

import { EditableData } from './EditableData';
import { StaticData } from './StaticData';

interface IReturnCommentProps {
  model: ReturnViewModel;
}

export const ReturnComment: FC<IReturnCommentProps> = observer(({ model }) => (
  <>
    {model.isCommentEditing ? (
      <EditableData
        comment={model.comment?.value}
        isLoading={model.isLoading}
        onEditToggle={model.onToggleCommentEditing}
        onEdit={model.onReturnCommentUpdate}
      />
    ) : (
      <StaticData
        comment={model.comment?.value}
        onEditToggle={model.onToggleCommentEditing}
      />
    )}
  </>
));
