import { ILongOrder } from 'common/types';
import {
  EPaymentCancelledStatuses,
  EDisputeStatuses,
} from 'common/types/order/enums';
import { makeAutoObservable } from 'mobx';

export class LongOrderModel {
  _order: ILongOrder;

  get order() {
    return this._order;
  }

  set order(order: ILongOrder) {
    this._order = order;
  }

  get id() {
    return this.order.id;
  }

  get items() {
    return this.order.items;
  }

  get itemStats() {
    return this.order.itemStats;
  }

  get processor() {
    return this.order.processor;
  }

  get isCancelled() {
    return !!EPaymentCancelledStatuses[
      this.order.payment as EPaymentCancelledStatuses
    ];
  }

  get isOngoingDispute() {
    return (
      this.order.dispute === EDisputeStatuses.DISPUTE_STRIPE_DISPUTE_STARTED ||
      this.order.dispute === EDisputeStatuses.DISPUTE_STRIPE_DISPUTE_REVIEW
    );
  }

  get disabled() {
    return this.isCancelled || this.isOngoingDispute;
  }

  constructor(order: ILongOrder) {
    this._order = order;

    makeAutoObservable(this);
  }
}
