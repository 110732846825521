import { FC } from 'react';

import { observer } from 'mobx-react-lite';

import { OrderExpandedViewModel } from '../../order-expanded-view-model';

import { CancelButton } from './CancelButton';
import { RefundButton } from './RefundButton';

import styles from './buttons-section.module.scss';

interface IButtonsSection {
  model: OrderExpandedViewModel;
}

export const ButtonsSection: FC<IButtonsSection> = observer(({ model }) => (
  <div className={styles.actionWrapper}>
    <RefundButton model={model} />
    <CancelButton model={model} />
  </div>
));
