import { FC } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useModal } from 'common/utils';
import { observer } from 'mobx-react-lite';
import { ShipmentModel } from 'store/shipment-model';

import { OrderExpandedViewModel } from '../../order-expanded-view-model';

import { CreateShipmentModal } from './CreateShipmentModal';
import ShipmentItem from './ShipmentItem';
import ShipmentSkeleton from './ShipmentSkeleton';

import styles from './shipment-section.module.scss';

interface IShipmentSectionProps {
  model: OrderExpandedViewModel;
}

const ShipmentSection: FC<IShipmentSectionProps> = observer(({ model }) => {
  const [openEditModal, handleEditModalOpen, handleEditModalClose] =
    useModal(false);

  const handleShipmentDelete = async (cancelledShipmment: ShipmentModel) => {
    model.deleteShipment(cancelledShipmment.id);
  };

  return (
    <div>
      <div className={styles.header}>
        <span className={styles.title}>Shipment</span>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={handleEditModalOpen}
          disabled={model.loading || model.order.disabled}
        >
          Add new shipment
        </Button>
      </div>
      <div className={styles.shipmentItemWrapper}>
        {model.loading && new Array(2).fill(<ShipmentSkeleton />)}
        {!model.loading &&
          model.shipments.map((shipment) => (
            <ShipmentItem
              key={shipment.id}
              shipment={shipment}
              handleShipmentCancel={handleShipmentDelete}
              model={model}
            />
          ))}
      </div>
      <CreateShipmentModal
        open={openEditModal}
        handleEditModalClose={handleEditModalClose}
        model={model}
      />
    </div>
  );
});

export default ShipmentSection;
