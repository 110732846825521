import { FC, ChangeEvent, useState } from 'react';

import { Input, Modal } from 'antd';

import styles from './comment-modal.module.scss';

export interface ICommentModalProps {
  open: boolean;
  title?: string;
  comment: string;
  isLoading?: boolean;
  onOk: () => void;
  onCancel: () => void;
  onSave: (value?: string) => Promise<void>;
}

export const CommentModal: FC<ICommentModalProps> = ({
  open,
  title,
  comment,
  isLoading,
  onOk,
  onCancel,
  onSave,
}) => {
  const [value, setValue] = useState(comment);

  const onChange = (e: ChangeEvent<HTMLTextAreaElement>) =>
    setValue(e.target.value);

  const handleSave = async () => {
    const val = value.trim();
    setValue(val);
    await onSave(val);

    onOk();
  };

  return (
    <Modal
      open={open}
      title={title}
      okButtonProps={{ loading: isLoading }}
      destroyOnClose
      onOk={handleSave}
      onCancel={onCancel}
    >
      <div className={styles.content}>
        <Input.TextArea
          value={value}
          maxLength={250}
          showCount
          autoSize={{ minRows: 3, maxRows: 6 }}
          onChange={onChange}
        />
      </div>
    </Modal>
  );
};
