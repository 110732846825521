import { getSupplierInvoices } from 'http/supplierInvoiceApi';

import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { TTablePaginationParams } from 'common/types';
import { notify } from 'common/utils';
import { action, computed, makeObservable, observable } from 'mobx';
import { FiltersModel } from 'store/filters-model';
import { InvoiceModel } from 'store/invoice-model';

import { BillingPageViewModel } from './billing-page-view-model';

export class InvoicesViewModel {
  _root: BillingPageViewModel;
  _supplierId: number | undefined;
  _isLoading = false;
  _filters: FiltersModel = new FiltersModel();
  _invoices: InvoiceModel[] = [];

  get supplierId() {
    return this._supplierId;
  }

  get isLoading() {
    return this._isLoading;
  }

  set isLoading(isLoading: boolean) {
    this._isLoading = isLoading;
  }

  get filters() {
    return this._filters;
  }

  set invoices(invoices: InvoiceModel[]) {
    this._invoices = invoices;
  }

  get invoices() {
    return this._invoices;
  }

  async getInvoices() {
    if (!this.supplierId) {
      return;
    }

    try {
      this.isLoading = true;

      const params = {
        page: this.filters.page,
        pageSize: this.filters.pageSize,
      };

      const { invoices, count } = await getSupplierInvoices(
        this.supplierId,
        params,
      );

      this.filters.count = count;

      this.invoices = invoices.map((invoice) => {
        const formattedInvoice = {
          id: invoice.id,
          createdAt: invoice.createdAt,
          total: invoice.calculation.total,
        };

        return new InvoiceModel(formattedInvoice);
      });
    } catch (e) {
      notify(`Error, reason:${e}`, 'error');
    } finally {
      this.isLoading = false;
    }

    return null;
  }

  changeFilters = (
    pagination: TTablePaginationParams,
    _filters: Record<string, FilterValue>,
    sorter: SorterResult<InvoiceModel>,
  ) => {
    const { current } = pagination;

    this._filters.page = current;
    this._filters.setSorterSortOptions(sorter);
    this.getInvoices();
  };

  constructor(root: BillingPageViewModel, supplierId?: number) {
    this._root = root;
    this._supplierId = supplierId;

    makeObservable(this, {
      _supplierId: observable,
      _isLoading: observable,
      _filters: observable,
      _invoices: observable,
      supplierId: computed,
      isLoading: computed,
      filters: computed,
      invoices: computed,
      getInvoices: action,
      changeFilters: action,
    });
  }
}
