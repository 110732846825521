import { FC, useEffect, useState, MouseEvent } from 'react';

import cn from 'clsx';
import { getNestedProperty } from 'common/utils';
import {
  Editor,
  EditorState,
  convertToRaw,
  RichUtils,
  convertFromRaw,
  ContentBlock,
} from 'draft-js';
import { draftjsToMd, mdToDraftjs } from 'draftjs-md-converter';
import { Controller, useFormContext } from 'react-hook-form';

import styles from './markdown.module.scss';

import 'draft-js/dist/Draft.css';

export interface IFormMarkdownProps {
  name: string;
  label?: string;
  labelPosition?: 'top' | 'left';
  required?: boolean;
  className?: string;
}

export interface IControlsProps {
  onToggle: (type: string) => void;
  editorState: EditorState;
}

export interface IStyleButtonProps {
  style: string;
  onToggle: (style: string) => void;
  active: boolean;
  label: string;
}

const StyleButton: FC<IStyleButtonProps> = ({
  style,
  onToggle,
  active,
  label,
}) => {
  const onMouseDown = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onToggle(style);
  };

  return (
    <span
      className={cn(styles.button, {
        [styles.active]: active,
      })}
      onMouseDown={onMouseDown}
    >
      {label}
    </span>
  );
};

const blockStyleFn = (contentBlock: ContentBlock) => {
  const type = contentBlock.getType();
  if (type === 'header-four') {
    return styles.header1;
  }

  if (type === 'header-five') {
    return styles.header2;
  }

  if (type === 'header-six') {
    return styles.header3;
  }

  if (type === 'blockquote') {
    return styles.blockquote;
  }

  return '';
};

const BLOCK_TYPES = [
  // { label: 'H1', style: 'header-one' },
  // { label: 'H2', style: 'header-two' },
  // { label: 'H3', style: 'header-three' },
  { label: 'H1', style: 'header-four' }, // not to break symantick templates
  { label: 'H2', style: 'header-five' },
  { label: 'H3', style: 'header-six' },
  { label: 'Blockquote', style: 'blockquote' },
  { label: 'UL', style: 'unordered-list-item' },
  { label: 'OL', style: 'ordered-list-item' },
];

const INLINE_STYLES = [
  { label: 'Bold', style: 'BOLD' },
  { label: 'Italic', style: 'ITALIC' },
];

const InlineStyleControls: FC<IControlsProps> = ({ editorState, onToggle }) => {
  const currentStyle = editorState.getCurrentInlineStyle();
  return (
    <div className={styles.richEditorControls}>
      {INLINE_STYLES.map((type) => (
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};

const BlockStyleControls: FC<IControlsProps> = ({ editorState, onToggle }) => {
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  return (
    <div className={styles.richEditorControls}>
      {BLOCK_TYPES.map((type) => (
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          onToggle={onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};

export const FormMarkdown: FC<IFormMarkdownProps> = ({
  name,
  label,
  labelPosition = 'top',
  required,
  className,
}) => {
  const { control, formState } = useFormContext();

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty(),
  );

  const togleBlockType = (blockType: string) => {
    setEditorState(RichUtils.toggleBlockType(editorState, blockType));
  };

  const togleInlineType = (inlineType: string) => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, inlineType));
  };

  useEffect(() => {
    const defaultValue = getNestedProperty(
      formState.defaultValues ?? {},
      name.replace(/[[]/g, '.').replace(/[\]]/g, '').split('.'),
    );

    if (defaultValue) {
      setEditorState(
        EditorState.createWithContent(
          convertFromRaw(mdToDraftjs(defaultValue)),
        ),
      );
    }
  }, []);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { name, onChange }, fieldState: { error } }) => {
        return (
          <div
            className={cn(
              styles.container,
              styles.inputWrapper,
              styles[`label-${labelPosition}`],
              className,
            )}
          >
            {!!label && (
              <label htmlFor={name} className={styles.label}>
                {label}
                {required && <span className={styles.required}>*</span>}
              </label>
            )}
            <div className={styles.editorContainer}>
              <BlockStyleControls
                editorState={editorState}
                onToggle={togleBlockType}
              />
              <InlineStyleControls
                editorState={editorState}
                onToggle={togleInlineType}
              />
              <div id={name} className={styles.editor}>
                <Editor
                  editorState={editorState}
                  onChange={(state) => {
                    setEditorState(state);
                    onChange(
                      draftjsToMd(convertToRaw(state.getCurrentContent())),
                    );
                  }}
                  blockStyleFn={blockStyleFn}
                />
              </div>
            </div>
            {!!error && <div className={styles.error}>{error?.message}</div>}
          </div>
        );
      }}
    />
  );
};
