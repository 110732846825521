import { changeOrderStatus } from 'http/orderApi';

import { todoOptions, disputeOptions } from 'common/constants';
import { ILongOrder, IServerError, IShortOrder } from 'common/types';
import { EDisputeStatuses, EReturnStatusEnum } from 'common/types/order/enums';
import {
  notify,
  removeStatusPrefix,
  upperUndescoreToRegularCase,
} from 'common/utils';
import { makeAutoObservable } from 'mobx';

import { ExpandedOrderViewModel } from './expanded-order-view-model';

export interface IStatuses {
  todo: string[];
  reshipments: string[];
  refund: string[];
  supplier: string[];
  return: EReturnStatusEnum | string;
  dispute: EDisputeStatuses | string;
}

export class StatusesViewModel {
  _root: ExpandedOrderViewModel | null = null;
  _isLoading = false;
  _statuses: IStatuses;
  _todo: string[];
  _dispute: EDisputeStatuses | string;
  todoOptions = todoOptions;
  disputeOptions = disputeOptions;

  get root() {
    return this._root;
  }

  get orderViewModel() {
    return this.root?.root;
  }

  get disabled() {
    return !!this.root?.order?.disabled;
  }

  get isLoading() {
    return this._isLoading;
  }

  set isLoading(isLoading: boolean) {
    this._isLoading = isLoading;
  }

  get statuses() {
    return this._statuses;
  }

  set statuses(statuses: IStatuses) {
    this._statuses = statuses;
  }

  get todo() {
    return this._todo;
  }

  set todo(todo: string[]) {
    this._todo = todo;
  }

  get todoView() {
    return this.todo.map((item) =>
      upperUndescoreToRegularCase(removeStatusPrefix(item, 'TODO_')),
    );
  }

  get dispute() {
    return this._dispute;
  }

  set dispute(dispute: EDisputeStatuses | string) {
    this._dispute = dispute;
  }

  get disputeView() {
    return upperUndescoreToRegularCase(
      removeStatusPrefix(this.dispute, 'DISPUTE_'),
    );
  }

  get reshipmentsView() {
    return this.statuses.reshipments.map((reshipment) =>
      upperUndescoreToRegularCase(removeStatusPrefix(reshipment, 'SHIPMENT_')),
    );
  }

  get refundView() {
    return this.statuses.refund.map((reshipment) =>
      upperUndescoreToRegularCase(removeStatusPrefix(reshipment, 'REFUND_')),
    );
  }

  get supplierView() {
    return this.statuses.supplier.map((supplier) =>
      upperUndescoreToRegularCase(supplier),
    );
  }

  get returnView() {
    return upperUndescoreToRegularCase(
      removeStatusPrefix(this.statuses.return, 'RETURN_'),
    );
  }

  syncOrderData = (order: ILongOrder) => {
    this.statuses = this.getStatuses(order);
    this.todo = this._statuses.todo;
    this.dispute = this._statuses.dispute;
  };

  getStatuses = (order: IShortOrder | ILongOrder) => {
    return {
      todo: order.todo,
      reshipments: order.reshipments,
      refund: order.refund,
      supplier: order.supplier,
      return: order.return,
      dispute: order.dispute,
    };
  };

  onStatusChange = async (status: string | string[], statusType: string) => {
    const orderId = this.orderViewModel?.shortOrder?.id;

    if (!orderId) {
      return;
    }

    try {
      this.isLoading = true;

      const statuses = !!status.length ? status : [''];
      const finalStatus = Array.isArray(status) ? statuses : status;

      const updatedOrder = await changeOrderStatus(
        orderId,
        finalStatus,
        statusType,
      );

      this.orderViewModel && (await this.orderViewModel.syncOrderData());

      this.statuses = this.getStatuses(updatedOrder);
    } catch (e) {
      const error = e as IServerError;

      notify(error.response.data.message, 'error');
    } finally {
      this.isLoading = false;
    }
  };

  constructor(
    root: ExpandedOrderViewModel | null,
    order: IShortOrder | ILongOrder,
  ) {
    if (root) {
      this._root = root;
    }

    this._statuses = this.getStatuses(order);
    this._todo = this._statuses.todo;
    this._dispute = this._statuses.dispute;

    makeAutoObservable(this);
  }
}
