import { FC, useMemo, useState } from 'react';

import { Modal, Button, InputNumber, Select, Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { reasonDefaultOptions } from 'common/constants';
import {
  cents2dollars,
  getVariationView,
  dollars2cents,
  roundToHundredths,
} from 'common/utils';
import { Table, title } from 'components/Table';
import { Observer, observer } from 'mobx-react-lite';
import { PurchaseModel } from 'store/purchase-model';

import { OrderExpandedViewModel } from '../../../../order-expanded-view-model';

import styles from './refund-modal.module.scss';

interface IRefundModal {
  model: OrderExpandedViewModel;
  isOpen: boolean;
  closeModal: () => void;
}

export const RefundModal: FC<IRefundModal> = observer(
  ({ model, isOpen, closeModal }) => {
    const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
    const [isShipping, setShipping] = useState<boolean>(false);
    const [isOverpayment, setOverpayment] = useState<boolean>(false);
    const [isCopyTotal, setCopyTotal] = useState<boolean>(false);
    const [sumRefund, setSumRefund] = useState<number>(0);
    const [responsible, setResponsible] = useState<string>('');
    const [reason, setReason] = useState<string>('');

    const maxPrice = model.order.order.maxSumToRefund;
    const overpaymentPrice = model.order.order.overpayment;

    const filteredPurchases = model.purchases.filter(
      (el) => selectedRowKeys.indexOf(el.id) >= 0,
    );

    const sumPurchases = filteredPurchases.reduce(
      (acc, val) => acc + val.refundSum,
      0,
    );

    const totalAmount = useMemo(() => {
      const overpayment = isOverpayment ? overpaymentPrice : 0;
      const shipping = isShipping ? model.order.shipping : 0;

      return shipping + overpayment + sumPurchases;
    }, [
      isOverpayment,
      isShipping,
      model.order.shipping,
      overpaymentPrice,
      sumPurchases,
    ]);

    const maxPriceOrTotalAmount =
      maxPrice < totalAmount ? maxPrice : totalAmount;

    const zeroOrTotalAmount =
      reason === 'STRIPE_DISPUTE'
        ? 0
        : isCopyTotal
        ? cents2dollars(maxPriceOrTotalAmount)
        : roundToHundredths(sumRefund);

    const handleChangeShipping = (e: CheckboxChangeEvent) => {
      setShipping(e.target.checked);
    };

    const handleChangeOverpayment = (e: CheckboxChangeEvent) => {
      setOverpayment(e.target.checked);
    };

    const handleChangeCopy = (e: CheckboxChangeEvent) => {
      setCopyTotal(e.target.checked);

      if (e.target.checked) {
        maxPrice < totalAmount
          ? setSumRefund(maxPrice)
          : setSumRefund(totalAmount);
      } else {
        setSumRefund(0);
      }
    };

    const resetStates = () => {
      setSelectedRowKeys([]);
      setShipping(false);
      setOverpayment(false);
      setCopyTotal(false);
      setResponsible('');
      setReason('');
      setSumRefund(0);
    };

    const handleCloseModal = () => {
      model.purchases.forEach((el) => {
        model.handleChangeRefund(0, el.id);
      });
      resetStates();
      closeModal();
    };

    const handleChangeCustomSum = (value: number) => {
      setCopyTotal(false);
      setSumRefund(value);
    };

    const handleClickRefund = () => {
      const params = {
        amount: dollars2cents(zeroOrTotalAmount),
        responsible: responsible,
        reason: reason,
      };

      model.createRefund(model.order.id, params);

      handleCloseModal();
    };

    const columns = [
      title({
        width: '180px',
        title: 'Title',
        dataIndex: 'title',
      }),
      title({
        width: '96px',
        title: 'Variation',
        dataIndex: 'variation',
        render: ({ content }) => {
          return <span>{getVariationView(content)}</span>;
        },
      }),
      title({
        width: '60px',
        title: 'Quantity',
        dataIndex: 'quantity',
      }),
      title({
        width: '60px',
        title: 'Price',
        dataIndex: 'adjustedPrice',
        render: (adjustedPrice) => <span>{cents2dollars(adjustedPrice)}</span>,
      }),
      title({
        width: '60px',
        title: 'Refund',
        dataIndex: 'refund',
        render: (_, data: PurchaseModel) => {
          return (
            <Observer>
              {() => (
                <InputNumber
                  defaultValue={0}
                  className={styles.inputRefund}
                  type="number"
                  min={0}
                  max={data.quantity}
                  onChange={(e) => model.handleChangeRefund(e, data.id)}
                />
              )}
            </Observer>
          );
        },
      }),
      title({
        width: '60px',
        title: 'Sum',
        dataIndex: 'refundSum',
        render: (refundSum) => (
          <span className={styles.sum}>{cents2dollars(refundSum)}</span>
        ),
      }),
    ];

    return (
      <Modal
        open={isOpen}
        width="60%"
        onCancel={handleCloseModal}
        closable={false}
        title="Start a refund"
        destroyOnClose
        footer={[
          <div className={styles.footer}>
            <p>${`${zeroOrTotalAmount} will be refunded to the customer`}</p>
            <Button
              disabled={!(reason && responsible)}
              type="primary"
              onClick={handleClickRefund}
            >
              Refund
            </Button>
            <Button onClick={handleCloseModal}>Cancel</Button>
          </div>,
        ]}
      >
        <div className={styles.tableWrapper}>
          <p className={styles.title}>Calculate the amount</p>
          <Table
            rowSelection={{
              type: 'checkbox',
              onChange: (selectedRowKeys: number[], _, info) => {
                if (info.type === 'all' && selectedRowKeys.length) {
                  setShipping(true);
                  setOverpayment(true);
                }

                setSelectedRowKeys(selectedRowKeys);
              },
            }}
            pagination={false}
            columns={columns}
            data={model.purchases}
          />
          <div className={styles.infoWrapper}>
            <p>Max price to refund: ${cents2dollars(maxPrice)}</p>
            <div className={styles.totalWrapper}>
              {!!overpaymentPrice && (
                <Checkbox
                  checked={isOverpayment}
                  onChange={handleChangeOverpayment}
                >
                  Overpayment: ${cents2dollars(overpaymentPrice)}
                </Checkbox>
              )}
              {!!model.order.shipping && (
                <Checkbox checked={isShipping} onChange={handleChangeShipping}>
                  Shipping cost: ${cents2dollars(model.order.shipping)}
                </Checkbox>
              )}
              <p>Total:</p>
              <p className={styles.sum}>${cents2dollars(totalAmount)}</p>
            </div>
          </div>
        </div>
        <div className={styles.selectWrapper}>
          <div>
            <p>Sum to refund</p>
            <div className={styles.refundSumWrapper}>
              <InputNumber
                className={styles.inputNumber}
                onChange={handleChangeCustomSum}
                value={
                  isCopyTotal ? cents2dollars(maxPriceOrTotalAmount) : sumRefund
                }
                defaultValue={0}
                prefix="$"
                min={0}
                max={cents2dollars(maxPrice)}
              />
              <Checkbox checked={isCopyTotal} onChange={handleChangeCopy}>
                Copy price from total
              </Checkbox>
            </div>
          </div>
          <div>
            <p>Responsible</p>
            <Select
              options={model.responsibleOptions}
              value={responsible}
              onChange={setResponsible}
              className={styles.select}
              dropdownStyle={{ zIndex: 1001 }}
            />
          </div>
          <div>
            <p>Reason</p>
            <Select
              className={styles.select}
              options={reasonDefaultOptions}
              onChange={setReason}
              dropdownStyle={{ zIndex: 1001 }}
            />
          </div>
        </div>
      </Modal>
    );
  },
);
