import { AxiosResponse } from 'axios';
import { IDataWithPagination } from 'common/types';
import { IBrand, IBrandParams } from 'common/types/brand';

import { $host } from './index';

const headers = {
  'Content-Type': 'multipart/form-data',
};

export const getAllBrands = async (params?: IBrandParams) => {
  const {
    data: { data },
  } = await $host.get<AxiosResponse<IDataWithPagination<IBrand>>>(
    'admin/brands',
    {
      params: params ?? {
        page: 1,
        pageSize: 50,
        sort: 'DESC',
        sortBy: 'updatedAt',
      },
    },
  );

  return data;
};

export const getBrandById = async (id: IBrand['id']) => {
  const {
    data: { data },
  } = await $host.get<AxiosResponse<IBrand>>(`admin/brands/id/${id}`);

  return data;
};

export const deleteById = async (id: IBrand['id']) => {
  const { data } = await $host.delete<AxiosResponse<IBrand>>(
    `admin/brands/${id}`,
  );

  return data.data;
};

export const createBrand = async (params: FormData) => {
  const { data } = await $host.post<AxiosResponse<IBrand>>(
    'admin/brands',
    params,
    {
      headers,
    },
  );

  return data.data;
};

export const editBrand = async (id: IBrand['id'], params: FormData) => {
  const { data } = await $host.put<AxiosResponse<IBrand>>(
    `admin/brands/${id}`,
    params,
    {
      headers,
    },
  );

  return data.data;
};
