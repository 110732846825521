import { FC } from 'react';

import { Switch, SwitchProps } from 'antd';
import cn from 'clsx';
import { Controller, useFormContext } from 'react-hook-form';

import styles from './switch.module.scss';

export interface IFormSwitchProps extends SwitchProps {
  name: string;
  type?: 'text' | 'password';
  label: string;
  labelPosition?: 'top' | 'left';
  required?: boolean;
  className?: string;
}

export const FormSwitch: FC<IFormSwitchProps> = ({
  name,
  type = 'text',
  label,
  labelPosition = 'top',
  required,
  className,
  ...props
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value, name }, fieldState: { error } }) => {
        return (
          <div
            className={cn(
              styles.container,
              styles.inputWrapper,
              styles[`label-${labelPosition}`],
              className,
            )}
          >
            <label htmlFor={name} className={styles.label}>
              {label}
              {required && <span className={styles.required}>*</span>}
            </label>
            <Switch
              id={name}
              onChange={onChange}
              checked={value}
              className={styles.switch}
              {...props}
            />
            {!!error && <span className={styles.error}>{error?.message}</span>}
          </div>
        );
      }}
    />
  );
};
