import { FC, useState } from 'react';

import { Modal, Button, Select } from 'antd';
import { cancelReasonDefaultOptions } from 'common/constants';
import { EPaymentCancelledStatuses } from 'common/types/order/enums';
import { observer } from 'mobx-react-lite';

import { OrderExpandedViewModel } from '../../../../order-expanded-view-model';

import styles from './cancel-modal.module.scss';

interface ICancelModal {
  model: OrderExpandedViewModel;
  isOpen: boolean;
  closeModal: () => void;
}

export const CancelModal: FC<ICancelModal> = observer(
  ({ model, isOpen, closeModal }) => {
    const [reason, setReason] = useState<
      keyof typeof EPaymentCancelledStatuses | undefined
    >();

    const handleCancelOrder = (orderId: number) => {
      if (reason) {
        model.cancelOrder(orderId, reason);
      }

      setReason(undefined);
      closeModal();
    };

    const handleCloseModal = () => {
      setReason(undefined);
      closeModal();
    };

    return (
      <Modal
        open={isOpen}
        onCancel={handleCloseModal}
        closable={false}
        title="Cancel order"
        destroyOnClose
        footer={[
          <Button
            type="primary"
            disabled={!reason}
            onClick={() => handleCancelOrder(model.order.order.id)}
          >
            Cancel order
          </Button>,
          <Button onClick={handleCloseModal}>Close</Button>,
        ]}
      >
        <p>
          This action cannot be undone and all money will be refunded to the
          buyer. Are you sure to cancel this order?
        </p>
        <div>
          <p>Reason:</p>
          <Select
            placeholder="Choose a reason"
            onChange={setReason}
            className={styles.select}
            options={cancelReasonDefaultOptions}
            dropdownStyle={{ zIndex: 1001 }}
          />
        </div>
      </Modal>
    );
  },
);
