import { FC } from 'react';

import cn from 'clsx';

import styles from './table-title.module.scss';

interface ITableTitleProps {
  title: string;
  className?: string;
}

export const TableTitle: FC<ITableTitleProps> = ({ title, className }) => (
  <span className={cn(styles.container, className)}>{title}</span>
);
