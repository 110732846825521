import { FC } from 'react';

import { Skeleton, Typography } from 'antd';
import { ColumnType } from 'antd/es/table';
import { cents2dollars, getObservableTableCellValue } from 'common/utils';
import { Observer } from 'mobx-react-lite';

export interface TCurrencyCellProps {
  value: number;
  loading?: boolean;
  observer?: boolean;
}

export type TCurrencyColumn<T> = ColumnType<T> &
  Omit<TCurrencyCellProps, 'value'>;

const CurrencyCell: FC<TCurrencyCellProps> = ({ value, loading }) => {
  if (loading) {
    return <Skeleton.Input active />;
  }

  return (
    <Typography.Text ellipsis={{ tooltip: cents2dollars(value) }}>
      {cents2dollars(value)}
    </Typography.Text>
  );
};

export const currency = <T,>({
  dataIndex = 'currency',
  key = 'currency',
  width = '10%',
  loading,
  observer,
  ...props
}: TCurrencyColumn<T>): ColumnType<T> => ({
  dataIndex: dataIndex,
  key: key,
  width: width,
  render: (value: number, record: T) => (
    <Observer>
      {() => {
        const observedValue = getObservableTableCellValue<T>(dataIndex, record);

        return <CurrencyCell value={observer ? observedValue : value} />;
      }}
    </Observer>
  ),
  ...props,
});
