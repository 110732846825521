import { IVariation } from 'common/types';
import { mockIdKeyGen, cents2dollars, dollars2cents } from 'common/utils';
import { computed, makeAutoObservable, observable } from 'mobx';

export class VariationModel {
  _variation: IVariation;

  get variation() {
    return this._variation;
  }

  get variationTitle() {
    return [
      this.variation.content.material,
      this.variation.content.color,
      this.variation.content.size,
      this.variation.content.type,
    ].join(' ');
  }

  get key() {
    return !!this._variation.id ? this._variation.id : mockIdKeyGen();
  }

  get mainPhoto() {
    return (
      this._variation.content.photos?.find((photo) => photo.main) ??
      this._variation.content.photos?.[0]
    );
  }

  get sku() {
    return this._variation.content.sku;
  }

  get variant() {
    return [
      {
        name: 'size',
        value: this._variation.content.size,
      },
      {
        name: 'color',
        value: this._variation.content.color,
      },
      {
        name: 'material',
        value: this._variation.content.material,
      },
      {
        name: 'placement',
        value: this._variation.content.placement,
      },
      {
        name: 'type',
        value: this._variation.content.type,
      },
      {
        name: 'style',
        value: this._variation.content.style,
      },
    ].filter((variant) => !!variant.value);
  }

  get status() {
    return this._variation.content.status;
  }

  get id() {
    return this._variation.id;
  }

  get price() {
    return cents2dollars(this._variation.content.price);
  }

  get totalPrice() {
    return cents2dollars(this._variation.content.totalPrice);
  }

  serializeVariation = (values: IVariation['content']) => {
    const fd = new FormData();
    // at the request of clients, we have temporarily hidden this information
    // fd.append('description', values.description);
    fd.append('price', dollars2cents(values.price).toString());
    fd.append('discount', (values.discount ?? 0).toString());
    fd.append('barcode', values.barcode);
    fd.append('order_link', values.order_link.toString());
    fd.append('status', values.status);

    if (values.sku && values.sku !== this.sku) {
      fd.append('sku', values.sku.toString());
    }
    //     if (values.photo && values.photo.file) {
    //   fd.append('photo', values.photo.file.fileSource);
    // }

    return fd;
  };

  constructor(variation: IVariation) {
    this._variation = variation;

    makeAutoObservable(this, {
      _variation: observable,
      variation: computed,
      key: computed,
    });
  }
}
