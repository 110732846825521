import { AxiosResponse } from 'axios';
import { IShipment } from 'common/types';

import { $host } from './index';

type IShipmentItems = {
  [key: string]: number;
};

interface IEditParams {
  orderId: number;
  items: IShipmentItems;
  supplier: string;
  reason: string;
  costOfPurchase: number;
  costOfShipping: number;
}

export const createShipment = async (params: IEditParams) => {
  const { data } = await $host.post<AxiosResponse<IShipment>>(
    'admin/shipments',
    params,
  );

  return data.data;
};

export const changeShipmentVariations = async (
  shipmentId: number,
  params: { items: IShipmentItems },
) => {
  const { data } = await $host.put<AxiosResponse<IShipment>>(
    `admin/shipments/${shipmentId}/items`,
    params,
  );

  return data.data;
};

export const editShipment = async (
  shipmentId: number,
  params: Record<string, string | number | undefined>,
) => {
  const { data } = await $host.put<AxiosResponse<IShipment>>(
    `admin/shipments/${shipmentId}`,
    params,
  );

  return data.data;
};

export const deleteShipment = async (shipmentId: number) => {
  const { data } = await $host.delete(`admin/shipments/${shipmentId}`);

  return data.data;
};
