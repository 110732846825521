import { FC, useEffect, useState } from 'react';

import { Button, List, Modal, Tag } from 'antd';
import {
  ICalculation,
  IUnpaidPackage,
  IUnpaidShipments,
} from 'common/types/supplier';
import { cents2dollars } from 'common/utils';

import styles from './payment-modal.module.scss';
interface IPaymentModalProps {
  open: boolean;
  onCancel: () => void;
  onPayment: () => void;
  getUnpaidPackages: () => Promise<IUnpaidShipments | undefined>;
}

export const PaymentModal: FC<IPaymentModalProps> = ({
  open,
  onCancel,
  onPayment,
  getUnpaidPackages,
}) => {
  const [isLoading, setLoading] = useState(true);
  const [unpaidPackages, setUnpaidPackages] = useState<IUnpaidPackage[]>([]);
  const [calculation, setCalculation] = useState<ICalculation | null>(null);

  const hasUnpaidPackages = !!unpaidPackages.length;

  const handlePayment = () => {
    onPayment();
    onCancel();
  };

  useEffect(() => {
    if (open) {
      setLoading(true);

      const getPackages = async () => {
        const packages = await getUnpaidPackages();

        packages && setUnpaidPackages(packages.shipments);
        packages && setCalculation(packages.calculation);
        setLoading(false);
      };

      getPackages();
    } else {
      setLoading(true);
      setUnpaidPackages([]);
      setCalculation(null);
    }
  }, [open, getUnpaidPackages]);

  return (
    <Modal
      title="Pay for all"
      open={open}
      onCancel={onCancel}
      footer={null}
      destroyOnClose
    >
      <List
        locale={{ emptyText: <span>Everything is paid</span> }}
        dataSource={unpaidPackages}
        renderItem={(item) => (
          <List.Item>
            <div className={styles.package}>
              <span className={styles.number}># {item.id}</span>
              <span className={styles.cost}>
                {cents2dollars(item.correction ?? item.cost)} $
              </span>
              <Tag className={styles.type}>{item.type}</Tag>
            </div>
          </List.Item>
        )}
        className={styles.packages}
        loading={isLoading}
        bordered
      />
      <p>
        Total:&nbsp;
        <span className={styles.total}>
          {cents2dollars(calculation?.total ?? 0)} $
        </span>
      </p>
      <div className={styles.buttons}>
        <Button
          type="primary"
          onClick={handlePayment}
          className={styles.button}
          disabled={isLoading || !hasUnpaidPackages}
        >
          Payment
        </Button>
        <Button htmlType="button" className={styles.button} onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </Modal>
  );
};
