import {
  addProductKit,
  editProductKit,
  getKitProductTypes,
  getProductKits,
  removeProductKit,
} from 'http/productKitsApi';

import { IKit, TOption, TTablePaginationParams } from 'common/types';
import { notify } from 'common/utils';
import { action, computed, makeObservable, observable } from 'mobx';
import { FiltersModel } from 'store/filters-model';
import { KitModel } from 'store/kit-model';

export class KitsViewModel {
  _isLoading = false;
  _filters: FiltersModel = new FiltersModel();
  _kits: KitModel[] = [];
  _kit: IKit | null = null;
  _productTypeOptions: TOption[] = [];
  _searchProductTypeValue = '';

  get isLoading() {
    return this._isLoading;
  }

  set isLoading(isLoading: boolean) {
    this._isLoading = isLoading;
  }

  get filters() {
    return this._filters;
  }

  get kits() {
    return this._kits;
  }

  set kits(kits: KitModel[]) {
    this._kits = kits;
  }

  get kit() {
    return this._kit;
  }

  set kit(kit: IKit | null) {
    this._kit = kit;
  }

  get searchProductTypeValue() {
    return this._searchProductTypeValue;
  }

  set searchProductTypeValue(value: string) {
    this._searchProductTypeValue = value;
  }

  get productTypeOptions() {
    return this._searchProductTypeValue
      ? this._productTypeOptions.filter((option) =>
          option.value
            .toLowerCase()
            .includes(this._searchProductTypeValue.toLowerCase()),
        )
      : this._productTypeOptions;
  }

  set productTypeOptions(productTypeOptions: TOption[]) {
    this._productTypeOptions = productTypeOptions;
  }

  changeFilters = (pagination: TTablePaginationParams) => {
    const { current } = pagination;

    this._filters.page = current;
    this.getKits();
  };

  async getProductTypeOptions() {
    this.isLoading = true;

    try {
      const productTypes = await getKitProductTypes();

      const formattedTypes = productTypes.reduce(
        (acc, curr) => [...acc, { value: curr, label: curr }],
        [],
      );

      this.productTypeOptions = formattedTypes;
    } catch (e) {
      notify(`Error, reason:${e}`, 'error');
    } finally {
      this.isLoading = false;
    }
  }

  async getKits() {
    this.isLoading = true;

    try {
      const params = {
        page: this.filters.page,
        pageSize: this.filters.pageSize,
      };

      const { data: kits, count } = await getProductKits(params);

      const kitsModels = kits.map((kit) => new KitModel(kit));

      this.filters.count = count;
      this.kits = kitsModels;
    } catch (e) {
      notify(`Error, reason:${e}`, 'error');
    } finally {
      this.isLoading = false;
    }
  }

  async addKit(productTypeOptions: TOption[]) {
    this._isLoading = true;

    try {
      const productTypes = productTypeOptions.reduce(
        (acc, curr) => [...acc, curr.value],
        [] as string[],
      );

      const newKit = await addProductKit({ productTypes });
      const newKitModel = new KitModel(newKit);

      this.kits = [...this.kits, newKitModel];
    } catch (e) {
      notify(`Error, reason:${e}`, 'error');
    } finally {
      this._isLoading = false;
    }
  }

  async editKit(id: number | undefined, productTypeOptions: TOption[]) {
    if (!id) {
      return;
    }

    this._isLoading = true;

    try {
      const productTypes = productTypeOptions.reduce(
        (acc, curr) => [...acc, curr.value],
        [] as string[],
      );

      const updatedKit = await editProductKit(id, { productTypes });

      const updatedKitModel = this.kits.find(
        (kit) => kit.id === id,
      ) as KitModel;

      updatedKitModel.kit = updatedKit;

      this.kits = [...this.kits];
    } catch (e) {
      notify(`Error, reason:${e}`, 'error');
    } finally {
      this._isLoading = false;
    }
  }

  async removeKit(id: number) {
    try {
      this.isLoading = true;

      await removeProductKit(id);

      this.kits = this.kits.filter((kit) => kit.id !== id);
    } catch (e) {
      notify(`Error, reason:${e}`, 'error');
    } finally {
      this.isLoading = false;
    }
  }

  constructor() {
    makeObservable(this, {
      _isLoading: observable,
      _filters: observable,
      _kits: observable,
      _kit: observable,
      _searchProductTypeValue: observable,
      _productTypeOptions: observable,
      searchProductTypeValue: computed,
      isLoading: computed,
      filters: computed,
      kits: computed,
      kit: computed,
      productTypeOptions: computed,
      changeFilters: action,
      getProductTypeOptions: action,
      getKits: action,
      addKit: action,
    });
  }
}
