import { FC } from 'react';

import { Button, Popconfirm, Spin } from 'antd';
import { useCurrentUserStore } from 'common/providers/store-provider';
import { observer } from 'mobx-react-lite';
import { ControlsViewModel } from 'pages/NewOrder/view-models/controls-view-model';

import { CancelOrderModal } from './CancelOrderModal';
import { RefundModal } from './RefundModal';

import styles from './controls.module.scss';

export interface IControlsProps {
  model: ControlsViewModel | null;
}

export const Controls: FC<IControlsProps> = observer(({ model }) => {
  const { isSupplier } = useCurrentUserStore();

  return (
    <>
      {!model ? (
        <Spin />
      ) : (
        <>
          <div className={styles.container}>
            <Button
              disabled={model.root?.order?.disabled || isSupplier}
              onClick={model.onRefundModalOpen}
            >
              Custom refund
            </Button>
            <Popconfirm
              placement="top"
              destroyTooltipOnHide
              title="Are you sure?"
              onConfirm={model.onRecalculate}
            >
              <Button disabled={model.root?.order?.disabled || isSupplier}>
                Recalculate
              </Button>
            </Popconfirm>
            <Button
              danger
              disabled={model.root?.order?.disabled || isSupplier}
              onClick={model.onCancelOrderModalOpen}
            >
              Cancel order
            </Button>
          </div>
          <RefundModal
            open={model.isRefundModalOpen}
            responsibleOptions={
              model.root?.root?.root?.responsibleOptions ?? []
            }
            reasonOptions={model.root?.root?.root?.defaultReasonOptions ?? []}
            isLoading={model.isLoading}
            onOk={model.onRefundModalSubmit}
            onCancel={model.onRefundModalClose}
          />
          <CancelOrderModal
            ok={model.isCancelOrderModalOpen}
            reasonOptions={model.cancelReasonDefaultOptions}
            isLoading={model.isLoading}
            onOk={model.onCancelOrderModalSubmit}
            onCancel={model.onCancelOrderModalClose}
          />
        </>
      )}
    </>
  );
});
