import { FC } from 'react';

import { cents2dollars } from 'common/utils';
import { observer } from 'mobx-react-lite';

import { OrderExpandedViewModel } from '../../../order-expanded-view-model';

import styles from './efficiency-content.module.scss';

interface IEfficiencySectionProps {
  model: OrderExpandedViewModel;
}

export const EfficiencyContent: FC<IEfficiencySectionProps> = observer(
  ({ model }) => {
    const { efficiency } = model.order;
    return (
      <div className={styles.efficiencyContent}>
        <div className={styles.string}>
          <span className={styles.title}>Purchase costs:&nbsp;</span>
          <span>{cents2dollars(efficiency.purchase)}</span>
        </div>
        <div className={styles.string}>
          <span className={styles.title}>Shipping costs:&nbsp;</span>
          <span>{cents2dollars(efficiency.shipping)}</span>
        </div>
        <div className={styles.string}>
          <span className={styles.title}>Fees:&nbsp;</span>
          <span>{cents2dollars(efficiency.fees)}</span>
        </div>
        <div className={styles.string}>
          <span className={styles.title}>Margin$:&nbsp;</span>
          <span>{cents2dollars(efficiency.margin)}</span>
        </div>
        <div className={styles.string}>
          <span className={styles.title}>Margin%:&nbsp;</span>
          <span>{efficiency.marginPercent}</span>
        </div>
        <div className={styles.string}>
          <span className={styles.title}>Shipments amount:&nbsp;</span>
          <span>{efficiency.shipmentAmount}</span>
        </div>
      </div>
    );
  },
);
