import { FC, useState } from 'react';

import { RightOutlined } from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';
import clsx from 'clsx';
import cn from 'clsx';
import { shipmentReasonDefaultOptions } from 'common/constants';
import { IShipmentItemWithControlAttrs } from 'common/types';
import {
  upperUndescoreToRegularCase,
  parseToShortDate,
  useModal,
  parseToLongDate,
  cents2dollars,
  removeStatusPrefix,
  combineString,
} from 'common/utils';
import { shipmentValidationSchema } from 'common/validation';
import { Form, FormSelect } from 'components/Form';
import { observer } from 'mobx-react-lite';
import { useFormContext } from 'react-hook-form';
import { ShipmentModel } from 'store/shipment-model';

import { OrderExpandedViewModel } from '../../../order-expanded-view-model';
import { EditShipmentModal } from '../EditShipmentModal';

import PurchaseItem from './PurchaseItem';

import styles from './shipment-item.module.scss';

interface IShipmentItemProps {
  shipment: ShipmentModel;
  handleShipmentCancel: (shipmment: ShipmentModel) => void;
  model: OrderExpandedViewModel;
}

const FormButtons = () => {
  const {
    formState: { isDirty, isValid },
  } = useFormContext();

  return (
    <div className={styles.btnWrapper}>
      <Button key="submit" htmlType="submit" disabled={!isDirty || !isValid}>
        Save
      </Button>
      <Button htmlType="reset">Discard</Button>
    </div>
  );
};

const ShipmentItem: FC<IShipmentItemProps> = observer(
  ({ shipment, handleShipmentCancel, model }) => {
    const { items } = shipment;
    const [isViewed, setIsViewed] = useState(false);
    const [isEdit, setEdit] = useState(false);
    const [openItemModal, handleItemModalOpen, handleItemModalClose] =
      useModal(false);
    const maxProductsCount = 10;

    const shipmentInitialValues = {
      supplier: shipment.supplier.name,
      reason: shipment.reason,
      supId: shipment.supId,
      trackingNumber: shipment.trackingNumber,
      costOfPurchase: cents2dollars(shipment.costOfPurchase),
      costOfShipping: cents2dollars(shipment.costOfShipping),
      comment: shipment.comment?.value,
    };

    const supplierName = shipment.supplier.user
      ? combineString([
          shipment.supplier.user.firstName,
          shipment.supplier.user.lastName,
        ])
      : shipment.supplier.name;

    const onlyFirstFiveProducts = items.slice(0, maxProductsCount);

    const photosList = onlyFirstFiveProducts.map((el) => {
      return el.photo;
    });

    const isCancelled = model.order.isCancelled;
    const isOngoingDispute = model.order.isOngoingDispute;

    const handleManageItems = () => {
      handleItemModalOpen();
    };

    const handleEdit = () => {
      setIsViewed(true);
      setEdit(true);
    };

    const handleDiscard = () => {
      setEdit(false);
    };

    const handleSave = async (
      values: Record<string, string | number | undefined>,
    ) => {
      setEdit(false);
      setIsViewed(true);
      model.changeShipmentFields(shipment.id, values);
    };

    const handleCancel = () => {
      handleShipmentCancel(shipment);
    };

    const toggleViewed = () => {
      setIsViewed(!isViewed);
    };

    const updateShipment = async (
      shipments: IShipmentItemWithControlAttrs[],
    ) => {
      model.changeShipmentItems(shipment.id, shipments);
    };

    return (
      <div className={cn(styles.shipmentItem)}>
        <div>
          <Form
            className={styles.shipmentForm}
            initialValues={shipmentInitialValues}
            validation={shipmentValidationSchema}
            onSubmit={handleSave}
            onReset={handleDiscard}
          >
            <div className={styles.shipmentContent}>
              <div className={styles.shipmentColumn}>
                <div className={styles.shipmentRow}>
                  <span className={styles.title}>
                    Shipment&nbsp;{shipment.id}
                  </span>
                  <div className={styles.photolist}>
                    {photosList.map((photo) => (
                      <img
                        key={photo.id}
                        src={photo.url}
                        alt={`${photo.key}`}
                        className={styles.image}
                      />
                    ))}
                  </div>
                  {items.length > maxProductsCount && (
                    <span>and&nbsp;etc...</span>
                  )}
                  <div className={styles.date}>
                    <span className={styles.title}>{`Creation date: `}</span>
                    <span>{parseToLongDate(shipment.createdAt)}</span>
                  </div>
                  <div className={styles.btnWrapper}>
                    <Button
                      onClick={handleManageItems}
                      disabled={isCancelled || isOngoingDispute}
                    >
                      Items
                    </Button>
                    <Popconfirm
                      placement="left"
                      destroyTooltipOnHide
                      rootClassName={styles.actions}
                      title={
                        <p className={styles.popconfirmMessage}>
                          You're cancelling the shipment <br />
                          This action cannot be undone <br />
                          Do you want to delete anyway?
                        </p>
                      }
                      onConfirm={handleCancel}
                    >
                      <Button disabled={isCancelled || isOngoingDispute}>
                        Delete
                      </Button>
                    </Popconfirm>
                  </div>
                </div>
                <div className={styles.shipmentFooter}>
                  <div className={styles.shipmentFooterContent}>
                    <RightOutlined
                      className={clsx(styles.icon, {
                        [styles.isViewed]: isViewed,
                        [styles.isNotViewed]: !isViewed,
                      })}
                      onClick={toggleViewed}
                    />
                    {isEdit ? (
                      <div className={styles.selectWrapper}>
                        <FormSelect
                          defaultValue={shipment.supplier.name}
                          options={model.supplierOptions}
                          name="supplier"
                          label="Supplier:"
                          selectClassName={styles.select}
                          className={styles.supplier}
                          showSearch
                        />
                        <FormSelect
                          showSearch
                          className={styles.shipReason}
                          name="reason"
                          label="Reason:"
                          options={shipmentReasonDefaultOptions}
                          selectClassName={styles.select}
                          defaultValue={shipment.reason}
                        />
                      </div>
                    ) : (
                      <div>
                        <div>
                          <span className={styles.title}>Supplier:&nbsp;</span>
                          <span>{supplierName}</span>
                        </div>
                        <div>
                          <span className={styles.title}>
                            Shipment's reason:&nbsp;
                          </span>
                          <span>
                            {upperUndescoreToRegularCase(
                              removeStatusPrefix(shipment.reason, 'SHIPMENT_'),
                            )}
                          </span>
                        </div>
                      </div>
                    )}
                    <div className={styles.trackingInfo}>
                      <div>
                        <span className={styles.title}>Date:&nbsp;</span>
                        <span>{parseToShortDate(shipment.trackingDate)}</span>
                      </div>
                      <div>
                        <span className={styles.title}>Status:&nbsp;</span>
                        <span>{shipment.trackingStatus}</span>
                      </div>
                    </div>
                  </div>
                  {isEdit ? (
                    <FormButtons />
                  ) : (
                    <Button
                      onClick={handleEdit}
                      disabled={isCancelled || isOngoingDispute}
                    >
                      Edit
                    </Button>
                  )}
                </div>
              </div>
            </div>
            {isViewed && (
              <div className={styles.shipmentDetailed}>
                <PurchaseItem shipment={shipment} isEdit={isEdit} />
              </div>
            )}
          </Form>
        </div>
        {openItemModal && (
          <EditShipmentModal
            openEditModal={openItemModal}
            handleEditModalClose={handleItemModalClose}
            updateShipment={updateShipment}
            shipmentItems={shipment.items}
            shipmentId={shipment.id}
            model={model}
          />
        )}
      </div>
    );
  },
);

export default ShipmentItem;
