import { FC } from 'react';

import { Button, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useCurrentUserStore } from 'common/providers/store-provider';
import {
  EPackageStatuses,
  EPackageTypes,
  IUnpaidShipments,
} from 'common/types/supplier';
import {
  cents2dollars,
  removeStatusPrefix,
  parseToShortDate,
  upperUndescoreToRegularCase,
  useModal,
} from 'common/utils';
import { Table, text } from 'components/Table';
import { TableTitle } from 'components/TableTitle';
import { observer } from 'mobx-react-lite';
import { PackageModel } from 'store/package-model';

import { PackagesViewModel } from '../packages-view-model';
import { PaymentModal } from '../PaymentModal';

import styles from './package-table.module.scss';

interface IPaymentButtonProps {
  onPayment: () => void;
  getUnpaidPackages: () => Promise<IUnpaidShipments | undefined>;
}

interface IPackageTableProps {
  model: PackagesViewModel;
}

const getColumns = (): ColumnsType<PackageModel> => [
  text({
    key: 'id',
    width: '150px',
    title: 'Record #',
    dataIndex: 'id',
  }),
  text({
    key: 'orderPublicId',
    width: '150px',
    title: 'Order #',
    dataIndex: 'orderPublicId',
  }),
  text({
    key: 'createdAt',
    width: '150px',
    title: 'Date',
    dataIndex: 'createdAt',
    render: (record: string) => <span>{parseToShortDate(record)}</span>,
  }),
  text({
    key: 'type',
    width: '150px',
    title: 'Type',
    dataIndex: 'type',
    filters: [
      {
        text: 'Shipment',
        value: EPackageTypes.SHIPMENT,
      },
      {
        text: 'Return',
        value: EPackageTypes.RETURN,
      },
    ],
    filterMultiple: false,
    render: (record: string) => (
      <Tag>{upperUndescoreToRegularCase(record)}</Tag>
    ),
  }),
  text({
    key: 'itemsCost',
    width: '150px',
    title: 'Cost of goods/purchase',
    dataIndex: 'itemsCost',
    render: (record: number) => <span>{cents2dollars(record)} $</span>,
  }),
  text({
    key: 'shippingCost',
    width: '150px',
    title: 'Cost of shipping',
    dataIndex: 'shippingCost',
    render: (record: number) => <span>{cents2dollars(record)} $</span>,
  }),
  text({
    key: 'status',
    width: '150px',
    title: 'Status',
    dataIndex: 'status',
    filters: [
      {
        text: 'Paid',
        value: EPackageStatuses.SHIPMENT_PAYMENT_PAID,
      },
      {
        text: 'Unpaid',
        value: EPackageStatuses.SHIPMENT_PAYMENT_UNPAID,
      },
    ],
    filterMultiple: false,
    render: (record: string) => (
      <Tag>
        {upperUndescoreToRegularCase(
          removeStatusPrefix(record, 'SHIPMENT_PAYMENT_'),
        )}
      </Tag>
    ),
  }),
];

const PaymentButton: FC<IPaymentButtonProps> = ({
  onPayment,
  getUnpaidPackages,
}) => {
  const currentUserStore = useCurrentUserStore();
  const [isOpen, openModal, closeModal] = useModal(false);

  const { isSupplier } = currentUserStore;

  const handlePayment = () => {
    onPayment();
    closeModal();
  };

  return (
    <>
      <Button
        type="primary"
        size="middle"
        onClick={openModal}
        disabled={isSupplier}
        className={styles.button}
      >
        Pay for all
      </Button>
      <PaymentModal
        open={isOpen}
        onCancel={closeModal}
        onPayment={handlePayment}
        getUnpaidPackages={getUnpaidPackages}
      />
    </>
  );
};

export const PackageTable: FC<IPackageTableProps> = observer(({ model }) => (
  <>
    <PaymentButton
      onPayment={() => model.payForPackages()}
      getUnpaidPackages={() => model.getUnpaidPackages()}
    />
    <div className={styles.content}>
      <Table
        title={() => <TableTitle title="Orders" />}
        columns={getColumns()}
        data={model.packages}
        pagination={{
          total: model.filters.count,
          disabled: model.isLoading,
          current: model.filters.page,
          pageSize: model.filters.pageSize,
        }}
        onChange={model.changeFilters}
        bordered
      />
    </div>
  </>
));
