import { FC, ReactNode } from 'react';

import { CaretRightOutlined } from '@ant-design/icons';
import { Collapse as AntdCollapse, CollapseProps } from 'antd';

export interface ICollapceProps extends Omit<CollapseProps, 'expandIcon'> {
  header: string;
  children: ReactNode;
}

export const Collapse: FC<ICollapceProps> = ({
  header,
  children,
  ...props
}) => (
  <AntdCollapse
    expandIcon={({ isActive }) => (
      <CaretRightOutlined rotate={isActive ? 90 : 0} />
    )}
    {...props}
  >
    <AntdCollapse.Panel key="1" header={header}>
      {children}
    </AntdCollapse.Panel>
  </AntdCollapse>
);
