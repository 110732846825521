import { FC } from 'react';

import { Button } from 'antd';
import { returnCommentValidationSchema } from 'common/validation';
import { FormTextArea } from 'components/Form';
import { Form } from 'components/Form';
import { ReturnShipmentModel } from 'store/return-shipment-model';

import styles from './editable-data.module.scss';

interface IEditableDataProps {
  returnItem: ReturnShipmentModel;
  handleEdit: (
    id: number,
    values: Record<string, string | number | undefined>,
  ) => void;
  toggleEdit: () => void;
}

export const EditableData: FC<IEditableDataProps> = ({
  returnItem,
  handleEdit,
  toggleEdit,
}) => {
  const initialValues = {
    comment: returnItem.comment?.value,
  };

  const handleSubmit = async (
    values: Record<string, string | number | undefined>,
  ) => {
    handleEdit(returnItem.id, values);
    toggleEdit();
  };

  return (
    <Form
      initialValues={initialValues}
      validation={returnCommentValidationSchema}
      onSubmit={handleSubmit}
      className={styles.container}
    >
      <FormTextArea
        label="Comment"
        name="comment"
        className={styles.textarea}
      />
      <div className={styles.buttons}>
        <Button htmlType="submit">Save</Button>
        <Button htmlType="button" onClick={toggleEdit}>
          Discard
        </Button>
      </div>
    </Form>
  );
};
