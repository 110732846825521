export enum EUserRoles {
  MANAGER = 'MANAGER',
  SUPPLIER = 'SUPPLIER',
  ADMIN = 'ADMIN',
}
export interface ICurrentUser {
  email: string;
  adminId: number;
  exp: number;
  iat: number;
  uuid: string;
  role: EUserRoles;
}

export interface ILoginParams {
  email: string;
  password: string;
}

export interface ILogin {
  accessToken: string;
}
