import { AxiosResponse } from 'axios';
import { IPageParams } from 'common/types';
import { ISupplierInvoicesResponse } from 'common/types/supplier-invoice';

import { $host } from './index';

export const getSupplierInvoices = async (id: number, params?: IPageParams) => {
  const {
    data: { data },
  } = await $host.get<AxiosResponse<ISupplierInvoicesResponse>>(
    `admin/supplier-invoices/employee/${id}`,
    { params },
  );

  return data;
};

export const getSupplierInvoicePDF = async (id: number) => {
  const { data } = await $host.get<Blob>(`admin/supplier-invoices/${id}/pdf`, {
    responseType: 'blob',
  });

  return data;
};

export const paySupplierInvoices = async (id: number) => {
  const {
    data: { data },
  } = await $host.post<AxiosResponse<unknown>>(
    `admin/supplier-invoices/employee/${id}`,
  );

  return data;
};
