import { FC } from 'react';

import { cents2dollars } from 'common/utils';
import { Table, text } from 'components/Table';
import { TableTitle } from 'components/TableTitle';
import { observer } from 'mobx-react-lite';

import { CalculationViewModel } from '../calculation-view-model';

interface ICalculationTableProps {
  model: CalculationViewModel;
}

const getColumns = () => [
  text({
    key: 'correction',
    width: '150px',
    title: 'Shipment correction',
    dataIndex: 'correction',
    render: (record: number) => <span>{cents2dollars(record)} $</span>,
  }),
  text({
    key: 'returns',
    width: '150px',
    title: 'Unpaid returns',
    dataIndex: 'returns',
    render: (record: number) => <span>{cents2dollars(record)} $</span>,
  }),
  text({
    key: 'shipments',
    width: '150px',
    title: 'Unpaid shipments',
    dataIndex: 'shipments',
    render: (record: number) => <span>{cents2dollars(record)} $</span>,
  }),
  text({
    key: 'total',
    width: '150px',
    title: 'Total',
    dataIndex: 'total',
    render: (record: number) => <span>{cents2dollars(record)} $</span>,
  }),
];

export const CalculationTable: FC<ICalculationTableProps> = observer(
  ({ model }) => (
    <div>
      <Table
        title={() => <TableTitle title="Calculations" />}
        columns={getColumns()}
        data={model.calculations}
        loading={model.isLoading}
        bordered
      />
    </div>
  ),
);
