import React, { FC, useState } from 'react';

import { Radio, RadioChangeEvent } from 'antd';
import { observer } from 'mobx-react-lite';

import { OrderExpandedViewModel } from '../../order-expanded-view-model';

import { CommentsTable } from './CommentsTable';
import { HistoryTable } from './HistoryTable';

import styles from './styles.module.scss';

type TabState = 'history' | 'comments';

interface IHistorySectionProps {
  model: OrderExpandedViewModel;
}

export const HistorySection: FC<IHistorySectionProps> = observer(
  ({ model }) => {
    const [currentTab, setCurrentTab] = useState<TabState>('history');

    const handleTabChange = (event: RadioChangeEvent) => {
      setCurrentTab(event.target.value);
    };

    return (
      <div className={styles.historySection}>
        <div className={styles.header}>
          <span className={styles.title}>History</span>
          <Radio.Group value={currentTab} onChange={handleTabChange}>
            <Radio.Button value="history">Order history</Radio.Button>
            {/* 
            TODO: temporary disabled
            <Radio.Button value="comments">Comments list</Radio.Button> 
            */}
          </Radio.Group>
        </div>
        <div className={styles.tableSection}>
          {currentTab === 'history' ? (
            <HistoryTable model={model} />
          ) : (
            <CommentsTable model={model} />
          )}
        </div>
      </div>
    );
  },
);
