import {
  changeOrderComment,
  changeOrderManager,
  getOrder,
} from 'http/orderApi';

import { ILongOrder, IServerError, IShortOrder } from 'common/types';
import { notify } from 'common/utils';
import { makeAutoObservable } from 'mobx';

import { ShortOrderModel } from '../models/short-order-model';

import { ExpandedOrderViewModel } from './expanded-order-view-model';
import { OrdersViewModel } from './orders-view-model';

export class OrderViewModel {
  _root: OrdersViewModel | null = null;
  _isLoading = false;
  _isCommentModalOpen = false;
  _shortOrder: ShortOrderModel;
  _expandedOrderViewModel: ExpandedOrderViewModel | null = null;

  get root() {
    return this._root;
  }

  get isLoading() {
    return this._isLoading;
  }

  set isLoading(isLoading: boolean) {
    this._isLoading = isLoading;
  }

  get isCommentModalOpen() {
    return this._isCommentModalOpen;
  }

  set isCommentModalOpen(isCommentModalOpen: boolean) {
    this._isCommentModalOpen = isCommentModalOpen;
  }

  get shortOrder() {
    return this._shortOrder;
  }

  set shortOrder(order: ShortOrderModel) {
    this._shortOrder = order;
  }

  get expandedOrderViewModel() {
    return this._expandedOrderViewModel;
  }

  set expandedOrderViewModel(
    expandedOrderViewModel: ExpandedOrderViewModel | null,
  ) {
    this._expandedOrderViewModel = expandedOrderViewModel;
  }

  onOrderExpand = async (isExpanded: boolean) => {
    if (isExpanded) {
      this.expandedOrderViewModel = new ExpandedOrderViewModel(
        this,
        this.shortOrder.id,
      );
    } else {
      this.expandedOrderViewModel = null;
    }
  };

  onCommentModalOpenChange = (isCommentModalOpen: boolean) => {
    this.isCommentModalOpen = isCommentModalOpen;
  };

  /* 
    TODO: specific request may not return required fields
    so we need to get whole order and 
    rerender dependant fields
  */
  syncOrderData = async (handling?: boolean) => {
    try {
      handling && (this.isLoading = true);

      const { data: order } = await getOrder(this.shortOrder.id);

      this.shortOrder = new ShortOrderModel(order as ILongOrder);
      this.expandedOrderViewModel?.syncOrderData(order as ILongOrder);

      return order;
    } catch (e) {
      if (!handling) {
        throw e;
      }

      const error = e as IServerError;

      notify(`Error, reason: ${error.response?.data?.message}`, 'error');
    } finally {
      handling && (this.isLoading = false);
    }
  };

  updateManager = async (id: number, value: string) => {
    try {
      this.isLoading = true;

      const updatedOrder = await changeOrderManager(id, value);

      this.shortOrder = new ShortOrderModel(updatedOrder);
    } catch (e) {
      const error = e as IServerError;

      notify(error.response.data.message, 'error');
    } finally {
      this.isLoading = false;
    }
  };

  updateComment = async (id: number, value: string) => {
    try {
      this.isLoading = true;

      const updatedOrder = await changeOrderComment(id, value);

      this.shortOrder = new ShortOrderModel(updatedOrder);
    } catch (e) {
      const error = e as IServerError;

      notify(error.response.data.message, 'error');
    } finally {
      this.isLoading = false;
    }
  };

  constructor(root: OrdersViewModel | null, order: IShortOrder) {
    this._root = root;
    this._shortOrder = new ShortOrderModel(order);

    makeAutoObservable(this);
  }
}
