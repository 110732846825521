import { FC } from 'react';

import {
  CheckOutlined,
  MinusOutlined,
  PlusOutlined,
  CheckCircleFilled,
  CloseCircleOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import { Tooltip, Modal } from 'antd';
import cn from 'clsx';
import { useModal } from 'common/utils';

import styles from './image.module.scss';

const buttonProps = { className: styles.hideButton };

export interface IImageProps {
  // ToDo inherit props from TPhoto
  id: number;
  url: string;
  selected?: boolean;
  main?: boolean;
  disabled?: boolean;
  block?: boolean;
  zoom?: boolean;
  setMain?: (id: number) => void;
  selectImage?: (id: number) => void;
  unselectImage?: (id: number) => void;
  handleDelete?: (id: number) => void;
  handleZoom?: (id: number) => void;
}

export const Image: FC<IImageProps> = ({
  id,
  url,
  selected,
  main,
  zoom,
  block = false,
  disabled = false,
  setMain,
  selectImage,
  unselectImage,
  handleDelete,
}) => {
  const [isOpen, openModal, closeModal] = useModal(false);

  return (
    <div
      className={cn(styles.imageContainer, {
        [styles.selected]: selected,
        [styles.disabled]: disabled,
        [styles.block]: block,
        [styles.action]: zoom || !!setMain,
      })}
    >
      {disabled && (
        <div
          className={cn(styles.disabledOverlay, {
            [styles.block]: block,
          })}
        />
      )}
      {setMain && main && (
        <CheckCircleFilled className={cn(styles.mainIcon, styles.checked)} />
      )}
      {!disabled && handleDelete && (
        <CloseCircleOutlined
          disabled={disabled}
          className={cn(styles.mainIcon, styles.delete)}
          onClick={() => !disabled && handleDelete(id)}
        />
      )}
      {zoom && (
        <Modal
          open={isOpen}
          onOk={closeModal}
          onCancel={closeModal}
          cancelButtonProps={buttonProps}
          okButtonProps={buttonProps}
          destroyOnClose
        >
          <div className={styles.modalContent}>
            <img src={url} height="100%" width="100%" alt="" />
          </div>
        </Modal>
      )}
      <img className={styles.image} src={url} alt="" />
      <div
        className={cn(styles.buttonsContainer, {
          [styles.block]: block,
        })}
      >
        {zoom && (
          <Tooltip title="Zoom image">
            <EyeOutlined
              disabled={disabled}
              onClick={openModal}
              className={styles.icon}
            />
          </Tooltip>
        )}
        {setMain && selected && !main && (
          <Tooltip title="Set this image as main">
            <CheckOutlined
              disabled={disabled}
              onClick={() => !disabled && setMain(id)}
              className={styles.icon}
            />
          </Tooltip>
        )}
        {unselectImage && selected && (
          <Tooltip title="Remove this image from variation images">
            <MinusOutlined
              disabled={disabled}
              onClick={() => !disabled && unselectImage?.(id)}
              className={styles.icon}
            />
          </Tooltip>
        )}
        {selectImage && !selected && (
          <Tooltip title="Add this image to variation images">
            <PlusOutlined
              disabled={disabled}
              onClick={() => !disabled && selectImage?.(id)}
              className={styles.icon}
            />
          </Tooltip>
        )}
      </div>
    </div>
  );
};
