// TODO: Make common component at PackageDetails/EditableData
import { FC } from 'react';

import { Button } from 'antd';
import { TOption } from 'common/types';
import { returnValidationSchema } from 'common/validation';
import { FormInput, FormSelect } from 'components/Form';
import { Form } from 'components/Form';
import { useFormContext } from 'react-hook-form';

import styles from './editable-data.module.scss';

interface IEditableDataProps {
  costOfGoods: number;
  supplier: string;
  supplierOptions: TOption[];
  responsible: string;
  responsibleOptions: TOption[];
  reason: string;
  reasonOptions: TOption[];
  disabled: boolean;
  isLoading?: boolean;
  onToggleEdit: () => void;
  onEdit: (values: Record<string, any>) => Promise<void>;
}

interface IFormButtons {
  disabled: boolean;
  isLoading?: boolean;
  onDiscard: () => void;
}

const FormButtons: FC<IFormButtons> = ({ disabled, isLoading, onDiscard }) => {
  const {
    formState: { isDirty, isValid },
  } = useFormContext();

  return (
    <div className={styles.buttons}>
      <Button
        htmlType="submit"
        disabled={disabled || !isDirty || !isValid}
        loading={isLoading}
      >
        Save
      </Button>
      <Button htmlType="button" onClick={onDiscard}>
        Discard
      </Button>
    </div>
  );
};

export const EditableData: FC<IEditableDataProps> = ({
  costOfGoods,
  supplier,
  supplierOptions,
  responsible,
  responsibleOptions,
  reason,
  reasonOptions,
  disabled,
  isLoading,
  onToggleEdit,
  onEdit,
}) => {
  const initialValues = {
    costOfGoods: costOfGoods,
    supplier: supplier,
    responsible: responsible,
    reason: reason,
  };

  const onSubmit = async (values: any) => {
    await onEdit(values);
  };

  return (
    <Form
      initialValues={initialValues}
      validation={returnValidationSchema}
      className={styles.container}
      onSubmit={onSubmit}
    >
      <div className={styles.content}>
        <FormInput
          defaultValue={costOfGoods}
          name="costOfGoods"
          label="Cost of goods:"
          prefix="$"
        />
        <FormSelect
          defaultValue={supplier}
          options={supplierOptions}
          name="supplier"
          label="Supplier:"
          showSearch
        />
        <FormSelect
          defaultValue={responsible}
          options={responsibleOptions}
          name="responsible"
          label="Responsible:"
          showSearch
        />
        <FormSelect
          defaultValue={reason}
          name="reason"
          label="Reason:"
          options={reasonOptions}
          showSearch
        />
      </div>
      <FormButtons
        disabled={disabled}
        isLoading={isLoading}
        onDiscard={onToggleEdit}
      />
    </Form>
  );
};
