import { ILog } from 'common/types/log';
import { parseToLongDate, upperUndescoreToRegularCase } from 'common/utils';
import { makeAutoObservable } from 'mobx';

export class LogModel {
  _log: ILog;

  get log() {
    return this._log;
  }

  set log(log: ILog) {
    this._log = log;
  }

  get id() {
    return this.log.id;
  }

  get createdAtView() {
    return parseToLongDate(this.log.createdAt) as string;
  }

  get actionView() {
    return upperUndescoreToRegularCase(this.log.action);
  }

  get authorView() {
    return `${this.log.author.firstName} ${this.log.author.lastName}`;
  }

  constructor(log: ILog) {
    this._log = log;

    makeAutoObservable(this);
  }
}
