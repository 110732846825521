import { FC } from 'react';

import { MetaForm } from 'components/MetaForm';
import { observer } from 'mobx-react-lite';

import { MainViewModel } from '../main-view-model';

interface IBrandsMetaProps {
  model: MainViewModel;
}

export const BrandsMeta: FC<IBrandsMetaProps> = observer(({ model }) => (
  <MetaForm
    title="Brands meta"
    values={model.brandsMeta}
    onSubmit={model.onBrandsMetaChange}
    isLoading={model.loading}
  />
));
