import {
  createSubcategory,
  editFeedDestinations,
  editSubcategory,
  getSubcategoryById,
} from 'http/categoryApi';

import { defaultSubcategoryEntity } from 'common/constants';
import { ICreateSubcategory, ISubcategory } from 'common/types';
import { EStatuses } from 'common/types/enums';
import { notify } from 'common/utils';
import { makeAutoObservable } from 'mobx';
import { BrandModel } from 'store/brand-model';

export class SubcategoryModel {
  _loading = false;
  _subcategory: ISubcategory = defaultSubcategoryEntity;
  _brand: BrandModel = new BrandModel();

  get loading() {
    return this._loading;
  }

  set loading(loading: boolean) {
    this._loading = loading;
  }

  get subcategory() {
    return this._subcategory;
  }

  set subcategory(subcategory: ISubcategory) {
    this._subcategory = subcategory;
  }

  get brand() {
    return this._brand;
  }

  set brand(brand: BrandModel) {
    this._brand = brand;
  }

  get title() {
    return this.subcategory.title;
  }

  get id() {
    return this.subcategory.id;
  }

  get photo() {
    return this.subcategory.photo;
  }

  get brandId() {
    return this.subcategory.brandId;
  }

  get description() {
    return this.subcategory.description;
  }

  get productsCount() {
    return this.subcategory.productsCount;
  }

  get status() {
    return this.subcategory.status;
  }

  get googleMerchantsDestinations() {
    return this.subcategory.googleMerchantsDestinations;
  }

  get metaTitle() {
    return this.subcategory.metaTitle ?? '';
  }

  get metaDescription() {
    return this.subcategory.metaDescription ?? '';
  }

  serializeSubcategory = (values: ICreateSubcategory) => {
    const fd = new FormData();
    const status = values.isArchive ? EStatuses.ARCHIVE : EStatuses.ACTIVE;

    fd.append('title', values.title);
    fd.append('brandId', values.brandId.toString());
    fd.append('description', values.description);
    fd.append('status', status);

    if (values.photo && values.photo.file) {
      fd.append('photo', values.photo.file);
    }

    if (values.googleMerchantsDestinations.length) {
      fd.append(
        'googleMerchantsDestinations',
        values.googleMerchantsDestinations.toString(),
      );
    }

    fd.append('metaTitle', values.metaTitle);
    fd.append('metaDescription', values.metaDescription);

    return fd;
  };

  fetchSubcategory = async (id: number) => {
    try {
      const subcategory = await getSubcategoryById(id);

      if (subcategory) {
        this.subcategory = subcategory;
        this.brand = new BrandModel(subcategory.brandId);
      }
    } catch (e) {
      notify('Can`t load category', 'error');

      setTimeout(() => {
        window.location.href = '/subcategory/new';
      }, 500);
    }
  };

  manageSubcategory = async (isUpdate: boolean, values: ICreateSubcategory) => {
    this.loading = true;

    const serializedSubcategory = this.serializeSubcategory(values);

    try {
      if (isUpdate) {
        await editSubcategory(this.id, serializedSubcategory);
        await editFeedDestinations(this.id, {
          googleMerchantsDestinations: values.googleMerchantsDestinations,
        });
      } else {
        await createSubcategory(serializedSubcategory);
      }

      notify(
        <div>
          Category <strong>{values.title}</strong> was{' '}
          {isUpdate ? 'updated' : 'created'} succesfully!
        </div>,
      );

      return true;
    } catch (e) {
      notify(`Failed to ${isUpdate ? 'update' : 'create'} Category`, 'error');
    } finally {
      this.loading = false;
    }
  };

  fetchData = async (subcategoryId?: number, subcategory?: ISubcategory) => {
    this.loading = true;

    try {
      if (subcategory) {
        this._subcategory = subcategory;
        this._brand = new BrandModel(subcategory.brandId, subcategory.brand);
      }

      if (subcategoryId && !subcategory) {
        await this.fetchSubcategory(subcategoryId);
      }
    } catch (e) {
      notify(`Error, reason:${e}`, 'error');
    } finally {
      this.loading = false;
    }
  };

  constructor(subcategoryId?: number, subcategory?: ISubcategory) {
    this.fetchData(subcategoryId, subcategory);

    makeAutoObservable(this);
  }
}
