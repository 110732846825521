import { FC } from 'react';

import { observer } from 'mobx-react-lite';
import { ShipmentViewModel } from 'pages/NewOrder/view-models/shipment-view-model';

import { StaticData } from '../../../PackageDetails/StaticData';

import { EditableData } from './EditableData';

interface IShipmentDetailsProps {
  model: ShipmentViewModel;
  disabled: boolean;
}

export const ShipmentDetails: FC<IShipmentDetailsProps> = observer(
  ({ model, disabled }) => {
    const staticField = [
      { label: 'Supplier', value: model.supplierView },
      { label: 'Reason', value: model.reasonView },
      { label: 'Supplier ID', value: model.supId },
      {
        label: 'Tracking number',
        value: model.trackingNumber,
        href: `natalexauto.aftership.com/${model.trackingNumber}`,
      },
      { label: 'Cost of purchase', value: model.costOfPurchaseView },
      { label: 'Cost of shipping', value: model.costOfShippingView },
      { label: 'Comment', value: model.comment?.value },
    ];
    return (
      <>
        {model.isEditing ? (
          <EditableData
            supplier={model.supplier.name}
            supplierOptions={
              model.root?.root?.root?.root?.supplierOptions ?? []
            }
            reason={model.reason}
            reasonOptions={
              model.root?.root?.root?.root?.defaultShipmentReasonOptions ?? []
            }
            supId={model.supId}
            trackingNumber={model.trackingNumber}
            costOfPurchase={model.costOfPurchaseView}
            costOfShipping={model.costOfShippingView}
            comment={model.comment?.value}
            disabled={disabled}
            isLoading={model.isLoading}
            onToggleEdit={model.onToggleEditing}
            onEdit={model.onShipmentUpdate}
          />
        ) : (
          <StaticData
            fields={staticField}
            disabled={disabled}
            onToggleEdit={model.onToggleEditing}
          />
        )}
      </>
    );
  },
);
