import { FC } from 'react';

import { Modal, Typography } from 'antd';

import { OrderExpandedViewModel } from '../../../../../order-expanded-view-model';
import { DndOrderLinksList } from '../DndOrderLinksList';

import styles from './order-links-modal.module.scss';

interface IOrderLinksModal {
  open: boolean;
  model: OrderExpandedViewModel;
  variationId: number;
  orderLinks: string[];
  onOk: () => void;
  onCancel: () => void;
}

const modalButtonProps = { className: styles.buttonHidden };

const { Title } = Typography;

export const OrderLinksModal: FC<IOrderLinksModal> = ({
  open,
  model,
  variationId,
  orderLinks,
  onOk,
  onCancel,
}) => (
  <Modal
    open={open}
    okButtonProps={modalButtonProps}
    cancelButtonProps={modalButtonProps}
    destroyOnClose
    onOk={onOk}
    onCancel={onCancel}
  >
    <Title className={styles.title} level={5}>
      Purchase link
    </Title>
    <DndOrderLinksList
      name="link"
      model={model}
      variationId={variationId}
      orderLinks={orderLinks}
      disabled={model.order.disabled}
      closeModal={onCancel}
    />
  </Modal>
);
