import { FC } from 'react';

import { Select } from 'antd';
import Search from 'antd/es/input/Search';
import { orderStatusCategoryColors, dateFilterParams } from 'common/constants';
import { TCategorizedDynamicOptions } from 'common/types';
import { ColoredMultiSelect } from 'components/ColoredMultiSelect';

import styles from './table-filter.module.scss';

interface ITableFilter {
  isLoading: boolean;
  getOrderStatusOptions: () => TCategorizedDynamicOptions;
  onSearch: (value: string) => void;
  onSearchEmail: (value: string) => void;
  onStatusChange: (values: string[]) => void;
  onDateChange: (value: string) => void;
}

const TableFilter: FC<ITableFilter> = ({
  isLoading,
  getOrderStatusOptions,
  onSearch,
  onSearchEmail,
  onStatusChange,
  onDateChange,
}) => (
  <div className={styles.container}>
    <div className={styles.field}>
      <span>Search</span>
      <Search
        placeholder="Search by input"
        disabled={isLoading}
        allowClear
        onSearch={onSearch}
      />
    </div>
    <div className={styles.field}>
      <span>Search by Email</span>
      <Search
        placeholder="Search by email"
        disabled={isLoading}
        allowClear
        onSearch={onSearchEmail}
      />
    </div>
    <div className={styles.field}>
      <span>Date</span>
      <Select
        placeholder="Sort by status"
        defaultValue="DESC"
        options={dateFilterParams}
        disabled={isLoading}
        onChange={onDateChange}
      />
    </div>
    <div className={styles.field}>
      <span>Order status</span>
      <ColoredMultiSelect
        placeholder="Filter by order status"
        colors={orderStatusCategoryColors}
        categoryDivider=":"
        disabled={isLoading}
        onChange={onStatusChange}
        getOptions={getOrderStatusOptions}
      />
    </div>
  </div>
);

export default TableFilter;
