import { getLogsByOrderId } from 'http/logs';
import {
  cancelOrderById,
  changeOrderFees,
  changeUserData,
  createInvoice,
  createRefund,
  getOrder,
} from 'http/orderApi';
import { editVariationOrderLinks, updatePurchases } from 'http/purchaseApi';
import {
  createReturnShipment,
  deleteReturnShipment,
  editReturnComment,
  editReturnShipment,
  finishReturnShipment,
} from 'http/returnShipment';
import {
  deleteShipment,
  changeShipmentVariations,
  createShipment,
  editShipment,
} from 'http/shipments';

import { AxiosError } from 'axios';
import {
  defaultDetailedOrderEntity,
  defaultImage,
  defaultUserDataEntity,
  defaultUserEntity,
} from 'common/constants';
import {
  IItemStats,
  IProductKit,
  IRefundParams,
  IServerError,
  IShipmentItemWithControlAttrs,
  IUser,
  IUserData,
  TEditableUserDataParams,
} from 'common/types';
import { EResponsibles } from 'common/types/enums';
import { ILog } from 'common/types/log';
import { EPaymentCancelledStatuses } from 'common/types/order/enums';
import {
  IPurchaseWithSelected,
  IOrderItem,
  TPurchaseItems,
} from 'common/types/purchase';
import { EReturnReasons } from 'common/types/return/enums';
import {
  IReturnShipmentItem,
  IReturnShipmentItemWithControlAttrs,
  IReturnWithSelected,
} from 'common/types/return-shipment';
import { EShipmentReasons, ESuppliers } from 'common/types/shipment/enums';
import {
  dollars2cents,
  notify,
  reduceOrderItems,
  reduceReturnShipmentItems,
} from 'common/utils';
import { makeAutoObservable } from 'mobx';
import { DetailedOrderModel } from 'store/order/detailed-order-model';
import { PurchaseModel } from 'store/purchase-model';
import { ReturnShipmentModel } from 'store/return-shipment-model';
import { ShipmentModel } from 'store/shipment-model';

import { OrderTableRowViewModel } from './order-table-row-view-model';

type orderLinksWithIds = { id: number; url: string };

export class OrderExpandedViewModel {
  _root: OrderTableRowViewModel | null = null;
  _loading = true;
  _logs: ILog[] = [];
  _purchases: PurchaseModel[] = [];
  _shipments: ShipmentModel[] = [];
  _returnShipments: ReturnShipmentModel[] = [];
  _order: DetailedOrderModel = new DetailedOrderModel(
    defaultDetailedOrderEntity,
  );
  _user: IUser = defaultUserEntity;
  _userData: IUserData = defaultUserDataEntity;
  _itemStats: IItemStats[] = [];

  get root() {
    return this._root;
  }

  get loading() {
    return this._loading;
  }

  set loading(value: boolean) {
    this._loading = value;
  }

  get logs() {
    return this._logs;
  }

  set logs(logs: ILog[]) {
    this._logs = logs;
  }

  get purchases() {
    return this._purchases;
  }

  set purchases(purchases: PurchaseModel[]) {
    this._purchases = purchases;
  }

  get order() {
    return this._order;
  }

  set order(order: DetailedOrderModel) {
    this._order = order;
  }

  get shipments() {
    return this._shipments;
  }

  set shipments(shipments: ShipmentModel[]) {
    this._shipments = shipments;
  }

  set returnShipments(shipments: ReturnShipmentModel[]) {
    this._returnShipments = shipments;
  }

  get returnShipments() {
    return this._returnShipments;
  }

  get user() {
    return this._user;
  }

  set user(user: IUser) {
    this._user = user;
  }

  get userData() {
    return this._userData;
  }

  set userData(userData: IUserData) {
    this._userData = userData;
  }

  get itemStats() {
    return this._itemStats;
  }

  set itemStats(itemStats: IItemStats[]) {
    this._itemStats = itemStats;
  }

  get skeleton() {
    return new Array(3).fill(new PurchaseModel());
  }

  get defaultResponsibleOptions() {
    return this.root?.root?.defaultResponsibleOptions ?? [];
  }

  get responsibleOptions() {
    return this.root?.root?.responsibleOptions ?? [];
  }

  get supplierOptions() {
    return this.root?.root?.supplierOptions ?? [];
  }

  formatifyPurchase(orderItem: IOrderItem) {
    const { variation } = orderItem;
    const supplier = orderItem.shipmentItems?.[0]
      ? orderItem.shipmentItems?.[0].shipment.supplier
      : '';

    const reasons = Array.from(
      orderItem.shipmentItems.reduce((acc, curr) => {
        acc.add(curr.shipment.reason);

        return acc;
      }, new Set<string>()),
    );

    const { content, product } = variation;
    const photo =
      content.photos?.find((photo) => photo.id === content.mainPhotoId)?.url ||
      defaultImage;

    return {
      id: orderItem.id,
      image: photo,
      title: product.title,
      variation: orderItem.variation,
      variationId: variation.id,
      productKit: orderItem.productKit,
      price: content.price,
      sku: content.sku || '',
      orderLink: content.order_link,
      quantity: orderItem.quantity,
      discount: orderItem.variation.content.discount,
      adjustedPrice: orderItem.adjustedPrice,
      totalPrice: orderItem.variation.content.totalPrice,
      supplier: supplier as ESuppliers,
      shipmentReasons: reasons as EShipmentReasons[],
      refund: 0,
      sum: 0,
    };
  }

  formatifyPurchases(orderItems: IOrderItem[]) {
    const formattedPurchases = orderItems.map((orderItem: IOrderItem) => {
      const formattedPurchaseData = this.formatifyPurchase(orderItem);

      return new PurchaseModel(formattedPurchaseData);
    });

    return formattedPurchases.sort(
      (a, b) => a.title.charCodeAt(0) - b.title.charCodeAt(0),
    );
  }

  getPurchasesKitsIds = (purchases: { productKit: IProductKit | null }[]) => {
    const productKitIdsSet = purchases.reduce((acc, el) => {
      if (el.productKit) {
        acc.add(el.productKit.id);
      }

      return acc;
    }, new Set<number>());

    return Array.from(productKitIdsSet);
  };

  getReturnItemsKitsIds = (
    returnItems: { orderItem: { productKit: IProductKit | null } }[],
  ) => {
    const productKitIdsSet = returnItems.reduce((acc, el) => {
      if (el.orderItem.productKit) {
        acc.add(el.orderItem.productKit.id);
      }

      return acc;
    }, new Set<number>());

    return Array.from(productKitIdsSet);
  };

  getOrderLogs = async (orderId: number) => {
    this.loading = true;

    try {
      const { data } = await getLogsByOrderId(orderId);

      this.logs = data.items;
    } catch (e) {
      notify(`Error, reason:${e}`, 'error');
    } finally {
      this.loading = false;
    }
  };

  cancelOrder = async (
    id: number,
    reason: keyof typeof EPaymentCancelledStatuses,
  ) => {
    this.loading = true;

    try {
      const updatedOrder = await cancelOrderById(id, {
        reason,
      });

      this.root &&
        (this.root.order.order = {
          ...this.root.order.order,
          ...updatedOrder,
        });

      this.order = new DetailedOrderModel(updatedOrder);
    } catch (e) {
      notify(`Order #${id} has shipments. Could not cancel`, 'error');
    } finally {
      this.loading = false;
    }
  };

  createInvoice = async (id: number, params: { items: TPurchaseItems }) => {
    this.loading = true;

    try {
      const updatedOrder = await createInvoice(id, params);

      this.root &&
        (this.root.order.order = {
          ...this.root.order.order,
          ...updatedOrder,
        });
    } catch (e) {
      notify(`Error, reason:${e}`, 'error');
      throw new Error();
    } finally {
      this.loading = false;
    }
  };

  createRefund = async (id: number, params: IRefundParams) => {
    this.loading = true;

    try {
      const updatedOrder = await createRefund(id, params);

      this.root &&
        (this.root.order.order = {
          ...this.root.order.order,
          ...updatedOrder,
        });

      this.order = new DetailedOrderModel({
        ...this.order.order,
        ...updatedOrder,
      });
    } catch (e: unknown) {
      if (e instanceof AxiosError) {
        notify(`${e.response?.data.message}`, 'error');

        throw new Error();
      }
    } finally {
      this.loading = false;
    }
  };

  handleChangeRefund(val: number | null, id: number) {
    const getPurchaseById = this._purchases.find((el) => el.id === id);

    if (getPurchaseById) {
      if (val) {
        getPurchaseById.refund = val;
      } else {
        getPurchaseById.refund = 0;
      }
    }
  }

  async changeFees(id: number, newValue: number) {
    this.loading = true;
    const order = await changeOrderFees(id, newValue);
    this.order = new DetailedOrderModel(order);
    this.loading = false;
  }

  async editOrderLinks(links: orderLinksWithIds[], variationId: number) {
    this.loading = true;
    const newLinks = links
      .filter((el) => el.url.trim().length)
      .map((el) => el.url);

    const params = { variationId, order_link: newLinks };
    const updatedOrder = await editVariationOrderLinks(this.order.id, params);

    if (updatedOrder) {
      this.fetchPurchases(this.order.id);
      notify('Purchase links list was updated!');
    }

    this.root &&
      (this.root.order.order = {
        ...this.root.order.order,
        ...updatedOrder,
      });

    this.loading = false;
  }

  async fetchPurchases(orderId: number) {
    this.loading = true;

    const { data: order } = await getOrder(orderId);

    this.order = new DetailedOrderModel(order);
    this.purchases = this.formatifyPurchases(order.items);
    this.shipments = order.shipments.map(
      (returnShipmentData) => new ShipmentModel(returnShipmentData),
    );
    this.returnShipments = order.returnShipments.map(
      (returnShipmentData) => new ReturnShipmentModel(returnShipmentData),
    );
    this.user = order.user;
    this.userData = order.userData;
    this.itemStats = order.itemStats;

    this.loading = false;
  }

  async handleUpdatePurchases(purchases: PurchaseModel[]) {
    try {
      this.loading = true;

      const formattedPurchases = purchases.map((purchase) => ({
        orderItemId: purchase.status !== 'added' ? purchase.id : undefined,
        sku: purchase.sku,
        quantity: purchase.quantityUpdated,
      }));

      const updatedOrder = await updatePurchases(
        this.order.id,
        formattedPurchases,
      );

      this.root &&
        (this.root.order.order = {
          ...this.root.order.order,
          ...updatedOrder,
        });

      this.order = new DetailedOrderModel(updatedOrder);
      this.purchases = this.formatifyPurchases(updatedOrder.items);
    } catch (e) {
      const error = e as IServerError;

      notify(`${error.response?.data.message}`, 'error');
    } finally {
      this.loading = false;
    }
  }

  createShipment = async (
    orderId: number,
    supplier: ESuppliers,
    shipmentReason: EShipmentReasons,
    selected: IPurchaseWithSelected[],
  ) => {
    this.loading = true;
    const items = reduceOrderItems(selected);

    const params = {
      orderId,
      items,
      supplier: supplier,
      reason: shipmentReason,
      trackingNumber: '',
      costOfPurchase: 0,
      costOfShipping: 0,
      comment: '',
    };

    try {
      this.loading = true;

      await createShipment(params);
      const { data: updatedOrder } = await getOrder(this._order.id);
      const orderModel = new DetailedOrderModel(updatedOrder);
      const updatedShipments = updatedOrder.shipments.map(
        (el) => new ShipmentModel(el),
      );

      this.root &&
        (this.root.order.order = {
          ...this.root.order.order,
          ...updatedOrder,
        });

      this.order = orderModel;
      this.purchases = this.formatifyPurchases(updatedOrder.items);
      this.shipments = updatedShipments;
      this.itemStats = orderModel.itemStats;
    } catch (e) {
      notify(`Error, reason:${e}`, 'error');
    } finally {
      this.loading = false;
    }
  };

  changeShipmentFields = async (
    id: number,
    params: Record<string, string | number | undefined>,
  ) => {
    this.loading = true;
    try {
      const formattedParams = {
        ...params,
        costOfPurchase: dollars2cents(params.costOfPurchase as number),
        costOfShipping: dollars2cents(params.costOfShipping as number),
      };

      await editShipment(id, formattedParams);
      const { data: updatedOrder } = await getOrder(this._order.id);

      const orderModel = new DetailedOrderModel(updatedOrder);
      const newShipments = updatedOrder.shipments.map(
        (el) => new ShipmentModel(el),
      );

      this.root &&
        (this.root.order.order = {
          ...this.root.order.order,
          ...updatedOrder,
        });

      this.order = orderModel;
      this.purchases = this.formatifyPurchases(updatedOrder.items);
      this.shipments = newShipments;
    } catch (e) {
      notify(`Error, reason:${e}`, 'error');
    } finally {
      this.loading = false;
    }
  };

  changeShipmentItems = async (
    shipmentId: number,
    shipmentItems: IShipmentItemWithControlAttrs[],
  ) => {
    this.loading = true;

    const formattedItems = reduceOrderItems(shipmentItems);

    try {
      await changeShipmentVariations(shipmentId, {
        items: formattedItems,
      });

      const { data: updatedOrder } = await getOrder(this._order.id);

      const orderModel = new DetailedOrderModel(updatedOrder);
      const newShipments = updatedOrder.shipments.map(
        (el) => new ShipmentModel(el),
      );

      this.root &&
        (this.root.order.order = {
          ...this.root.order.order,
          ...updatedOrder,
        });

      this.order = orderModel;
      this.shipments = newShipments;
      this.itemStats = orderModel.itemStats;
    } catch (e) {
      notify(`Error, reason:${e}`, 'error');

      return null;
    } finally {
      this.loading = false;
    }
  };

  deleteShipment = async (id: number) => {
    this.loading = true;

    try {
      await deleteShipment(id);
      const { data: updatedOrder } = await getOrder(this._order.id);
      const orderModel = new DetailedOrderModel(updatedOrder);

      const newShipments: ShipmentModel[] = this.shipments.filter(
        (el) => el.id !== id,
      );

      this.root &&
        (this.root.order.order = {
          ...this.root.order.order,
          ...updatedOrder,
        });

      this.order = orderModel;
      this.purchases = this.formatifyPurchases(updatedOrder.items);
      this.shipments = newShipments;
      this.itemStats = orderModel.itemStats;
    } catch (e) {
      notify(`Error, reason:${e}`, 'error');
    } finally {
      this.loading = false;
    }
  };

  createReturn = async (
    orderId: number,
    selectedItems: IReturnWithSelected[],
    supplier: ESuppliers,
    responsible: EResponsibles,
    reason: EReturnReasons,
    costOfGoods: number | null,
  ) => {
    this.loading = true;

    const deletedPurchaseKits = this.getPurchasesKitsIds(selectedItems);
    const haveDeletedKits = !!deletedPurchaseKits.length;

    const items = haveDeletedKits
      ? selectedItems.filter((item) => !item.productKit)
      : selectedItems;

    const formattedItems = items.reduce(
      (acc, el) => ({ ...acc, [el.id]: el.selectedQuantity || 1 }),
      {},
    );

    const hasItems = !!Object.keys(formattedItems).length;

    const params = {
      orderId,
      ...(hasItems && { items: { ...formattedItems } }),
      productKits: deletedPurchaseKits,
      reason: reason,
      supplier: supplier,
      responsible: responsible,
      trackingNumber: '',
      costOfGoods: dollars2cents(Number(costOfGoods)),
      comment: '',
    };

    try {
      await createReturnShipment(params);
      const { data: updatedOrder } = await getOrder(this._order.id);

      const orderModel = new DetailedOrderModel(updatedOrder);
      const newReturnShipments = updatedOrder.returnShipments.map(
        (el) => new ReturnShipmentModel(el),
      );

      this.root &&
        (this.root.order.order = {
          ...this.root.order.order,
          ...updatedOrder,
        });

      this.order = orderModel;
      this.returnShipments = newReturnShipments;
    } catch (e) {
      const error = e as IServerError;

      notify(`${error.response.data.message}`, 'error');
    } finally {
      this.loading = false;
    }
  };

  async changeReturn(
    returnShipmentId: number,
    returnItems: IReturnShipmentItemWithControlAttrs[],
    responsible: string,
    reason: string,
  ) {
    this.loading = true;

    const selectedKitItems = returnItems.filter(
      (item) => item.productKit && item.selected,
    );

    const selectedKitIds = this.getPurchasesKitsIds(selectedKitItems);
    const haveSelectedKits = !!selectedKitIds.length;

    const items = haveSelectedKits
      ? returnItems.filter((item) => !item.productKit)
      : returnItems;

    const formattedItems = reduceOrderItems(items);
    const hasItems = !!Object.keys(formattedItems).length;

    try {
      const updatedReturn = await editReturnShipment(returnShipmentId, {
        ...(hasItems && { items: { ...formattedItems } }),
        productKits: selectedKitIds,
        responsible,
        reason,
      });

      const updatedReturnModel = new ReturnShipmentModel(updatedReturn);

      this.returnShipments = this.returnShipments.map((returnShipment) => {
        if (returnShipment.id === updatedReturn.id) {
          return updatedReturnModel;
        }

        return returnShipment;
      });
    } catch (e) {
      const error = e as IServerError;

      notify(`${error.response.data.message}`, 'error');
    } finally {
      this.loading = false;
    }
  }

  changeReturnFields = async (
    returnShipmentId: number,
    returnItems: IReturnShipmentItem[],
    costOfGoods: number,
    supplier: string,
    responsible: string,
    reason: string,
  ) => {
    try {
      this.loading = true;

      const selectedKitIds = this.getReturnItemsKitsIds(returnItems);
      const haveSelectedKits = !!selectedKitIds.length;

      const items = haveSelectedKits
        ? returnItems.filter((item) => !item.orderItem.productKit)
        : returnItems;

      const formattedItems = reduceReturnShipmentItems(items);
      const hasItems = !!Object.keys(formattedItems).length;

      await editReturnShipment(returnShipmentId, {
        ...(hasItems && { items: { ...formattedItems } }),
        productKits: selectedKitIds,
        costOfGoods: dollars2cents(costOfGoods),
        supplier,
        responsible,
        reason,
      });

      const { data: updatedOrder } = await getOrder(this._order.id);

      const orderModel = new DetailedOrderModel(updatedOrder);
      const newReturnShipments = updatedOrder.returnShipments.map(
        (el) => new ReturnShipmentModel(el),
      );

      this.root &&
        (this.root.order.order = {
          ...this.root.order.order,
          ...updatedOrder,
        });

      this.order = orderModel;
      this.returnShipments = newReturnShipments;
    } catch (e) {
      const error = e as IServerError;

      notify(`${error.response.data.message}`, 'error');
    } finally {
      this.loading = false;
    }
  };

  editReturnComment = async (
    returnShipmentId: number,
    values: Record<string, string | number | undefined>,
  ) => {
    this.loading = true;

    try {
      const updatedReturn = await editReturnComment(returnShipmentId, values);
      const updatedReturnModel = new ReturnShipmentModel(updatedReturn);

      this.returnShipments = this.returnShipments.map((returnShipment) => {
        if (returnShipment.id === updatedReturn.id) {
          return updatedReturnModel;
        }

        return returnShipment;
      });
    } catch (e) {
      notify(`Error, reason:${e}`, 'error');
    } finally {
      this.loading = false;
    }
  };

  async finishReturn(returnShipmentId: number, easy = false) {
    this.loading = true;

    try {
      const finishedReturnShipment = await finishReturnShipment(
        returnShipmentId,
        easy,
      );

      const { data: updatedOrder } = await getOrder(this._order.id);

      this.root &&
        (this.root.order.order = {
          ...this.root.order.order,
          ...updatedOrder,
        });

      this.order = new DetailedOrderModel({
        ...this.order.order,
        ...updatedOrder,
      });

      this.returnShipments = this.returnShipments.map((returnShipment) => {
        if (returnShipment.id === finishedReturnShipment.id) {
          return new ReturnShipmentModel(finishedReturnShipment);
        }

        return returnShipment;
      });
    } catch (e) {
      const error = e as IServerError;

      notify(error.response.data.message, 'error');
    } finally {
      this.loading = false;
    }
  }

  async deleteReturn(returnShipmentId: number) {
    this.loading = true;

    try {
      const result = await deleteReturnShipment(returnShipmentId);
      const { data: updatedOrder } = await getOrder(this._order.id);

      if (result) {
        this.returnShipments = this.returnShipments.filter(
          (returnShipment) => returnShipment.id !== returnShipmentId,
        );
      }

      this.root &&
        (this.root.order.order = {
          ...this.root.order.order,
          ...updatedOrder,
        });
    } catch (e) {
      notify(`Error, reason:${e}`, 'error');
    } finally {
      this.loading = false;
    }
  }

  async changeUserData(id: number, values: TEditableUserDataParams) {
    this.loading = true;

    try {
      const updatedUserData = await changeUserData(id, values);

      this.userData = updatedUserData.userData;
    } catch (e) {
      notify(`Error, reason:${e}`, 'error');
    } finally {
      this.loading = false;
    }
  }

  constructor(root?: OrderTableRowViewModel | null, id?: number) {
    if (root) {
      this._root = root;
    }

    if (id) {
      this.getOrderLogs(id);
      this.fetchPurchases(id);
    }

    makeAutoObservable(this);
  }
}
