import { FC } from 'react';

import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { TPhoto } from 'common/types/common-types';
import { ZoomImage } from 'components/ZoomImage';
import { Navigation } from 'swiper';
import { Swiper as SwiperReact, SwiperSlide } from 'swiper/react';

import { VerticalSwiper } from './VerticalSwiper';

import styles from './swiper.module.scss';

interface ISwiperProps {
  photos: TPhoto[];
  alt: string;
  selectedPhoto: TPhoto;
  setSelectedPhoto: (photo: TPhoto) => void;
}

export const Swiper: FC<ISwiperProps> = ({
  selectedPhoto,
  setSelectedPhoto,
  photos,
  alt,
}) => {
  const currentPhotoIndex = photos.findIndex(
    (el) => el.id === selectedPhoto.id,
  );
  const lastIndex = photos.length - 1;

  const handleClickPrevious = () => {
    if (!currentPhotoIndex) {
      return setSelectedPhoto(photos[lastIndex]);
    }

    return setSelectedPhoto(photos[currentPhotoIndex - 1]);
  };

  const handleClickNext = () => {
    if (currentPhotoIndex === lastIndex) {
      return setSelectedPhoto(photos[0]);
    }

    return setSelectedPhoto(photos[currentPhotoIndex + 1]);
  };

  return (
    <div className={styles.contentWrapper}>
      <VerticalSwiper
        selectedPhoto={selectedPhoto}
        setSelectedPhoto={setSelectedPhoto}
        photos={photos}
      />
      <div className={styles.wrapperIcon}>
        <LeftOutlined
          id="prev"
          onClick={handleClickPrevious}
          className={styles.arrowIcon}
        />
      </div>
      <SwiperReact modules={[Navigation]}>
        {photos.map((photo) => (
          <SwiperSlide key={photo.key} className={styles.swiperSlide}>
            <ZoomImage alt={alt} src={selectedPhoto.url} />
          </SwiperSlide>
        ))}
      </SwiperReact>
      <div className={styles.wrapperIcon}>
        <RightOutlined
          id="next"
          onClick={handleClickNext}
          className={styles.arrowIcon}
        />
      </div>
    </div>
  );
};
