import { IUserData } from 'common/types';
import { string, object } from 'yup';

export const orderPersonalInformationValidationSchema = object().shape({
  email: string().email().required(),
});

export const getPersonalInformationFields = (userData: IUserData) => [
  {
    name: 'email',
    label: 'Email',
    value: userData.email,
  },
];
