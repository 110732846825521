import { FC } from 'react';

import { IShipment } from 'common/types/shipment';
import { cents2dollars } from 'common/utils';

import FloatInput from '../FloatInput';

import styles from './editable-data.module.scss';

interface IEditableDataProps {
  shipment: IShipment;
}

export const EditableData: FC<IEditableDataProps> = ({ shipment }) => (
  <>
    <div className={styles.info}>
      <FloatInput
        defaultValue={shipment.supId}
        name="supId"
        label="Supplier Id"
        disabled={!!shipment.trackingNumber}
      />
      <FloatInput
        defaultValue={shipment.trackingNumber}
        name="trackingNumber"
        label="Tracking"
      />
      <FloatInput
        defaultValue={cents2dollars(shipment.costOfPurchase)}
        name="costOfPurchase"
        label="Cost of Purchase"
      />
      <FloatInput
        defaultValue={cents2dollars(shipment.costOfShipping)}
        name="costOfShipping"
        label="Cost of Shipping"
      />
    </div>
    <FloatInput
      name="comment"
      label="Comment"
      defaultValue={shipment.comment?.value}
      fullWidth
    />
  </>
);
