import { defaultImage, defaultReturnShipmentEntity } from 'common/constants';
import {
  IReturnShipment,
  IReturnShipmentItem,
} from 'common/types/return-shipment';
import { mockIdKeyGen } from 'common/utils';
import { computed, makeAutoObservable, observable } from 'mobx';

export class ReturnShipmentItemModel {
  _item: IReturnShipmentItem;

  get item() {
    return this._item;
  }

  set item(item: IReturnShipmentItem) {
    this._item = item;
  }

  get id() {
    return this.item.id;
  }

  get createdAt() {
    return this.item.createdAt;
  }

  get updatedAt() {
    return this.item.createdAt;
  }

  get quantity() {
    return this.item.quantity;
  }

  get variation() {
    return this.item.variation;
  }

  get orderItem() {
    return this.item.orderItem;
  }

  get photo() {
    return (
      this.item.variation.content.photos?.find(
        (photo) => photo.id === this.item.variation.content.mainPhotoId,
      ) || {
        id: 0,
        key: 'key',
        url: defaultImage,
      }
    );
  }

  constructor(item: IReturnShipmentItem) {
    this._item = item;
  }
}

export class ReturnShipmentModel {
  _returnShipment: IReturnShipment = defaultReturnShipmentEntity;
  _loading = false;

  get returnShipment() {
    return this._returnShipment;
  }

  get key() {
    return !!this.returnShipment.id ? this.returnShipment.id : mockIdKeyGen();
  }

  get id() {
    return this.returnShipment.id;
  }

  get status() {
    return this.returnShipment.status;
  }

  get cancelled() {
    return this.returnShipment.cancelled;
  }

  get comment() {
    return this.returnShipment.comment;
  }

  get items() {
    return this.returnShipment.items.map(
      (item) => new ReturnShipmentItemModel(item),
    );
  }

  get order() {
    return this.returnShipment.order;
  }

  get reason() {
    return this.returnShipment.reason;
  }

  get createdAt() {
    return this.returnShipment.createdAt;
  }

  get resolved() {
    return this.returnShipment.resolved;
  }

  get trackingDate() {
    return this.returnShipment.trackingDate;
  }

  get supplier() {
    return this.returnShipment.supplier ?? { id: 0, name: '' };
  }

  get responsible() {
    return this.returnShipment.responsible ?? '';
  }

  get trackingNumber() {
    return this.returnShipment.trackingNumber;
  }

  get costOfReturn() {
    return this.returnShipment.costOfReturn;
  }

  get costOfGoods() {
    return this.returnShipment.costOfGoods;
  }

  get updatedAt() {
    return this.returnShipment.updatedAt;
  }

  get loading() {
    return this._loading;
  }

  constructor(returnShipment?: IReturnShipment) {
    if (returnShipment) {
      this._returnShipment = returnShipment;
    }

    makeAutoObservable(this, {
      _returnShipment: observable,
      _loading: observable,

      returnShipment: computed,
      loading: computed,
      key: computed,
    });
  }
}
