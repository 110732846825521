import { IPageParams } from '../common-types';

export interface ISupplierLinkedEmployee {
  id: number;
  firstName: string;
  lastName: string | null;
  email: string;
}

export interface ISupplier {
  id: number;
  name: string;
  address?: string | null;
  phoneNumber?: string | null;
  bankName?: string | null;
  swift?: string | null;
  accountNumber?: string | null;
  user?: ISupplierLinkedEmployee | null;
}

export enum EPackageTypes {
  SHIPMENT = 'SHIPMENT',
  RETURN = 'RETURN',
}

export enum EPackageStatuses {
  SHIPMENT_PAYMENT_PAID = 'SHIPMENT_PAYMENT_PAID',
  SHIPMENT_PAYMENT_UNPAID = 'SHIPMENT_PAYMENT_UNPAID',
}

export interface IPackage {
  orderId: number;
  orderPublicId: number;
  id: number;
  createdAt: string;
  type: EPackageTypes;
  status: EPackageStatuses;
  itemsCost: number;
  shippingCost: number;
}

export interface IUnpaidPackage {
  id: number;
  type: EPackageTypes;
  cost: number;
  correction: number | null;
}

export interface ICalculation {
  correction: number;
  returns: number;
  shipments: number;
  total: number;
}

export interface IUnpaidShipments {
  calculation: ICalculation;
  shipments: IUnpaidPackage[];
}

export interface IShipmentsResponse {
  shipments: IPackage[];
  count: number;
  page: number;
  pageSize: number;
}

export interface IShipmentsParams extends IPageParams {
  type?: string;
  status?: string;
}

export interface INewSupplierParams {
  name: string;
  address?: string;
  phoneNumber?: string;
  bankName?: string;
  swift?: string;
  accountNumber?: string;
}

export interface IEditableSupplierParams {
  name?: string;
  address?: string | null;
  phoneNumber?: string | null;
  bankName?: string | null;
  swift?: string | null;
  accountNumber?: string | null;
}
