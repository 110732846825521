import { FC } from 'react';

import { Skeleton } from 'antd';

import styles from './return-skeleton.module.scss';

export const ReturnSkeleton: FC = () => (
  <div className={styles.container}>
    <div className={styles.contentWrapper}>
      <div className={styles.content}>
        <Skeleton.Input active size="small" />
        {new Array(4).fill(
          <Skeleton.Avatar active size="default" shape="circle" />,
        )}
      </div>
      <div className={styles.btnWrapper}>
        {new Array(2).fill(<Skeleton.Button active size="default" />)}
      </div>
    </div>
    <div className={styles.footer}>
      <Skeleton.Button active size="small" />
      {new Array(2).fill(<Skeleton.Input active size="default" />)}
    </div>
  </div>
);
