import { addSupplier, deleteSupplier, getSuppliers } from 'http/supplierApi';

import { IServerError } from 'common/types';
import { notify } from 'common/utils';
import { action, computed, makeObservable, observable } from 'mobx';
import { SupplierModel } from 'store/supplier-model';

import { EmployeesPageViewModel } from './employees-page-view-model';
import { ISupplierFormValues } from './types';

export class SuppliersViewModel {
  _root: EmployeesPageViewModel;
  _isLoading = false;
  _suppliers: SupplierModel[] = [];

  get isLoading() {
    return this._isLoading;
  }

  set isLoading(isLoading: boolean) {
    this._isLoading = isLoading;
  }

  get suppliers() {
    return this._suppliers;
  }

  set suppliers(suppliers: SupplierModel[]) {
    this._suppliers = suppliers;
  }

  async addSupplier(values: ISupplierFormValues) {
    try {
      this.isLoading = true;
      const newSupplier = await addSupplier(values);
      const newSupplierModel = new SupplierModel(newSupplier);

      this.suppliers = [...this.suppliers, newSupplierModel];

      return true;
    } catch (e) {
      const error = e as IServerError;

      notify(error.response.data.message, 'error');

      return false;
    } finally {
      this.isLoading = false;
    }
  }

  async deleteSupplier(id: number) {
    try {
      this.isLoading = true;
      await deleteSupplier(id);

      this.suppliers = this.suppliers.filter((supplier) => supplier.id !== id);
    } catch (e) {
      notify(`Error, reason:${e}`, 'error');
    } finally {
      this.isLoading = false;
    }
  }

  async getSuppliers() {
    try {
      this.isLoading = true;
      const suppliers = await getSuppliers();

      this.suppliers = suppliers.map((supplier) => new SupplierModel(supplier));
    } catch (e) {
      notify(`Error, reason:${e}`, 'error');
    } finally {
      this.isLoading = false;
    }
  }

  constructor(root: EmployeesPageViewModel) {
    this._root = root;

    makeObservable(this, {
      _isLoading: observable,
      _suppliers: observable,
      isLoading: computed,
      suppliers: computed,
      addSupplier: action,
      getSuppliers: action,
    });
  }
}
