import { AutoComplete, Button } from 'antd';
import Search from 'antd/es/input/Search';
import { replaceHistoryState } from 'common/utils';
import { observer } from 'mobx-react-lite';
import { TParams } from 'store/filters-model';

import { FiltersModel, Option } from '../filters-model';

import styles from './filters.module.scss';

interface FiltersProps {
  filters: FiltersModel;
  loading: boolean;
  params: TParams;
  refetch: (setDefaultOffset?: boolean) => void;
}

export const Filters = observer(
  ({ filters, loading, params, refetch }: FiltersProps) => {
    const refetchData = (searchParams: TParams) => {
      filters.page = 1;
      filters.params = searchParams;

      replaceHistoryState('/product', searchParams);
      refetch();
    };

    const handleSelectBrand = (_: string, brand: Option) => {
      filters.selectedBrand = brand;
      filters.params.brandId = String(brand.id);

      const brandParams = brand.id
        ? { brandTitle: brand.value, brandId: String(brand.id) }
        : null;

      refetchData({
        page: '1',
        ...params,
        ...brandParams,
      });
    };

    const handleSelectCategory = (_: string, category: Option) => {
      filters.selectedCategory = category;
      filters.params.categoryId = String(category.id);

      const categoryParams = category.id
        ? { categoryTitle: category.value, categoryId: String(category.id) }
        : null;

      refetchData({
        page: '1',
        ...params,
        ...categoryParams,
      });
    };

    const handleSelectStatus = (_: string, status: Option) => {
      filters.selectedStatus = status;
      filters.params.statusId = String(status.id);

      const statusParams = status.id
        ? { statusTitle: status.value, statusId: String(status.id) }
        : null;

      refetchData({
        page: '1',
        ...params,
        ...statusParams,
      });
    };

    const handleSelectType = (_: string, type: Option) => {
      filters.selectedType = type;
      filters.params.typeId = String(type.id);

      const typeParams = type.id
        ? { typeTitle: type.value, typeId: String(type.id) }
        : null;

      refetchData({
        page: '1',
        ...params,
        ...typeParams,
      });
    };

    const handleSearch = (value: string) => {
      const copiedParams = { ...params };

      filters.search = value;

      if (value) {
        refetchData({
          page: '1',
          ...params,
          search: value,
        });
      } else {
        delete copiedParams.search;

        refetchData({
          page: '1',
          ...copiedParams,
        });
      }
    };

    const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
      const copiedParams = { ...params };

      if (!event.target.value) {
        filters.search = '';

        delete copiedParams.search;

        refetchData({
          page: '1',
          ...copiedParams,
        });
      }
    };

    const handleClearType = () => {
      filters.selectedType = null;

      const copiedParams = { ...params };

      delete copiedParams.typeId;
      delete copiedParams.typeTitle;

      refetchData({
        page: '1',
        ...copiedParams,
      });
    };

    const handleChangeType = (value: string) => {
      filters.searchTypeValue = value;

      if (!value) {
        handleClearType();
      }
    };

    const handleClearBrand = () => {
      filters.selectedBrand = null;
      filters.searchCategoryValue = '';

      const copiedParams = { ...params };

      delete copiedParams.brandId;
      delete copiedParams.brandTitle;

      refetchData({
        page: '1',
        ...copiedParams,
      });
    };

    const handleChangeBrand = (value: string) => {
      filters.searchBrandValue = value;

      if (!value) {
        handleClearBrand();
      }
    };

    const handleClearCategory = () => {
      filters.selectedCategory = null;

      const copiedParams = { ...params };

      delete copiedParams.categoryId;
      delete copiedParams.categoryTitle;

      refetchData({
        page: '1',
        ...copiedParams,
      });
    };

    const handleChangeCategory = (value: string) => {
      filters.searchCategoryValue = value;

      if (!value) {
        handleClearCategory();
      }
    };

    const handleClearStatus = () => {
      filters.selectedStatus = null;

      const copiedParams = { ...params };

      delete copiedParams.statusId;
      delete copiedParams.statusTitle;

      refetchData({
        page: '1',
        ...copiedParams,
      });
    };

    const handleChangeStatus = (value: string) => {
      filters.searchStatusValue = value;

      if (!value) {
        handleClearStatus();
      }
    };

    return (
      <div className={styles.container}>
        <Search
          placeholder="Search products"
          onSearch={handleSearch}
          defaultValue={filters.search}
          disabled={loading}
          className={styles.field}
          allowClear
          onChange={handleChangeSearch}
        />
        <AutoComplete
          options={filters.typeOptions}
          placeholder="Filter by type"
          onSelect={handleSelectType}
          allowClear
          disabled={loading}
          value={filters.searchTypeValue}
          onClear={handleClearType}
          onChange={handleChangeType}
          className={styles.field}
        />
        <AutoComplete
          options={filters.brandsOptions}
          placeholder="Filter by brand"
          onSelect={handleSelectBrand}
          allowClear
          disabled={loading}
          value={filters.searchBrandValue}
          onClear={handleClearBrand}
          onChange={handleChangeBrand}
          className={styles.field}
        />
        <AutoComplete
          options={filters.categoriesOptions}
          placeholder="Filter by category"
          onSelect={handleSelectCategory}
          allowClear
          disabled={loading || !filters.selectedBrand}
          value={filters.searchCategoryValue}
          onClear={handleClearCategory}
          onChange={handleChangeCategory}
          className={styles.field}
        />
        <AutoComplete
          options={filters.statusOptions}
          placeholder="Filter by status"
          onSelect={handleSelectStatus}
          allowClear
          disabled={loading}
          value={filters.searchStatusValue}
          onClear={handleClearStatus}
          onChange={handleChangeStatus}
          className={styles.field}
        />

        <Button
          size="small"
          type="dashed"
          onClick={() => filters.resetFilters()}
        >
          Reset filters
        </Button>
      </div>
    );
  },
);
