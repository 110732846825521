import { AxiosResponse } from 'axios';
import {
  IBulkPriceUpdatePayload,
  IBulkPriceUpdateStatus,
} from 'common/types/bulkPriceUpdate';

import { $host } from './index';

export const startBulkPriceUpdate = async (
  payload: IBulkPriceUpdatePayload,
) => {
  const {
    data: { data },
  } = await $host.post<AxiosResponse<IBulkPriceUpdateStatus>>(
    '/admin/products/prices-adjustment',
    payload,
  );

  return data;
};

export const getBulkUpdateStatus = async () => {
  const {
    data: { data },
  } = await $host.get<AxiosResponse<IBulkPriceUpdateStatus[]>>(
    '/admin/products/prices-adjustment/status',
  );

  return data;
};
