import { FC } from 'react';

import { LinkOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import { TPhoto } from 'common/types';
import { observer } from 'mobx-react-lite';

import styles from './purchase.module.scss';

export interface IPurchaseProps {
  productId: number;
  sku: string;
  quantity: number;
  price: number;
  kit?: number;
  title: string;
  variationView: string;
  photo: TPhoto;
  links: string[];
  disabled: boolean;
  onAddLinkClick: () => void;
}

export const Purchase: FC<IPurchaseProps> = observer(
  ({
    productId,
    sku,
    quantity,
    price,
    kit,
    title,
    variationView,
    photo,
    links,
    disabled,
    onAddLinkClick,
  }) => (
    <div className={styles.container}>
      <img src={photo.url} alt={title} className={styles.image} />
      <div className={styles.content}>
        <div className={styles.title}>
          <Typography.Text>
            {quantity} x&nbsp;
            <Typography.Link
              href={`/product/${productId}`}
              target="_blank"
              strong
            >
              {title}
            </Typography.Link>
          </Typography.Text>
          <Typography.Text strong>${price}</Typography.Text>
        </div>
        <Typography.Text strong>SKU</Typography.Text>
        <Typography.Text>{sku}</Typography.Text>
        <Typography.Text strong>Variation</Typography.Text>
        <Typography.Text>{variationView}</Typography.Text>
        <Typography.Text strong>Link</Typography.Text>
        <div className={styles.link}>
          <Button
            type="link"
            target="_blank"
            href={links[0]}
            size="small"
            icon={<LinkOutlined />}
            disabled={!links.length}
          />
          <Button
            size="small"
            className={styles.link}
            disabled={disabled}
            onClick={onAddLinkClick}
          >
            Add link
          </Button>
        </div>
        <Typography.Text strong>#Kit</Typography.Text>
        <Typography.Text>{kit ?? '-'}</Typography.Text>
      </div>
    </div>
  ),
);
