import { AxiosResponse } from 'axios';
import { IDashboard } from 'common/types/dasboard';

import { $host } from './index';

export const getDashboard = async (startDate: string, endDate: string) => {
  const { data } = await $host.get<AxiosResponse<IDashboard>>(
    '/admin/dashboard/date-range',
    {
      params: {
        startDate,
        endDate,
      },
    },
  );

  return data;
};
