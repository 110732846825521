import { getManagedOrders } from 'http/employeeApi';

import { IManagedOrder } from 'common/types/employee';
import { notify } from 'common/utils';
import { makeAutoObservable } from 'mobx';

export class OrderCounterViewModel {
  _isLoading = false;
  today = new Date();
  _date = this.today;
  _managedOrders: IManagedOrder[] = [];

  get isLoading() {
    return this._isLoading;
  }

  set isLoading(isLoading: boolean) {
    this._isLoading = isLoading;
  }

  get date() {
    return this._date;
  }

  set date(date: Date) {
    this._date = date;
  }

  get managedOrders() {
    return this._managedOrders;
  }

  set managedOrders(managedOrders: IManagedOrder[]) {
    this._managedOrders = managedOrders;
  }

  onDateChange = (date: Date) => {
    this.date = date;
  };

  onPickerOpenChange = async (isOpen: boolean) => {
    if (isOpen) {
      return;
    }

    await this.getManagedOrders(this.date);
  };

  async getManagedOrders(date: Date) {
    try {
      this.isLoading = true;

      const managedOrders = await getManagedOrders(
        date.getFullYear(),
        date.getMonth(),
      );

      this.managedOrders = managedOrders;
    } catch (e) {
      notify(`Error, reason:${e}`, 'error');
    } finally {
      this.isLoading = false;
    }
  }

  constructor() {
    this.getManagedOrders(new Date());

    makeAutoObservable(this);
  }
}
