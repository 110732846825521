import { defaultImage, defaultShipmentEntity } from 'common/constants';
import { IShipment, IShipmentItem } from 'common/types/shipment';
import { mockIdKeyGen } from 'common/utils';
import { makeAutoObservable } from 'mobx';

export class ShipmentItemModel {
  _item: IShipmentItem;

  get item() {
    return this._item;
  }

  set item(item: IShipmentItem) {
    this._item = item;
  }

  get id() {
    return this.item.id;
  }

  get createdAt() {
    return this.item.createdAt;
  }

  get updatedAt() {
    return this.item.createdAt;
  }

  get orderItem() {
    return this.item.orderItem;
  }

  get quantity() {
    return this.item.quantity;
  }

  get variation() {
    return this.item.variation;
  }

  get photo() {
    return (
      this.item.variation.content.photos?.find(
        (photo) => photo.id === this.item.variation.content.mainPhotoId,
      ) || {
        id: 0,
        key: 'key',
        url: defaultImage,
      }
    );
  }

  constructor(item: IShipmentItem) {
    this._item = item;
  }
}

export class ShipmentModel {
  _shipment: IShipment = defaultShipmentEntity;
  _loading = false;

  get shipment() {
    return this._shipment;
  }

  get key() {
    return !!this.shipment.id ? this.shipment.id : mockIdKeyGen();
  }

  get id() {
    return this.shipment.id;
  }

  get comment() {
    return this.shipment.comment;
  }

  get items() {
    return this.shipment.items.map((item) => new ShipmentItemModel(item));
  }

  get costOfPurchase() {
    return this.shipment.costOfPurchase;
  }

  get costOfShipping() {
    return this.shipment.costOfShipping;
  }

  get createdAt() {
    return this.shipment.createdAt;
  }

  get reason() {
    return this.shipment.reason;
  }

  get supplier() {
    return this.shipment.supplier ?? { id: 0, name: '' };
  }

  get supId() {
    return this.shipment.supId;
  }

  get trackingNumber() {
    return this.shipment.trackingNumber;
  }

  get trackingStatus() {
    return this.shipment.trackingStatus;
  }

  get trackingDate() {
    return this.shipment.trackingDate;
  }

  get updatedAt() {
    return this.shipment.updatedAt;
  }

  get loading() {
    return this._loading;
  }

  constructor(shipment?: IShipment) {
    if (shipment) {
      this._shipment = shipment;
    }

    makeAutoObservable(this);
  }
}
