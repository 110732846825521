import { FC } from 'react';

import { Button } from 'antd';

import styles from './static-data.module.scss';

interface IStaticDataProps {
  comment?: string;
  disabled?: boolean;
  onEditToggle: () => void;
}

export const StaticData: FC<IStaticDataProps> = ({
  comment,
  disabled,
  onEditToggle,
}) => {
  const hasComment = !!comment;

  return (
    <div className={styles.container}>
      <div>
        <span className={styles.title}>Comment:&nbsp;</span>
        <span>{comment}</span>
      </div>
      <Button onClick={onEditToggle} htmlType="button" disabled={disabled}>
        {hasComment ? 'Edit' : 'Add'}
      </Button>
    </div>
  );
};
