import { AxiosResponse } from 'axios';
import {
  IEditableSupplierParams,
  INewSupplierParams,
  IShipmentsParams,
  IShipmentsResponse,
  ISupplier,
  IUnpaidShipments,
} from 'common/types/supplier';

import { $host } from './index';

export const getSuppliers = async () => {
  const {
    data: { data },
  } = await $host.get<AxiosResponse<ISupplier[]>>('admin/suppliers');

  return data;
};

export const addSupplier = async (params: INewSupplierParams) => {
  const {
    data: { data },
  } = await $host.post<AxiosResponse<ISupplier>>('admin/suppliers', params);

  return data;
};

export const editSupplier = async (
  id: number,
  params: IEditableSupplierParams,
) => {
  const {
    data: { data },
  } = await $host.patch<AxiosResponse<ISupplier>>(
    `admin/suppliers/${id}`,
    params,
  );

  return data;
};

export const deleteSupplier = async (id: number) => {
  const {
    data: { data },
  } = await $host.delete<AxiosResponse<ISupplier>>(`admin/suppliers/${id}`);

  return data;
};

export const getSupplierUnpaidShipments = async (id: number) => {
  const {
    data: { data },
  } = await $host.get<AxiosResponse<IUnpaidShipments>>(
    `admin/suppliers/employee/id/${id}/unpaid-shipments`,
  );

  return data;
};

export const getSupplierShipments = async (
  id: number,
  params?: IShipmentsParams,
) => {
  const {
    data: { data },
  } = await $host.get<AxiosResponse<IShipmentsResponse>>(
    `/admin/suppliers/employee/id/${id}/shipments`,
    { params },
  );

  return data;
};
