import { FC } from 'react';

import { PlusCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import Search from 'antd/es/input/Search';
import { FilterValue, SortOrder, SorterResult } from 'antd/es/table/interface';
import { brandDeleteMessage } from 'common/constants';
import { useQueryParams } from 'common/hook/useQueryParams';
import { TTablePaginationParams } from 'common/types';
import { replaceHistoryState } from 'common/utils';
import {
  actions,
  image,
  title,
  Table,
  checkbox,
  text,
  tag,
  counter,
} from 'components/Table';
import { observer } from 'mobx-react-lite';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { BrandModel } from 'store/brand-model';

import { BrandViewModel } from './brand-view-model';

import styles from './styles.module.scss';

export interface IBrandProps {
  model: BrandViewModel;
}

const Brand: FC<IBrandProps> = observer(({ model }) => {
  const navigate = useNavigate();

  const params = model._filters.params;
  const search = params.search ? { search: params.search } : null;

  const navigationSearchParams = `?${createSearchParams({
    ...search,
    page: params.page,
  })}`;

  const create = () => navigate('/brand/new');

  const edit = (id: number) =>
    navigate({
      pathname: `/brand/${id}`,
      search: navigationSearchParams,
    });

  const onSearch = (value: string) => {
    model.search = value;

    const search = value ? { search: value } : null;

    replaceHistoryState('/brand', { ...search, page: '1' });
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.value) {
      model.search = '';

      replaceHistoryState('/brand', { page: '1' });
    }
  };

  const columns = [
    image({
      loading: model.loading,
      title: 'Photo',
      width: '5%',
    }),
    title({
      width: '10%',
      title: 'Brand',
      dataIndex: 'title',
      key: 'title',
      sorter: true,
      onClick: edit,
      getHref: (record) => `/brand/${record.id}${navigationSearchParams}`,
      loading: model.loading,
    }),
    tag({
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      loading: model.loading,
    }),
    checkbox({
      title: 'Main',
      dataIndex: 'main',
      key: 'main',
      loading: model.loading,
    }),
    counter({
      width: '5%',
      title: 'Categories',
      dataIndex: 'subcategoriesCount',
      key: 'subcategoriesCount',
      loading: model.loading,
    }),
    counter({
      width: '5%',
      title: 'Products',
      dataIndex: 'productsCount',
      key: 'productsCount',
      loading: model.loading,
    }),
    text({
      width: '7%',
      title: 'Last modified',
      dataIndex: 'updatedAtRender',
      key: 'updatedAt',
      defaultSortOrder: 'descend' as SortOrder | undefined,
      sorter: true,
      loading: model.loading,
    }),
    actions({
      onEdit: edit,
      onDelete: model.removeBrand,
      width: '5%',
      disabled: model.loading,
      deleteMessage: brandDeleteMessage,
    }),
  ];

  const handleChangeTable = (
    pagination: TTablePaginationParams,
    _: Record<string, FilterValue>,
    sorter: SorterResult<BrandModel>,
  ) => {
    const { current } = pagination;
    model._filters.page = current;

    model._filters.setSorterSortOptions(sorter);

    replaceHistoryState('/brand', { ...search, page: `${current}` });

    model.fetchBrands();
  };

  return (
    <div>
      <div className={styles.header}>
        <Search
          className={styles.search}
          placeholder="Search brands"
          onSearch={onSearch}
          defaultValue={model._filters.search}
          allowClear
          onChange={handleSearch}
        />
        <Button
          type="primary"
          icon={<PlusCircleOutlined />}
          size="middle"
          onClick={create}
        >
          Add Brand
        </Button>
      </div>
      <Table
        columns={columns}
        data={model.brands}
        pagination={{
          total: model.count,
          disabled: model.loading,
          current: model._filters.defaultCurrent,
        }}
        bordered
        skeleton={model.skeleton}
        loading={model.loading}
        onChange={handleChangeTable}
      />
    </div>
  );
});

export const BrandPage = () => {
  const params = useQueryParams();

  const model = new BrandViewModel({ ...params, sortBy: 'updatedAt' });

  return <Brand model={model} />;
};
