import { FC, useEffect, useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { BaseOptionType } from 'antd/es/select';
import cn from 'clsx';
import { TColor } from 'common/types';
import { getNestedProperty, mockIdKeyGen } from 'common/utils';
import { Controller, useFormContext } from 'react-hook-form';

import { ListItem, TListItem } from './ListItem';

import styles from './list.module.scss';

export type TListItemTypes =
  | {
      type?: 'color';
      options?: TColor[];
    }
  | {
      type?: 'autocomplete';
      options?: BaseOptionType[];
    }
  | {
      type?: 'default';
      options?: never;
    };
export interface IFormListProps {
  name: string;
  unique?: boolean;
  label?: string;
  labelPosition?: 'top' | 'left';
  required?: string;
  className?: string;
  lastRemovable?: boolean;
}

export const FormList: FC<IFormListProps & TListItemTypes> = ({
  name,
  label,
  labelPosition = 'top',
  type,
  required,
  className,
  unique = false,
  options,
  lastRemovable = true,
}) => {
  const { control, formState, setValue } = useFormContext();
  const [prevName, setPrevName] = useState<string>();

  const [listItems, setListItems] = useState<TListItem[]>([]);

  useEffect(() => {
    if (prevName !== name && formState.defaultValues) {
      setPrevName(name);
      const initialList = getNestedProperty(
        formState.defaultValues,
        name.split('.'),
      );

      if (initialList?.length) {
        const initialListItems = initialList.map((listItem: string) => {
          return {
            key: mockIdKeyGen(),
            value: listItem,
            unique: true,
          };
        }) as TListItem[];
        setListItems(initialListItems);
        setValue(
          name,
          initialListItems.map((listItem) => listItem.value),
        );
      } else {
        setListItems([]);
        setValue(name, []);
      }
    }
  }, [name, prevName]);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange }, fieldState: { error } }) => {
        const deleteLink = (key: string) => {
          const updatedListItems = listItems.filter((el) => el.key !== key);
          setListItems([...updatedListItems]);
          onChange([...updatedListItems.map((listItem) => listItem.value)]);
        };

        const changeOrderLink = (item: TListItem) => {
          const isUnique = listItems.find(
            (listItem) =>
              listItem.value === item.value.trim() && listItem.key !== item.key,
          );

          const updatedListItems = listItems.map((el) => {
            if (el.key === item.key) {
              return {
                key: item.key,
                value: item.value,
                unique: unique ? !isUnique?.key : true,
              };
            }
            return el;
          });
          setListItems([...updatedListItems]);
          onChange([
            ...updatedListItems.map((listItem) => listItem.value.trim()),
          ]);
        };

        const addListItem = () => {
          const updatedListItems = [
            ...listItems,
            { key: mockIdKeyGen(), value: '', unique: true },
          ];
          setListItems(updatedListItems);
          onChange([...updatedListItems.map((listItem) => listItem.value)]);
        };

        return (
          <div
            className={cn(
              styles.container,
              styles.listContainer,
              styles[`label-${labelPosition}`],
              className,
            )}
          >
            {!!label && (
              <label className={styles.label}>
                {label}
                {required && <span className={styles.required}>*</span>}
              </label>
            )}
            <div
              id={name}
              className={cn(styles.links, {
                [styles.lastRemovable]: !lastRemovable,
              })}
            >
              {listItems?.map((el: TListItem, index) => (
                <ListItem
                  type={type}
                  options={options}
                  key={el.key}
                  id={el.key}
                  unique={el.unique}
                  value={el.value}
                  onDelete={deleteLink}
                  onChange={changeOrderLink}
                />
              ))}
            </div>
            {Array.isArray(error) &&
              error.map((err) => (
                <span className={styles.error}>{err?.message}</span>
              ))}
            {!!error && <div className={styles.error}>{error?.message}</div>}
            <Button type="primary" onClick={addListItem}>
              <PlusOutlined />
              Add item
            </Button>
          </div>
        );
      }}
    />
  );
};
