import { changeUserData } from 'http/orderApi';

import { IServerError, IUserData, TEditableUserDataParams } from 'common/types';
import { notify } from 'common/utils';
import { makeAutoObservable } from 'mobx';

import { ExpandedOrderViewModel } from './expanded-order-view-model';

const getAddressInitialValues = (
  userData: IUserData | null,
  type: 'billingAddress' | 'shippingAddress',
) => {
  const data = userData ?? ({} as IUserData);

  return {
    firstName: data[type].firstName,
    lastName: data[type].lastName,
    companyName: data[type].companyName,
    address: data[type].address,
    address2: data[type].address2,
    city: data[type].city,
    state: data[type].state,
    postalCode: data[type].postalCode,
    country: data[type].country,
    phone: data[type].phone,
  };
};

const getAddressStaticFields = (
  userData: IUserData | null,
  type: 'billingAddress' | 'shippingAddress',
) => {
  const data = userData ?? ({} as IUserData);

  return [
    {
      fields: [
        {
          value: data[type].firstName,
        },
        {
          value: data[type].lastName,
        },
      ],
      divider: ' ',
    },
    { fields: [{ value: data[type].address }] },
    { fields: [{ value: data[type].address2 }] },
    {
      fields: [
        { value: data[type].city },
        { value: data[type].state },
        { value: data[type].postalCode },
      ],
      divider: ', ',
    },
    { fields: [{ value: data[type].country }] },
    { fields: [{ value: data[type].phone }] },
  ];
};

const addressDynamicFields = [
  {
    label: 'First name',
    name: 'firstName',
  },
  {
    label: 'Last name',
    name: 'lastName',
  },
  {
    label: 'Address 1',
    name: 'address',
  },
  {
    label: 'Address 2',
    name: 'address2',
  },
  {
    label: 'City',
    name: 'city',
  },
  {
    label: 'State',
    name: 'state',
  },
  {
    label: 'Postal code',
    name: 'postalCode',
  },
  {
    label: 'Country',
    name: 'country',
  },
  {
    label: 'Phone',
    name: 'phone',
  },
];

const getPersonalInfoInitialValues = (userData: IUserData | null) => {
  const data = userData ?? ({} as IUserData);

  return {
    email: data.email,
  };
};

const getPersonalInfoStaticFields = (userData: IUserData | null) => {
  const data = userData ?? ({} as IUserData);

  return [
    {
      fields: [
        {
          value: data.email,
        },
      ],
    },
  ];
};

const personalInfoDynamicFields = [
  {
    label: 'Email',
    name: 'email',
  },
];

export class BuyerDetailsViewModel {
  _root: ExpandedOrderViewModel | null = null;
  _isLoading = false;
  _userData: IUserData;

  get root() {
    return this._root;
  }

  get orderViewModel() {
    return this.root?.root;
  }

  get isLoading() {
    return this._isLoading;
  }

  set isLoading(isLoading: boolean) {
    this._isLoading = isLoading;
  }

  get userData() {
    return this._userData;
  }

  set userData(userData: IUserData) {
    this._userData = userData;
  }

  get billingAddressInitialValues() {
    return getAddressInitialValues(this.userData, 'billingAddress');
  }

  get billingAddressStaticFields() {
    return getAddressStaticFields(this.userData, 'billingAddress');
  }

  get billingAddressDynamicFields() {
    return addressDynamicFields;
  }

  get shippingAddressInitialValues() {
    return getAddressInitialValues(this.userData, 'shippingAddress');
  }

  get shippingAddressStaticFields() {
    return getAddressStaticFields(this.userData, 'shippingAddress');
  }

  get shippingAddressDynamicFields() {
    return addressDynamicFields;
  }

  get personalInfoInitialValues() {
    return getPersonalInfoInitialValues(this.userData);
  }

  get personalInfoStaticFields() {
    return getPersonalInfoStaticFields(this.userData);
  }

  get personalInfoDynamicFields() {
    return personalInfoDynamicFields;
  }

  get paymentInfoStaticFields() {
    return [
      {
        fields: [
          {
            value: this._root?.order?.processor,
          },
        ],
      },
    ];
  }

  onUserDataUpdate = async (values: TEditableUserDataParams) => {
    const orderId = this.orderViewModel?.shortOrder?.id;

    if (!orderId) {
      return;
    }

    try {
      this.isLoading = true;

      const updatedUserData = await changeUserData(orderId, values);

      this.orderViewModel && (await this.orderViewModel.syncOrderData());

      this.userData = updatedUserData.userData;
    } catch (e) {
      const error = e as IServerError;

      notify(`Error, reason:${error.response?.data?.message}`, 'error');
    } finally {
      this.isLoading = false;
    }
  };

  constructor(root: ExpandedOrderViewModel | null, userData: IUserData) {
    if (root) {
      this._root = root;
    }

    this._userData = userData;

    makeAutoObservable(this);
  }
}
