import { FC, useEffect, useState } from 'react';

import { Checkbox, Modal, Select } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { TOption } from 'common/types';
import { observer } from 'mobx-react-lite';
import { PurchaseViewModel } from 'pages/NewOrder/view-models/purchase-view-model';

import { ConfirmPurchasesList } from '../ConfirmPurchasesList';

import styles from './confirm-purchases-modal.module.scss';

interface IRefundData {
  value: boolean;
  responsible: string;
  reason: string;
}

export interface IConfirmPurchasesModalProps {
  open: boolean;
  isLoading?: boolean;
  purchases: PurchaseViewModel[];
  onOk: (isInvoice?: boolean, isRefund?: IRefundData | null) => Promise<void>;
  onCancel: () => void;
  responsibleOptions: TOption[];
  reasonOptions: TOption[];
}

export const ConfirmPurchasesModal: FC<IConfirmPurchasesModalProps> = observer(
  ({
    open,
    isLoading,
    purchases,
    responsibleOptions,
    reasonOptions,
    onOk,
    onCancel,
  }) => {
    const [isInvoice, setInvoice] = useState(true);
    const [isRefund, setRefund] = useState(true);
    const [responsible, setResponsible] = useState<string>();
    const [reason, setReason] = useState<string>();

    const addedPurchases = purchases.filter(
      (purchase) => purchase.status === 'added',
    );

    const updatedPurchases = purchases.filter(
      (purchase) => purchase.status === 'updated',
    );

    const removedPurchases = purchases.filter(
      (purchase) => purchase.status === 'removed',
    );

    const hasAddedPurchases = !!addedPurchases.length;
    const hasUpdatedPurchases = !!updatedPurchases.length;
    const hasRemovedPurchases = !!removedPurchases.length;

    const hasInvoice =
      hasAddedPurchases ||
      updatedPurchases.some(
        (purchase) => purchase.quantity < purchase.quantityUpdated,
      );

    const hasRefund =
      hasRemovedPurchases ||
      updatedPurchases.some(
        (purchase) => purchase.quantity > purchase.quantityUpdated,
      );

    const isOkDisabled = hasRefund && isRefund && !(responsible && reason);

    const onInvoiceChange = (e: CheckboxChangeEvent) =>
      setInvoice(e.target.checked);

    const onRefundChange = (e: CheckboxChangeEvent) =>
      setRefund(e.target.checked);

    const onResponsibleChange = (value: string) => setResponsible(value);
    const onReasonChange = (value: string) => setReason(value);

    const handleOk = async () => {
      const sendInvoice = hasInvoice && isInvoice;
      const sendRefund = hasRefund && isRefund && responsible && reason;

      await onOk(
        sendInvoice,
        sendRefund
          ? {
              value: isRefund,
              responsible: responsible,
              reason: reason,
            }
          : null,
      );
    };

    useEffect(() => {
      setInvoice(true);
      setRefund(true);
      setResponsible(undefined);
      setReason(undefined);
    }, [open]);

    return (
      <Modal
        open={open}
        title="Confirm purchases changes"
        okButtonProps={{
          loading: isLoading,
          disabled: isOkDisabled,
        }}
        destroyOnClose
        onOk={handleOk}
        onCancel={onCancel}
      >
        <div className={styles.container}>
          {hasAddedPurchases && (
            <ConfirmPurchasesList
              purchases={addedPurchases}
              title="Added"
              type="added"
            />
          )}
          {hasUpdatedPurchases && (
            <ConfirmPurchasesList
              purchases={updatedPurchases}
              title="Updated"
              type="updated"
            />
          )}
          {hasRemovedPurchases && (
            <ConfirmPurchasesList
              purchases={removedPurchases}
              title="Removed"
              type="removed"
            />
          )}
        </div>
        <div className={styles.controls}>
          <div className={styles.checkboxes}>
            {hasInvoice && (
              <Checkbox checked={isInvoice} onChange={onInvoiceChange}>
                Send invoice
              </Checkbox>
            )}
            {hasRefund && (
              <Checkbox checked={isRefund} onChange={onRefundChange}>
                Send refund
              </Checkbox>
            )}
          </div>
          {hasRefund && isRefund && (
            <>
              <Select
                options={responsibleOptions}
                value={responsible}
                placeholder="Responsible"
                className={styles.select}
                onChange={onResponsibleChange}
              />
              <Select
                options={reasonOptions}
                value={reason}
                placeholder="Reason"
                className={styles.select}
                onChange={onReasonChange}
              />
            </>
          )}
        </div>
      </Modal>
    );
  },
);
