import { FC } from 'react';

import cn from 'clsx';

import styles from './static-field.module.scss';

interface IStaticFieldProps {
  value?: string | null;
  label?: string;
  labelPosition?: 'top' | 'left';
  className?: string;
}

export const StaticField: FC<IStaticFieldProps> = ({
  value,
  label,
  labelPosition = 'top',
  className,
}) => (
  <div
    className={cn(
      styles.container,
      styles[`label-${labelPosition}`],
      className,
    )}
  >
    <p className={styles.label}>{label}</p>
    <span className={styles.value}>{value || '-'}</span>
  </div>
);
