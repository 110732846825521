import { FC, ChangeEvent } from 'react';

import { Input, InputProps } from 'antd';
import cn from 'clsx';
import { Controller, useFormContext } from 'react-hook-form';

import styles from '../form.module.scss';

export interface IFormInputProps extends InputProps {
  name: string;
  type?: 'text' | 'password';
  label?: string;
  labelPosition?: 'top' | 'left';
  required?: boolean;
  uppercase?: boolean;
  className?: string;
  onBlur?: () => void;
}

export const FormInput: FC<IFormInputProps> = ({
  name,
  type = 'text',
  label,
  labelPosition = 'top',
  required,
  className,
  uppercase = false,
  onBlur,
  ...props
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange, value, name, onBlur: internalFormBlur },
        fieldState: { error, invalid },
      }) => {
        const Component = type === 'password' ? Input.Password : Input;

        const handleOnChange = (evt: ChangeEvent<HTMLInputElement>) => {
          const val = evt.target.value;
          onChange(uppercase ? (val ?? '').toUpperCase() : val);
        };

        const handleBlur = () => {
          if (invalid) {
            return;
          }

          onBlur?.();
          internalFormBlur();
        };

        return (
          <div className={cn(styles.container, className)}>
            <div
              className={cn(
                styles.inputWrapper,
                styles[`label-${labelPosition}`],
              )}
            >
              {!!label && (
                <label htmlFor={name} className={styles.label}>
                  {label}
                  {required && <span className={styles.required}>*</span>}
                </label>
              )}
              <Component
                id={name}
                onChange={handleOnChange}
                value={value}
                name={name}
                status={!!error ? 'error' : ''}
                onBlur={handleBlur}
                {...props}
              />
            </div>
            {!!error && <div className={styles.error}>{error?.message}</div>}
          </div>
        );
      }}
    />
  );
};
