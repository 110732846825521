import { login } from 'http/authApi';

import { IServerError } from 'common/types';
import { checkCurrentUser, decodeJwt, notify } from 'common/utils';
import { makeAutoObservable } from 'mobx';

import {
  EUserRoles,
  ICurrentUser,
  ILoginParams,
} from '../common/types/current-user';

export class CurrentUserModel {
  _loading = false;
  _isAuth = false;
  _currentUser: ICurrentUser | null = null;

  get loading() {
    return this._loading;
  }

  set loading(loading: boolean) {
    this._loading = loading;
  }

  get isAuth() {
    return this._isAuth;
  }

  set isAuth(isAuth: boolean) {
    this._isAuth = isAuth;
  }

  get currentUser() {
    return this._currentUser;
  }

  set currentUser(currentUser: ICurrentUser | null) {
    this._currentUser = currentUser;
  }

  get role() {
    return this._currentUser?.role;
  }

  get isAdmin() {
    return this._currentUser?.role === EUserRoles.ADMIN;
  }

  get isManager() {
    return this._currentUser?.role === EUserRoles.MANAGER;
  }

  get isSupplier() {
    return this._currentUser?.role === EUserRoles.SUPPLIER;
  }

  login = async (values: ILoginParams) => {
    this.loading = true;

    try {
      const accessToken = await login(values);
      const decodedToken = decodeJwt<ICurrentUser>(accessToken);

      if (decodedToken?.email) {
        this.isAuth = true;
        this.currentUser = decodedToken;
      }
    } catch (e) {
      notify(
        (e as unknown as IServerError).response.data.message as string,
        'error',
      );
    } finally {
      this.loading = false;
    }
  };

  logout = () => {
    localStorage.removeItem('token');

    this.isAuth = false;
    this.currentUser = null;
  };

  checkAuth = () => {
    const response = checkCurrentUser();

    if (!response?.email) {
      return notify('User not authorized', 'error');
    }

    this.isAuth = true;
    this.currentUser = response;
  };

  constructor() {
    const token = localStorage.getItem('token');

    this._isAuth = !!token;
    this.currentUser = decodeJwt<ICurrentUser>(token);

    makeAutoObservable(this);
  }
}
