import { createInvoice, createRefund } from 'http/orderApi';
import { updatePurchases } from 'http/purchaseApi';
import { getVariationBySku } from 'http/variationApi';

import { ILongOrder, IServerError } from 'common/types';
import { IOrderItem } from 'common/types/purchase';
import { notify, getOrderItemsKitsIds } from 'common/utils';
import { makeAutoObservable } from 'mobx';

import { ExpandedOrderViewModel } from './expanded-order-view-model';
import { PurchaseViewModel } from './purchase-view-model';

interface IRefundData {
  value: boolean;
  responsible: string;
  reason: string;
}

export class PurchasesViewModel {
  _root: ExpandedOrderViewModel | null = null;
  _isLoading = false;
  _isEditModalOpen = false;
  _isAddModalOpen = false;
  _isConfirmModalOpen = false;
  _purchases: PurchaseViewModel[] = [];

  get root() {
    return this._root;
  }
  get orderViewModel() {
    return this.root?.root;
  }

  get disabled() {
    return !!this.root?.order?.disabled;
  }

  get isLoading() {
    return this._isLoading;
  }

  set isLoading(isLoading: boolean) {
    this._isLoading = isLoading;
  }

  get isEditModalOpen() {
    return this._isEditModalOpen;
  }

  set isEditModalOpen(isOpen: boolean) {
    this._isEditModalOpen = isOpen;
  }

  get isAddModalOpen() {
    return this._isAddModalOpen;
  }

  set isAddModalOpen(isOpen: boolean) {
    this._isAddModalOpen = isOpen;
  }

  get isConfirmModalOpen() {
    return this._isConfirmModalOpen;
  }

  set isConfirmModalOpen(isOpen: boolean) {
    this._isConfirmModalOpen = isOpen;
  }

  get purchases() {
    return this._purchases;
  }

  set purchases(purchases: PurchaseViewModel[]) {
    this._purchases = purchases;
  }

  onEditModalOpen = () => {
    this.isEditModalOpen = true;
  };

  onEditModalClose = () => {
    this.isEditModalOpen = false;

    this.purchases = this.purchases.reduce((acc, curr) => {
      if (curr.status !== 'added') {
        curr.status = 'origin';
        curr.quantityUpdated = curr.quantity;

        acc.push(curr);
      }

      return acc;
    }, [] as PurchaseViewModel[]);
  };

  onEditModalSubmit = () => {
    this.isConfirmModalOpen = true;
  };

  onAddModalOpen = () => {
    this.isAddModalOpen = true;
  };

  onAddModalClose = () => {
    this.isAddModalOpen = false;
  };

  onAddModalSubmit = async (sku: string, quantity: string) => {
    try {
      this.isLoading = true;

      const variation = await this.onFindPurchasebySku(sku);

      if (!variation) {
        throw new Error('Variation is not found!');
      }

      const formattedOrderItem = {
        ...variation,
        id: 0,
        quantity: +quantity,
        variation: variation,
        productKit: null,
        adjustedPrice: variation.content.totalPrice,
        shipmentItems: [],
      };

      const duplicatedPurchase = this.purchases.find(
        (purchase) => purchase.sku === formattedOrderItem.content.sku,
      );

      const duplicatedKit = duplicatedPurchase?.productKit?.id === undefined;

      const hasDuplicateError = duplicatedPurchase && duplicatedKit;

      if (hasDuplicateError) {
        throw new Error('Variation already exists!');
      }

      const addedPurchase = new PurchaseViewModel(this, formattedOrderItem);
      addedPurchase.status = 'added';
      addedPurchase.quantityUpdated = +quantity;

      this.purchases = [addedPurchase, ...this.purchases];
      this.isAddModalOpen = false;
    } catch (e) {
      const error = e as Error;

      notify(`${error.message}`, 'error');
    } finally {
      this.isLoading = false;
    }
  };

  onConfirmModalClose = () => {
    this.isConfirmModalOpen = false;
  };

  onConfirmModalSubmit = async (
    isInvoice?: boolean,
    isRefund?: IRefundData | null,
  ) => {
    const orderId = this.root?.order?.id;

    if (!orderId) {
      return;
    }

    try {
      this.isLoading = true;

      isInvoice && (await this.onInvoiceCreate(orderId, this.purchases));
      isRefund &&
        (await this.onRefundCreate(orderId, this.purchases, isRefund));

      const updatedOrder = await this.onPurchasesUpdate(
        orderId,
        this.purchases,
      );

      this.orderViewModel && (await this.orderViewModel.syncOrderData());

      this.purchases = this.getPurchases(updatedOrder.items);
      this.isConfirmModalOpen = false;
      this.isEditModalOpen = false;
    } catch (e) {
      const error = e as IServerError;

      notify(`${error.response?.data.message}`, 'error');
    } finally {
      this.isLoading = false;
    }
  };

  syncOrderData(order: ILongOrder) {
    this.purchases = this.getPurchases(order.items);
  }

  getPurchases(orderItems: IOrderItem[]) {
    const formattedPurchases = orderItems.map(
      (orderItem: IOrderItem) => new PurchaseViewModel(this, orderItem),
    );

    return formattedPurchases.sort(
      (a, b) => a.title.charCodeAt(0) - b.title.charCodeAt(0),
    );
  }

  getUpdatedPurchases(
    purchases: PurchaseViewModel[],
    status: 'added' | 'deleted',
    identifier: 'id' | 'sku',
  ) {
    return purchases.reduce((acc, el) => {
      const diff = Math.abs(el.quantityUpdated - el.quantity);
      const key = el[identifier];
      const value =
        (status === 'added' && (diff || el.quantityUpdated)) ||
        (status === 'deleted' && (diff || el.quantity));

      return {
        ...acc,
        [key]: value,
      };
    }, {});
  }

  onInvoiceCreate = async (orderId: number, purchases: PurchaseViewModel[]) => {
    const addedItems = purchases.filter(
      (purchase) => purchase.status === 'added' || purchase.isIncreasedQuantity,
    );

    const formattedPurchases = this.getUpdatedPurchases(
      addedItems,
      'added',
      'sku',
    );

    await createInvoice(orderId, { items: formattedPurchases });
  };

  onRefundCreate = async (
    orderId: number,
    purchases: PurchaseViewModel[],
    refundData: IRefundData,
  ) => {
    const deletedPurchases = purchases.filter(
      (purchase) =>
        purchase.status === 'removed' || purchase.isDecreasedQuantity,
    );

    const nonKitDeletedPurchases = purchases.filter(
      (purchase) =>
        (purchase.status === 'removed' || purchase.isDecreasedQuantity) &&
        !purchase.productKit,
    );

    const formattedDeletedPurchases = this.getUpdatedPurchases(
      deletedPurchases,
      'deleted',
      'id',
    );

    const formattedNonKitDeletedPurchases = this.getUpdatedPurchases(
      nonKitDeletedPurchases,
      'deleted',
      'id',
    );

    const deletedKitsIds = getOrderItemsKitsIds(deletedPurchases);

    const params = {
      items: deletedKitsIds.length
        ? formattedNonKitDeletedPurchases
        : formattedDeletedPurchases,
      productKits: deletedKitsIds,
      responsible: refundData.responsible,
      reason: refundData.reason,
    };

    await createRefund(orderId, params);
  };

  onPurchasesUpdate = async (
    orderId: number,
    purchases: PurchaseViewModel[],
  ) => {
    const existingPurchases = purchases.filter(
      (purchase) => purchase.status !== 'removed',
    );

    const formattedPurchases = existingPurchases.map((purchase) => ({
      orderItemId: purchase.status !== 'added' ? purchase.id : undefined,
      sku: purchase.sku,
      quantity: purchase.quantityUpdated,
    }));

    const order = await updatePurchases(orderId, formattedPurchases);

    return order;
  };

  onFindPurchasebySku = async (sku: string) => {
    const { data: variation } = await getVariationBySku(sku);

    return variation;
  };

  onKitPurchasesRemove = (id: number) => {
    this.purchases.forEach(
      (purchase) =>
        purchase.productKit?.id === id && (purchase.status = 'removed'),
    );
  };

  constructor(root: ExpandedOrderViewModel | null, orderItems: IOrderItem[]) {
    if (root) {
      this._root = root;
    }

    this._purchases = this.getPurchases(orderItems);

    makeAutoObservable(this);
  }
}
