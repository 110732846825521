import { FC, useEffect, useMemo } from 'react';

import { KitsViewModel } from './kits-page-view-model';
import { KitTable } from './KitTable';

interface IKitsContentProps {
  model: KitsViewModel;
}

const KitsContent: FC<IKitsContentProps> = ({ model }) => (
  <KitTable model={model} />
);

export const KitsPage: FC = () => {
  const kitsPageViewModel = useMemo(() => new KitsViewModel(), []);

  useEffect(() => {
    kitsPageViewModel.getProductTypeOptions();
    kitsPageViewModel.getKits();
  }, [kitsPageViewModel]);

  return <KitsContent model={kitsPageViewModel} />;
};
