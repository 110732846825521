import { FC } from 'react';

import { Checkbox, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table/interface';
import { Table, customActions, text } from 'components/Table';
import { TableTitle } from 'components/TableTitle';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { UserModel } from 'store/user-model';

import { UserViewModel } from '../user-view-model';

import styles from './user-table.module.scss';

interface ILinkCellProps {
  url: string;
}
interface IUserTableProps {
  model: UserViewModel;
}

const getColumns = (
  onDeactivate: (id: string) => Promise<void>,
  onReactivate: (id: string) => Promise<void>,
): ColumnsType<UserModel> => [
  text({
    key: 'firstName',
    width: '150px',
    title: 'First Name',
    dataIndex: 'firstName',
  }),
  text({
    key: 'lastName',
    width: '150px',
    title: 'Last Name',
    dataIndex: 'lastName',
  }),
  text({
    key: 'email',
    width: '150px',
    title: 'Email',
    dataIndex: 'email',
  }),
  text({
    key: 'id',
    width: '150px',
    title: 'Details',
    dataIndex: 'id',
    render: (record: string) => <LinkCell url={`/user/${record}`} />,
  }),
  customActions({
    title: 'Active',
    render: (_, record) => (
      <Checkbox
        checked={record.isActive}
        onChange={
          record.isActive
            ? () => onDeactivate(record.id)
            : () => onReactivate(record.id)
        }
      />
    ),
  }),
];

const LinkCell: FC<ILinkCellProps> = ({ url }) => {
  const navigate = useNavigate();

  return (
    <Tag onClick={() => navigate(url)} className={styles.link}>
      <span>Details</span>
    </Tag>
  );
};

export const UserTable: FC<IUserTableProps> = observer(({ model }) => {
  const handleDeactivate = (id: string) => model.deactivateUser(id);
  const handleReactivate = (id: string) => model.reactivateUser(id);

  return (
    <Table
      title={() => <TableTitle title="Users" />}
      columns={getColumns(handleDeactivate, handleReactivate)}
      data={model.users}
      pagination={{
        total: model.filters.count,
        disabled: model.isLoading,
        current: model.filters.page,
        pageSize: model.filters.pageSize,
      }}
      onChange={model.changeFilters}
      loading={model.isLoading}
      bordered
    />
  );
});
