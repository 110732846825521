import {
  createReturnShipment,
  deleteReturnShipment,
} from 'http/returnShipment';

import { ILongOrder, IReturnShipment, IServerError } from 'common/types';
import { EResponsibles } from 'common/types/enums';
import { EReturnReasons } from 'common/types/return/enums';
import { ESuppliers } from 'common/types/shipment/enums';
import { dollars2cents, notify, getPurchasesKitsIds } from 'common/utils';
import { makeAutoObservable } from 'mobx';

import { IReturnWithSelected } from '../types';

import { ExpandedOrderViewModel } from './expanded-order-view-model';
import { ReturnViewModel } from './return-view-model';

export class ReturnsViewModel {
  _root: ExpandedOrderViewModel | null = null;
  _isLoading = false;
  _isAddReturnModalOpen = false;
  _returnShipments: ReturnViewModel[] = [];

  get root() {
    return this._root;
  }

  get orderViewModel() {
    return this.root?.root;
  }

  get disabled() {
    return !!this.root?.order?.disabled;
  }

  get isLoading() {
    return this._isLoading;
  }

  set isLoading(isLoading: boolean) {
    this._isLoading = isLoading;
  }

  get isAddReturnModalOpen() {
    return this._isAddReturnModalOpen;
  }

  set isAddReturnModalOpen(isOpen: boolean) {
    this._isAddReturnModalOpen = isOpen;
  }

  get returnShipments() {
    return this._returnShipments;
  }

  set returnShipments(returnShipments: ReturnViewModel[]) {
    this._returnShipments = returnShipments;
  }

  onAddReturnModalOpen = () => {
    this.isAddReturnModalOpen = true;
  };

  onAddReturnModalClose = () => {
    this.isAddReturnModalOpen = false;
  };

  syncOrderData = (order: ILongOrder) => {
    this.returnShipments = order.returnShipments.map(
      (returnShipment) => new ReturnViewModel(this, returnShipment),
    );
  };

  onReturnCreate = async (
    returnItems: IReturnWithSelected[],
    supplier: ESuppliers,
    responsible: EResponsibles,
    reason: EReturnReasons,
    costOfGoods: number,
  ) => {
    const orderViewModel = this.root;
    const orderModel = orderViewModel?.order;

    if (!orderViewModel || !orderModel) {
      return;
    }

    try {
      this.isLoading = true;

      const formattedReturnItems = returnItems
        .filter((returnItem) => returnItem.selected)
        .map((returnItem) => {
          const { selected, ...restReturnItem } = returnItem;

          return { ...restReturnItem };
        });

      const deletedPurchaseKits = getPurchasesKitsIds(formattedReturnItems);
      const haveDeletedKits = !!deletedPurchaseKits.length;

      const items = haveDeletedKits
        ? formattedReturnItems.filter((item) => !item.productKit)
        : formattedReturnItems;

      const formattedItems = items.reduce(
        (acc, curr) => ({ ...acc, [curr.id]: curr.selectedQuantity || 1 }),
        {},
      );

      const hasItems = !!Object.keys(formattedItems).length;

      const params = {
        orderId: orderModel.id,
        ...(hasItems && { items: { ...formattedItems } }),
        productKits: deletedPurchaseKits,
        reason: reason,
        supplier: supplier,
        responsible: responsible,
        trackingNumber: '',
        costOfGoods: dollars2cents(Number(costOfGoods)),
        comment: '',
      };

      await createReturnShipment(params);

      const updatedOrder =
        this.orderViewModel && (await this.orderViewModel.syncOrderData());

      const updatedReturns = updatedOrder?.returnShipments.map(
        (returnShipment) => new ReturnViewModel(this, returnShipment),
      );

      this.returnShipments = updatedReturns ?? this.returnShipments;
      this.isAddReturnModalOpen = false;
    } catch (e) {
      const error = e as IServerError;

      notify(error.response.data.message, 'error');
    } finally {
      this.isLoading = false;
    }
  };

  onReturnDelete = async (id: number, handling?: boolean) => {
    if (!this.root) {
      return;
    }

    try {
      handling && (this.isLoading = true);

      await deleteReturnShipment(id);

      this.orderViewModel && (await this.orderViewModel.syncOrderData());

      const updatedReturns: ReturnViewModel[] = this.returnShipments.filter(
        (returnShipment) => returnShipment.id !== id,
      );

      this.returnShipments = updatedReturns;
    } catch (e) {
      if (!handling) {
        throw e;
      }

      const error = e as IServerError;

      notify(error.response.data.message, 'error');
    } finally {
      handling && (this.isLoading = false);
    }
  };

  constructor(
    root: ExpandedOrderViewModel | null,
    returnShipments: IReturnShipment[],
  ) {
    if (root) {
      this._root = root;
    }

    this._returnShipments = returnShipments.map(
      (returnShipment) => new ReturnViewModel(this, returnShipment),
    );

    makeAutoObservable(this);
  }
}
