import { FC } from 'react';

import { Button } from 'antd';
import Title from 'antd/es/typography/Title';
import { priorityOptions } from 'common/constants';
import { ICreateBrand } from 'common/types/brand';
import { EStatuses } from 'common/types/enums';
import { brandSchema } from 'common/validation';
import {
  Form,
  FormInput,
  FormSelect,
  FormSwitch,
  FormTextArea,
  FormUploader,
} from 'components/Form';
import { Spinner } from 'components/Spinner';
import { observer } from 'mobx-react-lite';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { BrandControllerViewModel } from './brand-controller-view-model';

import styles from 'styles/page-controller.module.scss';

export interface IBrandControllerProps {
  model: BrandControllerViewModel;
}

const BrandController: FC<IBrandControllerProps> = observer(({ model }) => {
  const navigate = useNavigate();
  const { search } = useLocation();

  const brand = model.brand;
  const isArchive = brand.status === EStatuses.ARCHIVE;

  const isUpdate = !!brand.id;
  const eventText = isUpdate ? 'Update' : 'Create';

  const onSubmit = async (values: ICreateBrand) => {
    const result = await brand.manageBrand(isUpdate, values);

    if (result) {
      navigate({
        pathname: '/brand',
        search: search,
      });
    }
  };

  const cancel = () =>
    navigate({
      pathname: '/brand',
      search: search,
    });

  if (brand.loading) {
    return (
      <div className={styles.formLoading}>
        <Spinner type="large" />
      </div>
    );
  }

  return (
    <div className={styles.formContainer}>
      <Title level={5} type="secondary" className={styles.title}>
        {`${eventText} brand`}
      </Title>
      <Form
        initialValues={{
          title: brand.title,
          main: brand.main,
          displayPriority: brand.displayPriority,
          description: brand.description,
          photo: brand.photo,
          isArchive: isArchive,
          metaTitle: brand.metaTitle,
          metaDescription: brand.metaDescription,
        }}
        validation={brandSchema}
        onSubmit={onSubmit}
      >
        <div className={styles.formContent}>
          <div className={styles.fields}>
            <FormUploader
              name="photo"
              label="Brand photo"
              required
              className={styles.imagesContent}
            />
            <FormInput
              required
              name="title"
              label="Title"
              className={styles.field}
            />
            <FormSwitch
              required
              name="main"
              label="Main"
              className={styles.field}
            />
            <FormSelect
              required
              name="displayPriority"
              label="Priority"
              defaultValue={brand.displayPriority}
              options={priorityOptions}
              className={styles.field}
            />
            <FormTextArea
              required
              name="description"
              label="Description"
              autoSize={{ minRows: 6, maxRows: 10 }}
              className={styles.field}
            />
            <FormSwitch
              required
              name="isArchive"
              label="Archive"
              className={styles.field}
            />
            <FormInput
              name="metaTitle"
              label="Meta title"
              showCount
              className={styles.field}
            />
            <FormTextArea
              name="metaDescription"
              label="Meta description"
              autoSize={{ minRows: 6, maxRows: 10 }}
              showCount
              className={styles.field}
            />
            <div className={styles.buttons}>
              <Button disabled={brand.loading} onClick={cancel} type="default">
                Cancel
              </Button>
              <Button
                loading={brand.loading}
                disabled={brand.loading}
                type="primary"
                htmlType="submit"
              >
                {eventText}
              </Button>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
});

export const BrandControllerPage = () => {
  const { brandId } = useParams();

  const model = new BrandControllerViewModel(Number(brandId));

  return <BrandController model={model} />;
};
