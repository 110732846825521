import { editVariation } from 'http/variationApi';

import { FC, useState } from 'react';

import { Button } from 'antd';
import { notify } from 'common/utils';
import Variation, { TVariationItem } from 'components/VariantsCard/Variation';
import { ProductControllerViewModel } from 'pages/Product/ProductController/product-controller-view-model';
import { useFormContext } from 'react-hook-form';

import styles from './editable-variation.module.scss';

interface IEditableVariationProps {
  name: TVariationItem['type'];
  toggleEdit: () => void;
  model: ProductControllerViewModel;
  values: string[];
}

type TVariationField = 'type' | 'size' | 'color' | 'material' | 'placement';

const EditableVariation: FC<IEditableVariationProps> = ({
  toggleEdit,
  name,
  model,
  values,
}) => {
  const [loading, setLoading] = useState(false);
  const { formState, resetField, trigger, getFieldState, getValues } =
    useFormContext();

  const variations = model.product.variations;

  const handleSave = async () => {
    await trigger('variations');

    const isValid = !getFieldState(`variations.${name}`).invalid;

    if (isValid) {
      setLoading(true);

      const valuesForm = getValues(`variations.${name}`) as string[];
      const defaultValues = formState.defaultValues?.variations?.[
        name
      ] as string[];

      const valuesSet = new Set(valuesForm);
      const defaultValuesSet = new Set(defaultValues);
      const onlyNewValues = valuesForm.filter(
        (value: string) => !defaultValuesSet.has(value),
      );

      const noNeedToReplace = defaultValues.filter((value) =>
        valuesSet.has(value),
      );

      const noNeedToReplaceSet = new Set(noNeedToReplace);

      const newValuesWithoutDuplicates = valuesForm.filter(
        (value) => !noNeedToReplaceSet.has(value),
      );

      const oldValuesWithotDuplicates = defaultValues.filter(
        (value) => !noNeedToReplaceSet.has(value),
      );

      const replace = oldValuesWithotDuplicates.reduce(
        (acc, value, index) => ({
          ...acc,
          [value]: newValuesWithoutDuplicates[index],
        }),
        {} as Record<string, string>,
      );

      const variationsMapping = variations
        .map((variation) => ({
          [name]: variation.variation.content[name as TVariationField],
          variationId: variation.id,
        }))
        .map((variation) => {
          if (!valuesSet.has(variation[name] as TVariationField)) {
            return {
              ...variation,
              [name]: replace[variation[name] as TVariationField] ?? null,
            };
          }
          return variation;
        })
        .map((variation) => {
          if (onlyNewValues.length && !variation[name]) {
            return {
              ...variation,
              [name]: null,
            };
          }
          return variation;
        });

      const newVariationsMapping = valuesForm.reduce((acc, value) => {
        if (!variationsMapping.map((el) => el[name]).includes(value)) {
          return [
            ...acc,
            {
              variationId: null,
              [name]: value,
            },
          ];
        }

        return acc;
      }, []);

      const res = await editVariation(model.product.id, [
        ...variationsMapping,
        ...newVariationsMapping,
      ]);

      if (res) {
        await model.product.fetchProduct(model.product.id, true);

        notify('Variations list was updated!');
        resetField(`variations.${name}`, {
          defaultValue:
            model.initialFormValues.variations[name as TVariationField],
        });

        setLoading(false);
        toggleEdit();
      }
    }
  };

  const handleCancel = () => {
    resetField(`variations.${name}`);
    toggleEdit();
  };

  return (
    <div className={styles.content}>
      <div className={styles.variationTypeWrapper}>
        <div className={styles.name}>{name}</div>
      </div>
      <Variation
        editable={false}
        model={model}
        selectedOptions={new Set(values)}
        type={name}
        lastRemovable={false}
      />
      <div className={styles.btnWrapper}>
        <Button
          className={styles.button}
          disabled={loading}
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          className={styles.button}
          loading={loading}
          disabled={loading}
          onClick={handleSave}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default EditableVariation;
