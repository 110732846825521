import { createShipment, deleteShipment } from 'http/shipments';

import { ILongOrder, IServerError, IShipment } from 'common/types';
import { EShipmentReasons, ESuppliers } from 'common/types/shipment/enums';
import { dollars2cents, notify, reduceOrderItems } from 'common/utils';
import { makeAutoObservable } from 'mobx';

import { IPurchaseWithSelected } from '../types';

import { ExpandedOrderViewModel } from './expanded-order-view-model';
import { ShipmentViewModel } from './shipment-view-model';

export class ShipmentsViewModel {
  _root: ExpandedOrderViewModel | null = null;
  _isLoading = false;
  _isAddShipmentModalOpen = false;
  _shipments: ShipmentViewModel[] = [];

  get root() {
    return this._root;
  }

  get orderViewModel() {
    return this.root?.root;
  }

  get disabled() {
    return !!this.root?.order?.disabled;
  }

  get isLoading() {
    return this._isLoading;
  }

  set isLoading(isLoading: boolean) {
    this._isLoading = isLoading;
  }

  get isAddShipmentModalOpen() {
    return this._isAddShipmentModalOpen;
  }

  set isAddShipmentModalOpen(isOpen: boolean) {
    this._isAddShipmentModalOpen = isOpen;
  }

  get shipments() {
    return this._shipments;
  }

  set shipments(shipments: ShipmentViewModel[]) {
    this._shipments = shipments;
  }

  onAddShipmentModalOpen = () => {
    this.isAddShipmentModalOpen = true;
  };

  onAddShipmentModalClose = () => {
    this.isAddShipmentModalOpen = false;
  };

  syncOrderData = (order: ILongOrder) => {
    this.shipments = order.shipments.map(
      (shipment) => new ShipmentViewModel(this, shipment),
    );
  };

  onShipmentCreate = async (
    supplier: ESuppliers,
    shipmentReason: EShipmentReasons,
    supId: string,
    costOfPurchase: number,
    costOfShipping: number,
    selected: IPurchaseWithSelected[],
  ) => {
    const orderViewModel = this.orderViewModel?.expandedOrderViewModel?.order;
    const orderId = orderViewModel?.order?.id;

    if (!orderId) {
      return;
    }

    try {
      this.isLoading = true;
      const items = reduceOrderItems(selected);

      const params = {
        orderId: orderId,
        items,
        supplier: supplier,
        reason: shipmentReason,
        supId,
        costOfPurchase: dollars2cents(costOfPurchase),
        costOfShipping: dollars2cents(costOfShipping),
      };

      await createShipment(params);

      const updatedOrder =
        this.orderViewModel && (await this.orderViewModel.syncOrderData());

      const updatedShipments = updatedOrder?.shipments?.map(
        (shipment) => new ShipmentViewModel(this, shipment),
      );

      this.shipments = updatedShipments ?? this.shipments;
      this.isAddShipmentModalOpen = false;
    } catch (e) {
      const error = e as IServerError;

      notify(error.response.data.message, 'error');
    } finally {
      this.isLoading = false;
    }
  };

  onShipmentDelete = async (id: number, handling?: boolean) => {
    try {
      handling && (this.isLoading = true);

      await deleteShipment(id);
      this.orderViewModel && this.orderViewModel.syncOrderData();

      const updatedShipments: ShipmentViewModel[] = this.shipments.filter(
        (shipment) => shipment.id !== id,
      );

      this.shipments = updatedShipments;
    } catch (e) {
      if (!handling) {
        throw e;
      }

      const error = e as IServerError;

      notify(error.response.data.message, 'error');
    } finally {
      handling && (this.isLoading = false);
    }
  };

  constructor(root: ExpandedOrderViewModel | null, shipments: IShipment[]) {
    if (root) {
      this._root = root;
    }

    this._shipments = shipments.map(
      (shipment) => new ShipmentViewModel(this, shipment),
    );

    makeAutoObservable(this);
  }
}
