import { FC } from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

import styles from './spinner.module.scss';

export interface ISpinnerProps {
  type: 'medium' | 'large';
}

export const Spinner: FC<ISpinnerProps> = ({ type }) => (
  <Spin indicator={<LoadingOutlined className={styles[type]} spin />} />
);
