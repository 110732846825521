import { FC } from 'react';

import { InputNumber, InputNumberProps } from 'antd';
import cn from 'clsx';
import { Controller, useFormContext } from 'react-hook-form';

import styles from '../form.module.scss';

export interface IFormNumberInputProps extends InputNumberProps {
  name: string;
  label?: string;
  labelPosition?: 'top' | 'left';
  required?: boolean;
  className?: string;
  inputClassName?: string;
}

export const FormNumberInput: FC<IFormNumberInputProps> = ({
  name,
  label,
  labelPosition = 'top',
  required,
  className,
  inputClassName,
  onChange,
  min,
  ...props
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange: internalChange, value, name },
        fieldState: { error },
      }) => {
        const handleInputChange = (value: number) => {
          const inputValue = typeof min !== 'undefined' && !value ? min : value;

          internalChange(inputValue);
          onChange?.(inputValue);
        };

        return (
          <div
            className={cn(
              styles.container,
              styles.inputWrapper,
              styles[`label-${labelPosition}`],
              className,
            )}
          >
            {!!label && (
              <label htmlFor={name} className={styles.label}>
                {label}
                {required && <span className={styles.required}>*</span>}
              </label>
            )}
            <InputNumber
              id={name}
              onChange={handleInputChange}
              value={value}
              name={name}
              min={min}
              status={!!error ? 'error' : ''}
              className={inputClassName}
              {...props}
            />
            {!!error && <div className={styles.error}>{error?.message}</div>}
          </div>
        );
      }}
    />
  );
};
