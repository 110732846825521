import { ChangeEvent, FC, Fragment, useEffect, useState } from 'react';

import { Button, Divider, Input, Modal, Select } from 'antd';
import { defaultImage, returnReasonDefaultOptions } from 'common/constants';
import { IItemStats } from 'common/types';
import { EResponsibles } from 'common/types/enums';
import { EReturnReasons } from 'common/types/return/enums';
import { IReturnWithSelected } from 'common/types/return-shipment';
import { ESuppliers } from 'common/types/shipment/enums';
import { PurchaseModel } from 'store/purchase-model';

import { OrderExpandedViewModel } from '../../../order-expanded-view-model';
import { ShipmentModalItem } from '../../ShipmentModalItem';

import styles from './create-return-shipment-modal.module.scss';

interface IReturnModalProps {
  openEditModal: boolean;
  handleEditModalClose: () => void;
  model: OrderExpandedViewModel;
}

const getAvailablePurchaseIds = (itemStats: IItemStats[]) =>
  itemStats.reduce((acc, curr) => {
    if (curr.totalShipped) {
      acc.push(curr.orderItemId);
    }

    return acc;
  }, [] as number[]);

const getFormattedPurchases = (
  purchases: PurchaseModel[],
  availableIds: number[],
): IReturnWithSelected[] =>
  purchases.reduce((acc, curr) => {
    if (availableIds.includes(curr.id)) {
      const hasProductKit = !!curr.productKit;

      acc.push({
        ...curr.purchase,
        selected: false,
        selectedQuantity: hasProductKit ? curr.quantity : 1,
      });
    }

    return acc;
  }, [] as IReturnWithSelected[]);

const getReturnShipmentModalItemProps = (returnItem: IReturnWithSelected) => {
  const title = returnItem.productKit
    ? `${returnItem.title} - KIT №${returnItem.productKit.id}`
    : returnItem.title;

  const photo = returnItem.variation.content.photos?.find(
    (photo) => photo.id === returnItem.variation.content.mainPhotoId,
  ) || {
    id: 0,
    key: 'key',
    url: defaultImage,
  };

  const disabled = { input: !returnItem.selected || !!returnItem.productKit };

  return { title, photo, disabled };
};

export const CreateReturnShipmentModal: FC<IReturnModalProps> = ({
  openEditModal,
  handleEditModalClose,
  model,
}) => {
  const { purchases, itemStats } = model;
  const [supplier, setSupplier] = useState<ESuppliers | null>(null);
  const [responsible, setResponsible] = useState<EResponsibles | null>(null);
  const [reason, setReason] = useState<EReturnReasons | null>(null);
  const [costOfGoods, setCostOfGoods] = useState('');
  const [returnItems, setReturnItems] = useState<IReturnWithSelected[]>([]);
  const [isAllSelected, setAllSelected] = useState(false);

  const hasReturnItems = !!returnItems.length;
  const isCostOfGoodsValid =
    +costOfGoods && !isNaN(+costOfGoods) && +costOfGoods > 0;

  const canAdd =
    returnItems.some((item) => item.selected) &&
    !!supplier &&
    !!responsible &&
    !!reason &&
    isCostOfGoodsValid;

  const onSupplierChange = (supplier: ESuppliers) => setSupplier(supplier);

  const onResponsibleChange = (responsible: EResponsibles) =>
    setResponsible(responsible);

  const onReasonChange = (reason: EReturnReasons) => setReason(reason);

  const onCostOfGoodsChange = (e: ChangeEvent<HTMLInputElement>) =>
    setCostOfGoods(e.target.value);

  const handleToggleAll = () => {
    const updatedItems = returnItems.map((returnItem) => ({
      ...returnItem,
      selected: !isAllSelected,
      selectedQuantity: !isAllSelected ? returnItem.quantity : 1,
    }));

    setReturnItems(updatedItems);
  };

  const handleChangeSelected = (selectedReturnItem: IReturnWithSelected) => {
    const updatedItems = returnItems.map((returnItem) => {
      const shouldChangeSelected =
        returnItem.id === selectedReturnItem.id ||
        (returnItem.productKit &&
          selectedReturnItem.productKit &&
          returnItem.productKit.id === selectedReturnItem.productKit.id);

      if (shouldChangeSelected) {
        return { ...returnItem, selected: !returnItem.selected };
      }

      return returnItem;
    });

    setReturnItems(updatedItems);
  };

  const handleChangeValue = (purchaseId: number, value: number | null) => {
    const updatedItems = returnItems.map((returnItem) => {
      if (returnItem.id === purchaseId) {
        return { ...returnItem, selectedQuantity: Number(value) };
      }

      return returnItem;
    });

    setReturnItems(updatedItems);
  };

  const handleCreate = () => {
    const formattedReturnItems = returnItems
      .filter((returnItem) => returnItem.selected)
      .map((returnItem) => {
        const { selected, ...restReturnItem } = returnItem;

        return { ...restReturnItem };
      });

    model.createReturn(
      model.order.id,
      formattedReturnItems,
      supplier as ESuppliers,
      responsible as EResponsibles,
      reason as EReturnReasons,
      +costOfGoods,
    );

    handleEditModalClose();
  };

  useEffect(() => {
    if (openEditModal) {
      const availablePurchasesIds = getAvailablePurchaseIds(itemStats);
      const formattedPurchases = getFormattedPurchases(
        purchases,
        availablePurchasesIds,
      );

      setSupplier(null);
      setResponsible(null);
      setReason(null);
      setCostOfGoods('');
      setReturnItems(formattedPurchases);
    }
  }, [openEditModal, purchases, itemStats]);

  useEffect(() => {
    const allSelected = !returnItems.some((returnItem) => !returnItem.selected);

    setAllSelected(allSelected);
  }, [returnItems]);

  return (
    <Modal
      destroyOnClose
      open={openEditModal}
      onOk={handleEditModalClose}
      onCancel={handleEditModalClose}
      footer={null}
    >
      <div className={styles.title}>Add new Return</div>
      <div className={styles.selectors}>
        <Select
          placeholder="Supplier"
          options={model.supplierOptions}
          value={supplier}
          onChange={onSupplierChange}
          className={styles.selector}
        />
        <Select
          placeholder="Responsible"
          options={model.defaultResponsibleOptions}
          value={responsible}
          onChange={onResponsibleChange}
          className={styles.selector}
        />
        <Select
          placeholder="Reason"
          value={reason}
          options={returnReasonDefaultOptions}
          className={styles.selector}
          onChange={onReasonChange}
        />
        <Button
          onClick={handleToggleAll}
          disabled={!hasReturnItems}
          className={styles.button}
        >
          {isAllSelected ? 'Unselect all' : 'Select all'}
        </Button>
      </div>
      <div className={styles.productList}>
        {hasReturnItems ? (
          returnItems.map((returnItem) => {
            const { title, photo, disabled } =
              getReturnShipmentModalItemProps(returnItem);

            return (
              <Fragment key={returnItem.id}>
                <ShipmentModalItem
                  title={title}
                  photo={photo}
                  selected={returnItem.selected}
                  quantity={returnItem.selectedQuantity}
                  min={1}
                  max={returnItem.quantity}
                  disabled={disabled}
                  onSelect={() => handleChangeSelected(returnItem)}
                  onChange={(e) => handleChangeValue(returnItem.id, e)}
                />
                <Divider />
              </Fragment>
            );
          })
        ) : (
          <span>No purchases available for return</span>
        )}
      </div>
      <div className={styles.footer}>
        <Input
          value={costOfGoods}
          onChange={onCostOfGoodsChange}
          placeholder="Cost of goods"
          className={styles.input}
        />
        <Button type="primary" onClick={handleCreate} disabled={!canAdd}>
          Add Return
        </Button>
      </div>
    </Modal>
  );
};
