import { ILongOrder } from 'common/types';
import { cents2dollars } from 'common/utils';
import { makeAutoObservable } from 'mobx';

import { ExpandedOrderViewModel } from './expanded-order-view-model';

export interface IPerformance {
  shipments: number;
  purchaseCost: number;
  shippingCost: number;
  fees: number;
  margin: number;
  marginPercent: number;
}

export class PerformanceViewModel {
  _root: ExpandedOrderViewModel | null = null;
  _isLoading = false;
  _performance: IPerformance;

  get root() {
    return this._root;
  }

  get disabled() {
    return !!this.root?.order?.disabled;
  }

  get isLoading() {
    return this._isLoading;
  }

  set isLoading(isLoading: boolean) {
    this._isLoading = isLoading;
  }

  get performance() {
    return this._performance;
  }

  set performance(performance: IPerformance) {
    this._performance = performance;
  }

  syncOrderData = (order: ILongOrder) => {
    this.performance = this.getPerformance(order);
  };

  getPerformance = (order: ILongOrder) => {
    return {
      shipments: order.efficiency.shipmentAmount,
      purchaseCost: cents2dollars(order.efficiency.purchase),
      shippingCost: cents2dollars(order.efficiency.shipping),
      fees: cents2dollars(order.efficiency.fees),
      margin: cents2dollars(order.efficiency.margin),
      marginPercent: order.efficiency.marginPercent,
    };
  };

  constructor(root: ExpandedOrderViewModel | null, order: ILongOrder) {
    if (root) {
      this._root = root;
    }

    this._performance = this.getPerformance(order);

    makeAutoObservable(this);
  }
}
