import { computed, makeObservable, observable } from 'mobx';

import { CalculationViewModel } from './calculation-view-model';
import { InvoicesViewModel } from './invoices-view-model';
import { PackagesViewModel } from './packages-view-model';

export class BillingPageViewModel {
  _supplierId: number | undefined;
  _calculationViewModel: CalculationViewModel;
  _invoicesViewModel: InvoicesViewModel;
  _packagesViewModel: PackagesViewModel;

  get supplierId() {
    return this._supplierId;
  }

  get calculationViewModel() {
    return this._calculationViewModel;
  }

  get invoicesViewModel() {
    return this._invoicesViewModel;
  }

  get packagesViewModel() {
    return this._packagesViewModel;
  }

  constructor(supplierId?: number) {
    this._supplierId = supplierId;

    this._calculationViewModel = new CalculationViewModel(this, supplierId);
    this._invoicesViewModel = new InvoicesViewModel(this, supplierId);
    this._packagesViewModel = new PackagesViewModel(this, supplierId);

    makeObservable(this, {
      _supplierId: observable,
      _calculationViewModel: observable,
      _invoicesViewModel: observable,
      _packagesViewModel: observable,
      supplierId: computed,
      calculationViewModel: computed,
      invoicesViewModel: computed,
      packagesViewModel: computed,
    });
  }
}
