import { FC, ButtonHTMLAttributes } from 'react';

import cn from 'clsx';

import styles from './text-button.module.scss';

export interface ITextButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  highlighted?: boolean;
}

export const TextButton: FC<ITextButtonProps> = ({
  children,
  disabled,
  highlighted,
  className,
  onClick,
  ...props
}) => (
  <button
    disabled={disabled}
    className={cn(styles.container, className, {
      [styles.highlighted]: highlighted,
    })}
    onClick={onClick}
    {...props}
  >
    {children}
  </button>
);
