import { deactivateUser, getUsers, reactivateUser } from 'http/userApi';

import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { TTablePaginationParams } from 'common/types';
import { notify } from 'common/utils';
import { action, computed, makeObservable, observable } from 'mobx';
import { FiltersModel } from 'store/filters-model';
import { UserModel } from 'store/user-model';

export class UserViewModel {
  _isLoading = false;
  _filters: FiltersModel = new FiltersModel();
  _users: UserModel[] = [];

  get isLoading() {
    return this._isLoading;
  }

  set isLoading(isLoading: boolean) {
    this._isLoading = isLoading;
  }

  get filters() {
    return this._filters;
  }

  get users() {
    return this._users;
  }

  set users(users: UserModel[]) {
    this._users = users;
  }

  async getUsers() {
    try {
      this.isLoading = true;

      const params = {
        page: this.filters.page,
        pageSize: this.filters.pageSize,
      };

      const { users, count } = await getUsers(params);

      this.filters.count = count;
      this.users = users.map((user) => new UserModel(user));
    } catch (e) {
      notify(`Error, reason:${e}`, 'error');
    } finally {
      this.isLoading = false;
    }
  }

  async deactivateUser(id: string) {
    try {
      const deactivatedUser = await deactivateUser(id);

      const updatedUser = this.users.find(
        (user) => user.id === id,
      ) as UserModel;

      updatedUser.user = deactivatedUser;

      this.users = [...this.users];
    } catch (e) {
      notify(`Error, reason:${e}`, 'error');
    }
  }

  async reactivateUser(id: string) {
    try {
      const reactivatedUser = await reactivateUser(id);

      const updatedUser = this.users.find(
        (user) => user.id === id,
      ) as UserModel;

      updatedUser.user = reactivatedUser;

      this.users = [...this.users];
    } catch (e) {
      notify(`Error, reason:${e}`, 'error');
    }
  }

  changeFilters = (
    pagination: TTablePaginationParams,
    _filters: Record<string, FilterValue>,
    sorter: SorterResult<UserModel>,
  ) => {
    const { current } = pagination;

    this._filters.page = current;
    this._filters.setSorterSortOptions(sorter);
    this.getUsers();
  };

  constructor() {
    makeObservable(this, {
      _isLoading: observable,
      _filters: observable,
      _users: observable,
      isLoading: computed,
      filters: computed,
      users: computed,
      getUsers: action,
      changeFilters: action,
    });
  }
}
