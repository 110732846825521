import { getSupplierUnpaidShipments } from 'http/supplierApi';

import { notify } from 'common/utils';
import { action, computed, makeObservable, observable } from 'mobx';
import { CalculationModel } from 'store/calculation-model';

import { BillingPageViewModel } from './billing-page-view-model';

export class CalculationViewModel {
  _root: BillingPageViewModel;
  _supplierId: number | undefined;
  _isLoading = false;
  _calculations: CalculationModel[] = [];

  get supplierId() {
    return this._supplierId;
  }

  get isLoading() {
    return this._isLoading;
  }

  set isLoading(isLoading: boolean) {
    this._isLoading = isLoading;
  }

  get calculations() {
    return this._calculations;
  }

  set calculations(calculations: CalculationModel[]) {
    this._calculations = calculations;
  }

  async getCalculations() {
    if (!this.supplierId) {
      return;
    }

    try {
      this.isLoading = true;

      const { calculation } = await getSupplierUnpaidShipments(this.supplierId);

      this.calculations = [new CalculationModel(calculation)];
    } catch (e) {
      notify(`Error, reason:${e}`, 'error');
    } finally {
      this.isLoading = false;
    }
  }

  constructor(root: BillingPageViewModel, supplierId?: number) {
    this._root = root;
    this._supplierId = supplierId;

    makeObservable(this, {
      _supplierId: observable,
      _isLoading: observable,
      _calculations: observable,
      supplierId: computed,
      isLoading: computed,
      calculations: computed,
      getCalculations: action,
    });
  }
}
