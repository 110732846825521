import { FC } from 'react';

import { observer } from 'mobx-react-lite';
import { ReturnViewModel } from 'pages/NewOrder/view-models/return-view-model';

import { StaticData } from '../../../PackageDetails/StaticData';

import { EditableData } from './EditableData';

interface IReturnDetailsProps {
  model: ReturnViewModel;
  disabled: boolean;
}

export const ReturnDetails: FC<IReturnDetailsProps> = observer(
  ({ model, disabled }) => {
    const staticField = [
      { label: 'Cost of goods', value: model.costOfGoodsView },
      { label: 'Supplier', value: model.supplierView },
      { label: 'Responsible', value: model.responsible },
      { label: 'Reason', value: model.reasonView },
    ];
    return (
      <>
        {model.isEditing ? (
          <EditableData
            costOfGoods={model.costOfGoodsView}
            supplier={model.supplier.name}
            supplierOptions={
              model.root?.root?.root?.root?.supplierOptions ?? []
            }
            responsible={model.responsible ?? ''}
            responsibleOptions={
              model.root?.root?.root?.root?.defaultResponsibleOptions ?? []
            }
            reason={model.reason}
            reasonOptions={
              model.root?.root?.root?.root?.defaultReturnReasonOptions ?? []
            }
            disabled={disabled}
            isLoading={model.isLoading}
            onToggleEdit={model.onToggleEditing}
            onEdit={model.onReturnUpdate}
          />
        ) : (
          <StaticData
            fields={staticField}
            disabled={disabled}
            onToggleEdit={model.onToggleEditing}
          />
        )}
      </>
    );
  },
);
