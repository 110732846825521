import { FC } from 'react';

import { Tooltip } from 'antd';
import {
  EPaymentCancelledStatuses,
  EPaymentStatuses,
} from 'common/types/order/enums';
import { removeStatusPrefix, upperUndescoreToRegularCase } from 'common/utils';

import { ReactComponent as CrossIcon } from '../../../../assets/svg/cross.svg';
import { ReactComponent as DollarIcon } from '../../../../assets/svg/dollar.svg';

import styles from './payment-cell.module.scss';
export interface IPaymentCellProps {
  payment: EPaymentStatuses | EPaymentCancelledStatuses;
}
type TPaymentStatusKey = keyof typeof EPaymentStatuses;
type TPaymentCancelledStatusKey = keyof typeof EPaymentCancelledStatuses;

const paymentColors = {
  [EPaymentStatuses.PAYMENT_NOT_PAID]: '#000000',
  [EPaymentStatuses.PAYMENT_PARTIALLY_PAID]: '#D8D8D8',
  [EPaymentStatuses.PAYMENT_PAID]: '#88CC43',
  [EPaymentStatuses.PAYMENT_OVERPAYMENT]: '#E54F4F',
};

export const PaymentCell: FC<IPaymentCellProps> = ({ payment }) => {
  const isCancelled =
    EPaymentCancelledStatuses[payment as TPaymentCancelledStatusKey];

  const tooltip = upperUndescoreToRegularCase(
    removeStatusPrefix(
      payment,
      isCancelled ? 'PAYMENT_CANCELLED_' : 'PAYMENT_',
    ),
  );

  return (
    <div className={styles.container}>
      <Tooltip title={tooltip}>
        {isCancelled ? (
          <CrossIcon color="#E54F4F"></CrossIcon>
        ) : (
          <DollarIcon color={paymentColors[payment as TPaymentStatusKey]} />
        )}
      </Tooltip>
    </div>
  );
};
