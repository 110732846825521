import { FC, Fragment, useEffect, useRef, useState } from 'react';

import { Button, Modal, Divider } from 'antd';
import { defaultImage } from 'common/constants';
import { IItemStats } from 'common/types';
import { IOrderItem } from 'common/types/purchase';
import {
  IReturnShipmentItemWithControlAttrs,
  IReturnShipment,
  IReturnShipmentItem,
} from 'common/types/return-shipment';
import { compareFlatObjects, reduceOrderItems } from 'common/utils';
import { observer } from 'mobx-react-lite';

import { PackageModalItem } from '../../PackageModalItem';

import styles from './edit-return-modal.module.scss';

interface IEditReturnModalProps {
  open: boolean;
  orderItems: IOrderItem[];
  itemStats: IItemStats[];
  returnShipment: IReturnShipment;
  isLoading?: boolean;
  onOk: (reducedItems: IReturnShipmentItemWithControlAttrs[]) => void;
  onCancel: () => void;
}

const getAvailableReturnItemIds = (itemStats: IItemStats[]) =>
  itemStats.reduce((acc, curr) => {
    if (curr.totalShipped) {
      acc.push(curr.orderItemId);
    }

    return acc;
  }, [] as number[]);

const getAvailableReturnItems = (
  orderItems: IOrderItem[],
  availableIds: number[],
) => orderItems.filter((orderItem) => availableIds.includes(orderItem.id));

const getFormattedReturnItems = (
  availableItems: IOrderItem[],
  returnItems: IReturnShipmentItem[],
): IReturnShipmentItemWithControlAttrs[] =>
  availableItems.reduce((acc, curr) => {
    const relatedReturnItem = returnItems.find(
      (item) => item.orderItem?.id === curr.id,
    );

    const hasProductKit = !!curr.productKit;

    acc.push({
      id: curr.id,
      quantity: curr.quantity,
      variation: curr.variation,
      productKit: curr.productKit,
      orderItem: relatedReturnItem?.orderItem,
      selected: !!relatedReturnItem,
      selectedQuantity: hasProductKit
        ? curr.quantity
        : relatedReturnItem?.quantity ?? 1,
    });

    return acc;
  }, [] as IReturnShipmentItemWithControlAttrs[]);

const getReturnShipmentModalItemProps = (
  returnShipment: IReturnShipment,
  returnItem: IReturnShipmentItemWithControlAttrs,
) => {
  const { product } = returnItem.variation;
  const title = returnItem.productKit?.id
    ? `${product.title} - KIT №${returnItem.productKit.id}`
    : product.title;

  const photo = returnItem.variation.content.photos?.find(
    (photo) => photo.id === returnItem.variation.content.mainPhotoId,
  ) || {
    id: 0,
    key: 'key',
    url: defaultImage,
  };

  const disabled = {
    checkbox: returnShipment.resolved,
    input:
      returnShipment.resolved ||
      !returnItem.selected ||
      !!returnItem.productKit,
  };

  return { title, photo, disabled };
};

export const EditReturnModal: FC<IEditReturnModalProps> = observer(
  ({
    open,
    orderItems,
    itemStats,
    returnShipment,
    isLoading,
    onOk,
    onCancel,
  }) => {
    const [returnItems, setReturnItems] = useState<
      IReturnShipmentItemWithControlAttrs[]
    >([]);
    const [isAllSelected, setAllSelected] = useState(true);
    const [hasAvailableReturnItems, setAvailableReturnItems] = useState(false);

    const initialReducedItems = useRef<Record<number, number>>({});
    const reducedItems = reduceOrderItems(returnItems);

    const canControl = hasAvailableReturnItems && !returnShipment.resolved;
    const hasSelected = returnItems.some((returnItem) => returnItem.selected);
    const areItemsEqual = compareFlatObjects(
      initialReducedItems.current,
      reducedItems,
    );

    const canChange =
      hasSelected &&
      hasAvailableReturnItems &&
      !areItemsEqual &&
      !returnShipment.resolved;

    const handleToggleAll = () => {
      const updatedItems = returnItems.map((returnItem) => ({
        ...returnItem,
        selected: !isAllSelected,
      }));

      setReturnItems(updatedItems);
    };

    const handleChangeSelected = (
      selectedReturnItem: IReturnShipmentItemWithControlAttrs,
    ) => {
      const updatedItems = returnItems.map((returnItem) => {
        const shouldChangeSelected =
          returnItem.id === selectedReturnItem.id ||
          (returnItem.productKit &&
            selectedReturnItem.productKit &&
            returnItem.productKit.id === selectedReturnItem.productKit.id);

        if (shouldChangeSelected) {
          return { ...returnItem, selected: !returnItem.selected };
        }

        return returnItem;
      });

      setReturnItems(updatedItems);
    };
    const handleChangeValue = (returnItemId: number, value: number | null) => {
      const updatedItems = returnItems.map((returnItem) => {
        if (returnItem.id === returnItemId) {
          return { ...returnItem, selectedQuantity: Number(value) };
        }

        return returnItem;
      });

      setReturnItems(updatedItems);
    };

    const handleChangeReturn = async () => onOk(returnItems);

    useEffect(() => {
      if (open) {
        const availableReturnItemIds = getAvailableReturnItemIds(itemStats);
        const availableReturnItems = getAvailableReturnItems(
          orderItems,
          availableReturnItemIds,
        );

        const formattedItems = getFormattedReturnItems(
          availableReturnItems,
          returnShipment.items,
        );

        const allSelected =
          availableReturnItems.length ===
          formattedItems.filter((returnItem) => returnItem.selected).length;

        initialReducedItems.current = reduceOrderItems(formattedItems);
        setReturnItems(formattedItems);
        setAvailableReturnItems(!!availableReturnItemIds.length);
        setAllSelected(allSelected);
      }
    }, [itemStats, open, orderItems, returnShipment]);

    useEffect(() => {
      const allSelected = !returnItems.some(
        (returnItem) => !returnItem.selected,
      );

      setAllSelected(allSelected);
    }, [returnItems]);

    return (
      <Modal
        destroyOnClose
        open={open}
        title={`Edit Return #${returnShipment.id}`}
        okButtonProps={{ disabled: !canChange, loading: isLoading }}
        onOk={handleChangeReturn}
        onCancel={onCancel}
      >
        <div className={styles.selectorWrapper}>
          <Button
            onClick={handleToggleAll}
            disabled={!canControl}
            className={styles.toggle}
          >
            {isAllSelected ? 'Unselect all' : 'Select all'}
          </Button>
        </div>
        <div className={styles.productList}>
          {hasAvailableReturnItems ? (
            returnItems.map((returnItem) => {
              const { title, photo, disabled } =
                getReturnShipmentModalItemProps(returnShipment, returnItem);

              return (
                <Fragment key={returnItem.id}>
                  <PackageModalItem
                    title={title}
                    photo={photo}
                    selected={returnItem.selected}
                    quantity={returnItem.selectedQuantity}
                    max={returnItem.quantity}
                    disabled={disabled}
                    onSelect={() => handleChangeSelected(returnItem)}
                    onChange={(e) => handleChangeValue(returnItem.id, e)}
                  />
                  <Divider />
                </Fragment>
              );
            })
          ) : (
            <span>No available purchases to edit</span>
          )}
        </div>
      </Modal>
    );
  },
);
