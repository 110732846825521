import { FC } from 'react';

import { IShipment } from 'common/types/shipment';

import { EditableData } from './EditableData';
import { StaticData } from './StaticData';

import styles from './purchase-item.module.scss';

interface IPurchaseItemProps {
  shipment: IShipment;
  isEdit: boolean;
}

const PurchaseItem: FC<IPurchaseItemProps> = ({ shipment, isEdit }) => {
  return (
    <div key={shipment.id} className={styles.purchase}>
      <div className={styles.content}>
        {!isEdit ? (
          <StaticData shipment={shipment} />
        ) : (
          <EditableData shipment={shipment} />
        )}
      </div>
    </div>
  );
};

export default PurchaseItem;
