import { FC } from 'react';

import { Spin, Typography, Modal } from 'antd';
import { useCurrentUserStore } from 'common/providers/store-provider';
import { EDisputeStatuses } from 'common/types/order/enums';
import { observer } from 'mobx-react-lite';
import { StatusesViewModel } from 'pages/NewOrder/view-models/statuses-view-model';

import { Status } from './Status';

import styles from './statuses.module.scss';

interface IStatusesProps {
  model: StatusesViewModel | null;
}

export const Statuses: FC<IStatusesProps> = observer(({ model }) => {
  const [modal, contextHolder] = Modal.useModal();
  const { isSupplier } = useCurrentUserStore();

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Typography.Title level={4} className={styles.title}>
          Statuses
        </Typography.Title>
      </div>
      <div className={styles.content}>
        {!model ? (
          <Spin className={styles.spin} />
        ) : (
          <div className={styles.statuses}>
            <Status
              label="TO DO"
              field={{
                type: 'select',
                props: {
                  value: model.todo,
                  mode: 'multiple',
                  placeholder: 'Select event',
                  options: model.todoOptions,
                  disabled: model.root?.order?.disabled || isSupplier,
                  loading: model.isLoading,
                  onChange: (e: string[]) => model.onStatusChange(e, 'todo'),
                },
              }}
            />
            <Status
              label="Reshipments"
              values={model.reshipmentsView}
              field={{ type: 'multiple' }}
            />
            <Status
              label="Refund"
              values={model.refundView}
              field={{ type: 'multiple' }}
            />
            <Status
              label="Supplier"
              values={model.supplierView}
              field={{ type: 'multiple' }}
            />
            <Status label="Return" value={model.returnView} />
            <Status
              label="Dispute"
              field={{
                type: 'select',
                props: {
                  value: model.disputeView,
                  placeholder: 'Select dispute',
                  options: model.disputeOptions,
                  disabled:
                    model.dispute ===
                      EDisputeStatuses.DISPUTE_STRIPE_DISPUTE_LOST ||
                    model.root?.order?.isCancelled ||
                    isSupplier,
                  loading: model.isLoading,
                  onChange: (e: string[]) => {
                    if (
                      typeof e === 'string' &&
                      e === EDisputeStatuses.DISPUTE_STRIPE_DISPUTE_LOST
                    ) {
                      modal
                        .confirm({
                          content:
                            'Grand total and Total paid values will be reset and it can’t be undone. Are you sure?',
                        })
                        .then(
                          (confirmed) => {
                            if (confirmed) {
                              model.onStatusChange(e, 'disputeStatus');
                            }
                          },
                          () => {
                            console.log('REJECT :>> ', model.dispute);
                          },
                        );
                    } else {
                      model.onStatusChange(e, 'disputeStatus');
                    }
                  },
                },
              }}
            />
          </div>
        )}
      </div>

      {contextHolder}
    </div>
  );
});
