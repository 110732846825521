import { FC } from 'react';

import { Skeleton } from 'antd';

import styles from './efficiency-skeleton.module.scss';

const efficiencyColumns = [
  'Purchase costs:',
  'Shipping costs:',
  'Fees:',
  'Margin$:',
  'Margin%:',
  'Shipments amount:',
];

export const EfficiencySkeleton: FC = () => (
  <div className={styles.efficiencySkeleton}>
    {efficiencyColumns.map((column) => (
      <div className={styles.content}>
        <span className={styles.title}>{column}&nbsp;</span>
        <Skeleton.Button active size="small" shape="default" block={false} />
      </div>
    ))}
  </div>
);
