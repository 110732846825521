import { FC } from 'react';

import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, List, Popconfirm } from 'antd';
import { newSupplierSchema } from 'common/validation';
import { Form, FormInput } from 'components/Form';
import { TableTitle } from 'components/TableTitle';
import { observer } from 'mobx-react-lite';
import { UseFormReturn } from 'react-hook-form';

import { SuppliersViewModel } from '../suppliers-view-model';
import { ISupplierFormValues } from '../types';

import styles from './supplier-list.module.scss';

interface IHeaderProps {
  onCreate: (values: ISupplierFormValues) => Promise<boolean>;
}

interface ISupplierProps {
  model: SuppliersViewModel;
}

const initialValues = { name: '' };

const Header: FC<IHeaderProps> = ({ onCreate }) => {
  const handleSubmit = async (
    values: ISupplierFormValues,
    methods: UseFormReturn<any> | undefined,
  ) => {
    const isValid = await onCreate(values);

    if (isValid) {
      methods?.reset();
    }
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validation={newSupplierSchema}
      className={styles.header}
    >
      <FormInput name="name" />
      <Button
        type="primary"
        icon={<PlusCircleOutlined />}
        size="middle"
        htmlType="submit"
      >
        Add new supplier
      </Button>
    </Form>
  );
};

export const SupplierList: FC<ISupplierProps> = observer(({ model }) => (
  <div className={styles.container}>
    <Header onCreate={(values) => model.addSupplier(values)} />
    <List
      header={<TableTitle title="Suppliers" />}
      dataSource={model.suppliers}
      renderItem={(item) => (
        <List.Item>
          <div className={styles.supplier}>
            <span>{item.name}</span>
            <Popconfirm
              placement="left"
              title="Are you sure you want to delete this supplier?"
              onConfirm={() => model.deleteSupplier(item.id)}
              disabled={!!item.user}
              destroyTooltipOnHide
            >
              <Button disabled={!!item.user}>Delete</Button>
            </Popconfirm>
          </div>
        </List.Item>
      )}
      loading={model.isLoading}
      bordered
    />
  </div>
));
