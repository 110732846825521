import { FC, useEffect, useState } from 'react';

import { Button, Modal, Select } from 'antd';
import { defaultImage, shipmentReasonDefaultOptions } from 'common/constants';
import { IPurchaseWithSelected } from 'common/types/purchase';
import { EShipmentReasons, ESuppliers } from 'common/types/shipment/enums';
import { PurchaseModel } from 'store/purchase-model';

import { OrderExpandedViewModel } from '../../../order-expanded-view-model';
import { ShipmentModalItem } from '../../ShipmentModalItem';

import styles from './create-shipment-modal.module.scss';

interface ICreateShipmentModalProps {
  open: boolean;
  handleEditModalClose: () => void;
  model: OrderExpandedViewModel;
}

const getFormattedPurchases = (
  purchases: PurchaseModel[],
): IPurchaseWithSelected[] =>
  purchases.map((purchase) => ({
    ...purchase.purchase,
    id: purchase.id,
    productKit: purchase.productKit,
    title: purchase.title,
    quantity: purchase.quantity,
    selectedQuantity: 1,
    selected: false,
  }));

const getShipmentModalItemProps = (purchase: IPurchaseWithSelected) => {
  const title = purchase.productKit
    ? `${purchase.title} - KIT №${purchase.productKit.id}`
    : purchase.title;

  const photo = purchase.variation.content.photos?.find(
    (photo) => photo.id === purchase.variation.content.mainPhotoId,
  ) || {
    id: 0,
    key: 'key',
    url: defaultImage,
  };

  const disabled = { input: !purchase.selected };

  return { title, photo, disabled };
};

export const CreateShipmentModal: FC<ICreateShipmentModalProps> = ({
  open,
  handleEditModalClose,
  model,
}) => {
  const [supplier, setSupplier] = useState<ESuppliers | null>(null);
  const [shipmentReason, setShipmentReason] = useState<EShipmentReasons | null>(
    null,
  );

  const [formattedPurchases, setFormattedPurchases] = useState<
    IPurchaseWithSelected[]
  >([]);

  const canAdd = !(
    formattedPurchases.filter((purchase) => purchase.selected).length &&
    !!supplier &&
    !!shipmentReason
  );

  const onSupplierChange = (supplier: ESuppliers) => setSupplier(supplier);
  const onShipmentReasonChange = (reason: EShipmentReasons) =>
    setShipmentReason(reason);

  const toggleSelectAll = (selected: boolean) => {
    const selectedProduct = formattedPurchases.map((purchase) => ({
      ...purchase,
      selected: selected,
      selectedQuantity: selected ? purchase.quantity : 1,
    }));

    setFormattedPurchases(selectedProduct);
  };

  const onSelectedChange = (id: number) => {
    const updatedPurchases = formattedPurchases.map((purchase) => {
      if (purchase.id === id) {
        return { ...purchase, selected: !purchase.selected };
      }

      return purchase;
    });

    setFormattedPurchases(updatedPurchases);
  };

  const onQuantityChange = (id: number, value: number | null) => {
    const updatedPurchases = formattedPurchases.map((purchase) => {
      if (purchase.id === id) {
        return { ...purchase, selectedQuantity: Number(value) };
      }

      return purchase;
    });

    setFormattedPurchases(updatedPurchases);
  };

  const handleAdd = () => {
    const selected = formattedPurchases.filter((el) => el.selected);
    model.createShipment(
      model.order.id,
      supplier as ESuppliers,
      shipmentReason as EShipmentReasons,
      selected,
    );

    handleEditModalClose();
  };

  useEffect(() => {
    if (model.purchases.length && open) {
      const formattedValues = getFormattedPurchases(model.purchases);

      setSupplier(null);
      setShipmentReason(null);
      setFormattedPurchases(formattedValues);
    }
  }, [model.purchases, open]);

  return (
    <Modal
      open={open}
      onOk={handleEditModalClose}
      onCancel={handleEditModalClose}
      footer={null}
    >
      <div className={styles.title}>Add new Shipment</div>
      <div className={styles.selectorWrapper}>
        {!!formattedPurchases.filter((el) => !el.selected).length && (
          <Button onClick={() => toggleSelectAll(true)}>Select all</Button>
        )}
        {formattedPurchases.filter((el) => el.selected).length ===
          formattedPurchases.length && (
          <Button onClick={() => toggleSelectAll(false)}>Unselect all</Button>
        )}
        <Select
          placeholder="Supplier"
          options={model.supplierOptions}
          value={supplier}
          onChange={onSupplierChange}
          className={styles.selector}
          dropdownStyle={{ zIndex: 1001 }}
        />
        <Select
          placeholder="Reason"
          value={shipmentReason}
          options={shipmentReasonDefaultOptions}
          className={styles.selector}
          onChange={onShipmentReasonChange}
        />
      </div>
      <div className={styles.productList}>
        {formattedPurchases.map((purchase) => {
          const { title, photo, disabled } =
            getShipmentModalItemProps(purchase);

          return (
            <ShipmentModalItem
              key={purchase.id}
              title={title}
              photo={photo}
              selected={purchase.selected}
              quantity={purchase.selectedQuantity}
              min={1}
              max={purchase.quantity}
              disabled={disabled}
              onSelect={() => onSelectedChange(purchase.id)}
              onChange={(value) => onQuantityChange(purchase.id, value)}
            />
          );
        })}
      </div>
      <div className={styles.footer}>
        <Button disabled={canAdd} type="primary" onClick={handleAdd}>
          Add Shipment
        </Button>
      </div>
    </Modal>
  );
};
