import { getAllBrands, deleteById } from 'http/brandApi';

import { IBrand, IBrandParams } from 'common/types';
import { notify } from 'common/utils';
import { computed, makeAutoObservable, observable } from 'mobx';
import { FiltersModel, TParams } from 'store/filters-model';

import { BrandModel } from '../../store/brand-model';

export class BrandViewModel {
  _loading = false;
  _brands: BrandModel[] = [];
  _count = 0;
  _filters: FiltersModel;

  get loading() {
    return this._loading;
  }

  set loading(value: boolean) {
    this._loading = value;
  }

  set search(value: string) {
    this._filters.search = value;

    this.fetchBrands();
  }

  get brands() {
    return this._brands;
  }

  set brands(brands: BrandModel[]) {
    this._brands = brands;
  }

  get skeleton() {
    return new Array(5).fill(new BrandModel());
  }

  get count() {
    return this._count;
  }

  removeBrand = async (id: number) => {
    const updatedBrands = this._brands.filter((brand) => brand.id !== id);
    const result = await deleteById(id);

    if (result) {
      this.brands = updatedBrands;
      notify(
        <div>
          Brand <strong>{result.title}</strong> was removed succesfully!
        </div>,
      );
    }
  };

  fetchBrands = async () => {
    const { page, pageSize, search, sort, sortBy } = this._filters;

    const params: IBrandParams = {
      page: page,
      pageSize: pageSize,
      sort: sort,
      sortBy: sortBy,
    };

    if (search) {
      params.title = search;
    }

    this.loading = true;

    try {
      const { count, items, page } = await getAllBrands(params);

      this._brands = items.map(
        (brand: IBrand) => new BrandModel(brand.id, brand),
      );

      this._count = count;
      this._filters.page = page;

      return items;
    } catch (e) {
      notify(`Error, reason:${e}`, 'error');
    } finally {
      this.loading = false;
    }
  };

  constructor(params?: TParams) {
    this._filters = new FiltersModel(() => null, params);

    this.fetchBrands();

    makeAutoObservable(this, {
      _loading: observable,
      _brands: observable,
      _count: observable,

      loading: computed,
    });
  }
}
