import { FC } from 'react';

import { Button } from 'antd';
import Logo from 'assets/images/natalex-logo.jpeg';
import { useCurrentUserStore } from 'common/providers/store-provider';
import { ILoginParams } from 'common/types/current-user';
import { loginSchema } from 'common/validation';
import { FormInput, Form } from 'components/Form';
import { observer } from 'mobx-react-lite';

import styles from './styles.module.scss';

export const Auth: FC = observer(() => {
  const currentUserStore = useCurrentUserStore();
  const { loading, login } = currentUserStore;

  const submit = async (formValues: ILoginParams) => {
    login(formValues);
  };

  return (
    <div className={styles.auth}>
      <div className={styles.logo}>
        <img src={Logo} alt="Natalex admin" className={styles.logoImage} />
      </div>
      <Form
        initialValues={{
          email: '',
          password: '',
        }}
        validation={loginSchema}
        onSubmit={submit}
        className={styles.form}
      >
        <FormInput required name="email" label="Email" />
        <FormInput required type="password" name="password" label="Password" />
        <Button
          loading={loading}
          disabled={loading}
          type="primary"
          htmlType="submit"
        >
          Login
        </Button>
      </Form>
    </div>
  );
});
