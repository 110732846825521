import { AxiosResponse } from 'axios';
import { IDataWithPagination } from 'common/types';
import { ProductAutocompleteTypeEnum } from 'common/types/enums';

import { IAttributes, IProduct, ICommonProduct } from '../common/types/product';

import { $host } from './index';

const headers = {
  'Content-Type': 'multipart/form-data',
};

type TParams = {
  title: string;
  brandId: number;
  subcategoryId: number;
  status: 'ACTIVE' | 'DRAFT' | 'ARCHIVE';
};

export const getProductsWithFilters = async (params: TParams) => {
  const {
    data: { data },
  } = await $host.get<AxiosResponse<IDataWithPagination<ICommonProduct>>>(
    'admin/products',
    { params: params ?? { pageSize: 50, page: 1 } },
  );

  return data;
};

export const deleteById = async (id: IProduct['id']) => {
  const { data } = await $host.delete<AxiosResponse<IProduct>>(
    `admin/products/${id}`,
  );

  return data.data;
};

export const createProduct = async (params: FormData) => {
  const { data } = await $host.post<AxiosResponse<IProduct>>(
    `admin/products`,
    params,
    { headers },
  );

  return data.data;
};

export const deleteProductPhotos = async (imageIds: number[]) => {
  return await Promise.all(
    imageIds.map(async (imageId: number) => {
      return $host.delete(`upload/${imageId}`, { headers });
    }),
  );
};

export const editProduct = async (id: number, params: FormData) => {
  const { data } = await $host.put<AxiosResponse<IProduct>>(
    `admin/products/${id}`,
    params,
    { headers },
  );

  return data.data;
};

export const getAttributes = async (): Promise<IAttributes> => {
  const { data } = await $host.get<AxiosResponse<IAttributes>>(
    'admin/products/attributes',
  );

  return data.data;
};

export const getProductById = async (id: IProduct['id']) => {
  const { data } = await $host.get<AxiosResponse<IProduct>>(
    `admin/products/id/${id}`,
  );

  return data;
};

export const getProductAutocompleteByType = async (
  type: keyof typeof ProductAutocompleteTypeEnum,
) => {
  const {
    data: { data },
  } = await $host.get(`admin/products/autocomplete/${type}`);

  return data;
};

export const editFeedDestinations = async (
  id: number,
  params: Pick<IProduct, 'googleMerchantsDestinations'>,
) => {
  const {
    data: { data },
  } = await $host.put<AxiosResponse<IProduct>>(
    `admin/products/${id}/feed-destinations`,
    { ...params },
  );

  return data;
};

export const updateAllVariations = async (
  id: number,
  params: Record<string, string | number | undefined>,
) => {
  const {
    data: { data },
  } = await $host.patch<AxiosResponse<IProduct>>(
    `admin/products/${id}/variations`,
    params,
  );

  return data;
};
