import { getProductById } from 'http/productApi';

import { defaultProductEntity } from 'common/constants';
import {
  ICreateProduct,
  IMarkdownSection,
  IProduct,
  IVariation,
  IVariationsObject,
  TPhoto,
} from 'common/types';
import {
  notify,
  mockIdKeyGen,
  cents2dollars,
  dollars2cents,
} from 'common/utils';
import { action, computed, makeAutoObservable, observable } from 'mobx';

import { SubcategoryModel } from './subcategory-model';
import { VariationModel } from './variation-model';

function getVariationAttributes(
  variations: IVariation[],
  property: keyof IVariation['content'],
) {
  const values = variations.map((variation) => variation.content[property]);

  return values[0] ? Array.from(new Set(values)) : [];
}

export class ProductModel {
  _loading = false;
  _product: IProduct = defaultProductEntity;
  _subcategory: SubcategoryModel = new SubcategoryModel();
  _variations: VariationModel[] = [];

  get loading() {
    return this._loading || this._subcategory.loading;
  }

  set loading(loading: boolean) {
    this._loading = loading;
  }

  get product() {
    return this._product;
  }

  set product(product: IProduct) {
    this._product = product;
  }

  get subcategory() {
    return this._subcategory;
  }

  set subcategory(subcategory: SubcategoryModel) {
    this._subcategory = subcategory;
  }

  get variations() {
    return this._variations;
  }

  set variations(variations: VariationModel[]) {
    this._variations = variations;
  }

  get variationsObject() {
    return {
      color: getVariationAttributes(this._product.variations, 'color'),
      material: getVariationAttributes(this._product.variations, 'material'),
      placement: getVariationAttributes(this._product.variations, 'placement'),
      size: getVariationAttributes(this._product.variations, 'size'),
      style: getVariationAttributes(this._product.variations, 'style'),
    } as IVariationsObject;
  }

  get key() {
    return !!this._product.id ? this._product.id : mockIdKeyGen();
  }

  get uniquePhotos() {
    return [
      ...(this.product.photos ?? []),
      ...this.variations.reduce(
        (acc, { variation }) => [...acc, ...(variation.content.photos ?? [])],
        [] as TPhoto[],
      ),
    ].reduce((images, image) => {
      const img = images.find((img: TPhoto) => img.id === image.id);

      if (img) {
        return images;
      }

      return [...images, image];
    }, []);
  }

  get type() {
    return this._product.type;
  }

  get title() {
    return this._product.title;
  }

  get status() {
    return this._product.status;
  }

  get id() {
    return this._product.id;
  }

  get updatedAt() {
    return this._product.updatedAt;
  }

  get price() {
    return cents2dollars(this._product.price);
  }

  get markdownSections() {
    return this._product.markdownSections.reduce((sections, section) => {
      const id = mockIdKeyGen();

      return {
        ...sections,
        [id]: { ...section, id },
      };
    }, {} as Record<string, IMarkdownSection & { id: string }>);
  }

  serializeProduct = (values: ICreateProduct) => {
    const isEdit = !!this.id;

    const newPhotos = values.photos.filter((photo) => photo.file);
    const nonEmptyLinks = values.order_link.filter((link) => link);

    const fd = new FormData();
    newPhotos.forEach((photo) => {
      if (photo.file) {
        fd.append('photos', photo.file);
      }
    });

    if (values.mainPhotoId) {
      fd.append('mainPhotoId', values.mainPhotoId);
    }
    fd.append('title', values.title);
    fd.append('description', values.description);
    fd.append('type', values.type);
    fd.append('material', values.material);
    fd.append('color', values.color);
    fd.append('price', dollars2cents(values.price).toString());
    fd.append('discount', (values.discount ?? 0).toString());
    fd.append('subcategoryId', values.subcategoryId.toString());
    fd.append('status', values.status);
    if (Object.entries(values.markdownSections).length) {
      Object.values(values.markdownSections).forEach(({ id, ...section }) => {
        fd.append('markdownSections', JSON.stringify(section));
      });
    } else {
      fd.append('markdownSections', '');
    }

    if (nonEmptyLinks.length) {
      fd.append('order_link', nonEmptyLinks.toString());
    }

    if (values.googleMerchantsDestinations.length) {
      fd.append(
        'googleMerchantsDestinations',
        values.googleMerchantsDestinations.toString(),
      );
    }

    if (values.placement) {
      fd.append('placement', values.placement);
    }

    if (values.barcode) {
      fd.append('barcode', values.barcode);
    }

    if (!isEdit) {
      if (this.product.sku !== values.sku) {
        fd.append('sku', values.sku ?? '');
      }
    }

    if (isEdit) {
      fd.append('metaTitle', values.metaTitle ?? '');
      fd.append('metaDescription', values.metaDescription ?? '');
    }

    return fd;
  };

  getVariationById(id: number) {
    return this._variations.find((variation) => variation.id === id);
  }

  fetchProduct = async (id: number, silent = false) => {
    !silent && (this._loading = true);

    const product = await getProductById(id);

    if (product) {
      this.product = product.data;
      this.subcategory = new SubcategoryModel(
        undefined,
        product.data.subcategory,
      );
      this.variations = product.data.variations.map(
        (variation) => new VariationModel(variation),
      );
      !silent && (this.loading = false);

      return this.product;
    }

    notify('Can`t load product', 'error');
    setTimeout(() => {
      window.location.href = '/product/new';
    }, 500);
  };

  constructor(productId?: number, product?: IProduct) {
    if (product) {
      this._product = product;
      this._subcategory = new SubcategoryModel(undefined, product.subcategory);
      this._variations = product.variations.map(
        (variation) => new VariationModel(variation),
      );
    }
    if (productId && !product) {
      this.fetchProduct(productId);
    }

    makeAutoObservable(this, {
      _product: observable,
      _variations: observable,
      _loading: observable,
      _subcategory: observable,
      product: computed,
      loading: computed,
      key: computed,
      variations: computed,
      subcategory: computed,
      variationsObject: computed,
      fetchProduct: action,
    });
  }
}
