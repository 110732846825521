import { FC } from 'react';

import { Checkbox, InputNumber } from 'antd';
import { TPhoto } from 'common/types';

import styles from './package-modal-item.module.scss';

interface IPackageModalItemProps {
  title: string;
  photo: TPhoto;
  selected: boolean | undefined;
  quantity: number | undefined;
  min?: number;
  max?: number;
  disabled?: { checkbox?: boolean; input?: boolean };
  onSelect: () => void;
  onChange: (e: number | null) => void;
}

export const PackageModalItem: FC<IPackageModalItemProps> = ({
  title,
  photo,
  selected,
  quantity,
  min = 1,
  max,
  disabled,
  onSelect,
  onChange,
}) => (
  <div className={styles.container}>
    <Checkbox
      checked={selected}
      disabled={disabled?.checkbox}
      onClick={onSelect}
    />
    <img src={photo.url} alt={photo.key} className={styles.image} />
    <span className={styles.title}>{title}</span>
    <InputNumber
      size="small"
      type="number"
      min={min}
      max={max}
      value={quantity}
      disabled={disabled?.input}
      className={styles.input}
      onChange={onChange}
    />
    <span className={styles.max}>/ {max}</span>
  </div>
);
