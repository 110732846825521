import { defaultPurchaseEntity } from 'common/constants';
import { IPurchase } from 'common/types/purchase';
import { mockIdKeyGen } from 'common/utils';
import { makeAutoObservable } from 'mobx';

type TStatus = 'origin' | 'empty' | 'added' | 'updated' | 'deleted';

export class PurchaseModel {
  _purchase: IPurchase = defaultPurchaseEntity;
  _status: TStatus = 'origin';
  _quantityUpdated = 1;

  get purchase() {
    return this._purchase;
  }
  set purchase(purchase: IPurchase) {
    this._purchase = purchase;
  }

  get status() {
    return this._status;
  }

  set status(status: TStatus) {
    this._status = status;
  }

  get quantityUpdated() {
    return this._quantityUpdated;
  }

  set quantityUpdated(quantityUpdated: number) {
    this._quantityUpdated = quantityUpdated;
  }

  get isDecreasedQuantity() {
    return this.quantity > this.quantityUpdated;
  }

  get isIncreasedQuantity() {
    return this.quantity < this.quantityUpdated;
  }

  get key() {
    return !!this.purchase.id ? this.purchase.id : mockIdKeyGen();
  }

  get id() {
    return this.purchase.id;
  }

  get image() {
    return this.purchase.image;
  }

  get title() {
    return this.purchase.title;
  }

  get variation() {
    return this.purchase.variation;
  }

  get variationId() {
    return this.purchase.variationId;
  }

  get productKit() {
    return this.purchase.productKit;
  }

  get quantity() {
    return this.purchase.quantity;
  }

  get price() {
    return this.purchase.price;
  }

  get adjustedPrice() {
    return this.purchase.adjustedPrice;
  }

  get totalPrice() {
    return this.purchase.totalPrice;
  }

  get sku() {
    return this.purchase.sku;
  }

  get orderLink() {
    return this.purchase.orderLink;
  }

  get discount() {
    return this.purchase.discount;
  }

  get supplier() {
    return this.purchase.supplier;
  }

  get shipmentReasons() {
    return this.purchase.shipmentReasons;
  }

  set refund(val) {
    this.purchase.refund = val;
  }

  get refund() {
    return this.purchase.refund;
  }

  get refundSum() {
    return (
      (this.purchase.refund / this.purchase.quantity) *
      this.purchase.adjustedPrice
    );
  }

  constructor(purchase?: IPurchase) {
    if (purchase) {
      this._purchase = purchase;
      this._quantityUpdated = purchase.quantity;
    }

    makeAutoObservable(this);
  }
}
