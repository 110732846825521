import { useState, useEffect } from 'react';

import { Divider, Button } from 'antd';
import cn from 'clsx';
import { IMarkdownSection } from 'common/types';
import { mockIdKeyGen } from 'common/utils';
import { Collapse } from 'components/Collapse';
import { FormInput, FormMarkdown } from 'components/Form';
import { useFormContext } from 'react-hook-form';

import styles from './additional-description.module.scss';

export const AdditionalDescription = () => {
  const [descriptions, setDescriptions] = useState<
    (IMarkdownSection & { id: string })[]
  >([]);

  const { formState, setValue, watch, clearErrors } = useFormContext();

  const markdownSections = watch('markdownSections');

  useEffect(() => {
    const markdownDescriptions = formState.defaultValues
      ?.markdownSections as IMarkdownSection[];

    setDescriptions(
      Object.entries(markdownDescriptions).map(([key, description]) => ({
        ...description,
        id: key,
      })),
    );
  }, []);

  const removeDescription = (id: string) => {
    const updatedDescriptions = descriptions.filter(
      (description) => description.id !== id,
    );

    let updatedMarkdownSections = {};

    for (const key in markdownSections) {
      if (key !== id) {
        updatedMarkdownSections = {
          ...updatedMarkdownSections,
          [key]: markdownSections[key],
        };
      }
    }

    clearErrors('markdownSections');
    setValue('markdownSections', updatedMarkdownSections, {
      shouldValidate: true,
    });
    setDescriptions(updatedDescriptions);
  };

  const addNewDescription = () => {
    setDescriptions((oldValue) => [
      ...oldValue,
      { title: '', description: '', id: mockIdKeyGen() },
    ]);
  };

  return (
    <Collapse
      className={cn(styles.markdownDescriptions, {
        [styles.error]: !!formState.errors['markdownSections'],
      })}
      bordered={false}
      header="Additional description"
    >
      {descriptions.map((description) => {
        return (
          <div key={description.id}>
            <Divider />
            <FormInput
              className={styles.inputWidth}
              required
              name={`markdownSections[${description.id}].title`}
              label="Title"
            />
            <FormMarkdown
              required
              name={`markdownSections[${description.id}].description`}
              label="Description"
            />
            <Button
              className={styles.addDescriptionButton}
              type="default"
              htmlType="button"
              onClick={() => removeDescription(description.id)}
            >
              Remove description
            </Button>
          </div>
        );
      })}
      {descriptions.length < 3 && (
        <Button
          className={styles.addDescriptionButton}
          type="primary"
          htmlType="button"
          onClick={addNewDescription}
        >
          Add new description
        </Button>
      )}
    </Collapse>
  );
};
