import { FC } from 'react';

import { Typography } from 'antd';
import { useCurrentUserStore } from 'common/providers/store-provider';
import { observer } from 'mobx-react-lite';

import { OrderFilters } from './OrderFilters';
import { OrderTable } from './OrderTable';
import { OrdersViewModel } from './view-models/orders-view-model';

import styles from './order.module.scss';

interface IOrderPageContentProps {
  model: OrdersViewModel;
}

const OrderPageContent: FC<IOrderPageContentProps> = observer(({ model }) => (
  <div className={styles.container}>
    <Typography.Title level={1}>Orders</Typography.Title>
    <OrderFilters model={model} />
    <OrderTable model={model} />
  </div>
));

export const OrderPage = () => {
  const currentUserModel = useCurrentUserStore();
  const model = new OrdersViewModel(currentUserModel);

  return <OrderPageContent model={model} />;
};
