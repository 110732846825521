import { FC } from 'react';

import { Button, Tag } from 'antd';

import styles from './showable-variation.module.scss';

interface IShowableVariationProps {
  name: string;
  values: string[];
  isLoading: boolean;
  toggleEdit: () => void;
  handleDelete: () => void;
}

const ShowableVariation: FC<IShowableVariationProps> = ({
  name,
  values,
  isLoading,
  toggleEdit,
  handleDelete,
}) => (
  <div className={styles.showableVariation}>
    <div className={styles.content}>
      <div className={styles.name}>{name}</div>
      <div className={styles.values}>
        {values?.map((value) => (
          <Tag key={value} className={styles.tag} color="default">
            {value}
          </Tag>
        ))}
      </div>
    </div>
    <div className={styles.buttons}>
      <Button onClick={toggleEdit}>Edit</Button>
      {name !== 'color' && name !== 'material' && (
        <Button onClick={handleDelete} loading={isLoading} disabled={isLoading}>
          Delete
        </Button>
      )}
    </div>
  </div>
);

export default ShowableVariation;
