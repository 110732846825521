import { FC } from 'react';

import { SaveOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';

import styles from './fees-input.module.scss';

interface IFeesInputProps {
  value: string | number;
  disabled?: boolean;
  isLoading?: boolean;
  onChange: (value: string | number) => void;
  onSave: (value: number) => Promise<void>;
}

export const FeesInput: FC<IFeesInputProps> = ({
  value,
  disabled,
  isLoading,
  onChange,
  onSave,
}) => (
  <div className={styles.container}>
    <Input
      value={value}
      size="small"
      prefix="$"
      className={styles.input}
      onChange={(e) => onChange(e.target.value)}
    />
    <Button
      size="small"
      icon={<SaveOutlined />}
      disabled={disabled || !value || isNaN(+value)}
      loading={isLoading}
      onClick={() => onSave(+value)}
    />
  </div>
);
