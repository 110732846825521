import { FC, useEffect, useMemo } from 'react';

import {
  getBillingAddressFields,
  getPersonalInformationFields,
  getShippingAddressFields,
} from 'common/utils';
import {
  personalInformationValidationSchema,
  shippingAddressValidationSchema,
  billingAddressValidationSchema,
} from 'common/validation';
import { EditableSection } from 'components/EditableSection';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';

import { UserViewModel } from './user-view-model';

import styles from './user.module.scss';

interface IUserPageContentProps {
  model: UserViewModel;
}

const UserPageContent: FC<IUserPageContentProps> = observer(({ model }) => (
  <div className={styles.sections}>
    <EditableSection
      title="Personal info"
      fields={getPersonalInformationFields(model.user)}
      validationSchema={personalInformationValidationSchema}
      classNames={{ field: styles.field, staticField: styles.field }}
      onEdit={(values: Record<string, string>) => model.updateUserData(values)}
      isLoading={model.isLoading}
      disabled={model.isLoading}
      forcedRender
    />
    <EditableSection
      title="Shipping address"
      fields={getShippingAddressFields(model.user)}
      validationSchema={shippingAddressValidationSchema}
      classNames={{ field: styles.field, staticField: styles.field }}
      onEdit={(values: Record<string, string>) =>
        model.updateUserData({ shippingAddress: values })
      }
      isLoading={model.isLoading}
      disabled={model.isLoading}
      forcedRender
    />
    <EditableSection
      title="Billing address"
      fields={getBillingAddressFields(model.user)}
      validationSchema={billingAddressValidationSchema}
      classNames={{ field: styles.field, staticField: styles.field }}
      onEdit={(values: Record<string, string>) =>
        model.updateUserData({ billingAddress: values })
      }
      isLoading={model.isLoading}
      disabled={model.isLoading}
      forcedRender
    />
  </div>
));

export const UserPage: FC = () => {
  const { id } = useParams();
  const userViewModel = useMemo(() => new UserViewModel(id), [id]);

  useEffect(() => {
    userViewModel.getUser();
  }, [userViewModel]);

  return (
    <div className={styles.container}>
      <UserPageContent model={userViewModel} />
    </div>
  );
};
