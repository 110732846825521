import { FC, SyntheticEvent, useState } from 'react';

import { Button } from 'antd';
import { reasonDefaultOptions } from 'common/constants';
import { observer } from 'mobx-react-lite';
import { useFormContext } from 'react-hook-form';
import { PurchaseModel } from 'store/purchase-model';

import { OrderExpandedViewModel } from '../../../order-expanded-view-model';

import { SaveModal } from './SaveModal';
import { TableContent } from './TableContent';

import styles from './purchase-table.module.scss';

interface IFormValues {
  purchases: { [key: number]: IFormPurchase };
}

interface IFormPurchase {
  quantity: number;
  sku: string;
}

interface IPurchaseTableProps {
  isOpenModal: boolean;
  isEdit: boolean;
  model: OrderExpandedViewModel;
  initialPurchases: IFormValues;
  purchases: PurchaseModel[];
  toggleEdit: (value?: boolean) => void;
  closeModal: (value?: boolean) => void;
  onDiscard: () => void;
  onPurchasesUpdate: (purchase: PurchaseModel[]) => void;
  onPurchaseDelete: (purchase: PurchaseModel) => void;
}

const formatPurchaseItems = (
  purchases: PurchaseModel[],
  status: 'added' | 'deleted',
  identifier: 'id' | 'sku',
) =>
  purchases.reduce((acc, el) => {
    const diff = Math.abs(el.quantityUpdated - el.quantity);
    const key = el[identifier];
    const value =
      (status === 'added' && (diff || el.quantityUpdated)) ||
      (status === 'deleted' && (diff || el.quantity));

    return {
      ...acc,
      [key]: value,
    };
  }, {});

export const PurchaseTable: FC<IPurchaseTableProps> = observer(
  ({
    isEdit,
    isOpenModal,
    model,
    initialPurchases,
    purchases,
    toggleEdit,
    closeModal,
    onDiscard,
    onPurchasesUpdate,
    onPurchaseDelete,
  }) => {
    const [isInvoice, setInvoice] = useState(false);
    const [isRefund, setRefund] = useState(false);
    const [responsible, setResponsible] = useState('');
    const [reason, setReason] = useState('');
    const { reset } = useFormContext();

    const isRefundValid = !!(reason && responsible);

    const addedPurchases = purchases.filter(
      (purchase) => purchase.status === 'added',
    );

    const updatedPurchases = purchases.filter(
      (purchase) => purchase.status === 'updated',
    );

    const deletedPurchases = purchases.filter(
      (purchase) => purchase.status === 'deleted',
    );

    const deletedKitsIds = model.getPurchasesKitsIds(deletedPurchases);

    const hasAddedPurchases = !!addedPurchases.length;
    const hasDeletedPurchases = !!deletedPurchases.length;
    const hasDeletedKits = !!deletedKitsIds.length;

    const hasInvoice =
      hasAddedPurchases ||
      updatedPurchases.some(
        (purchase) => purchase.quantity < purchase.quantityUpdated,
      );

    const hasRefund =
      hasDeletedPurchases ||
      updatedPurchases.some(
        (purchase) => purchase.quantity > purchase.quantityUpdated,
      );

    const handleEdit = (e: SyntheticEvent) => {
      e.preventDefault();
      toggleEdit();
    };

    const handleDiscard = () => {
      onDiscard();
      reset(initialPurchases);

      toggleEdit();
      closeModal();
    };

    const handleSavePurchases = async () => {
      const remainingItems = purchases.filter(
        (purchase) => purchase.status !== 'deleted',
      );

      try {
        if (isRefund && hasRefund && isRefundValid) {
          const deletedItems = purchases.filter(
            (purchase) =>
              purchase.status === 'deleted' || purchase.isDecreasedQuantity,
          );

          const nonKitDeletedItems = purchases.filter(
            (purchase) =>
              (purchase.status === 'deleted' || purchase.isDecreasedQuantity) &&
              !purchase.productKit,
          );

          const formattedDeletedItems = formatPurchaseItems(
            deletedItems,
            'deleted',
            'id',
          );

          const formattedNonKitDeletedItems = formatPurchaseItems(
            nonKitDeletedItems,
            'deleted',
            'id',
          );

          const params = {
            items: hasDeletedKits
              ? formattedNonKitDeletedItems
              : formattedDeletedItems,
            productKits: deletedKitsIds,
            responsible: responsible,
            reason: reason,
          };

          await model.createRefund(model.order.id, params);
        }

        if (isInvoice && hasInvoice) {
          const addedItems = purchases.filter(
            (purchase) =>
              purchase.status === 'added' || purchase.isIncreasedQuantity,
          );

          const formattedaddedItems = formatPurchaseItems(
            addedItems,
            'added',
            'sku',
          );

          const params = {
            items: formattedaddedItems,
          };

          await model.createInvoice(model.order.id, params);
        }

        await model.handleUpdatePurchases(remainingItems);
      } catch (e) {
        throw new Error();
      } finally {
        toggleEdit();
      }
    };

    return (
      <div className={styles.contentWrapper}>
        <div className={styles.btnWrapper}>
          {isEdit ? (
            <>
              <Button size="small" htmlType="submit" className={styles.btn}>
                Save
              </Button>
              <Button
                size="small"
                className={styles.btn}
                onClick={handleDiscard}
              >
                Discard
              </Button>
            </>
          ) : (
            <Button
              size="small"
              htmlType="reset"
              className={styles.btn}
              onClick={handleEdit}
              disabled={model.order.disabled}
            >
              Edit
            </Button>
          )}
        </div>
        <TableContent
          model={model}
          purchases={purchases}
          isEdit={isEdit}
          onUpdate={onPurchasesUpdate}
          onDelete={onPurchaseDelete}
        />
        <SaveModal
          isOpen={isOpenModal}
          addedPurchases={addedPurchases}
          updatedPurchases={updatedPurchases}
          deletedPurchases={deletedPurchases}
          isInvoice={isInvoice}
          isRefund={isRefund}
          isRefundValid={isRefundValid}
          responsibleOptions={model.responsibleOptions}
          reasonOptions={reasonDefaultOptions}
          hasInvoice={hasInvoice}
          hasRefund={hasRefund}
          closeModal={closeModal}
          onSave={handleSavePurchases}
          onInvoiceChange={setInvoice}
          onRefundChange={setRefund}
          onResponsibleChange={setResponsible}
          onReasonChange={setReason}
        />
      </div>
    );
  },
);
