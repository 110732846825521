import { FC, useEffect, useMemo } from 'react';

import { Progress, Collapse, Typography } from 'antd';
import { parseDate } from 'common/utils';
import { bulkPriceUpdateSchema } from 'common/validation';
import { ConfirmButton } from 'components/ConfirmButton';
import { Form, FormAutocomplete } from 'components/Form';
import { FormSelect, FormNumberInput } from 'components/Form';
import { observer } from 'mobx-react-lite';
import { useFormContext } from 'react-hook-form';

import { BulkPriceUpdateModel, IBuilUpdateValues } from './bulk-update-model';
import { ErrorDetails } from './ErrorDetails';

import styles from './styles.module.scss';

const BulkPriceUpdateFormContent: FC<{
  model: BulkPriceUpdateModel;
}> = observer(({ model }) => {
  const form = useFormContext();
  const submit = async () => {
    const isValid = await form.trigger();

    if (isValid) {
      const values = form.getValues() as IBuilUpdateValues;
      model.handleUpdate(values);
    }
  };

  return (
    <>
      <FormAutocomplete
        name="brand"
        label="Brand"
        options={model.brands}
        disabled={model.changeDisabled}
        className={styles.autocomplete}
      />
      <FormSelect
        name="action"
        label="Action"
        options={['increase', 'decrease'].map((status) => ({
          label: status,
          value: status,
        }))}
        disabled={model.changeDisabled}
      />
      <FormNumberInput
        precision={2}
        name="amount"
        label="Amount (%)"
        min={0.01}
        disabled={model.changeDisabled}
        addonAfter="%"
      />
      <ConfirmButton
        confirm={{ title: 'This action will change ALL prices. Are you sure?' }}
        disabled={model.changeDisabled}
        isLoading={model.loading}
        onConfirm={submit}
      >
        Save
      </ConfirmButton>
    </>
  );
});

export const BulkPriceUpdateContent: FC<{
  model: BulkPriceUpdateModel;
}> = observer(({ model }) => (
  <div className={styles.container}>
    <h2>Bulk price change</h2>
    <Form
      initialValues={{
        action: 'increase',
      }}
      validation={bulkPriceUpdateSchema}
      onSubmit={model.handleUpdate}
      className={styles.form}
    >
      <BulkPriceUpdateFormContent model={model} />
    </Form>
    {model.percentage !== null && (
      <Progress
        className={styles.progress}
        size={['100%', 20]}
        percent={model.percentage}
        status="active"
      />
    )}
    {model.lastStartDate && (
      <Typography.Paragraph>
        <strong>Previous start date:</strong> {parseDate(model.lastStartDate)}
      </Typography.Paragraph>
    )}
    {model.lastCompleteDate && (
      <Typography.Paragraph>
        <strong>Previous complete date:</strong>{' '}
        {parseDate(model.lastCompleteDate)}
      </Typography.Paragraph>
    )}
    {!!model.errors.length && (
      <Collapse
        items={model.errors.map((error) => ({
          key: error.id,
          label: `Price update failed for Product with id ${error.productId}`,
          children: <ErrorDetails {...error} />,
        }))}
      />
    )}
    {!model.errors.length && (
      <Typography.Paragraph className={styles.no_errors}>
        <pre>No errors</pre>
      </Typography.Paragraph>
    )}
  </div>
));

export const BulkPriceUpdatePage = () => {
  const model = useMemo(() => new BulkPriceUpdateModel(), []);

  useEffect(() => {
    return () => model.clear();
  }, [model]);

  return <BulkPriceUpdateContent model={model} />;
};
