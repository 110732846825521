import { FC, useEffect, Fragment } from 'react';

import { Layout } from 'antd';
import {
  StoreProvider,
  useCurrentUserStore,
} from 'common/providers/store-provider';
import { EUserRoles } from 'common/types';
import { observer } from 'mobx-react-lite';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { privateRoutes, publicRoutes, redirectRoutes } from './common/routes';
import Sidebar from './components/Sidebar';
import { buildAxiosInterceptors } from './http';

import 'react-toastify/dist/ReactToastify.css';
import './global.scss';
import styles from './app.module.scss';

const { Content } = Layout;

const App: FC = observer(() => {
  const currentUserStore = useCurrentUserStore();
  const { isAuth, logout, role } = currentUserStore;

  useEffect(() => {
    buildAxiosInterceptors({
      logout: logout,
    });
  }, []);

  return (
    <Layout className={styles.layout}>
      {isAuth && <Sidebar />}
      <Content className={styles.content}>
        <Routes>
          {isAuth &&
            privateRoutes.map(({ path, Component, permissions }) => (
              <Fragment key={path}>
                {permissions[role as EUserRoles] && (
                  <Route key={path} path={path} element={<Component />} />
                )}
              </Fragment>
            ))}
          {!isAuth &&
            publicRoutes.map(({ path, Component }) => (
              <Route key={path} path={path} element={<Component />} />
            ))}
          <Route
            path={'*'}
            element={
              <Navigate
                to={isAuth ? redirectRoutes[role as EUserRoles] : '/auth'}
              />
            }
          />
        </Routes>
      </Content>
    </Layout>
  );
});

export const RootApp = () => (
  <StoreProvider>
    <BrowserRouter>
      <App />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        closeOnClick
        pauseOnHover
      />
    </BrowserRouter>
  </StoreProvider>
);
