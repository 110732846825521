import { FC, useState } from 'react';

import { EditOutlined, MoreOutlined, DeleteOutlined } from '@ant-design/icons';
import { Dropdown, MenuProps, Modal } from 'antd';
import { ColumnType } from 'antd/es/table';
import { defaultDeleteMessage } from 'common/constants';
import { useModal } from 'common/utils';

import styles from './actions.module.scss';

export type TActionsColumn<T> = ColumnType<T> &
  Omit<IActionsMenuProps, 'id' | 'record'>;

export interface IActionsMenuProps {
  onEdit?: (id: number, record?: unknown) => void;
  onDelete?: (id: number) => void;
  id: number;
  record: unknown;
  disabled?: boolean;
  items?: MenuProps['items'];
  deleteMessage?: string;
}

interface IDeleteItem {
  message?: string;
  onConfirm: () => void;
}

const DeleteItem: FC<IDeleteItem> = ({ onConfirm, message }) => {
  const [isOpen, openModal, closeModal] = useModal(false);

  const handleConfirm = () => {
    onConfirm();
    closeModal();
  };

  return (
    <>
      <div onClick={openModal}>
        <DeleteOutlined /> Delete
      </div>
      <Modal open={isOpen} onCancel={closeModal} onOk={handleConfirm}>
        <p>{message}</p>
      </Modal>
    </>
  );
};

const ActionsMenu: FC<IActionsMenuProps> = ({
  id,
  record,
  onDelete,
  onEdit,
  items,
  disabled,
  deleteMessage,
}) => {
  const [open, setOpen] = useState(false);

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    if (e.key === 'edit') {
      setOpen(false);

      onEdit?.(id, record);
    }
  };

  const handleOpenChange = (
    flag: boolean,
    info: {
      source: 'trigger' | 'menu';
    },
  ) => {
    info.source === 'trigger' && setOpen(flag);
  };

  const handleDelete = () => {
    onDelete?.(id);
  };

  const menuItems: MenuProps['items'] = [
    {
      label: 'Edit',
      key: 'edit',
      icon: <EditOutlined />,
    },
    {
      label: <DeleteItem message={deleteMessage} onConfirm={handleDelete} />,
      key: 'delete',
    },
  ];

  return (
    <Dropdown
      trigger={['click']}
      placement="bottomLeft"
      disabled={disabled}
      menu={{
        items: items ? items : menuItems,
        onClick: handleMenuClick,
      }}
      onOpenChange={handleOpenChange}
      open={open}
    >
      <MoreOutlined className={styles.moreIcon} />
    </Dropdown>
  );
};

export const actions = <T,>({
  dataIndex = 'id',
  key = 'actions',
  width = '5%',
  onDelete,
  disabled = false,
  onEdit,
  items,
  deleteMessage = defaultDeleteMessage,
}: TActionsColumn<T>): ColumnType<T> => ({
  dataIndex: dataIndex,
  key: key,
  width: width,
  render: (id: number, record: T) => (
    <ActionsMenu
      id={id}
      record={record}
      disabled={disabled}
      onEdit={onEdit}
      onDelete={onDelete}
      items={items}
      deleteMessage={deleteMessage}
    />
  ),
});
