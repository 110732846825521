export enum ProductAutocompleteTypeEnum {
  brands = 'brands',
  subcategories = 'subcategories',
  types = 'types',
}

export enum ActiveStatusEnum {
  Active = 'Active',
  Inactive = 'Inactive',
}

export enum GoogleMerchantsDestinations {
  SHOPPING_ADS = 'Shopping_ads',
  BUY_ON_GOOGLE_LISTINGS = 'Buy_on_Google_listings',
  DISPLAY_ADS = 'Display_ads',
  LOCAL_INVENTORY_ADS = 'Local_inventory_ads',
  FREE_LISTINGS = 'Free_listings',
  FREE_LOCAL_LISTINGS = 'Free_local_listings',
  YOUTUBE_SHOPPING = 'YouTube_Shopping',
}

export enum EStatuses {
  ACTIVE = 'ACTIVE',
  ARCHIVE = 'ARCHIVE',
  DRAFT = 'DRAFT',
}

export enum EResponsibles {
  NONE = '',
  CUSTOMER = 'Customer',
  SUPPLIER = 'Supplier',
  DELIVERY_COMPANY = 'Delivery company',
}
