import { FC } from 'react';

import { Button } from 'antd';
import { useModal } from 'common/utils';
import { observer } from 'mobx-react-lite';

import { OrderExpandedViewModel } from '../../../order-expanded-view-model';

import { RefundModal } from './RefundModal';

interface IRefundButton {
  model: OrderExpandedViewModel;
}

export const RefundButton: FC<IRefundButton> = observer(({ model }) => {
  const [isOpen, openModal, closeModal] = useModal(false);

  return (
    <>
      <Button
        onClick={openModal}
        loading={model.loading}
        disabled={model.order.disabled}
      >
        Refund
      </Button>
      <RefundModal model={model} isOpen={isOpen} closeModal={closeModal} />
    </>
  );
});
