import { FC, useEffect } from 'react';

import { variationStatusesOptions } from 'common/constants';
import { DndImagesList } from 'components/DndImagesList';
import { FormUploader, FormSelect } from 'components/Form';
import { observer } from 'mobx-react-lite';
import { useFormContext } from 'react-hook-form';

import { ProductControllerViewModel } from '../product-controller-view-model';

import styles from './media-form-content.module.scss';

export interface IMediaFormContentProps {
  model: ProductControllerViewModel;
}

export const MediaFormContent: FC<IMediaFormContentProps> = observer(
  ({ model }) => {
    const { watch, setValue } = useFormContext();

    const photos = watch('photos');
    const brandId = watch('brandId');
    const subcategoryId = watch('subcategoryId');

    useEffect(() => {
      model.selectedBrand = brandId;
    }, [brandId]);

    useEffect(() => {
      const subcategory = model.subcategoryOptions.find(
        (option) => option.value === subcategoryId,
      );

      if (!subcategory && subcategoryId) {
        setValue('subcategoryId', null);
      }
    }, [model.subcategoryOptions, subcategoryId]);

    return (
      <div className={styles.mediaBlock}>
        <FormUploader multiple label="Media" name="photos" />
        {!!photos?.length && (
          <DndImagesList
            photos={photos}
            name="photos"
            className={styles.imagesList}
          />
        )}
        <div className={styles.selects}>
          <FormSelect
            showSearch
            name="brandId"
            label="Brand"
            required
            options={model.brandOptions}
          />
          <FormSelect
            showSearch
            name="subcategoryId"
            label="Category"
            required
            disabled={!brandId}
            options={model.subcategoryOptions}
          />
          <FormSelect
            showSearch
            name="status"
            label="Status"
            options={variationStatusesOptions}
          />
        </div>
      </div>
    );
  },
);
