import { FC } from 'react';

import { Select, SelectProps } from 'antd';

export type TManagerCellProps = SelectProps;

export const ManagerCell: FC<TManagerCellProps> = ({
  defaultValue,
  options,
  value,
  placeholder,
  size,
  className,
  disabled,
  allowClear,
  loading,
  onChange,
}) => (
  <Select
    defaultValue={defaultValue}
    options={options}
    placeholder={placeholder}
    size={size}
    value={value}
    allowClear={allowClear}
    className={className}
    disabled={disabled}
    loading={loading}
    onChange={(val, option) => onChange?.(val, option)}
  />
);
