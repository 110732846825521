import { FC } from 'react';

import { Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import { ExpandedOrderViewModel } from 'pages/NewOrder/view-models/expanded-order-view-model';

import { BuyerDetails } from './BuyerDetails';
import { Controls } from './Controls';
import { History } from './History';
import { Performance } from './Performance';
import { Purchases } from './Purchases';
import { Returns } from './Returns';
import { Shipments } from './Shipments';
import { Statuses } from './Statuses';
import { Summary } from './Summary';

import styles from './expanded-order.module.scss';

export interface IExpandedOrder {
  model: ExpandedOrderViewModel | null;
}

export const ExpandedOrder: FC<IExpandedOrder> = observer(({ model }) => (
  <div className={styles.container}>
    {!model || model?.isLoading ? (
      <Spin size="large" className={styles.spin} />
    ) : (
      <>
        <div className={styles.column}>
          <Purchases model={model.purchasesViewModel} />
          <Shipments model={model.shipmentsViewModel} />
          <Returns model={model.returnsViewModel} />
          <History model={model.historyViewModel} />
        </div>
        <div className={styles.column}>
          <Statuses model={model.statusesViewModel} />
          <BuyerDetails model={model.buyerDetailsViewModel} />
          <div className={styles.statistics}>
            <Summary model={model.summaryViewModel} />
            {(model.root?.root?.user.isAdmin ||
              model.root?.root?.user.isManager) && (
              <Performance model={model.performanceViewModel} />
            )}
          </div>
          <Controls model={model.controlsViewModel} />
        </div>
      </>
    )}
  </div>
));
