import { FC } from 'react';

import { ColumnsType } from 'antd/es/table';
import { useCurrentUserStore } from 'common/providers/store-provider';
import { TOption } from 'common/types';
import {
  EDeliveryStatuses,
  EPaymentCancelledStatuses,
  EPaymentStatuses,
  EPurchaseStatuses,
  ESendingStatuses,
} from 'common/types/order/enums';
import { upperUndescoreToRegularCase, removeStatusPrefix } from 'common/utils';
import {
  Table,
  customActions,
  text,
  colored,
  currency,
} from 'components/Table';
import { Observer, observer } from 'mobx-react-lite';

import { ExpandedOrder } from '../ExpandedOrder';
import { OrderViewModel } from '../view-models/order-view-model';
import { OrdersViewModel } from '../view-models/orders-view-model';

import { CommentCell } from './CommentCell';
import { CommentModal } from './CommentModal';
import { DeliveryCell } from './DeliveryCell';
import { ManagerCell } from './ManagerCell';
import { PaymentCell } from './PaymentCell';

import styles from './order-table.module.scss';

export interface IOrderTableProps {
  model: OrdersViewModel;
}

const FULFILlED_MAPPER = {
  SENDING_SENT: 'fulfilled',
  SENDING_PARTIALLY_SENT: 'partially fulfilled',
  SENDING_NOT_SENT: 'not fulfilled',
};

const columns = (
  managerOptions: TOption[],
  isSupplier: boolean,
): ColumnsType<OrderViewModel> => [
  text({
    dataIndex: ['shortOrder', 'createdAt'],
    title: 'Date',
    width: '120px',
  }),
  customActions({
    dataIndex: ['shortOrder', 'manager'],
    title: 'Manager',
    width: '200px',
    render: (_value, record) => (
      <Observer>
        {() => (
          <ManagerCell
            value={record.shortOrder.manager?.email}
            options={managerOptions}
            placeholder="Select manager"
            size="small"
            allowClear
            className={styles.select}
            disabled={
              record.shortOrder.isCancelled || record.isLoading || isSupplier
            }
            loading={record.isLoading}
            onChange={(value: string) =>
              record.updateManager(record.shortOrder.id, value)
            }
          />
        )}
      </Observer>
    ),
  }),
  text({
    dataIndex: ['shortOrder', 'publicId'],
    title: 'Order #',
    width: '120px',
  }),
  customActions({
    dataIndex: ['shortOrder', 'comment'],
    title: 'Note',
    width: '120px',
    render: (_value, record) => (
      <Observer>
        {() => (
          <>
            <CommentCell
              disabled={isSupplier}
              value={record.shortOrder.comment.value}
              onClick={() => record.onCommentModalOpenChange(true)}
            />
            <CommentModal
              open={record.isCommentModalOpen}
              title="Edit note"
              comment={record.shortOrder.comment.value}
              isLoading={record.isLoading}
              onCancel={() => record.onCommentModalOpenChange(false)}
              onOk={() => record.onCommentModalOpenChange(false)}
              onSave={(value: string) =>
                record.updateComment(record.shortOrder.id, value)
              }
            />
          </>
        )}
      </Observer>
    ),
  }),
  text({
    dataIndex: ['shortOrder', 'buyer'],
    title: 'Buyer',
    width: '120px',
    observer: true,
  }),
  customActions({
    dataIndex: ['shortOrder', 'payment'],
    title: '$',
    width: '60px',
    render: (_value, record) => (
      <Observer>
        {() => (
          <PaymentCell
            payment={
              record.shortOrder.payment as
                | EPaymentStatuses
                | EPaymentCancelledStatuses
            }
          />
        )}
      </Observer>
    ),
  }),
  currency({
    dataIndex: ['shortOrder', 'amountPaid'],
    title: 'Total, $',
    width: '100px',
    observer: true,
  }),
  currency({
    dataIndex: ['shortOrder', 'cost'],
    title: 'Cost, $',
    width: '100px',
    observer: true,
  }),
  colored({
    dataIndex: ['shortOrder', 'purchasing'],
    title: 'Purchasing',
    width: '140px',
    formatter: (value: EPurchaseStatuses) =>
      upperUndescoreToRegularCase(removeStatusPrefix(value, 'PURCHASE_')),
    colors: {
      [EPurchaseStatuses.PURCHASE_NOT_PURCHASED]: ['white', 'black'],
      [EPurchaseStatuses.PURCHASE_PARTIALLY_PURCHASED]: ['#FC9FD1', 'black'],
      [EPurchaseStatuses.PURCHASE_PURCHASED]: ['#C27CA1 ', 'white'],
    },
    observer: true,
  }),
  colored({
    dataIndex: ['shortOrder', 'sending'],
    title: 'Fulfilling',
    width: '140px',
    formatter: (value: ESendingStatuses | null) => {
      return value ? FULFILlED_MAPPER[value] : '';
    },
    colors: {
      [ESendingStatuses.SENDING_NOT_SENT]: ['white', 'black'],
      [ESendingStatuses.SENDING_PARTIALLY_SENT]: ['#BEFC9F', 'black'],
      [ESendingStatuses.SENDING_SENT]: ['#94C37D', 'black'],
    },
    observer: true,
  }),
  customActions({
    dataIndex: ['shortOrder', 'delivery'],
    title: 'Delivery',
    width: '100px',
    render: (_value, record) => (
      <Observer>
        {() => (
          <DeliveryCell
            delivery={
              record.shortOrder.delivery ??
              EDeliveryStatuses.DELIVERY_NOT_DELIVERED
            }
            tooltip={upperUndescoreToRegularCase(
              removeStatusPrefix(
                record.shortOrder.delivery ??
                  EDeliveryStatuses.DELIVERY_NOT_DELIVERED,
                'DELIVERY_',
              ),
            )}
          />
        )}
      </Observer>
    ),
  }),
];

export const OrderTable: FC<IOrderTableProps> = observer(({ model }) => {
  const { isSupplier } = useCurrentUserStore();

  return (
    <Table
      columns={columns(model.managerOptions, isSupplier)}
      rowKey={(record) => record.shortOrder.id}
      data={model.orderViewModels}
      pagination={{
        total: model.filters.count,
        pageSize: model.filters.pageSize,
        current: model.filters.page,
        showSizeChanger: true,
        disabled: model.isLoading,
      }}
      bordered
      loading={model.isLoading}
      expandable={{
        expandedRowRender: (record) => (
          <ExpandedOrder model={record.expandedOrderViewModel} />
        ),
        onExpand: (isExpanded, record) => record.onOrderExpand(isExpanded),
      }}
      onChange={(pagination) => {
        model.onTableChange(pagination);
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }}
    />
  );
});
