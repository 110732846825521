import { FC } from 'react';

import { AutoComplete, AutoCompleteProps } from 'antd';
import { BaseOptionType } from 'antd/es/select';
import cn from 'clsx';
import { Controller, useFormContext } from 'react-hook-form';

import styles from '../form.module.scss';

export interface IFormAutocompleteProps
  extends Omit<AutoCompleteProps, 'options'> {
  name: string;
  label: string;
  labelPosition?: 'top' | 'left';
  required?: boolean;
  className?: string;
  options: BaseOptionType[];
}

export const FormAutocomplete: FC<IFormAutocompleteProps> = ({
  name,
  label,
  labelPosition = 'top',
  required,
  className,
  options,
  ...props
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value, name }, fieldState: { error } }) => (
        <div
          className={cn(
            styles.container,
            styles.inputWrapper,
            styles[`label-${labelPosition}`],
            className,
          )}
        >
          <label htmlFor={name} className={styles.label}>
            {label}
            {required && <span className={styles.required}>*</span>}
          </label>
          <AutoComplete
            id={name}
            className={styles.inputWidth}
            onChange={onChange}
            value={value}
            options={options}
            onSelect={onChange}
            status={!!error ? 'error' : ''}
            filterOption={(inputValue, option) =>
              !!(
                option &&
                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
                  -1
              )
            }
            {...props}
          />
          {!!error && <div className={styles.error}>{error?.message}</div>}
        </div>
      )}
    />
  );
};
