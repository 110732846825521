import { getAllPromocodes } from 'http/promocodeApi';

import { defaultPromocodeEntity } from 'common/constants';
import { IPromocode } from 'common/types/promocode';
import { PromoTypeEnum } from 'common/types/promocode/enums';
import { notify, mockIdKeyGen, parseDate, cents2dollars } from 'common/utils';
import { computed, makeAutoObservable, observable } from 'mobx';

export class PromocodeModel {
  _promocode: IPromocode = defaultPromocodeEntity;
  _loading = false;

  get key() {
    return !!this._promocode.id ? this._promocode.id : mockIdKeyGen();
  }

  get id() {
    return this._promocode.id;
  }

  get discount() {
    return this._promocode.discount;
  }

  get purchaseSum() {
    return cents2dollars(this._promocode.purchaseSum);
  }

  get promocode() {
    return this._promocode.promocode;
  }

  get promoType() {
    return this._promocode.promoType;
  }

  get usesLeft() {
    return this._promocode.usesLeft < 0
      ? 'Unlimited'
      : this._promocode.usesLeft;
  }

  get isPrimary() {
    return this._promocode.isPrimary;
  }

  get isActive() {
    return this._promocode.isActive;
  }

  get isLimited() {
    return this._promocode.isLimited;
  }

  get createdAt() {
    return parseDate(this._promocode.createdAt ?? '');
  }

  get updatedAt() {
    return parseDate(this._promocode.updatedAt ?? '');
  }

  get loading() {
    return this._loading;
  }

  get infoDiscountValue() {
    return this.promoType === PromoTypeEnum.PERCENTAGE
      ? `${this.discount}%`
      : `${cents2dollars(this.discount)}$`;
  }

  fetchPromocode = async (id: number) => {
    this._loading = true;

    const { promos } = await getAllPromocodes();

    const promocode = promos.find((promocode) => promocode.id === id);

    if (promocode) {
      this._promocode = promocode;
      this._loading = false;
      return;
    }

    notify('Can`t load promocode', 'error');

    setTimeout(() => {
      window.location.href = '/promocode/new';
    }, 500);
  };

  constructor(promocodeId?: number, promocode?: IPromocode) {
    if (promocode) {
      this._promocode = promocode;
    }
    if (promocodeId && !promocode) {
      this.fetchPromocode(promocodeId);
    }

    makeAutoObservable(this, {
      _promocode: observable,
      _loading: observable,
      promocode: computed,
      loading: computed,
      key: computed,
    });
  }
}
