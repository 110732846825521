import { FC } from 'react';

import { Button, Typography } from 'antd';

import styles from './comment-cell.module.scss';

export interface ICommentCellProps {
  value: string;
  onClick: () => void;
  disabled?: boolean;
}

export const CommentCell: FC<ICommentCellProps> = ({
  value,
  onClick,
  disabled = false,
}) => (
  <>
    {value ? (
      <Typography.Text
        ellipsis={{ tooltip: value }}
        className={styles.text}
        onClick={onClick}
      >
        {value}
      </Typography.Text>
    ) : (
      <Button disabled={disabled} onClick={onClick}>
        Add
      </Button>
    )}
  </>
);
