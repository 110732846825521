import {
  editReturnComment,
  editReturnShipment,
  finishReturnShipment,
} from 'http/returnShipment';

import {
  IReturnShipment,
  IReturnShipmentItemWithControlAttrs,
  IServerError,
} from 'common/types';
import {
  cents2dollars,
  combineString,
  dollars2cents,
  getPurchasesKitsIds,
  getReturnItemsKitsIds,
  notify,
  parseToLongDate,
  reduceOrderItems,
  reduceReturnShipmentItems,
  removeStatusPrefix,
  upperUndescoreToRegularCase,
} from 'common/utils';
import { makeAutoObservable } from 'mobx';

import { ReturnItemModel } from '../models/return-item-model';

import { ReturnsViewModel } from './returns-view-model';

export class ReturnViewModel {
  _root: ReturnsViewModel | null = null;
  _isLoading = false;
  _isExpanded = false;
  _isEditing = false;
  _isCommentEditing = false;
  _isEditModalOpen = false;
  _returnShipment: IReturnShipment;

  get root() {
    return this._root;
  }

  get orderViewModel() {
    return this.root?.root?.root;
  }

  get isLoading() {
    return this._isLoading;
  }

  set isLoading(isLoading: boolean) {
    this._isLoading = isLoading;
  }

  get isExpanded() {
    return this._isExpanded;
  }

  set isExpanded(isExpanded: boolean) {
    this._isExpanded = isExpanded;
  }

  get isEditing() {
    return this._isEditing;
  }

  set isEditing(isEditing: boolean) {
    this._isEditing = isEditing;
  }

  get isCommentEditing() {
    return this._isCommentEditing;
  }

  set isCommentEditing(isEditing: boolean) {
    this._isCommentEditing = isEditing;
  }

  get isEditModalOpen() {
    return this._isEditModalOpen;
  }

  set isEditModalOpen(isOpen: boolean) {
    this._isEditModalOpen = isOpen;
  }

  get returnShipment() {
    return this._returnShipment;
  }

  set returnShipment(returnShipment: IReturnShipment) {
    this._returnShipment = returnShipment;
  }

  get id() {
    return this.returnShipment.id;
  }

  get createdAt() {
    return this.returnShipment.createdAt;
  }

  get createdAtView() {
    return parseToLongDate(this.returnShipment.createdAt);
  }

  get status() {
    return this.returnShipment.status;
  }

  get resolved() {
    return this.returnShipment.resolved;
  }

  get items() {
    return this.returnShipment.items;
  }

  get itemsModels() {
    return this.items.map((item) => new ReturnItemModel(item));
  }

  get itemsModelsView() {
    return this.itemsModels.slice(0, 5);
  }

  get costOfGoods() {
    return this.returnShipment.costOfGoods;
  }

  get costOfGoodsView() {
    return cents2dollars(this.costOfGoods);
  }

  get costOfReturn() {
    return this.returnShipment.costOfReturn;
  }

  get costOfReturnView() {
    return cents2dollars(Math.round(this.costOfReturn));
  }

  get costOfEasyReturnView() {
    return cents2dollars(Math.round(this.costOfReturn * 0.7));
  }

  get supplier() {
    return this.returnShipment.supplier ?? { id: 0, name: '' };
  }

  get supplierView() {
    return this.supplier.user
      ? combineString([
          this.supplier.user.firstName,
          this.supplier.user.lastName,
        ])
      : this.supplier.name;
  }

  get responsible() {
    return this.returnShipment.responsible;
  }

  get reason() {
    return this.returnShipment.reason;
  }

  get reasonView() {
    return upperUndescoreToRegularCase(
      removeStatusPrefix(this.returnShipment.reason, 'SHIPMENT_'),
    );
  }

  get comment() {
    return this.returnShipment.comment;
  }

  onToggleExpanded = () => {
    this.isExpanded = !this.isExpanded;
    console.log(this.isExpanded);
  };

  onToggleEditing = () => {
    this.isEditing = !this.isEditing;
  };

  onToggleCommentEditing = () => {
    this.isCommentEditing = !this.isCommentEditing;
  };

  onEditModalOpen = () => {
    this.isEditModalOpen = true;
  };

  onEditModalClose = () => {
    this.isEditModalOpen = false;
  };

  onReturnUpdate = async (values: Record<string, any>) => {
    try {
      this.isLoading = true;

      const selectedKitIds = getReturnItemsKitsIds(this.items);
      const haveSelectedKits = !!selectedKitIds.length;

      const items = haveSelectedKits
        ? this.items.filter((item) => !item.orderItem.productKit)
        : this.items;

      const formattedItems = reduceReturnShipmentItems(items);
      const hasItems = !!Object.keys(formattedItems).length;

      const updatedShipment = await editReturnShipment(this.id, {
        ...(hasItems && { items: { ...formattedItems } }),
        productKits: selectedKitIds,
        costOfGoods: dollars2cents(values.costOfGoods),
        supplier: values.supplier,
        responsible: values.responsible,
        reason: values.reason,
      });

      this.orderViewModel && (await this.orderViewModel.syncOrderData());

      this.returnShipment = { ...this.returnShipment, ...updatedShipment };
      this.isEditing = false;
    } catch (e) {
      const error = e as IServerError;

      notify(error.response.data.message, 'error');
    } finally {
      this.isLoading = false;
    }
  };

  onReturnCommentUpdate = async (values: any) => {
    try {
      this.isLoading = true;

      const updatedReturn = await editReturnComment(this.id, values);

      this.orderViewModel && (await this.orderViewModel.syncOrderData());

      this.returnShipment = { ...this.returnShipment, ...updatedReturn };
      this.isCommentEditing = false;
    } catch (e) {
      const error = e as IServerError;

      notify(error.response.data.message, 'error');
    } finally {
      this.isLoading = false;
    }
  };

  onFinishReturn = async (easy = false) => {
    try {
      this.isLoading = true;

      const finishedReturnShipment = await finishReturnShipment(this.id, easy);

      this.orderViewModel && (await this.orderViewModel.syncOrderData());

      this.returnShipment = finishedReturnShipment;
    } catch (e) {
      const error = e as IServerError;

      notify(error.response.data.message, 'error');
    } finally {
      this.isLoading = false;
    }
  };

  onReturnItemsUpdate = async (
    returnShipmentsItems: IReturnShipmentItemWithControlAttrs[],
  ) => {
    try {
      this.isLoading = true;

      const selectedKitItems = returnShipmentsItems.filter(
        (item) => item.productKit && item.selected,
      );

      const selectedKitIds = getPurchasesKitsIds(selectedKitItems);
      const haveSelectedKits = !!selectedKitIds.length;

      const items = haveSelectedKits
        ? returnShipmentsItems.filter((item) => !item.productKit)
        : returnShipmentsItems;

      const formattedItems = reduceOrderItems(items);
      const hasItems = !!Object.keys(formattedItems).length;

      const updatedReturn = await editReturnShipment(this.id, {
        ...(hasItems && { items: { ...formattedItems } }),
        productKits: selectedKitIds,
        responsible: this.responsible,
        reason: this.reason,
      });

      this.orderViewModel && (await this.orderViewModel.syncOrderData());

      this.returnShipment = { ...this.returnShipment, ...updatedReturn };
      this.isEditModalOpen = false;
    } catch (e) {
      const error = e as IServerError;

      notify(error.response.data.message, 'error');
    } finally {
      this.isLoading = false;
    }
  };

  handleReturnDelete = async (onDelete: (id: number) => Promise<void>) => {
    try {
      this.isLoading = true;

      await onDelete(this.id);
    } catch (e) {
      const error = e as IServerError;

      notify(error.response.data.message, 'error');
    } finally {
      this.isLoading = false;
    }
  };

  constructor(root: ReturnsViewModel | null, returnShipment: IReturnShipment) {
    if (root) {
      this._root = root;
    }

    this._returnShipment = returnShipment;

    makeAutoObservable(this);
  }
}
