import { createContext, ReactNode, useContext } from 'react';

import { RootStore } from 'store/root-model';

let store: RootStore;

const StoreContext = createContext<RootStore | undefined>(undefined);

function initializeStore(): RootStore {
  const _store = store ?? new RootStore();

  return _store;
}

export function StoreProvider({ children }: { children: ReactNode }) {
  const root = initializeStore();

  return <StoreContext.Provider value={root}>{children}</StoreContext.Provider>;
}

export function useRootStore() {
  const context = useContext(StoreContext);

  if (context === undefined) {
    throw new Error('useRootStore must be used within RootStoreProvider');
  }

  return context;
}

export function useCurrentUserStore() {
  const { currentUserStore } = useRootStore();

  return currentUserStore;
}
