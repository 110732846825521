import { ReactNode, useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { scrollToFormError } from 'common/utils';
import {
  FieldValues,
  FormProvider,
  useForm,
  DeepPartial,
  UseFormReturn,
  useFormContext,
} from 'react-hook-form';
import * as yup from 'yup';

export interface IFormProps<T>
  extends Omit<IInternalFormProps, 'onSubmit' | 'trigger'> {
  initialValues: DeepPartial<T>;
  onSubmit: (values: T, methods?: UseFormReturn<any>) => void;
  validation: yup.ObjectSchema<any>;
  validateOnBlur?: boolean;
  shouldUnregister?: boolean;
  scrollToError?: boolean;
}

interface IInternalFormProps {
  children: ReactNode;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  onReset?: () => void;
  className?: string;
  validateOnBlur?: boolean;
  trigger: any;
  id?: string;
  scrollToError?: boolean;
}

const InternalForm = ({
  children,
  className,
  onSubmit,
  onReset,
  id,
  scrollToError,
}: IInternalFormProps) => {
  const {
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    scrollToError && scrollToFormError(errors);
  }, [errors, scrollToError]);

  return (
    <form
      id={id}
      className={className}
      onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
        e.stopPropagation();
        onSubmit(e);
      }}
      onReset={(e: React.FormEvent<HTMLFormElement>) => {
        e.stopPropagation();

        if (onReset) {
          onReset();
        }
      }}
    >
      {children}
    </form>
  );
};

export const Form = <T extends FieldValues>({
  initialValues,
  children,
  onSubmit,
  onReset,
  className,
  validation,
  validateOnBlur,
  shouldUnregister,
  scrollToError,
  id = 'purchase',
}: IFormProps<T>): JSX.Element => {
  const formObject = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validation),
    shouldUnregister: shouldUnregister,
  });

  const submit = (values: T) => {
    onSubmit(values, formObject);
  };

  const reset = () => {
    formObject.reset(initialValues);

    if (onReset) {
      onReset();
    }
  };

  return (
    <FormProvider {...formObject}>
      <InternalForm
        className={className}
        validateOnBlur={validateOnBlur}
        onSubmit={formObject.handleSubmit(
          submit as (values: FieldValues) => void,
        )}
        onReset={reset}
        trigger={formObject.trigger}
        id={id}
        scrollToError={scrollToError}
      >
        {children}
      </InternalForm>
    </FormProvider>
  );
};
