import { IKit } from 'common/types/kit';

export class KitModel {
  _kit: IKit;

  get kit() {
    return this._kit;
  }

  set kit(kit: IKit) {
    this._kit = kit;
  }

  get id() {
    return this._kit.id;
  }

  get type1() {
    const type = Object.entries(this._kit.productTypes)[0];

    return { value: type?.[0], enabled: type?.[1] };
  }

  get type2() {
    const type = Object.entries(this._kit.productTypes)[1];

    return { value: type?.[0], enabled: type?.[1] };
  }

  get type3() {
    const type = Object.entries(this._kit.productTypes)[2];

    return { value: type?.[0], enabled: type?.[1] };
  }

  get type4() {
    const type = Object.entries(this._kit.productTypes)[3];

    return { value: type?.[0], enabled: type?.[1] };
  }

  constructor(kit: IKit) {
    this._kit = kit;
  }
}
