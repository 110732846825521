import { AxiosResponse } from 'axios';
import { IPageParams } from 'common/types';
import { IPatchedUserParams, IUser, IUsers } from 'common/types/user';

import { $host } from './index';

export const getUsers = async (params: IPageParams) => {
  const {
    data: { data },
  } = await $host.get<AxiosResponse<IUsers>>('admin/users', {
    params,
  });

  return data;
};

export const getUser = async (id: string) => {
  const {
    data: { data },
  } = await $host.get<AxiosResponse<IUser>>(`admin/users/id/${id}`);

  return data;
};

export const patchUser = async (id: string, params: IPatchedUserParams) => {
  const {
    data: { data },
  } = await $host.patch<AxiosResponse<IUser>>(`admin/users/${id}`, {
    ...params,
  });

  return data;
};

export const deactivateUser = async (id: string) => {
  const {
    data: { data },
  } = await $host.delete<AxiosResponse<IUser>>(`admin/users/${id}/deactivate`);

  return data;
};

export const reactivateUser = async (id: string) => {
  const {
    data: { data },
  } = await $host.put<AxiosResponse<IUser>>(`admin/users/${id}/reactivate`);

  return data;
};
