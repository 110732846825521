import { FC } from 'react';

import { Modal } from 'antd';
import { observer } from 'mobx-react-lite';

import { DndOrderLinksList, TLink } from '../DndOrderLinksList';

interface IOrderLinksModal {
  open: boolean;
  isLoading: boolean;
  orderLinks: string[];
  onOk: (links: TLink[]) => Promise<void>;
  onCancel: () => void;
  onSync: () => Promise<void>;
}

export const AddLinksModal: FC<IOrderLinksModal> = observer(
  ({ open, isLoading, orderLinks, onOk, onCancel, onSync }) => {
    const handleSave = async (links: TLink[]) => {
      await onOk(links);
    };

    return (
      <Modal
        open={open}
        title="Add links"
        destroyOnClose
        footer={null}
        onCancel={onCancel}
      >
        <DndOrderLinksList
          orderLinks={orderLinks}
          isLoading={isLoading}
          onSave={handleSave}
          onSync={onSync}
        />
      </Modal>
    );
  },
);
