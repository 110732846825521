import { FC } from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import { Button, InputNumber, Popconfirm, Typography } from 'antd';
import { TPhoto } from 'common/types';
import { observer } from 'mobx-react-lite';

import styles from './edit-modal-purchase.module.scss';

export interface IEditModalPurchase {
  photo: TPhoto;
  title: string;
  variationView: string;
  quantity: number;
  price: number;
  sku: string;
  kit?: number;
  onQuantityChange: (value: number) => void;
  onRemove: () => void;
}

export const EditModalPurchase: FC<IEditModalPurchase> = observer(
  ({
    photo,
    title,
    variationView,
    quantity,
    price,
    sku,
    kit,
    onQuantityChange,
    onRemove,
  }) => (
    <div className={styles.container}>
      <img src={photo.url} alt={title} className={styles.image} />
      <div className={styles.content}>
        <Typography.Text strong>Title</Typography.Text>
        <Typography.Text strong>Variation</Typography.Text>
        <Typography.Text strong>Quantity</Typography.Text>
        <Typography.Text strong>Price</Typography.Text>
        <Typography.Text strong>SKU</Typography.Text>
        <Typography.Text strong>#Kit</Typography.Text>
        <Typography.Text strong>Actions</Typography.Text>
        <Typography.Text>{title}</Typography.Text>
        <Typography.Text>{variationView}</Typography.Text>
        <InputNumber
          value={quantity}
          min={1}
          max={30}
          disabled={!!kit}
          onChange={onQuantityChange}
        />
        <Typography.Text>${price}</Typography.Text>
        <Typography.Text>{sku}</Typography.Text>
        <Typography.Text>{kit ?? '-'}</Typography.Text>
        <Popconfirm
          placement="left"
          destroyTooltipOnHide
          rootClassName={styles.actions}
          title={
            kit
              ? `All items from KIT №${kit} will be also removed, are you sure to delete?`
              : 'Are you sure to delete?'
          }
          onConfirm={onRemove}
        >
          <Button size="small" shape="circle" icon={<DeleteOutlined />} />
        </Popconfirm>
      </div>
    </div>
  ),
);
