import { FC } from 'react';

import { PlusCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { TOption } from 'common/types';
import { useModal } from 'common/utils';
import { Table, actions, text } from 'components/Table';
import { TableTitle } from 'components/TableTitle';
import { observer } from 'mobx-react-lite';
import { KitModel } from 'store/kit-model';

import { KitModal } from '../KitModal';
import { KitsViewModel } from '../kits-page-view-model';
import { IFormattedType } from '../types';

import styles from './kit-table.module.scss';

interface IKitTableProps {
  model: KitsViewModel;
}

const getColumns = (
  onEdit: (id: number, record: KitModel) => void,
  onDelete: (id: number) => void,
): ColumnsType<KitModel> => [
  text({
    key: 'type1',
    width: '150px',
    title: 'Type 1',
    dataIndex: 'type1',
    render: (record: IFormattedType) => <span>{record.value}</span>,
  }),
  text({
    key: 'type2',
    width: '150px',
    title: 'Type 2',
    dataIndex: 'type2',
    render: (record: IFormattedType) => <span>{record.value}</span>,
  }),
  text({
    key: 'type3',
    width: '150px',
    title: 'Type 3',
    dataIndex: 'type3',
    render: (record: IFormattedType) => <span>{record.value}</span>,
  }),
  text({
    key: 'type4',
    width: '150px',
    title: 'Type 4',
    dataIndex: 'type4',
    render: (record: IFormattedType) => <span>{record.value}</span>,
  }),
  actions({
    width: '30px',
    onEdit: onEdit,
    onDelete: onDelete,
    deleteMessage: 'Are you sure you want to delete this kit?',
  }),
];

export const KitTable: FC<IKitTableProps> = observer(({ model }) => {
  const [isOpen, openModal, closeModal] = useModal(false);

  const isEdit = !!model.kit;

  const openEditModal = async (_id: number, record: KitModel) => {
    model.kit = record.kit;

    openModal();
  };

  const closeEditModal = () => {
    model.kit = null;

    closeModal();
  };

  const handleAdd = (productTypeOptions: TOption[]) =>
    model.addKit(productTypeOptions);

  const handleEdit = (productTypeOptions: TOption[]) =>
    model.editKit(model.kit?.id, productTypeOptions);

  const handleDelete = (id: number) => model.removeKit(id);

  return (
    <>
      <div className={styles.buttonContainer}>
        <Button
          type="primary"
          icon={<PlusCircleOutlined />}
          size="middle"
          onClick={openModal}
          disabled={model.isLoading}
        >
          Add new kit
        </Button>
      </div>
      <Table
        title={() => <TableTitle title="Kits" />}
        columns={getColumns(openEditModal, handleDelete)}
        data={model.kits}
        loading={model.isLoading}
        bordered
        pagination={{
          total: model.filters.count,
          current: model.filters.page,
          pageSize: model.filters.pageSize,
          disabled: model.isLoading,
        }}
        onChange={model.changeFilters}
      />
      <KitModal
        isOpen={isOpen}
        isEdit={isEdit}
        model={model}
        onCancel={isEdit ? closeEditModal : closeModal}
        onSubmit={isEdit ? handleEdit : handleAdd}
      />
    </>
  );
});
