import { FC } from 'react';

import { Skeleton, Typography } from 'antd';
import { ColumnType } from 'antd/es/table';
import { getNestedProperty, getObservableTableCellValue } from 'common/utils';
import { Observer } from 'mobx-react-lite';

import styles from './text.module.scss';

export type TTextColumn<T> = ColumnType<T> & Omit<TTextCellProps, 'text'>;

type TVariantOption = { name: string; value: string };
export interface TTextCellProps {
  text: string | TVariantOption[];
  type?: 'variant' | 'default';
  loading?: boolean;
  nested?: boolean;
  observer?: boolean;
}

const TextCell: FC<TTextCellProps> = ({ text, loading, type }) => {
  if (loading) {
    return (
      <Skeleton active paragraph={{ rows: 1 }} className={styles.skeleton} />
    );
  }

  if (type === 'variant') {
    return (
      <div>
        {(text as TVariantOption[]).map((option) => {
          return (
            <div key={option.name}>
              <strong>{option.name}:</strong>
              <span>&nbsp;{option.value}</span>
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <Typography.Text
      className={styles.textCell}
      ellipsis={{ tooltip: text as string }}
    >
      {text as string}
    </Typography.Text>
  );
};

export const text = <T,>({
  dataIndex = 'title',
  key = 'title',
  width = '10%',
  loading,
  type = 'default',
  nested = false,
  observer,
  ...props
}: TTextColumn<T>): ColumnType<T> => ({
  dataIndex: dataIndex,
  key: key,
  width: width,
  render: (title: string, record: T) => (
    <Observer>
      {() => {
        const text = nested
          ? getNestedProperty(
              record as Record<string, any>,
              (dataIndex as string).split('.'),
            )
          : title;

        const observedValue = getObservableTableCellValue<T>(dataIndex, record);

        return (
          <TextCell
            type={type}
            text={observer ? observedValue : text}
            loading={loading}
          />
        );
      }}
    </Observer>
  ),
  ...props,
});
