import { FC } from 'react';

import { Skeleton, Checkbox } from 'antd';
import { ColumnType } from 'antd/es/table';

export type TCheckboxColumn<T> = ColumnType<T> &
  Omit<TCheckboxCellProps, 'title'>;

export interface TCheckboxCellProps {
  checked?: boolean;
  loading?: boolean;
}

const CheckboxCell: FC<TCheckboxCellProps> = ({ checked, loading }) => {
  if (loading) {
    return <Skeleton.Input active />;
  }

  return <Checkbox checked={checked} disabled />;
};

export const checkbox = <T,>({
  dataIndex = 'title',
  key = 'title',
  width = '10%',
  loading,
  ...props
}: TCheckboxColumn<T>): ColumnType<T> => ({
  dataIndex: dataIndex,
  key: key,
  width: width,
  render: (checked: boolean) => (
    <CheckboxCell checked={checked} loading={loading} />
  ),
  ...props,
});
