import { FC, useState } from 'react';

import { Modal, Button, Select } from 'antd';
import { TOption } from 'common/types';
import { EPaymentCancelledStatuses } from 'common/types/order/enums';
import { observer } from 'mobx-react-lite';

import styles from './cancel-order-modal.module.scss';

interface ICancelOrderModal {
  ok: boolean;
  reasonOptions: TOption[];
  isLoading?: boolean;
  onOk: (value: EPaymentCancelledStatuses | string) => Promise<void>;
  onCancel: () => void;
}

export const CancelOrderModal: FC<ICancelOrderModal> = observer(
  ({ ok, reasonOptions, isLoading, onOk, onCancel }) => {
    const [reason, setReason] = useState<EPaymentCancelledStatuses>(
      EPaymentCancelledStatuses['PAYMENT_CANCELLED_FRAUDULENT_ORDER'],
    );

    return (
      <Modal
        open={ok}
        onCancel={onCancel}
        title="Cancel order"
        destroyOnClose
        footer={[
          <Button
            type="primary"
            disabled={!reason}
            loading={isLoading}
            onClick={() => onOk(reason)}
          >
            Cancel order
          </Button>,
          <Button onClick={onCancel}>Close</Button>,
        ]}
      >
        <p>
          This action cannot be undone and all money will be refunded to the
          buyer. Are you sure to cancel this order?
        </p>
        <div>
          <p>Reason:</p>
          <Select
            value={reason}
            placeholder="Choose a reason"
            options={reasonOptions}
            className={styles.select}
            onChange={setReason}
          />
        </div>
      </Modal>
    );
  },
);
