import {
  cancelOrderById,
  createRefund,
  recalculateOrderCost,
} from 'http/orderApi';

import { cancelReasonDefaultOptions } from 'common/constants';
import { IServerError } from 'common/types';
import { EPaymentCancelledStatuses } from 'common/types/order/enums';
import { dollars2cents, notify } from 'common/utils';
import { makeAutoObservable } from 'mobx';

import { ExpandedOrderViewModel } from './expanded-order-view-model';

export class ControlsViewModel {
  _root: ExpandedOrderViewModel | null = null;
  _isLoading = false;
  _isRefundModalOpen = false;
  _isCancelOrderModalOpen = false;
  cancelReasonDefaultOptions = cancelReasonDefaultOptions;

  get root() {
    return this._root;
  }

  get orderViewModel() {
    return this.root?.root;
  }

  get isLoading() {
    return this._isLoading;
  }

  set isLoading(isLoading: boolean) {
    this._isLoading = isLoading;
  }

  get isRefundModalOpen() {
    return this._isRefundModalOpen;
  }

  set isRefundModalOpen(isOpen: boolean) {
    this._isRefundModalOpen = isOpen;
  }

  get isCancelOrderModalOpen() {
    return this._isCancelOrderModalOpen;
  }

  set isCancelOrderModalOpen(isOpen: boolean) {
    this._isCancelOrderModalOpen = isOpen;
  }

  onRefundModalOpen = () => {
    this.isRefundModalOpen = true;
  };

  onRefundModalClose = () => {
    this.isRefundModalOpen = false;
  };

  onRefundModalSubmit = async (
    sum: number,
    responsible: string,
    reason: string,
  ) => {
    const orderId = this.orderViewModel?.shortOrder?.id;

    if (!orderId) {
      return;
    }

    try {
      this.isLoading = true;

      const params = {
        amount: dollars2cents(sum),
        responsible: responsible,
        reason: reason,
      };

      await createRefund(orderId, params);
      this.orderViewModel && (await this.orderViewModel.syncOrderData());

      this.isRefundModalOpen = false;
    } catch (e) {
      const error = e as IServerError;

      notify(`Error, reason: ${error.response?.data?.message}`, 'error');
    } finally {
      this.isLoading = false;
    }
  };

  onRecalculate = async () => {
    this.root?.order?.id && (await recalculateOrderCost(this.root?.order?.id));

    this.orderViewModel && (await this.orderViewModel.syncOrderData());
  };

  onCancelOrderModalOpen = () => {
    this.isCancelOrderModalOpen = true;
  };

  onCancelOrderModalClose = () => {
    this.isCancelOrderModalOpen = false;
  };

  onCancelOrderModalSubmit = async (
    reason: EPaymentCancelledStatuses | string,
  ) => {
    const orderId = this.orderViewModel?.shortOrder?.id;

    if (!orderId) {
      return;
    }

    try {
      this.isLoading = true;

      await cancelOrderById(orderId, {
        reason,
      });

      this.orderViewModel && (await this.orderViewModel.syncOrderData());

      this.isCancelOrderModalOpen = false;
    } catch (e) {
      const error = e as IServerError;

      notify(`Error, reason: ${error.response?.data?.message}`, 'error');
    } finally {
      this.isLoading = false;
    }
  };

  constructor(root?: ExpandedOrderViewModel | null) {
    if (root) {
      this._root = root;
    }

    makeAutoObservable(this);
  }
}
