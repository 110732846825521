import { FC, useState } from 'react';

import type { ColumnsType } from 'antd/es/table';
import { variationDeleteMessage } from 'common/constants';
import { actions, image, Table, tag, text } from 'components/Table';
import { observer } from 'mobx-react-lite';
import { ProductControllerViewModel } from 'pages/Product/ProductController/product-controller-view-model';
import { useFormContext } from 'react-hook-form';
import { VariationModel } from 'store/variation-model';

import { IVariation } from '../../../common/types/product';
import { useModal } from '../../../common/utils';

import { EditImagesModal } from './EditImagesModal';
import { EditVariationModal } from './EditVariationModal';

import styles from './styles.module.scss';

interface IVariationTableProps {
  model: ProductControllerViewModel;
}

const VariationTable: FC<IVariationTableProps> = observer(({ model }) => {
  const [openEditModal, handleEditModalOpen, handleEditModalClose] =
    useModal(false);
  const [openImagesModal, handleImagesModalOpen, handleImagesModalClose] =
    useModal(false);
  const [variation, setVariation] = useState<VariationModel | undefined>();

  const { reset, watch } = useFormContext();

  const handleProductRefetch = () => {
    model.product.fetchProduct(model.product.id, true);
  };

  const handleCloseModal = () => {
    setVariation(undefined);
    handleEditModalClose();
    handleImagesModalClose();
  };

  const editVariationHandler = (id: number) => {
    setVariation(model.product.getVariationById(id));
    handleEditModalOpen();
  };

  const editVariationImagesHandler = (id: number) => {
    setVariation(model.product.getVariationById(id));
    handleImagesModalOpen();
  };

  const allFormFields = watch();

  const columns: ColumnsType<any> = [
    image<IVariation>({
      title: 'Photo',
      dataIndex: 'mainPhoto',
      onClick: editVariationImagesHandler,
      clickOnPlaceholder: true,
    }),
    text({
      title: 'Variant',
      dataIndex: 'variant',
      width: '20%',
      type: 'variant',
    }),
    text({
      title: 'Total price (price)',
      dataIndex: 'price',
      render: (_, record: VariationModel) => {
        return record.totalPrice !== record.price ? (
          <div className={styles.price}>
            <span>${record.totalPrice}</span>
            <span>
              (<s>${record.price}</s>)
            </span>
          </div>
        ) : (
          <span>${record.price}</span>
        );
      },
    }),
    tag({
      title: 'Status',
      dataIndex: 'status',
    }),
    text({
      title: 'SKU',
      dataIndex: 'sku',
    }),
    actions({
      onEdit: editVariationHandler,
      onDelete: async (id) => {
        const response = await model.removeVariation(id);

        if (response) {
          reset({
            ...allFormFields,
            variations: model.product.variationsObject,
          });
        }
      },
      deleteMessage: variationDeleteMessage,
    }),
  ];

  return (
    <>
      {openEditModal && variation && (
        <EditVariationModal
          model={variation}
          isOpen={openEditModal}
          closeModal={handleCloseModal}
          refetchProduct={handleProductRefetch}
        />
      )}
      {openImagesModal && variation && (
        <EditImagesModal
          model={variation}
          isOpen={openImagesModal}
          closeModal={handleCloseModal}
          refetchProduct={handleProductRefetch}
          uniquePhotos={model.product.uniquePhotos}
        />
      )}
      <Table bordered data={model.product.variations} columns={columns} />
    </>
  );
});

export default VariationTable;
