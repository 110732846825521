import { IEmployee } from 'common/types/employee';
import { computed, makeObservable, observable } from 'mobx';

export class EmployeeModel {
  _employee: IEmployee;

  get employee() {
    return this._employee;
  }

  set employee(employee: IEmployee) {
    this._employee = employee;
  }

  get id() {
    return this._employee.id;
  }

  get email() {
    return this._employee.email;
  }

  get firstName() {
    return this._employee.firstName;
  }

  get lastName() {
    return this._employee.lastName;
  }

  get role() {
    return this._employee.role;
  }

  get key() {
    return this._employee.id;
  }

  constructor(employee: IEmployee) {
    this._employee = employee;

    makeObservable(this, {
      _employee: observable,
      employee: computed,
      id: computed,
      email: computed,
      firstName: computed,
      lastName: computed,
      role: computed,
      key: computed,
    });
  }
}
