import { FC, useEffect } from 'react';

import { InboxOutlined } from '@ant-design/icons';
import cn from 'clsx';
import { TPhoto } from 'common/types';
import { notify } from 'common/utils';
import { Image } from 'components/Image';
import { DndProvider, useDrop } from 'react-dnd';
import { NativeTypes, HTML5Backend } from 'react-dnd-html5-backend';
import { Controller, useFormContext } from 'react-hook-form';

import styles from './uploader.module.scss';

export interface IFormUploaderProps {
  id?: string;
  name: string;
  label?: string;
  labelPosition?: 'top' | 'left';
  limit?: number;
  required?: boolean;
  className?: string;
  multiple?: boolean;
}

const fileReader = async (file: File): Promise<TPhoto> => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      resolve({
        id: -Math.floor(Math.random() * Date.now()),
        url: reader.result as string,
        key: file.name,
        file: file,
      });
    };
  });
};

const Uploader: FC<
  Pick<IFormUploaderProps, 'multiple' | 'id'> & {
    handleImageAdd: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onDrop: (items: FileList | null) => void;
  }
> = ({ id, onDrop, handleImageAdd, multiple }) => {
  const [_, drop] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop({ files }) {
        if (onDrop) {
          onDrop(files);
        }
      },
    }),
    [onDrop],
  );

  return (
    <div id={id} ref={drop} className={styles.uploader}>
      <div className={styles.titleWrapper}>
        <div>
          <InboxOutlined className={styles.uploadIcon} />
        </div>
        <div className={styles.title}>Click or drag file to upload</div>
      </div>
      <input
        className={styles.upload}
        type="file"
        onChange={handleImageAdd}
        accept="image/png, image/jpeg"
        {...(multiple ? { multiple: true } : undefined)}
      />
    </div>
  );
};

export const FormUploader: FC<IFormUploaderProps> = ({
  id,
  name,
  className,
  label,
  labelPosition = 'top',
  multiple = false,
  limit = 2, // Default 2Mb upload limit
  required,
}) => {
  const { control, setValue, getValues } = useFormContext();

  useEffect(() => {
    setValue(name, getValues(name) ?? null);
  }, []);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        const handleDeleteImage = () => {
          onChange(null);
        };

        const handleFiles = async (files: FileList | null) => {
          const arrayFromFiles = Array.from(files ?? []);

          const photos = await Promise.all(
            arrayFromFiles.map(async (file) => {
              const isFileSizeLarge = file.size / 1048576 > limit;

              if (isFileSizeLarge) {
                return undefined;
              }

              const photo = await fileReader(file);

              return photo;
            }),
          );

          const newPhotos = photos.filter((photo) => !!photo);

          if (newPhotos.length !== arrayFromFiles?.length) {
            notify(
              `${
                multiple ? 'One of files' : 'File'
              } exceed ${limit}Mb limit and was not added`,
              'warning',
            );
          }

          onChange(multiple ? [...value, ...newPhotos] : newPhotos[0] ?? null);
        };

        const handleImageAdd = async (
          event: React.ChangeEvent<HTMLInputElement>,
        ) => {
          const files = event.currentTarget.files;

          handleFiles(files);

          event.target.value = '';
        };

        return (
          <DndProvider backend={HTML5Backend}>
            <div
              className={cn(
                styles.container,
                styles.inputWrapper,
                styles[`label-${labelPosition}`],
                className,
              )}
            >
              <label htmlFor={name} className={styles.label}>
                {label}
                {required && <span className={styles.required}>*</span>}
              </label>
              {!multiple && value?.url && (
                <div id={name} className={styles.imageWrapper}>
                  <Image
                    id={value.id}
                    url={value.url}
                    handleDelete={handleDeleteImage}
                    block
                  />
                </div>
              )}
              {multiple && (
                <Uploader
                  id={name}
                  onDrop={handleFiles}
                  handleImageAdd={handleImageAdd}
                  multiple={multiple}
                />
              )}
              {!multiple && !value?.url && (
                <Uploader
                  id={name}
                  onDrop={handleFiles}
                  handleImageAdd={handleImageAdd}
                  multiple={multiple}
                />
              )}

              {Array.isArray(error) &&
                error.map((err) => (
                  <span className={styles.error}>{err?.message}</span>
                ))}
              {error?.message && (
                <span className={styles.error}>{error.message}</span>
              )}
            </div>
          </DndProvider>
        );
      }}
    />
  );
};
