import { FC } from 'react';

import { ColumnsType } from 'antd/es/table';
import { ILog } from 'common/types/log';
import { parseToLongDate, upperUndescoreToRegularCase } from 'common/utils';
import { Table, text } from 'components/Table';
import { observer } from 'mobx-react-lite';

import { OrderExpandedViewModel } from '../../../order-expanded-view-model';

interface IHistoryTableProps {
  model: OrderExpandedViewModel;
}

const columns: ColumnsType<ILog> = [
  text({
    title: 'Date',
    dataIndex: 'createdAt',
    width: '15%',
    key: 'date',
    render: (value: string) => <span>{parseToLongDate(value)}</span>,
  }),
  text({
    width: '13%',
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    render: (value: string) => (
      <span>{upperUndescoreToRegularCase(value)}</span>
    ),
  }),
  text({
    width: '10%',
    title: 'Author',
    key: 'author',
    render: (_value: never, record: ILog) => (
      <span>{`${record.author.firstName} ${record.author.lastName}`}</span>
    ),
  }),
];

export const HistoryTable: FC<IHistoryTableProps> = observer(({ model }) => (
  <Table
    key={`disabled-${model.logs.length}`}
    columns={columns}
    data={model.logs}
    pagination={false}
    bordered
    fixedHeight={500}
  />
));
