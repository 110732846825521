import { FC } from 'react';

import { Button } from 'antd';
import { returnCommentValidationSchema } from 'common/validation';
import { FormTextArea } from 'components/Form';
import { Form } from 'components/Form';

import styles from './editable-data.module.scss';

interface IEditableDataProps {
  comment?: string;
  isLoading?: boolean;
  onEditToggle: () => void;
  onEdit: (values: any) => Promise<void>;
}

export const EditableData: FC<IEditableDataProps> = ({
  comment,
  isLoading,
  onEditToggle,
  onEdit,
}) => {
  const initialValues = {
    comment: comment,
  };

  const handleSubmit = async (values: any) => {
    await onEdit(values);
  };

  return (
    <Form
      initialValues={initialValues}
      validation={returnCommentValidationSchema}
      onSubmit={handleSubmit}
      className={styles.container}
    >
      <FormTextArea
        label="Comment"
        name="comment"
        className={styles.textarea}
      />
      <div className={styles.buttons}>
        <Button htmlType="submit" loading={isLoading}>
          Save
        </Button>
        <Button htmlType="button" onClick={onEditToggle}>
          Discard
        </Button>
      </div>
    </Form>
  );
};
