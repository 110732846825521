import { FC } from 'react';

import { Divider } from 'antd';
import { observer } from 'mobx-react-lite';

import { BrandsMeta } from './BrandsMeta';
import { DiscountBar } from './DiscountBar';
import { MainViewModel } from './main-view-model';
import { SliderForm } from './SliderForm';

import styles from './styles.module.scss';

interface IMainPageProps {
  model: MainViewModel;
}

const Main: FC<IMainPageProps> = observer(({ model }) => {
  return (
    <div className={styles.pageContent}>
      <DiscountBar model={model} />
      <Divider />
      <SliderForm model={model} />
      <Divider />
      <BrandsMeta model={model} />
    </div>
  );
});

export const MainPage = () => {
  const model = new MainViewModel();

  return <Main model={model} />;
};
