import { IShortOrder } from 'common/types';
import { mockIdKeyGen } from 'common/utils';
import { makeAutoObservable } from 'mobx';
import { OrderModel } from 'store/order/order-model';

import { OrderExpandedViewModel } from './order-expanded-view-model';
import { OrderViewModel } from './order-view-model';

export class OrderTableRowViewModel {
  _root: OrderViewModel | null = null;
  _order: OrderModel;
  _orderExpandedViewModel: OrderExpandedViewModel =
    new OrderExpandedViewModel();

  get root() {
    return this._root;
  }

  get key() {
    return !!this._order.id ? this.order.id : mockIdKeyGen();
  }

  get order() {
    return this._order;
  }

  set order(order: OrderModel) {
    this._order = order;
  }

  get orderExpandedViewModel() {
    return this._orderExpandedViewModel;
  }

  set orderExpandedViewModel(model: OrderExpandedViewModel) {
    this._orderExpandedViewModel = model;
  }

  constructor(root: OrderViewModel | null, order: IShortOrder) {
    if (root) {
      this._root = root;
    }

    this._order = new OrderModel(order);

    makeAutoObservable(this);
  }
}
