import { getProductAutocompleteByType } from 'http/productApi';

import { IAutocompleteTypeParams, IBrand } from 'common/types';
import { ProductAutocompleteTypeEnum } from 'common/types/enums';
import { notify, serializeEntityOptions } from 'common/utils';
import { computed, makeAutoObservable } from 'mobx';
import { SubcategoryModel } from 'store/subcategory-model';

export class SubcategoryControllerViewModel {
  _subcategory: SubcategoryModel;
  _brandOptions: IBrand[] = [];
  _loading = false;

  get subcategory() {
    return this._subcategory;
  }

  get loading() {
    return this._subcategory.loading || this._loading;
  }

  get brandOptions() {
    return serializeEntityOptions(this._brandOptions);
  }

  fetchBrandOptions = async () => {
    this._loading = true;

    try {
      const brands = await getProductAutocompleteByType(
        ProductAutocompleteTypeEnum.brands,
      );

      this._brandOptions = brands.map((brand: IAutocompleteTypeParams) => {
        return { id: brand.id, title: brand.value };
      });
    } catch (e) {
      notify(`Error, reason:${e}`, 'error');
    } finally {
      this._loading = false;
    }
  };

  constructor(id: number) {
    this.fetchBrandOptions();

    this._subcategory = new SubcategoryModel(id);

    makeAutoObservable(this, {
      subcategory: computed,
      brandOptions: computed,
    });
  }
}
