import { FC, useEffect, useMemo, useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import { defaultPurchaseEntity } from 'common/constants';
import { useModal } from 'common/utils';
import { purchasesValidationSchema } from 'common/validation';
import { Form } from 'components/Form';
import { Table, image, title } from 'components/Table';
import { observer } from 'mobx-react-lite';
import { useFormContext } from 'react-hook-form';
import { PurchaseModel } from 'store/purchase-model';

import { OrderExpandedViewModel } from '../../order-expanded-view-model';

import { PurchaseTable } from './PurchaseTable';

import styles from './purchase-section.module.scss';

interface IPurchaseSectionProps {
  model: OrderExpandedViewModel;
}

interface IPurchaseSectionContentProps {
  model: OrderExpandedViewModel;
  isModalOpen: boolean;
  initialPurchases: { purchases: IFormPurchases };
  purchases: PurchaseModel[];
  closeModal: () => void;
  onPurchasesUpdate: (purchases: PurchaseModel[]) => void;
}

interface IFormValues {
  purchases: { [key: number]: IFormPurchase };
}

interface IFormPurchases {
  [key: number]: IFormPurchase;
}

interface IFormPurchase {
  quantity: number;
  sku: string;
  status: 'valid' | 'invalid' | 'deleted';
}

const skeletonColumns = (loading: boolean) => [
  image({
    title: 'Photo',
    loading: loading,
    width: '40px',
  }),
  title({
    width: '70px',
    title: 'Title',
    loading: loading,
  }),
  title({
    width: '50px',
    title: 'Shipment',
    loading: loading,
  }),
  title({
    width: '55px',
    title: 'Quantity',
    loading: loading,
  }),
  title({
    width: '70px',
    title: 'Variation',
    loading: loading,
  }),
  title({
    width: '40px',
    title: 'Price',
    loading: loading,
  }),
  title({
    width: '80px',
    title: 'SKU',
    loading: loading,
  }),
  title({
    width: '50px',
    title: 'Purchase Link',
    loading: loading,
  }),
  title({
    width: '50px',
  }),
];

const { Title } = Typography;

const PurchaseSectionContent: FC<IPurchaseSectionContentProps> = observer(
  ({
    model,
    isModalOpen,
    initialPurchases,
    purchases,
    closeModal,
    onPurchasesUpdate,
  }) => {
    const [isEdit, setEdit] = useState(false);
    const { setValue, reset } = useFormContext();

    const toggleEdit = (edit = !isEdit) => {
      setEdit(edit);
    };

    const onDiscard = () => {
      const purchases = model.purchases.map(
        (purchase) => new PurchaseModel(purchase.purchase),
      );

      reset(initialPurchases);
      onPurchasesUpdate(purchases);
    };

    const handleAddPurchase = () => {
      const id = -Date.now();
      const newPurchase = new PurchaseModel({
        ...defaultPurchaseEntity,
        id: id,
      });

      newPurchase.status = 'empty';

      // we need to mark new value to handle special validation
      setValue(`purchases.${newPurchase.id}`, {
        quantity: 1,
        sku: '',
        status: 'invalid',
      });

      onPurchasesUpdate([newPurchase, ...purchases]);
      setEdit(true);
    };

    const onPurchaseDelete = (purchase: PurchaseModel) => {
      // we need to mark values as deleted as they are still in the form
      if (purchase.productKit) {
        purchases.forEach((item) => {
          if (item.productKit?.id === purchase.productKit?.id) {
            item.status = 'deleted';

            setValue(`purchases.${item.id}.status`, 'deleted');
          }
        });

        onPurchasesUpdate([...purchases]);

        return;
      }

      if (purchase.status === 'empty' || purchase.status === 'added') {
        const originPurchases = purchases.filter(
          (existingPurchase) => existingPurchase.id !== purchase.id,
        );

        setValue(`purchases.${purchase.id}.status`, 'deleted');
        onPurchasesUpdate(originPurchases);

        return;
      }

      purchase.status = 'deleted';

      setValue(`purchases.${purchase.id}.status`, 'deleted');
      onPurchasesUpdate([...purchases]);
    };

    return (
      <div>
        <div className={styles.header}>
          <Title level={4}>Purchase</Title>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            disabled={model.order.disabled}
            onClick={handleAddPurchase}
          >
            Add an Item
          </Button>
        </div>
        <PurchaseTable
          isOpenModal={isModalOpen}
          isEdit={isEdit}
          model={model}
          initialPurchases={initialPurchases}
          purchases={purchases}
          toggleEdit={toggleEdit}
          closeModal={closeModal}
          onDiscard={onDiscard}
          onPurchasesUpdate={onPurchasesUpdate}
          onPurchaseDelete={onPurchaseDelete}
        />
      </div>
    );
  },
);

export const PurchaseSection: FC<IPurchaseSectionProps> = observer(
  ({ model }) => {
    const [purchases, setPurchases] = useState<PurchaseModel[]>([]);
    const [isModalOpen, openModal, closeModal] = useModal(false);

    const initialPurchases = useMemo(() => {
      const values: IFormPurchases = {};

      model.purchases.forEach((purchase) => {
        values[purchase.id] = {
          quantity: purchase.quantity,
          sku: purchase.sku,
          status: 'valid',
        };
      });

      return { purchases: values };
    }, [model.purchases]);

    const onPurchasesUpdate = (purchases: PurchaseModel[]) =>
      setPurchases(purchases);

    const updatePurchase = (
      purchase: PurchaseModel,
      formPurchase: IFormPurchase,
    ) => {
      const isPurchase = formPurchase && purchases.includes(purchase);
      const isQuantityUpdated = formPurchase.quantity !== purchase.quantity;

      const isOriginChanged =
        isPurchase && isQuantityUpdated && purchase.status === 'origin';

      const isUpdatedOrAddedChanged =
        isPurchase &&
        isQuantityUpdated &&
        (purchase.status === 'added' || purchase.status === 'updated');

      const isUpdatedReverted =
        isPurchase && !isQuantityUpdated && purchase.status === 'updated';

      if (isOriginChanged) {
        purchase.status = 'updated';
        purchase.quantityUpdated = formPurchase.quantity;

        return purchase;
      }

      if (isUpdatedOrAddedChanged) {
        purchase.quantityUpdated = formPurchase.quantity;

        return purchase;
      }

      if (isUpdatedReverted) {
        purchase.status = 'origin';
        purchase.quantityUpdated = formPurchase.quantity;

        return purchase;
      }

      return purchase;
    };

    const onSubmit = (data: IFormValues) => {
      const updatedPurchases = purchases.map((purchase) =>
        updatePurchase(purchase, data.purchases[purchase.id]),
      );

      setPurchases(updatedPurchases);
      openModal();
    };

    useEffect(() => {
      if (!model.loading) {
        const purchases = model.purchases.map(
          (purchase) => new PurchaseModel(purchase.purchase),
        );

        setPurchases(purchases);
      }
    }, [model.purchases, model.loading]);

    return (
      <>
        {model.loading ? (
          <Table
            data={purchases}
            columns={skeletonColumns(model.loading)}
            skeleton={model.skeleton}
            loading={model.loading}
          />
        ) : (
          <Form
            initialValues={initialPurchases}
            validation={purchasesValidationSchema}
            onSubmit={onSubmit}
          >
            <PurchaseSectionContent
              model={model}
              isModalOpen={isModalOpen}
              initialPurchases={initialPurchases}
              purchases={purchases}
              closeModal={closeModal}
              onPurchasesUpdate={onPurchasesUpdate}
            />
          </Form>
        )}
      </>
    );
  },
);
