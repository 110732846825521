import { FC } from 'react';

import { observer } from 'mobx-react-lite';

import { OrderExpandedViewModel } from '../../order-expanded-view-model';

import { EfficiencyContent } from './EfficiencyContent';
import { EfficiencySkeleton } from './EfficiencySkeleton';

interface IEfficiencySectionProps {
  model: OrderExpandedViewModel;
}

export const EfficiencySection: FC<IEfficiencySectionProps> = observer(
  ({ model }) => {
    if (model.loading) {
      return <EfficiencySkeleton />;
    }

    return <EfficiencyContent model={model} />;
  },
);
