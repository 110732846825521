import { FC } from 'react';

import { observer } from 'mobx-react-lite';

import { PromopcodeViewModel } from './promocode-view-model';
import { TableContent } from './TableContent';

interface IPromocodeProps {
  model: PromopcodeViewModel;
}

const Promocode: FC<IPromocodeProps> = observer(({ model }) => (
  <TableContent model={model} />
));

export const PromocodePage = () => {
  const model = new PromopcodeViewModel();

  return <Promocode model={model} />;
};
