import { FC } from 'react';

import {
  activeStatusDefaultOptions,
  promoTypeDefaultOptions,
  shippingAmount,
} from 'common/constants';
import { PromoTypeEnum } from 'common/types/promocode/enums';
import { FormInput, FormNumberInput, FormSelect } from 'components/Form';
import { FormCheckbox } from 'components/Form/Checkbox';
import { useFormContext } from 'react-hook-form';
import { PromocodeModel } from 'store/promocode-model';

import styles from './form-content.module.scss';

interface IFormContentProps {
  editablePromo: PromocodeModel | null;
}

export const FormContent: FC<IFormContentProps> = ({ editablePromo }) => {
  const { watch, setValue } = useFormContext();

  const promoType = watch('promoType');
  const amount = watch('discount');
  const isLimited = watch('isLimited');

  const haveFreeDelivery = promoType === PromoTypeEnum.FREE_DELIVERY;
  const havePercentage = promoType === PromoTypeEnum.PERCENTAGE;

  const prefixAmount = havePercentage ? '%' : '$';
  const valueAmount = haveFreeDelivery ? shippingAmount : amount;

  const handleChangeType = (type: keyof typeof PromoTypeEnum) => {
    setValue(
      'discount',
      type === PromoTypeEnum.FREE_DELIVERY ? shippingAmount : 0,
    );
  };

  return (
    <>
      <FormInput required name="promocode" label="Promocode:" uppercase />
      <div className={styles.promoTypeWrapper}>
        <FormSelect
          required
          className={styles.select}
          label="Type of promocode:"
          onChange={handleChangeType}
          options={promoTypeDefaultOptions}
          disabled={!!editablePromo}
          name="promoType"
          zIndex={1001}
        />
        <FormNumberInput
          prefix={promoType && prefixAmount}
          disabled={!promoType || !!editablePromo}
          name="discount"
          value={valueAmount}
          label="Amount:"
          min={0}
          max={havePercentage ? 100 : undefined}
          readOnly={haveFreeDelivery}
        />
      </div>
      <FormNumberInput
        name="purchaseSum"
        label="Sum to get discount:"
        disabled={!!editablePromo}
        prefix="$"
        min={0}
      />
      <div className={styles.limitedCountWrapper}>
        <FormCheckbox name="isLimited">Limited</FormCheckbox>
        <span>Uses count:</span>
        <FormNumberInput disabled={!isLimited} name="usesLeft" min={1} />
      </div>
      <FormSelect
        className={styles.select}
        options={activeStatusDefaultOptions}
        name="activeStatus"
        label="Select promocode status:"
        zIndex={1001}
      />
    </>
  );
};
