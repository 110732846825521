import { getResponsibleList, getSupplierList } from 'http/employeeApi';
import {
  changeOrderManager,
  changeOrderStatus,
  getOrders,
  getOrderStatusOptions,
  getOrdersWithFilters,
} from 'http/orderApi';

import {
  defaultOrderEntity,
  responsibleDefaultOptions,
} from 'common/constants';
import {
  IServerError,
  IShortOrder,
  OrderTableColumn,
  TOption,
} from 'common/types';
import { EEmployeeRoles } from 'common/types/employee/enums';
import { combineString, notify } from 'common/utils';
import {
  computed,
  makeAutoObservable,
  makeObservable,
  observable,
  override,
} from 'mobx';
import { FiltersModel, TParams } from 'store/filters-model';
import { OrderModel } from 'store/order/order-model';

import { OrderTableRowViewModel } from './order-table-row-view-model';

class OrderFiltersModel extends FiltersModel {
  _buyerEmail: string;

  get buyerEmail() {
    return this._buyerEmail;
  }

  set buyerEmail(value: string) {
    this._buyerEmail = value;
    this._page = 1;

    this._params = { ...this.params, search: value };
  }

  constructor(refreshData?: () => void, params?: TParams) {
    super(refreshData, params);

    this._buyerEmail = params?.buyerEmail || '';

    makeObservable(this, {
      _filters: override,
      _pageSize: override,
      _count: override,
      _page: override,
      _search: override,
      _params: override,
      _sort: override,
      _sortBy: override,
      refresh: override,
      filters: override,
      pageSize: override,
      count: override,
      page: override,
      search: override,
      params: override,
      sort: override,
      sortBy: override,
      defaultCurrent: override,
      setSorterSortOptions: override,
      setSortOptions: override,
      _buyerEmail: observable,
      buyerEmail: computed,
    });
  }
}

export class OrderViewModel {
  _loading = false;
  _filters: OrderFiltersModel = new OrderFiltersModel();
  _statusFilter: string[] = [];
  _managerOptions: TOption[] = [];
  _responsibleOptions: TOption[] = [];
  _supplierOptions: TOption[] = [];
  _orderTableRowViewModels: OrderTableRowViewModel[] = [];

  defaultResponsibleOptions = responsibleDefaultOptions;

  get loading() {
    return this._loading;
  }

  set loading(value: boolean) {
    this._loading = value;
  }

  get skeleton() {
    return new Array(5).fill(new OrderModel(defaultOrderEntity));
  }

  get filters() {
    return this._filters;
  }

  get statusFilter() {
    return this._statusFilter;
  }

  set statusFilter(statusFilter: string[]) {
    this._statusFilter = statusFilter;
  }

  get managerOptions() {
    return this._managerOptions;
  }

  set managerOptions(managerOptions: TOption[]) {
    this._managerOptions = managerOptions;
  }

  get responsibleOptions() {
    return this._responsibleOptions;
  }

  set responsibleOptions(responsibleOptions: TOption[]) {
    this._responsibleOptions = responsibleOptions;
  }

  get supplierOptions() {
    return this._supplierOptions;
  }

  set supplierOptions(supplierOptions: TOption[]) {
    this._supplierOptions = supplierOptions;
  }

  get orderTableRowViewModels() {
    return this._orderTableRowViewModels;
  }

  set orderTableRowViewModels(models: OrderTableRowViewModel[]) {
    this._orderTableRowViewModels = models;
  }

  fetchOrders = async () => {
    this.loading = true;
    const { count, items } = await getOrders();
    this.orderTableRowViewModels = items.map(
      (order) => new OrderTableRowViewModel(this, order),
    );
    this.filters.count = count;
    this.loading = false;

    return items;
  };

  refetchOrders = async () => {
    const { page, pageSize, sortBy, sort, search, buyerEmail } = this._filters;

    const params = {
      searchQuery: search,
      buyerEmail: buyerEmail,
      page: page,
      pageSize: pageSize,
      sortBy: sortBy,
      sort: sort,
      status: this._statusFilter,
    };

    this.loading = true;
    const { count, items } = await getOrdersWithFilters(params);

    this.orderTableRowViewModels = items.map(
      (order: IShortOrder) => new OrderTableRowViewModel(this, order),
    );

    this.filters.count = count;
    this.loading = false;

    return items;
  };

  getResponsibleOptions = async () => {
    this.loading = true;
    try {
      const responsibles = await getResponsibleList();

      const formattedResponsibles = responsibles.reduce(
        (acc, curr) => [...acc, { value: curr.email, label: curr.name }],
        [],
      );

      const formattedManagers = responsibles
        .filter((responsible) => responsible.role === EEmployeeRoles.MANAGER)
        .reduce(
          (acc, curr) => [...acc, { value: curr.email, label: curr.name }],
          [],
        );

      this.responsibleOptions = [
        ...this.defaultResponsibleOptions,
        ...formattedResponsibles,
      ];

      this.managerOptions = formattedManagers;
    } catch (e) {
      notify(`Error, reason:${e}`, 'error');
    } finally {
      this.loading = false;
    }
  };

  getSupplierOptions = async () => {
    this.loading = true;
    try {
      const suppliers = await getSupplierList();

      const formattedSuppliers = suppliers.reduce((acc, curr) => {
        const linkedUser = curr.user;
        const linkedUserFullName = combineString([
          linkedUser?.firstName,
          linkedUser?.lastName,
        ]);
        return [
          ...acc,
          {
            value: curr.name,
            label: linkedUser ? linkedUserFullName : curr.name,
          },
        ];
      }, []);

      this.supplierOptions = formattedSuppliers;
    } catch (e) {
      notify(`Error, reason:${e}`, 'error');
    } finally {
      this.loading = false;
    }
  };

  getOrderStatusOptions = async () => {
    try {
      const { data } = await getOrderStatusOptions();

      return data;
    } catch (e) {
      notify(`Error, reason:${e}`, 'error');
    }
  };

  changeManager = async (
    orderId: OrderTableColumn['id'],
    managerEmail: string,
  ) => {
    try {
      this.loading = true;

      const updatedOrder = await changeOrderManager(orderId, managerEmail);

      this.orderTableRowViewModels = this.orderTableRowViewModels.map((el) => {
        if (el.order.id === updatedOrder.id) {
          return new OrderTableRowViewModel(this, updatedOrder);
        }

        return el;
      });
    } catch (e) {
      const error = e as IServerError;

      notify(error.response.data.message, 'error');
    } finally {
      this.loading = false;
    }
  };

  changeOrderStatus = async (
    status: string | string[],
    orderId: OrderTableColumn['id'],
    statusType: string,
  ) => {
    try {
      this.loading = true;

      const statuses = !!status.length ? status : [''];
      const finalStatus = Array.isArray(status) ? statuses : status;

      const updatedOrder = await changeOrderStatus(
        orderId,
        finalStatus,
        statusType,
      );

      this.orderTableRowViewModels = this.orderTableRowViewModels.map((el) => {
        if (el.order.id === updatedOrder.id) {
          return new OrderTableRowViewModel(this, updatedOrder);
        }

        return el;
      });
    } catch (e) {
      const error = e as IServerError;

      notify(error.response.data.message, 'error');
    } finally {
      this.loading = false;
    }
  };

  constructor() {
    this.fetchOrders();
    this.getResponsibleOptions();
    this.getSupplierOptions();

    makeAutoObservable(this);
  }
}
