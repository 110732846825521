import { FC } from 'react';

import { Spin, Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import { HistoryViewModel } from 'pages/NewOrder/view-models/history-view-model';

import { Log } from './Log';

import styles from './history.module.scss';

interface IReturnsProps {
  model: HistoryViewModel | null;
}

export const History: FC<IReturnsProps> = observer(({ model }) => (
  <div className={styles.container}>
    <div className={styles.header}>
      <Typography.Title level={4} className={styles.title}>
        History
      </Typography.Title>
    </div>
    {!model || model.isLoading ? (
      <Spin className={styles.spin} />
    ) : (
      <div className={styles.content}>
        {model.logs.map((log) => (
          <Log
            key={log.id}
            date={log.createdAtView}
            action={log.actionView}
            author={log.authorView}
          />
        ))}
      </div>
    )}
  </div>
));
