import { FormInput, FormTextArea } from 'components/Form';

import styles from './meta-tag-card.module.scss';

const MetaTagCard = () => (
  <div className={styles.metaCardConteiner}>
    <FormInput
      name="metaTitle"
      label="Meta title"
      showCount
      className={styles.field}
    />
    <FormTextArea
      name="metaDescription"
      label="Meta description"
      showCount
      autoSize={{ minRows: 3, maxRows: 5 }}
      className={styles.field}
    />
  </div>
);

export default MetaTagCard;
