import { IDetailedEmployee, IEmployee } from 'common/types/employee';
import { EEmployeeRoles } from 'common/types/employee/enums';

import { IEmployeeFormValues } from './types';

const newEmployeeInitialValues = {
  firstName: '',
  lastName: '',
  role: '',
  email: '',
  password: '',
};

const getEmployeeInitialValues = (employee: IEmployee) => ({
  firstName: employee.firstName,
  lastName: employee.lastName,
  role: employee.role,
  email: employee.email,
  password: '',
});

const getSupplierInitialValues = (employee: IDetailedEmployee) => ({
  firstName: employee.firstName,
  lastName: employee.lastName,
  role: employee.role,
  email: employee.email,
  password: '',
  name: employee.supplier?.name,
  address: employee.supplier?.address,
  phoneNumber: employee.supplier?.phoneNumber,
  bankName: employee.supplier?.bankName,
  swift: employee.supplier?.swift,
  accountNumber: employee.supplier?.accountNumber,
});

export const getInitialValues = (
  isEdit: boolean,
  employee: IDetailedEmployee | null | undefined,
): IEmployeeFormValues => {
  if (isEdit && employee) {
    if (employee.role === EEmployeeRoles.SUPPLIER) {
      return getSupplierInitialValues(employee);
    }

    return getEmployeeInitialValues(employee);
  }

  return newEmployeeInitialValues;
};
