import { FC } from 'react';

import { Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { parseToShortDate } from 'common/utils';
import { cents2dollars } from 'common/utils';
import { Table, customActions, text } from 'components/Table';
import { TableTitle } from 'components/TableTitle';
import { observer } from 'mobx-react-lite';
import { InvoiceModel } from 'store/invoice-model';

import { InvoicesViewModel } from '../invoices-view-model';

import styles from './invoice-table.module.scss';

interface IInvoiceTableProps {
  model: InvoicesViewModel;
  className?: string;
}

const getColumns = (): ColumnsType<InvoiceModel> => [
  text({
    key: 'id',
    width: '150px',
    title: 'Invoice #',
    dataIndex: 'id',
  }),
  text({
    key: 'createdAt',
    width: '150px',
    title: 'Date',
    dataIndex: 'createdAt',
    render: (record: string) => <span>{parseToShortDate(record)}</span>,
  }),
  text({
    key: 'total',
    width: '150px',
    title: 'Sum',
    dataIndex: 'total',
    render: (record: number) => <span>{cents2dollars(record)} $</span>,
  }),
  customActions({
    render: (_string, record) => {
      return (
        <Tag onClick={() => record.getInvoicePDF()} className={styles.tag}>
          Save
        </Tag>
      );
    },
  }),
];

export const InvoiceTable: FC<IInvoiceTableProps> = observer(
  ({ model, className }) => (
    <div className={className}>
      <Table
        title={() => <TableTitle title="Invoice history" />}
        columns={getColumns()}
        data={model.invoices}
        pagination={{
          total: model.filters.count,
          disabled: model.isLoading,
          current: model.filters.page,
        }}
        onChange={model.changeFilters}
        loading={model.isLoading}
        bordered
      />
    </div>
  ),
);
