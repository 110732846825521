import { FC } from 'react';

import TextArea, { TextAreaProps } from 'antd/es/input/TextArea';
import cn from 'clsx';
import { Controller, useFormContext } from 'react-hook-form';

import styles from '../form.module.scss';

export interface IFormTextAreaProps extends TextAreaProps {
  name: string;
  type?: 'text' | 'password';
  label: string;
  labelPosition?: 'top' | 'left';
  required?: boolean;
  className?: string;
}

export const FormTextArea: FC<IFormTextAreaProps> = ({
  name,
  type = 'text',
  label,
  labelPosition = 'top',
  required,
  className,
  ...props
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value, name }, fieldState: { error } }) => {
        return (
          <div
            className={cn(
              styles.container,
              styles.inputWrapper,
              styles[`label-${labelPosition}`],
              className,
            )}
          >
            <label htmlFor={name} className={styles.label}>
              {label}
              {required && <span className={styles.required}>*</span>}
            </label>
            <TextArea
              id={name}
              name={name}
              onChange={onChange}
              value={value}
              status={!!error ? 'error' : ''}
              {...props}
            />
            {!!error && <span className={styles.error}>{error?.message}</span>}
          </div>
        );
      }}
    />
  );
};
