import { FC } from 'react';

import { PlusCircleOutlined } from '@ant-design/icons';
import { AutoComplete, Button } from 'antd';
import Search from 'antd/es/input/Search';
import { FilterValue, SortOrder, SorterResult } from 'antd/es/table/interface';
import { categoryDeleteMessage } from 'common/constants';
import { useQueryParams } from 'common/hook/useQueryParams';
import { IAutocompleteTypeParams, TTablePaginationParams } from 'common/types';
import { replaceHistoryState } from 'common/utils';
import {
  actions,
  image,
  title,
  Table,
  text,
  tag,
  counter,
} from 'components/Table';
import { observer } from 'mobx-react-lite';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { SubcategoryModel } from 'store/subcategory-model';

import { SubcategoryViewModel } from './subcategory-view-model';

import styles from './styles.module.scss';

export interface ISubcategoryProps {
  model: SubcategoryViewModel;
}

const Subcategory: FC<ISubcategoryProps> = observer(({ model }) => {
  const navigate = useNavigate();

  const params = model._filters.params;

  const searchParams = params.search ? { search: params.search } : '';
  const brandParams = params.brandId
    ? { brandTitle: params.brandTitle, brandId: params.brandId }
    : null;

  const navigationSearchParams = `?${createSearchParams({
    ...searchParams,
    page: params.page,
    ...brandParams,
  })}`;

  const create = () => navigate('/category/new');

  const edit = (id: number) =>
    navigate({
      pathname: `/category/${id}`,
      search: navigationSearchParams,
    });

  const onSearch = (value: string) => {
    model.search = value;

    const search = value ? { search: value } : '';

    replaceHistoryState('/category', { page: '1', ...search, ...brandParams });
  };

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.value) {
      model.search = '';

      replaceHistoryState('/category', { page: '1', ...brandParams });
    }
  };

  const handleSelectBrand = (_: string, brand: IAutocompleteTypeParams) => {
    model.selectedBrand = brand;
    params.brandId = String(brand.id);

    const brandParams = brand.id
      ? { brandTitle: brand.value, brandId: String(brand.id) }
      : null;

    replaceHistoryState('/category', {
      page: '1',
      ...searchParams,
      ...brandParams,
    });
  };

  const handleClearBrand = () => {
    model.selectedBrand = null;
    model.searchBrandValue = '';

    model._filters.page = 1;
    model._filters.params = { page: '1', ...searchParams };

    model.fetchSubcategories();

    replaceHistoryState('/category', { page: '1', ...searchParams });
  };

  const handleChangeBrand = (value: string) => {
    model.searchBrandValue = value;

    if (!value) {
      handleClearBrand();
    }
  };

  const columns = [
    image({
      loading: model.loading,
      title: 'Photo',
      width: '5%',
    }),
    title({
      width: '10%',
      title: 'Category',
      dataIndex: 'title',
      key: 'title',
      onClick: edit,
      sorter: true,
      getHref: (record) => `/category/${record.id}${navigationSearchParams}`,
      loading: model.loading,
    }),
    text({
      width: '10%',
      title: 'Brand',
      dataIndex: 'brand.title',
      key: 'brand',
      sorter: true,
      loading: model.loading,
      nested: true,
    }),
    tag({
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      loading: model.loading,
    }),
    counter({
      width: '5%',
      title: 'Products',
      dataIndex: 'productsCount',
      key: 'productsCount',
      loading: model.loading,
    }),
    text({
      width: '7%',
      title: 'Last modified',
      dataIndex: 'updatedAtRender',
      key: 'updatedAt',
      defaultSortOrder: 'descend' as SortOrder | undefined,
      sorter: true,
      loading: model.loading,
    }),
    actions({
      onEdit: edit,
      onDelete: model.removeSubcategory,
      disabled: model.loading,
      deleteMessage: categoryDeleteMessage,
    }),
  ];

  const handleChangeTable = (
    pagination: TTablePaginationParams,
    _: Record<string, FilterValue>,
    sorter: SorterResult<SubcategoryModel>,
  ) => {
    const { current } = pagination;
    model._filters.page = current;

    model._filters.setSorterSortOptions(sorter);

    const search = params.search ? { search: params.search } : '';

    replaceHistoryState('/category', {
      page: `${current}`,
      ...search,
      ...brandParams,
    });

    model.fetchSubcategories();
  };

  return (
    <div className={styles.subcategoryPage}>
      <div className={styles.header}>
        <div className={styles.filters}>
          <Search
            placeholder="Search categories"
            onSearch={onSearch}
            defaultValue={model._filters.search}
            className={styles.search}
            allowClear
            onChange={handleChangeSearch}
          />
          <AutoComplete
            options={model.brandOptions}
            placeholder="Filter by brand"
            onSelect={handleSelectBrand}
            allowClear
            value={model.searchBrandValue}
            onClear={handleClearBrand}
            onChange={handleChangeBrand}
            className={styles.search}
          />
        </div>
        <Button
          type="primary"
          icon={<PlusCircleOutlined />}
          size="middle"
          onClick={create}
        >
          Add Category
        </Button>
      </div>
      <Table
        bordered
        loading={model.loading}
        skeleton={model.skeleton}
        data={model.subcategories}
        columns={columns}
        pagination={{
          total: model.count,
          disabled: model.loading,
          current: model._filters.defaultCurrent,
        }}
        onChange={handleChangeTable}
      />
    </div>
  );
});

export const SubcategoryPage = () => {
  const params = useQueryParams();

  const model = new SubcategoryViewModel({ ...params, sortBy: 'updatedAt' });

  return <Subcategory model={model} />;
};
