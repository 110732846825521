import { FC, useState, useRef, useEffect, useCallback } from 'react';

import cn from 'clsx';
import { TPhoto } from 'common/types';
import { Image } from 'components/Image';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useFormContext } from 'react-hook-form';

import styles from './dnd-images-list.module.scss';

export interface IDndImagesListProps {
  name: string;
  className?: string;
  photos: TPhoto[];
}

export interface IListItemProps {
  image: TPhoto;
  moveImage: (dragIndex: number, hoverIndex: number) => void;
  index: number;
  handleDelete: (id: number) => void;
}

const ListItem: FC<IListItemProps> = ({
  image,
  index,
  moveImage,
  handleDelete,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [, drop] = useDrop({
    accept: 'image',
    hover: (item: { id: number; index: number }) => {
      requestAnimationFrame(() => {
        if (ref.current) {
          const dragIndex = item.index;
          const hoverIndex = index;
          if (dragIndex === hoverIndex) {
            return;
          }
          moveImage(dragIndex, hoverIndex);
          item.index = hoverIndex;
        }
      });
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: 'image',
    item: () => {
      return { id: image.id, index };
    },
    collect: (monitor) => {
      return {
        isDragging: monitor.isDragging(),
      };
    },
  });
  drag(drop(ref));
  return (
    <div
      ref={ref}
      className={cn(styles.imageContainer, {
        [styles.opacity]: isDragging,
      })}
    >
      <Image id={image.id} url={image.url} handleDelete={handleDelete} zoom />
    </div>
  );
};

const ImagesListContent: FC<IDndImagesListProps> = ({
  name,
  photos,
  className,
}) => {
  const { setValue, getValues } = useFormContext();

  const [imgs, setImgs] = useState<TPhoto[]>(photos);
  const moveImage = useCallback((dragIndex: number, hoverIndex: number) => {
    setImgs((originalImages) => {
      const clonedCards = [...originalImages];
      const removedItem = clonedCards.splice(dragIndex, 1)[0];
      clonedCards.splice(hoverIndex, 0, removedItem);
      return clonedCards;
    });
  }, []);

  const handleDelete = (id: number) => {
    const values = getValues(name) as TPhoto[];
    setValue(
      name,
      values.filter((value) => value.id !== id),
    );
  };

  useEffect(() => {
    if (photos.length !== imgs.length) {
      setImgs(photos);
    }
  }, [photos, imgs]);

  useEffect(() => {
    setValue(name, imgs);
  }, [imgs]);

  return (
    <div className={className}>
      {imgs.map((image, index) => (
        <ListItem
          key={image.id}
          image={image}
          moveImage={moveImage}
          index={index}
          handleDelete={handleDelete}
        />
      ))}
    </div>
  );
};

export const DndImagesList: FC<IDndImagesListProps> = ({
  photos,
  name,
  className,
}) => (
  <DndProvider backend={HTML5Backend}>
    <ImagesListContent photos={photos} className={className} name={name} />
  </DndProvider>
);
