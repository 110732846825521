import { FC } from 'react';

import { Checkbox } from 'antd';
import { CheckboxChangeEvent, CheckboxProps } from 'antd/es/checkbox';
import cn from 'clsx';
import { Controller, useFormContext } from 'react-hook-form';

import styles from './checkbox.module.scss';

export interface IFormCheckbox extends CheckboxProps {
  name: string;
  label?: string;
  labelPosition?: 'top' | 'right';
  required?: boolean;
  className?: string;
}

export const FormCheckbox: FC<IFormCheckbox> = ({
  name,
  label,
  labelPosition = 'top',
  required,
  className,
  onChange,
  ...props
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange: internalChange, value, name },
        fieldState: { error },
      }) => {
        const handleChange = (e: CheckboxChangeEvent) => {
          internalChange(e);
          onChange?.(e);
        };

        return (
          <div
            className={cn(
              styles.container,
              styles[`label-${labelPosition}`],
              className,
            )}
          >
            {!!label && (
              <label htmlFor={name} className={styles.label}>
                {label}
                {required && <span className={styles.required}>*</span>}
              </label>
            )}
            <Checkbox
              id={name}
              onChange={handleChange}
              checked={value}
              name={name}
              {...props}
            />
            {!!error && <div className={styles.error}>{error?.message}</div>}
          </div>
        );
      }}
    />
  );
};
