import { SorterResult } from 'antd/es/table/interface';
import { action, computed, makeObservable, observable } from 'mobx';

export type TParams = Record<string, string | any>;
export type TFilters = Record<string, string[] | null>;

export class FiltersModel<T extends TParams = TParams> {
  _filters: TFilters = {};
  _pageSize = 50;
  _count: number;
  _page: number;
  _search: string;
  _params: T;
  _sort: string;
  _sortBy: string;
  _refresh: () => void;

  get filters() {
    return this._filters;
  }

  set filters(filters: TFilters) {
    this._filters = filters;
  }

  get pageSize() {
    return this._pageSize;
  }

  set pageSize(pageSize: number) {
    this._pageSize = pageSize;
  }

  get count() {
    return this._count;
  }

  set count(count: number) {
    this._count = count;
  }

  get page() {
    return this._page;
  }

  set page(page: number) {
    this._page = page;

    this._params = { ...this.params, page: String(page) };
  }

  get search() {
    return this._search;
  }

  set search(value: string) {
    this._search = value;
    this._page = 1;

    this._params = { ...this.params, search: value };
  }

  get params() {
    return this._params;
  }

  set params(params: T) {
    this._params = params;
  }

  get sort() {
    return this._sort;
  }

  set sort(sort: string) {
    this._sort = sort;
  }

  get sortBy() {
    return this._sortBy;
  }

  set sortBy(sortBy: string) {
    this._sortBy = sortBy;
  }

  get refresh() {
    return this._refresh;
  }

  set refresh(refresh: () => void) {
    this._refresh = refresh;
  }

  get defaultCurrent() {
    return this.page;
  }

  setSorterSortOptions = (sorter: SorterResult<unknown>) => {
    if (sorter.order) {
      if (sorter.order === 'ascend') {
        this.sort = 'ASC';
      } else {
        this.sort = 'DESC';
      }

      this.sortBy = (sorter?.column?.key ?? 'updatedAt') as string;
    } else {
      this.sort = 'DESC';
      this.sortBy = 'updatedAt';
    }
  };

  setSortOptions = (sortBy: string, sort: string) => {
    this._sortBy = sortBy;
    this._sort = sort;
  };

  constructor(refreshData?: () => void, params?: T) {
    this._params = params || ({} as T);
    this._count = Number(params?.count) || 1;
    this._page = Number(params?.page) || 1;
    this._search = params?.search || '';
    this._sort = params?.sort || 'DESC';
    this._sortBy = params?.sortBy || 'createdAt';
    this._refresh = () => null;

    if (refreshData) {
      this._refresh = refreshData;
    }

    makeObservable(this, {
      _filters: observable,
      _pageSize: observable,
      _count: observable,
      _page: observable,
      _search: observable,
      _params: observable,
      _sort: observable,
      _sortBy: observable,
      _refresh: observable,
      filters: computed,
      pageSize: computed,
      count: computed,
      page: computed,
      search: computed,
      params: computed,
      sort: computed,
      sortBy: computed,
      refresh: computed,
      defaultCurrent: computed,
      setSorterSortOptions: action,
      setSortOptions: action,
    });
  }
}
