import { FC } from 'react';

import { Button } from 'antd';
import { useModal } from 'common/utils';
import { observer } from 'mobx-react-lite';

import { OrderExpandedViewModel } from '../../../order-expanded-view-model';

import { CancelModal } from './CancelModal';

interface ICancelButton {
  model: OrderExpandedViewModel;
}

export const CancelButton: FC<ICancelButton> = observer(({ model }) => {
  const [isOpen, openModal, closeModal] = useModal(false);

  return (
    <>
      <Button
        loading={model.loading}
        onClick={openModal}
        disabled={model.order.disabled}
      >
        Cancel
      </Button>
      <CancelModal isOpen={isOpen} model={model} closeModal={closeModal} />
    </>
  );
});
