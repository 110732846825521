import { AxiosResponse } from 'axios';
import {
  IKit,
  IKitParams,
  INewKitParams,
  IKits,
  TKitTypes,
} from 'common/types/kit';

import { $host } from './index';

export const getKitProductTypes = async () => {
  const {
    data: { data },
  } = await $host.get<AxiosResponse<TKitTypes>>(
    'admin/product-kits/product-types',
  );

  return data;
};

export const getProductKits = async (params: IKitParams) => {
  const {
    data: { data },
  } = await $host.get<AxiosResponse<IKits>>('admin/product-kits', {
    params,
  });

  return data;
};

export const addProductKit = async (params: INewKitParams) => {
  const {
    data: { data },
  } = await $host.post<AxiosResponse<IKit>>('admin/product-kits', params);

  return data;
};

export const editProductKit = async (id: number, params: INewKitParams) => {
  const {
    data: { data },
  } = await $host.put<AxiosResponse<IKit>>(`admin/product-kits/${id}`, params);

  return data;
};

export const removeProductKit = async (id: number) => {
  const {
    data: { data },
  } = await $host.delete<AxiosResponse<IKit[]>>(`admin/product-kits/${id}`);

  return data;
};
