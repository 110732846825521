import { getResponsibleList, getSupplierList } from 'http/employeeApi';
import { getOrders, getOrderStatusOptions } from 'http/orderApi';

import { TablePaginationConfig } from 'antd';
import {
  reasonDefaultOptions,
  responsibleDefaultOptions,
  returnReasonDefaultOptions,
  searchTypeOptions,
  shipmentReasonDefaultOptions,
} from 'common/constants';
import {
  IOrderStatusOption,
  IPageParams,
  IServerError,
  TOption,
} from 'common/types';
import { IResponsible } from 'common/types/employee';
import { EEmployeeRoles } from 'common/types/employee/enums';
import {
  EPaymentStatuses,
  EPurchaseStatuses,
  ESendingStatuses,
} from 'common/types/order/enums';
import { ISupplier } from 'common/types/supplier';
import { combineString, compareArraysOfPrimitives, notify } from 'common/utils';
import { makeAutoObservable } from 'mobx';
import { CurrentUserModel } from 'store/current-user-model';
import { FiltersModel } from 'store/filters-model';

import { OrderViewModel } from './order-view-model';

interface IFiltersParams {
  searchType: string;
  searchQuery: string;
  status: string[];
}

type TOrderStatusPreset =
  | 'all'
  | 'awaiting_purchase'
  | 'awaiting_fulfilling'
  | 'awaiting_payment';

const orderStatusPresets = [
  {
    name: 'all',
    status: [],
  },
  {
    name: 'awaiting_purchase',
    status: [
      EPurchaseStatuses.PURCHASE_NOT_PURCHASED,
      EPurchaseStatuses.PURCHASE_PARTIALLY_PURCHASED,
    ],
  },
  {
    name: 'awaiting_fulfilling',
    status: [
      ESendingStatuses.SENDING_NOT_SENT,
      ESendingStatuses.SENDING_PARTIALLY_SENT,
    ],
  },
  {
    name: 'awaiting_payment',
    status: [EPaymentStatuses.PAYMENT_PARTIALLY_PAID],
  },
];

export class OrdersViewModel {
  _isLoading = false;
  _user: CurrentUserModel;
  _filters: FiltersModel<IFiltersParams> = new FiltersModel(undefined, {
    searchType: 'ORDER_NUMBER',
    searchQuery: '',
    status: [],
  });
  _searchTypeOptions = searchTypeOptions;
  _managerOptions: TOption[] = [];
  _defaultResponsibleOptions = responsibleDefaultOptions;
  _responsibleOptions: TOption[] = [];
  _defaultReasonOptions = reasonDefaultOptions;
  _defaultShipmentReasonOptions = shipmentReasonDefaultOptions;
  _defaultReturnReasonOptions = returnReasonDefaultOptions;
  _supplierOptions: TOption[] = [];
  _orderStatusOptions: IOrderStatusOption[] = [];
  _orderViewModels: OrderViewModel[] = [];

  _getFormattedResponsibleOptions = (responsibles: IResponsible[]) =>
    responsibles.reduce(
      (acc, curr) => [...acc, { value: curr.email, label: curr.name }],
      [],
    );

  _getFormattedManagersOptions = (responsibles: IResponsible[]) =>
    responsibles
      .filter((responsible) => responsible.role === EEmployeeRoles.MANAGER)
      .reduce(
        (acc, curr) => [...acc, { value: curr.email, label: curr.name }],
        [],
      );

  _getFormattedSuppliersOptions = (suppliers: ISupplier[]) =>
    suppliers.reduce((acc, curr) => {
      const linkedUser = curr.user;
      const linkedUserFullName = combineString([
        linkedUser?.firstName,
        linkedUser?.lastName,
      ]);
      return [
        ...acc,
        {
          value: curr.name,
          label: linkedUser ? linkedUserFullName : curr.name,
        },
      ];
    }, []);

  get isLoading() {
    return this._isLoading;
  }

  set isLoading(value: boolean) {
    this._isLoading = value;
  }

  get user() {
    return this._user;
  }

  get filters() {
    return this._filters;
  }

  get orderStatusPreset(): TOrderStatusPreset | undefined {
    return orderStatusPresets.find((preset) =>
      compareArraysOfPrimitives(preset.status, this.filters.params.status),
    )?.name as TOrderStatusPreset | undefined;
  }

  get searchTypeOptions() {
    return this._searchTypeOptions;
  }

  get managerOptions() {
    return this._managerOptions;
  }

  set managerOptions(managerOptions: TOption[]) {
    this._managerOptions = managerOptions;
  }

  get defaultResponsibleOptions() {
    return this._defaultResponsibleOptions;
  }

  get responsibleOptions() {
    return this._responsibleOptions;
  }

  set responsibleOptions(responsibleOptions: TOption[]) {
    this._responsibleOptions = responsibleOptions;
  }

  get defaultReasonOptions() {
    return this._defaultReasonOptions;
  }

  get defaultShipmentReasonOptions() {
    return this._defaultShipmentReasonOptions;
  }

  get defaultReturnReasonOptions() {
    return this._defaultReturnReasonOptions;
  }

  get supplierOptions() {
    return this._supplierOptions;
  }

  set supplierOptions(supplierOptions: TOption[]) {
    this._supplierOptions = supplierOptions;
  }

  get orderStatusOptions() {
    return this._orderStatusOptions;
  }

  set orderStatusOptions(orderStatusOptions: IOrderStatusOption[]) {
    this._orderStatusOptions = orderStatusOptions;
  }

  get orderViewModels() {
    return this._orderViewModels;
  }

  set orderViewModels(models: OrderViewModel[]) {
    this._orderViewModels = models;
  }

  getOrders = async (params?: IPageParams, handling?: boolean) => {
    handling && (this.isLoading = true);

    try {
      const { items, count } = await getOrders(params);

      this.filters.count = count;
      this.orderViewModels = items.map(
        (order) => new OrderViewModel(this, order),
      );
    } catch (e) {
      if (!handling) {
        throw e;
      }

      const error = e as IServerError;

      notify(`Error, reason: ${error.response?.data?.message}`, 'error');
    } finally {
      handling && (this.isLoading = false);
    }
  };

  getResponsibleOptions = async (handling?: boolean) => {
    try {
      handling && (this.isLoading = true);

      const responsibles = await getResponsibleList();

      const formattedResponsibles =
        this._getFormattedResponsibleOptions(responsibles);

      const formattedManagers = this._getFormattedManagersOptions(responsibles);

      this.responsibleOptions = [
        ...this.defaultResponsibleOptions,
        ...formattedResponsibles,
      ];

      this.managerOptions = formattedManagers;
    } catch (e) {
      if (!handling) {
        throw e;
      }

      const error = e as IServerError;

      notify(`Error, reason: ${error.response?.data?.message}`, 'error');
    } finally {
      handling && (this.isLoading = false);
    }
  };

  getSupplierOptions = async (handling?: boolean) => {
    handling && (this.isLoading = true);

    try {
      const suppliers = await getSupplierList();

      const formattedSuppliers = this._getFormattedSuppliersOptions(suppliers);

      this.supplierOptions = formattedSuppliers;
    } catch (e) {
      if (!handling) {
        throw e;
      }
      const error = e as IServerError;

      notify(`Error, reason: ${error.response?.data?.message}`, 'error');
    } finally {
      handling && (this.isLoading = false);
    }
  };

  getOrderStatusOptions = async (handling?: boolean) => {
    handling && (this.isLoading = true);

    try {
      const { data } = await getOrderStatusOptions();

      this.orderStatusOptions = data;
    } catch (e) {
      if (!handling) {
        throw e;
      }
      const error = e as IServerError;

      notify(`Error, reason: ${error.response?.data?.message}`, 'error');
    } finally {
      handling && (this.isLoading = false);
    }
  };

  fetchData = async () => {
    this.isLoading = true;

    try {
      await Promise.all([
        this.getOrders(),
        this.getResponsibleOptions(),
        this.getSupplierOptions(),
        this.getOrderStatusOptions(),
      ]);
    } catch (e) {
      const error = e as IServerError;

      notify(`Error, reason: ${error.response?.data?.message}`, 'error');
    } finally {
      this.isLoading = false;
    }
  };

  onSearchTypeOptionChange = (values: string) => {
    this.filters.params.searchType = values;
  };

  onSearchChange = (value: string) => {
    this.filters.params.searchQuery = value;
  };

  onSearch = async (value: string) => {
    this.filters.params.searchQuery = value;
    this.filters.page = 1;

    const params = {
      page: 1,
      ...this.filters.params,
    };

    await this.getOrders(params, true);
  };

  onOrderStatusOptionChange = async (values: string[]) => {
    this.filters.params.status = values;
    this.filters.page = 1;

    const params = {
      page: 1,
      pageSize: this.filters.pageSize,
      ...this.filters.params,
    };

    await this.getOrders(params, true);
  };

  onOrderStatusPresetChange = async (preset: TOrderStatusPreset) => {
    this.filters.params.status =
      orderStatusPresets.find((statusPreset) => statusPreset.name === preset)
        ?.status ?? [];

    this.filters.page = 1;

    const params = {
      page: 1,
      pageSize: this.filters.pageSize,
      ...this.filters.params,
    };

    await this.getOrders(params, true);
  };

  onTableChange = async (pagination: TablePaginationConfig) => {
    const { current, pageSize } = pagination;

    this.filters.page = current!;
    this.filters.pageSize = pageSize!;

    const params = {
      page: this.filters.page,
      pageSize: this.filters.pageSize,
      ...this.filters.params,
    };

    await this.getOrders(params, true);
  };

  constructor(user: CurrentUserModel) {
    this._user = user;

    this.fetchData();

    makeAutoObservable(this);
  }
}
