import {
  getBulkUpdateStatus,
  startBulkPriceUpdate,
} from 'http/bulkPriceUpdate';
import { getProductAutocompleteByType } from 'http/productApi';

import { BaseOptionType } from 'antd/es/select';
import { IAutocompleteSubcategoryTypeParams } from 'common/types';
import type { IPriceUpdateError } from 'common/types/bulkPriceUpdate';
import {
  IReactionDisposer,
  action,
  computed,
  makeObservable,
  observable,
} from 'mobx';

export interface IBuilUpdateValues {
  action: 'increase' | 'decrease';
  amount: number;
  brand: string;
}

let interval: NodeJS.Timer | null = null;

type TStatus = 'progress' | 'idle';

export class BulkPriceUpdateModel {
  loading = false;
  percentage: number | null = null;
  status: TStatus = 'progress';
  errors: IPriceUpdateError[] = [];
  dispose: IReactionDisposer | null = null;
  brands: BaseOptionType[] = [];
  lastStartDate: string | null = null;
  lastCompleteDate: string | null = null;

  get changeDisabled() {
    return !!(this.loading || this.status === 'progress');
  }

  handleUpdate = async (data: IBuilUpdateValues) => {
    this.loading = true;

    const brand = this.brands.find((brand) => brand.value === data.brand);

    await startBulkPriceUpdate({
      coef: parseFloat(
        (
          (100 + (data.action === 'increase' ? 1 : -1) * data.amount) /
          100
        ).toFixed(2),
      ),
      brandId: +brand?.id,
    });

    this.percentage = 0;
    this.status = 'progress';
    this.loading = false;
    await this.startCheck();
    this.autorun();
  };

  clear = () => {
    interval && clearInterval(interval);
    interval = null;
  };

  autorun = () => {
    interval = setInterval(this.startCheck, 5000);
  };

  startCheck = async () => {
    if (this.status === 'idle') {
      this.clear();
      return;
    }

    const [result] = await getBulkUpdateStatus();

    this.status = result.finished ? 'idle' : 'progress';

    this.percentage = result.finished
      ? null
      : parseFloat(((result.processed * 100) / result.totalRows).toFixed(2));

    this.errors = result.priceAdjustmentErrors;

    this.lastStartDate = result.createdAt;
    this.lastCompleteDate = result.finishedAt;

    return this.status;
  };

  checkStatus = async () => {
    const status = await this.startCheck();

    if (status === 'progress') {
      this.autorun();
    }
  };

  loadCategories = async () => {
    const brands = (await getProductAutocompleteByType(
      'brands',
    )) as IAutocompleteSubcategoryTypeParams[];

    this.brands = brands.map((option) => ({
      value: `${option.value}`,
      label: option.value,
      id: option.id,
    }));
  };

  constructor() {
    this.checkStatus();
    this.loadCategories();

    makeObservable(this, {
      loading: observable,
      status: observable,
      percentage: observable,
      brands: observable,
      dispose: observable,
      lastStartDate: observable,
      lastCompleteDate: observable,
      changeDisabled: computed,
      handleUpdate: action,
      clear: action,
    });
  }
}
