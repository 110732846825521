import { FC } from 'react';

import { Modal } from 'antd';
import { Form, FormInput } from 'components/Form';
import { observer } from 'mobx-react-lite';

export interface IField {
  label: string;
  name: string;
}

export interface IEditModal {
  id: string;
  open: boolean;
  initialValues: any;
  validation: any;
  fields: IField[];
  isLoading?: boolean;
  onOk: (values: any) => Promise<void>;
  onCancel: () => void;
}

export const EditModal: FC<IEditModal> = observer(
  ({
    id,
    open,
    initialValues,
    validation,
    fields,
    isLoading,
    onOk,
    onCancel,
  }) => (
    <Modal
      open={open}
      okButtonProps={{ htmlType: 'submit', form: id, loading: isLoading }}
      onCancel={onCancel}
      title="Edit"
      destroyOnClose
    >
      <Form
        id={id}
        initialValues={initialValues}
        validation={validation}
        onSubmit={(values) => {
          onOk(values);
          onCancel();
        }}
      >
        {fields.map((field) => (
          <FormInput key={field.name} label={field.label} name={field.name} />
        ))}
      </Form>
    </Modal>
  ),
);
