import { FC } from 'react';

import { IShipment } from 'common/types/shipment';
import { cents2dollars } from 'common/utils';

import styles from './static-data.module.scss';

interface IStaticDataProps {
  shipment: IShipment;
}

export const StaticData: FC<IStaticDataProps> = ({ shipment }) => {
  const trackLink = 'natalexauto.aftership.com/' + shipment.trackingNumber;

  return (
    <div>
      <div className={styles.info}>
        <div className={styles.infoItem}>
          <span>Supplier Id:&nbsp;</span>
          <span>{shipment.supId}</span>
        </div>
        <div className={styles.infoItem}>
          {shipment.trackingNumber ? (
            <a
              className={styles.trackLink}
              href={`//${trackLink}`}
              target="_blank"
              rel="noreferrer"
            >
              {trackLink}
            </a>
          ) : (
            <span>Tracking #:&nbsp;</span>
          )}
        </div>
        <div className={styles.infoItem}>
          <span>Cost of Purchase:&nbsp;</span>
          <span>{cents2dollars(shipment.costOfPurchase)}</span>
        </div>
        <div className={styles.infoItem}>
          <span>Cost of Shipping:&nbsp;</span>
          <span>{cents2dollars(shipment.costOfShipping)}</span>
        </div>
      </div>
      <div className={styles.comment}>
        <span>Comment:&nbsp;</span>
        <span>{shipment.comment?.value}</span>
      </div>
    </div>
  );
};
