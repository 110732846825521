import { FC, useEffect, useMemo } from 'react';

import { UserViewModel } from './user-view-model';
import { UserTable } from './UserTable';

import styles from './users.module.scss';

interface IUserPageContentProps {
  model: UserViewModel;
}

const UserPageContent: FC<IUserPageContentProps> = ({ model }) => (
  <div className={styles.content}>
    <UserTable model={model} />
  </div>
);

export const UsersPage = () => {
  const userViewModel = useMemo(() => new UserViewModel(), []);

  useEffect(() => {
    userViewModel.getUsers();
  }, [userViewModel]);

  return <UserPageContent model={userViewModel} />;
};
