import { FC } from 'react';

import { Typography } from 'antd';

import styles from './stat-item.module.scss';

export interface IStatItemProps {
  label: string;
  value?: string | number;
}

export const StatItem: FC<IStatItemProps> = ({ label, value }) => (
  <div className={styles.container}>
    <Typography.Text strong>{label}:</Typography.Text>
    <Typography.Text>{value ?? '-'}</Typography.Text>
  </div>
);
