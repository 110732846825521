import { FC } from 'react';

import { Button } from 'antd';
import { returnReasonDefaultOptions } from 'common/constants';
import { IReturnShipmentItem, TOption } from 'common/types';
import { cents2dollars } from 'common/utils';
import { returnValidationSchema } from 'common/validation';
import { FormInput, FormSelect } from 'components/Form';
import { Form } from 'components/Form';
import { useFormContext } from 'react-hook-form';
import { ReturnShipmentModel } from 'store/return-shipment-model';

import styles from './editable-data.module.scss';

interface IEditableDataProps {
  returnItem: ReturnShipmentModel;
  disabled?: boolean;
  handleEdit: (
    id: number,
    items: IReturnShipmentItem[],
    costOfGoods: number,
    supplier: string,
    responsible: string,
    reason: string,
  ) => void;
  toggleEdit: () => void;
  supplierOptions: TOption[];
  responsibleOptions: TOption[];
}

interface IFormButtons {
  onDiscard: () => void;
}

const FormButtons: FC<IFormButtons> = ({ onDiscard }) => {
  const {
    formState: { isDirty, isValid },
  } = useFormContext();

  return (
    <div className={styles.buttons}>
      <Button htmlType="submit" disabled={!isDirty || !isValid}>
        Save
      </Button>
      <Button htmlType="button" onClick={onDiscard}>
        Discard
      </Button>
    </div>
  );
};

export const EditableData: FC<IEditableDataProps> = ({
  returnItem,
  disabled,
  handleEdit,
  toggleEdit,
  supplierOptions,
  responsibleOptions,
}) => {
  const initialValues = {
    costOfGoods: cents2dollars(returnItem.costOfGoods),
    supplier: returnItem.supplier.name,
    responsible: returnItem.responsible,
    reason: returnItem.reason,
  };

  const handleSubmit = async (values: {
    costOfGoods: number;
    supplier: string;
    responsible: string;
    reason: string;
  }) => {
    handleEdit(
      returnItem.id,
      returnItem.items,
      values.costOfGoods,
      values.supplier,
      values.responsible,
      values.reason,
    );

    toggleEdit();
  };

  return (
    <Form
      initialValues={initialValues}
      validation={returnValidationSchema}
      onSubmit={handleSubmit}
      className={styles.container}
    >
      <div className={styles.content}>
        <div className={styles.item}>
          <span>Cost of return:&nbsp;</span>
          <span>{cents2dollars(returnItem.costOfReturn)}</span>
        </div>
        <FormInput
          min={0}
          label="Cost of goods"
          name="costOfGoods"
          defaultValue={cents2dollars(returnItem.costOfGoods)}
          disabled={disabled}
          className={styles.field}
        />
        <FormSelect
          label="Supplier"
          name="supplier"
          defaultValue={returnItem.supplier}
          options={supplierOptions}
          disabled={disabled}
          className={styles.field}
        />
        <FormSelect
          label="Responsible"
          name="responsible"
          defaultValue={returnItem.responsible}
          options={responsibleOptions}
          disabled={disabled}
          className={styles.field}
        />
        <FormSelect
          label="Reason"
          name="reason"
          options={returnReasonDefaultOptions}
          disabled={disabled}
          className={styles.field}
        />
      </div>
      <FormButtons onDiscard={toggleEdit} />
    </Form>
  );
};
