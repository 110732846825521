export enum EReturnStatusEnum {
  EMPTY = 'EMPTY',
  RETURN_STARTED = 'RETURN_STARTED',
  STANDARD_RETURN_FINISHED = 'STANDARD_RETURN_FINISHED',
  EASY_RETURN_FINISHED = 'EASY_RETURN_FINISHED',
}

export enum EDisputeStatuses {
  DISPUTE_EMPTY = 'DISPUTE_EMPTY',
  DISPUTE_STRIPE_DISPUTE_STARTED = 'DISPUTE_STRIPE_DISPUTE_STARTED',
  DISPUTE_STRIPE_DISPUTE_REVIEW = 'DISPUTE_STRIPE_DISPUTE_REVIEW',
  DISPUTE_STRIPE_DISPUTE_WON = 'DISPUTE_STRIPE_DISPUTE_WON',
  DISPUTE_STRIPE_DISPUTE_LOST = 'DISPUTE_STRIPE_DISPUTE_LOST',
}

export enum EToDoStatuses {
  TODO_SPECIFY_ADDRESS = 'TODO_SPECIFY_ADDRESS',
  TODO_REPLACEMENT_REQUEST = 'TODO_REPLACEMENT_REQUEST',
  TODO_LISTING_REQUEST = 'TODO_LISTING_REQUEST',
  TODO_ORDER_SPECIFICATION = 'TODO_ORDER_SPECIFICATION',
  TODO_REQUEST_AN_INVOICE = 'TODO_REQUEST_AN_INVOICE',
}

export enum EPaymentStatuses {
  PAYMENT_NOT_PAID = 'PAYMENT_NOT_PAID', // FE only
  PAYMENT_PARTIALLY_PAID = 'PAYMENT_PARTIALLY_PAID',
  PAYMENT_PAID = 'PAYMENT_PAID',
  PAYMENT_OVERPAYMENT = 'PAYMENT_OVERPAYMENT',
}

export enum EPaymentCancelledStatuses {
  PAYMENT_CANCELLED_FRAUDULENT_ORDER = 'PAYMENT_CANCELLED_FRAUDULENT_ORDER',
  PAYMENT_CANCELLED_BY_CUSTOMER = 'PAYMENT_CANCELLED_BY_CUSTOMER',
  PAYMENT_CANCELLED_OUT_OF_STOCK = 'PAYMENT_CANCELLED_OUT_OF_STOCK',
  PAYMENT_CANCELLED_UNABLE_TO_SEND = 'PAYMENT_CANCELLED_UNABLE_TO_SEND',
}

export enum EPurchaseStatuses {
  PURCHASE_NOT_PURCHASED = 'PURCHASE_NOT_PURCHASED',
  PURCHASE_PURCHASED = 'PURCHASE_PURCHASED',
  PURCHASE_PARTIALLY_PURCHASED = 'PURCHASE_PARTIALLY_PURCHASED',
}

export enum ESendingStatuses {
  SENDING_NOT_SENT = 'SENDING_NOT_SENT',
  SENDING_PARTIALLY_SENT = 'SENDING_PARTIALLY_SENT',
  SENDING_SENT = 'SENDING_SENT',
}

export enum EDeliveryStatuses {
  DELIVERY_NOT_DELIVERED = 'DELIVERY_NOT_DELIVERED', // FE only
  DELIVERY_PARTIALLY_DELIVERED = 'DELIVERY_PARTIALLY_DELIVERED',
  DELIVERY_DELIVERED = 'DELIVERY_DELIVERED',
}
