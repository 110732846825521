import { AxiosResponse } from 'axios';
import { IReturnShipment } from 'common/types';
import { EResponsibles } from 'common/types/enums';
import { EReturnReasons } from 'common/types/return/enums';
import { ESuppliers } from 'common/types/shipment/enums';

import { $host } from './index';

type IReturnItems = {
  [key: string]: number;
};

interface IEditParams {
  orderId: number;
  items?: IReturnItems;
  productKits?: number[];
  supplier: ESuppliers;
  responsible: EResponsibles;
  reason: EReturnReasons;
  costOfGoods: number;
  trackingNumber: string;
  comment: string;
}

export const createReturnShipment = async (params: IEditParams) => {
  const {
    data: { data },
  } = await $host.post<AxiosResponse<IReturnShipment>>('admin/returns', params);

  return data;
};

export const finishReturnShipment = async (
  shipmentId: number,
  easy: boolean,
) => {
  const path = easy ? 'easy-return' : 'standard-return';
  const {
    data: { data },
  } = await $host.patch<AxiosResponse<IReturnShipment>>(
    `admin/returns/${shipmentId}/${path}`,
  );

  return data;
};

export const deleteReturnShipment = async (shipmentId: number) => {
  const {
    data: { data },
  } = await $host.delete<AxiosResponse<IReturnShipment>>(
    `admin/returns/${shipmentId}`,
  );

  return data;
};

export const editReturnShipment = async (
  shipmentId: number,
  params: {
    [key: string]:
      | string
      | number
      | null
      | undefined
      | Record<number, number | undefined>;
  },
) => {
  const {
    data: { data },
  } = await $host.patch<AxiosResponse<IReturnShipment>>(
    `admin/returns/${shipmentId}`,
    params,
  );

  return data;
};

export const editReturnComment = async (
  shipmentId: number,
  params: {
    [key: string]:
      | string
      | number
      | undefined
      | Record<number, number | undefined>;
  },
) => {
  const {
    data: { data },
  } = await $host.put<AxiosResponse<IReturnShipment>>(
    `/admin/returns/${shipmentId}/comment`,
    params,
  );

  return data;
};
