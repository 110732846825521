import { FC } from 'react';

import { Button, Modal } from 'antd';
import { ActiveStatusEnum } from 'common/types/enums';
import { TPromoCreateData, TPromoParams } from 'common/types/promocode';
import { PromoTypeEnum } from 'common/types/promocode/enums';
import { cents2dollars, dollars2cents } from 'common/utils';
import { promocodeValidationSchema } from 'common/validation';
import { Form } from 'components/Form';
import { PromopcodeViewModel } from 'pages/Promocode/promocode-view-model';
import { PromocodeModel } from 'store/promocode-model';

import { FormContent } from './FormContent';

import styles from './promo-create-modal.module.scss';

interface IPromoCreateModalProps {
  closeModal: () => void;
  editablePromo: PromocodeModel | null;
  model: PromopcodeViewModel;
  isOpen: boolean;
}

export const PromoCreateModal: FC<IPromoCreateModalProps> = ({
  closeModal,
  editablePromo,
  model,
  isOpen,
}) => {
  const havePercentage = editablePromo?.promoType === PromoTypeEnum.PERCENTAGE;
  const discountValue =
    editablePromo?.discount &&
    (havePercentage
      ? editablePromo.discount
      : cents2dollars(editablePromo.discount));

  const promoInitialValues: TPromoCreateData = {
    discount: discountValue || 0,
    purchaseSum: editablePromo?.purchaseSum || 0,
    promocode: editablePromo?.promocode || '',
    promoType: editablePromo?.promoType || null,
    isLimited: editablePromo?.isLimited || false,
    activeStatus: editablePromo?.isActive
      ? ActiveStatusEnum.Active
      : ActiveStatusEnum.Inactive,
    usesLeft:
      (editablePromo?.isLimited &&
        typeof editablePromo?.usesLeft === 'number' &&
        editablePromo.usesLeft) ||
      1,
  };

  const mode = editablePromo ? 'Change' : 'Add';

  const onSubmit = (values: TPromoCreateData) => {
    const formattedValues: TPromoParams = {
      purchaseSum: dollars2cents(values.purchaseSum),
      discount:
        values.promoType === PromoTypeEnum.PERCENTAGE
          ? values.discount
          : dollars2cents(values.discount),
      isActive: values.activeStatus === ActiveStatusEnum.Active,
      promocode: values.promocode,
      isLimited: values.isLimited,
      usesLeft: values.usesLeft,
    };

    if (!editablePromo) {
      if (values.promoType) {
        model.createPromocode({
          ...formattedValues,
          promoType: values.promoType,
        });
      }
    } else {
      model.changePromocode(editablePromo.id, formattedValues);
    }

    closeModal();
  };

  return (
    <Modal
      className={styles.modalWrapper}
      destroyOnClose
      open={isOpen}
      footer={null}
      onCancel={closeModal}
    >
      <span className={styles.title}>{mode} promocode</span>
      <Form
        initialValues={promoInitialValues}
        validation={promocodeValidationSchema}
        onSubmit={onSubmit}
        className={styles.form}
      >
        <FormContent editablePromo={editablePromo} />
        <div className={styles.buttonWrapper}>
          <Button type="primary" htmlType="submit" className={styles.button}>
            {mode}
          </Button>
          <Button
            htmlType="button"
            className={styles.button}
            onClick={closeModal}
          >
            Cancel
          </Button>
        </div>
      </Form>
    </Modal>
  );
};
