import { FC } from 'react';

import { PlusCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { FilterValue, SortOrder, SorterResult } from 'antd/es/table/interface';
import { productDeleteMessage } from 'common/constants';
import { useQueryParams } from 'common/hook/useQueryParams';
import { TTablePaginationParams } from 'common/types';
import { replaceHistoryState } from 'common/utils';
import { actions, image, Table, tag, text, title } from 'components/Table';
import { observer } from 'mobx-react-lite';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { ProductModel } from 'store/product-model';

import { Filters } from './Filters';
import { ProductViewModel } from './product-view-model';

import styles from './styles.module.scss';

export interface IProductProps {
  model: ProductViewModel;
}

const Product: FC<IProductProps> = observer(({ model }) => {
  const navigate = useNavigate();

  const params = model._filters.params;

  const searchParams = params.search ? { search: params.search } : null;
  const brandParams = params.brandId
    ? { brandTitle: params.brandTitle, brandId: params.brandId }
    : null;
  const typeParams = params.typeId
    ? { typeTitle: params.typeTitle, typeId: params.typeId }
    : null;
  const categoryParams = params.categoryId
    ? { categoryTitle: params.categoryTitle, categoryId: params.categoryId }
    : null;
  const statusParams = params.statusId
    ? { statusTitle: params.statusTitle, statusId: params.statusId }
    : null;

  const filtersParams = {
    ...searchParams,
    ...brandParams,
    ...typeParams,
    ...categoryParams,
    ...statusParams,
  };

  const navigationSearchParams = `?${createSearchParams({
    page: params.page,
    ...filtersParams,
  })}`;

  const handleCreate = () => navigate('/product/new');

  const edit = async (id: number) =>
    navigate({
      pathname: `/product/${id}`,
      search: navigationSearchParams,
    });

  const columns = [
    image({
      loading: model.loading,
      title: 'Photo',
      dataIndex: 'mainPhoto',
      width: '5%',
    }),
    title({
      title: 'Product',
      dataIndex: 'title',
      sorter: true,
      onClick: edit,
      getHref: (record) => `/product/${record.id}${navigationSearchParams}`,
      width: '15%',
      loading: model.loading,
    }),
    tag({
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      sorter: model._filters._selectedStatus ? false : true,
      loading: model.loading,
    }),
    title({
      title: 'Type',
      key: 'type',
      dataIndex: 'type',
      loading: model.loading,
    }),
    title({
      title: 'Brand',
      key: 'brand',
      dataIndex: 'subcategory.brand.title',
      nested: true,
      sorter: model._filters._selectedBrand ? false : true,
      loading: model.loading,
    }),
    title({
      title: 'Category',
      key: 'subcategory',
      dataIndex: 'subcategory.title',
      nested: true,
      sorter: model._filters._selectedCategory ? false : true,
      loading: model.loading,
    }),
    text({
      width: '7%',
      key: 'updatedAt',
      title: 'Last modified',
      dataIndex: 'updatedAt',
      defaultSortOrder: 'descend' as SortOrder | undefined,
      sorter: true,
      loading: model.loading,
    }),
    actions({
      onEdit: edit,
      onDelete: model.removeProduct,
      disabled: model.loading,
      deleteMessage: productDeleteMessage,
    }),
  ];

  const handleChange = (
    pagination: TTablePaginationParams,
    _: Record<string, FilterValue>,
    sorter: SorterResult<ProductModel>,
  ) => {
    const { current } = pagination;
    model._filters.page = current;

    model._filters.setSortOptions(sorter);

    replaceHistoryState('/product', {
      page: `${current}`,
      ...filtersParams,
    });

    model.fetchProducts();
  };

  return (
    <div className={styles.productsPage}>
      <div className={styles.header}>
        <Filters
          filters={model._filters}
          loading={model.loading}
          params={filtersParams}
          refetch={model.fetchProducts}
        />
        <Button
          type="primary"
          icon={<PlusCircleOutlined />}
          size="middle"
          onClick={handleCreate}
        >
          Add Product
        </Button>
      </div>
      <Table
        bordered
        loading={model.loading}
        skeleton={model.skeleton}
        data={model.products}
        pagination={{
          total: model._count,
          disabled: model.loading,
          current: model.defaultCurrent,
        }}
        columns={columns}
        onChange={handleChange}
      />
    </div>
  );
});

export const ProductPage = () => {
  const params = useQueryParams();

  const model = new ProductViewModel(params);

  return <Product model={model} />;
};
