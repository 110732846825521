import { FC } from 'react';

import { Button, Modal } from 'antd';
import { variationStatusOptions } from 'common/constants';
import { notify } from 'common/utils';
import { changeAllVariationSchema } from 'common/validation';
import { Form, FormInput, FormSelect } from 'components/Form';
import { useFormContext } from 'react-hook-form';

import { ProductControllerViewModel } from '../product-controller-view-model';

import styles from './change-all-modal.module.scss';

interface IChangeAllModalProps {
  open: boolean;
  onCancel: () => void;
  model: ProductControllerViewModel;
}

const formInitialValues = { status: '', price: '', discount: '' };

const SubmitBtn = () => {
  const {
    formState: { isDirty },
  } = useFormContext();

  return (
    <Button
      type="primary"
      htmlType="submit"
      className={styles.button}
      disabled={!isDirty}
    >
      Save
    </Button>
  );
};

export const ChangeAllModal: FC<IChangeAllModalProps> = ({
  open,
  onCancel,
  model,
}) => {
  const onSubmit = async (values: Record<string, string>) => {
    await model.changeAllVariations(model.product.id, values);
    await model.product.fetchProduct(model.product.id, true);

    notify('Variations list was updated!', 'success');

    onCancel();
  };

  return (
    <Modal
      title="Change all"
      open={open}
      onCancel={onCancel}
      footer={null}
      destroyOnClose
    >
      <Form
        initialValues={formInitialValues}
        validation={changeAllVariationSchema}
        onSubmit={onSubmit}
        className={styles.form}
      >
        <FormSelect
          name="status"
          label="Status"
          options={variationStatusOptions}
          zIndex={1000}
        />
        <FormInput name="price" label="Price" />
        <FormInput name="discount" label="Discount" />
        <div className={styles.buttons}>
          <SubmitBtn />
          <Button
            htmlType="button"
            className={styles.button}
            onClick={onCancel}
          >
            Cancel
          </Button>
        </div>
      </Form>
    </Modal>
  );
};
