import { FC } from 'react';

import { observer } from 'mobx-react-lite';

import { DatePicker } from '../../components/DatePicker';

import { DashboardViewModel } from './dashboard-view-model';
import { Section } from './Section';

import styles from './dashboard.module.scss';

interface IDashboardContent {
  model: DashboardViewModel;
}

const DashboardContent: FC<IDashboardContent> = observer(({ model }) => (
  <div className={styles.container}>
    <div className={styles.header}>
      <span className={styles.label}>Date range</span>
      <DatePicker.RangePicker
        value={[model.startDate, model.endDate]}
        allowClear={false}
        disabledDate={(current) => current.getTime() > model.today.getTime()}
        disabled={model.isLoading}
        className={styles.datePicker}
        onChange={model.onRangeChange}
        onOpenChange={model.onPickerOpenChange}
      />
    </div>
    <div className={styles.content}>
      <div className={styles.column}>
        <Section
          titles={['Finance', 'Value']}
          items={model.finance}
          prefix="$"
          isLoading={model.isLoading}
        />
      </div>
      <div className={styles.column}>
        <Section
          titles={['Orders', 'Value']}
          items={model.orders}
          isLoading={model.isLoading}
        />
      </div>
    </div>
  </div>
));

export const DashboardPage: FC = () => {
  const model = new DashboardViewModel();

  return (
    <div>
      <DashboardContent model={model} />
    </div>
  );
};
