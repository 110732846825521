import { FC } from 'react';

import { IAddress } from 'common/types';
import {
  getBillingAddressFields,
  getShippingAddressFields,
} from 'common/utils';
import {
  billingAddressValidationSchema,
  shippingAddressValidationSchema,
} from 'common/validation';
import { EditableSection } from 'components/EditableSection';
import { observer } from 'mobx-react-lite';

import { OrderExpandedViewModel } from '../../order-expanded-view-model';

import {
  getPersonalInformationFields,
  orderPersonalInformationValidationSchema,
} from './utils';

import styles from './customer-info.module.scss';

interface ICustomerInfoProps {
  model: OrderExpandedViewModel;
}

const CustomerInfo: FC<ICustomerInfoProps> = observer(({ model }) => (
  <div className={styles.container}>
    <EditableSection
      title="Personal info"
      fields={getPersonalInformationFields(model.userData)}
      validationSchema={orderPersonalInformationValidationSchema}
      classNames={{ container: styles.section, field: styles.field }}
      onEdit={(values) =>
        model.changeUserData(model.order.id, { email: values.email })
      }
      disabled={model.loading || model.order.disabled}
      labelPosition="left"
      forcedRender
    />
    <EditableSection
      title="Shipping address"
      fields={getShippingAddressFields(model.userData)}
      validationSchema={shippingAddressValidationSchema}
      classNames={{ container: styles.section, field: styles.field }}
      onEdit={(values) =>
        model.changeUserData(model.order.id, {
          shippingAddress: values as unknown as IAddress,
        })
      }
      disabled={model.loading || model.order.disabled}
      labelPosition="left"
      forcedRender
    />
    <EditableSection
      title="Billing address"
      fields={getBillingAddressFields(model.userData)}
      validationSchema={billingAddressValidationSchema}
      classNames={{ container: styles.section, field: styles.field }}
      onEdit={(values) =>
        model.changeUserData(model.order.id, {
          billingAddress: values as unknown as IAddress,
        })
      }
      disabled={model.loading || model.order.disabled}
      labelPosition="left"
      forcedRender
    />
  </div>
));

export default CustomerInfo;
