import { FC } from 'react';

import { Spin, Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import { SummaryViewModel } from 'pages/NewOrder/view-models/summary-view-model';

import { FeesInput } from '../FeesInput';
import { StatItem } from '../StatItem';

import styles from './summary.module.scss';

interface ISummaryProps {
  model: SummaryViewModel | null;
}

export const Summary: FC<ISummaryProps> = observer(({ model }) => (
  <div className={styles.container}>
    <div className={styles.header}>
      <Typography.Title level={4} className={styles.title}>
        Summary
      </Typography.Title>
    </div>
    <div className={styles.content}>
      {!model ? (
        <Spin className={styles.spin} />
      ) : (
        <div className={styles.items}>
          <StatItem label="Items" value={model.summary.items} />
          {(model.root?.root?.root?.user.isManager ||
            model.root?.root?.root?.user.isAdmin) && (
            <div className={styles.input}>
              <Typography.Text strong>Fees:</Typography.Text>
              <FeesInput
                value={model.fees}
                disabled={model.root?.order?.isCancelled}
                isLoading={model.isLoading}
                onChange={model.onFeesChange}
                onSave={model.onFeesUpdate}
              />
            </div>
          )}
          <StatItem label="Discount" value={model.summary.discount} />
          <StatItem label="Refunded" value={model.summary.refunded} />
          <StatItem label="Subtotal" value={model.summary.subtotal} />
          <StatItem label="Shipping" value={model.summary.shipping} />
          <StatItem label="Grand total" value={model.summary.grandTotal} />
          <StatItem label="Total paid" value={model.summary.totalPaid} />
        </div>
      )}
    </div>
  </div>
));
