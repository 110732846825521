import { IPackage } from 'common/types/supplier';

export class PackageModel {
  _package: IPackage;

  get id() {
    return this._package.id;
  }

  get orderId() {
    return this._package.orderId;
  }

  get orderPublicId() {
    return this._package.orderPublicId;
  }

  get createdAt() {
    return this._package.createdAt;
  }

  get type() {
    return this._package.type;
  }

  get status() {
    return this._package.status;
  }

  get itemsCost() {
    return this._package.itemsCost;
  }

  get shippingCost() {
    return this._package.shippingCost;
  }

  constructor(pkg: IPackage) {
    this._package = pkg;
  }
}
