import { FC } from 'react';

import { ArrowRightOutlined } from '@ant-design/icons';
import { List, Typography } from 'antd';
import { PurchaseViewModel } from 'pages/NewOrder/view-models/purchase-view-model';

import styles from './confirm-purchases-list.module.scss';

interface IConfirmPurchasesListProps {
  purchases: PurchaseViewModel[];
  title: string;
  type: 'added' | 'updated' | 'removed';
}

export const ConfirmPurchasesList: FC<IConfirmPurchasesListProps> = ({
  purchases,
  title,
  type,
}) => (
  <List
    key={title}
    bordered
    dataSource={purchases}
    className={styles.container}
    header={title}
    renderItem={(purchase, index) => (
      <List.Item>
        <div className={styles.item}>
          <Typography.Text>
            <Typography.Text strong>{index + 1}. </Typography.Text>
            <Typography.Text>{purchase.title}</Typography.Text>
          </Typography.Text>
          <Typography.Text>
            <Typography.Text strong>Variation: </Typography.Text>
            <Typography.Text>{purchase.variationView}</Typography.Text>
          </Typography.Text>
          {type === 'added' && (
            <Typography.Text>
              <Typography.Text strong>Quantity: </Typography.Text>
              <Typography.Text>{purchase.quantityUpdated}</Typography.Text>
            </Typography.Text>
          )}
          {type === 'updated' && (
            <Typography.Text className={styles.updatedQuantity}>
              <Typography.Text strong>Quantity: </Typography.Text>
              <Typography.Text>{purchase.quantity}</Typography.Text>
              <ArrowRightOutlined className={styles.icon} />
              <Typography.Text>{purchase.quantityUpdated}</Typography.Text>
            </Typography.Text>
          )}
          {type === 'removed' && (
            <Typography.Text>
              <Typography.Text strong>Quantity: </Typography.Text>
              <Typography.Text>{purchase.quantity}</Typography.Text>
            </Typography.Text>
          )}
          <Typography.Text>
            <Typography.Text strong>SKU: </Typography.Text>
            <Typography.Text>{purchase.sku}</Typography.Text>
          </Typography.Text>
          <Typography.Text>
            <Typography.Text strong>#KIT: </Typography.Text>
            <Typography.Text>{purchase.productKit?.id ?? '-'}</Typography.Text>
          </Typography.Text>
        </div>
      </List.Item>
    )}
  />
);
