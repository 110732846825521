import { FC, useEffect } from 'react';

import { Button } from 'antd';
import Title from 'antd/es/typography/Title';
import { metaInfo } from 'common/validation';
import { Form, FormInput, FormTextArea } from 'components/Form';
import { useFormContext } from 'react-hook-form';

import styles from './meta-form.module.scss';

interface IMetaValues {
  metaTitle: string | null;
  metaDescription: string | null;
}
interface IMetaFormContentProps {
  values: IMetaValues;
  isLoading?: boolean;
}

interface IMetaFormProps {
  title: string;
  values: IMetaValues;
  isLoading?: boolean;
  onSubmit: (values: IMetaValues) => Promise<void>;
}

const MetaFormContent: FC<IMetaFormContentProps> = ({ values, isLoading }) => {
  const { reset } = useFormContext();

  useEffect(() => {
    reset(values);
  }, [values, reset]);

  return (
    <>
      <FormInput
        name="metaTitle"
        label="Meta title"
        showCount
        disabled={isLoading}
      />
      <FormTextArea
        name="metaDescription"
        label="Meta description"
        autoSize={{ minRows: 3, maxRows: 5 }}
        showCount
        disabled={isLoading}
      />
      <Button
        htmlType="submit"
        type="primary"
        disabled={isLoading}
        loading={isLoading}
        className={styles.button}
      >
        Save
      </Button>
    </>
  );
};

export const MetaForm: FC<IMetaFormProps> = ({
  title,
  values,
  isLoading,
  onSubmit,
}) => {
  const initialValues = values;

  const handleSubmit = async (values: IMetaValues) => await onSubmit(values);

  return (
    <>
      <Title level={5} type="secondary">
        {title}
      </Title>
      <Form
        id="meta-form"
        initialValues={initialValues}
        validation={metaInfo}
        className={styles.container}
        onSubmit={handleSubmit}
      >
        <MetaFormContent values={values} isLoading={isLoading} />
      </Form>
    </>
  );
};
