import { IShortOrder } from 'common/types';
import { EPaymentCancelledStatuses } from 'common/types/order/enums';
import { parseToShortDate } from 'common/utils';
import { makeAutoObservable } from 'mobx';

export class OrderModel {
  _order: IShortOrder;

  get order() {
    return this._order;
  }

  set order(tableOrder: IShortOrder) {
    this._order = tableOrder;
  }

  get id() {
    return this.order.id;
  }

  get publicId() {
    return this.order.publicId;
  }

  get createdAt() {
    return parseToShortDate(this.order.createdAt);
  }

  get manager() {
    return this.order.manager;
  }

  get buyer() {
    return this.order.buyer;
  }

  get delivery() {
    return this.order.delivery;
  }

  get dispute() {
    return this.order.dispute;
  }

  get efficiency() {
    return this.order.efficiency;
  }

  get payment() {
    return this.order.payment;
  }

  get isCancelled() {
    return !!EPaymentCancelledStatuses[
      this.order.payment as EPaymentCancelledStatuses
    ];
  }

  get purchasing() {
    return this.order.purchasing;
  }

  get refund() {
    return this.order.refund;
  }

  get reshipments() {
    return this.order.reshipments;
  }

  get return() {
    return this.order.return;
  }

  get sending() {
    return this.order.sending;
  }

  get supplier() {
    return this.order.supplier;
  }

  get todo() {
    return this.order.todo;
  }

  get cost() {
    return this.order.cost;
  }

  get total() {
    return this.order.total;
  }

  get totalItemFinal() {
    return this.order.totalItemFinal;
  }

  get totalPriceFinal() {
    return this.order.totalPriceFinal;
  }

  get comment() {
    return this.order.comment ?? { id: 0, value: '' };
  }

  constructor(order: IShortOrder) {
    this._order = order;

    makeAutoObservable(this);
  }
}
