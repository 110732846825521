import { getLogsByOrderId } from 'http/logs';

import { ILongOrder, IServerError } from 'common/types';
import { notify } from 'common/utils';
import { makeAutoObservable } from 'mobx';

import { LogModel } from '../models/log-model';

import { ExpandedOrderViewModel } from './expanded-order-view-model';

export class HistoryViewModel {
  _root: ExpandedOrderViewModel | null = null;
  _isLoading = false;
  _logs: LogModel[] = [];

  get root() {
    return this._root;
  }

  get orderViewModel() {
    return this.root?.root;
  }

  get disabled() {
    return !!this.root?.order?.disabled;
  }

  get isLoading() {
    return this._isLoading;
  }

  set isLoading(isLoading: boolean) {
    this._isLoading = isLoading;
  }

  get logs() {
    return this._logs;
  }

  set logs(logs: LogModel[]) {
    this._logs = logs;
  }

  syncOrderData = async (order: ILongOrder) => {
    await this.getLogs(order.id);
  };

  async getLogs(orderId: number) {
    this.isLoading = true;

    try {
      const { data } = await getLogsByOrderId(orderId);

      this.logs = data.items.map((log) => new LogModel(log));
    } catch (e) {
      const error = e as IServerError;

      notify(`Error, reason: ${error.response?.data?.message}`, 'error');
    } finally {
      this.isLoading = false;
    }
  }

  constructor(root: ExpandedOrderViewModel | null, orderId: number) {
    if (root) {
      this._root = root;
    }

    this.getLogs(orderId);

    makeAutoObservable(this);
  }
}
