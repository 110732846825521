import { defaultTableProductEntity } from 'common/constants';
import { ICommonProduct } from 'common/types';
import { parseDate } from 'common/utils';
import { makeAutoObservable, observable } from 'mobx';

export class TableProductModel {
  _tableProduct: ICommonProduct = defaultTableProductEntity;

  get mainPhoto() {
    return this._tableProduct.mainPhotoId
      ? this._tableProduct.photos?.find(
          (photo) => photo.id === this._tableProduct.mainPhotoId,
        )
      : this._tableProduct.photos?.[0];
  }

  get title() {
    return this._tableProduct.title;
  }

  get status() {
    return this._tableProduct.status;
  }

  get type() {
    return this._tableProduct.type;
  }

  get subcategory() {
    return this._tableProduct.subcategory;
  }

  get id() {
    return this._tableProduct.id;
  }

  get updatedAt() {
    return parseDate(this._tableProduct.updatedAt ?? '');
  }

  constructor(tableProduct?: ICommonProduct) {
    if (tableProduct) {
      this._tableProduct = tableProduct;
    }

    makeAutoObservable(this, {
      _tableProduct: observable,
    });
  }
}
