import { FC, ReactNode } from 'react';

import { Popconfirm, Button, PopconfirmProps, ButtonProps } from 'antd';

export interface IConfirmButtonProps {
  confirm: PopconfirmProps;
  button?: ButtonProps;
  disabled?: boolean;
  isLoading?: boolean;
  children: ReactNode;
  onConfirm: () => void;
}

export const ConfirmButton: FC<IConfirmButtonProps> = ({
  confirm,
  button,
  disabled,
  isLoading,
  children,
  onConfirm,
}) => (
  <Popconfirm
    placement="left"
    destroyTooltipOnHide
    onConfirm={onConfirm}
    {...confirm}
  >
    <Button disabled={disabled} loading={isLoading} {...button}>
      {children}
    </Button>
  </Popconfirm>
);
