import { FC } from 'react';

import { Tooltip } from 'antd';
import { EDeliveryStatuses } from 'common/types/order/enums';

import { ReactComponent as PackageHalfColorIcon } from '../../../../assets/svg/package-half-color.svg';
import { ReactComponent as PackageIcon } from '../../../../assets/svg/package.svg';

export interface IPaymentCellProps {
  delivery: EDeliveryStatuses | string;
  tooltip: string;
}

type DeliverIconsKeys = keyof typeof deliveryIcons;

const deliveryIcons = {
  [EDeliveryStatuses.DELIVERY_NOT_DELIVERED]: <PackageIcon color="#D8D8D8" />,
  [EDeliveryStatuses.DELIVERY_PARTIALLY_DELIVERED]: <PackageHalfColorIcon />,
  [EDeliveryStatuses.DELIVERY_DELIVERED]: <PackageIcon color="#88CC43" />,
};

export const DeliveryCell: FC<IPaymentCellProps> = ({ delivery, tooltip }) => (
  <Tooltip title={tooltip}>
    {deliveryIcons[delivery as DeliverIconsKeys]}
  </Tooltip>
);
