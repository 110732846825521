import { FC } from 'react';

import { Select } from 'antd';
import Search from 'antd/es/input/Search';
import { observer } from 'mobx-react-lite';

import { OrdersViewModel } from '../view-models/orders-view-model';

import { TextButton } from './TextButton';

import styles from './order-filters.module.scss';

export interface IOrderFiltersProps {
  model: OrdersViewModel;
}

export const OrderFilters: FC<IOrderFiltersProps> = observer(({ model }) => (
  <div className={styles.container}>
    <div className={styles.buttons}>
      <TextButton
        highlighted={model.orderStatusPreset === 'all'}
        disabled={model.isLoading}
        onClick={() => model.onOrderStatusPresetChange('all')}
      >
        All
      </TextButton>
      <TextButton
        highlighted={model.orderStatusPreset === 'awaiting_purchase'}
        disabled={model.isLoading}
        onClick={() => model.onOrderStatusPresetChange('awaiting_purchase')}
      >
        Awaiting purchase
      </TextButton>
      <TextButton
        highlighted={model.orderStatusPreset === 'awaiting_fulfilling'}
        disabled={model.isLoading}
        onClick={() => model.onOrderStatusPresetChange('awaiting_fulfilling')}
      >
        Awaiting fulfilling
      </TextButton>
      <TextButton
        highlighted={model.orderStatusPreset === 'awaiting_payment'}
        disabled={model.isLoading}
        onClick={() => model.onOrderStatusPresetChange('awaiting_payment')}
      >
        Awaiting payment
      </TextButton>
    </div>
    <div className={styles.selects}>
      <Select
        value={model.filters.params.searchType}
        options={model.searchTypeOptions}
        placeholder="Search by"
        disabled={model.isLoading}
        className={styles.field}
        onChange={model.onSearchTypeOptionChange}
      />
      <Search
        value={model.filters.params.searchQuery}
        placeholder="Search"
        allowClear
        disabled={model.isLoading}
        className={styles.field}
        onChange={(e) => model.onSearchChange(e.target.value)}
        onSearch={model.onSearch}
      />
      <Select
        value={model.filters.params.status}
        options={model.orderStatusOptions}
        placeholder="Filter by order status"
        mode="multiple"
        disabled={model.isLoading}
        className={styles.field}
        onChange={model.onOrderStatusOptionChange}
      />
    </div>
  </div>
));
