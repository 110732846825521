import { getProductAutocompleteByType } from 'http/productApi';

import { SorterResult } from 'antd/es/table/interface';
import { variationStatuses } from 'common/constants';
import { ProductAutocompleteTypeEnum } from 'common/types/enums';
import { notify, replaceHistoryState } from 'common/utils';
import { makeAutoObservable } from 'mobx';
import { TParams } from 'store/filters-model';
import { ProductModel } from 'store/product-model';

const filterBySearchValue = (array: Option[], search: string): Option[] =>
  array.filter((option) =>
    option.value.toLowerCase().includes(search.toLowerCase()),
  );

export interface Option {
  value: string;
  id: number | string;
  brandId?: number;
}
export class FiltersModel {
  _search: string;
  _searchBrandValue: string;
  _searchCategoryValue: string;
  _searchStatusValue: string;
  _searchTypeValue: string;
  _page: number;
  _pageSize = 50;
  _sort = 'DESC';
  _sortBy = 'updatedAt';
  _params: TParams;

  _selectedBrand: Option | null;
  _selectedCategory: Option | null;
  _selectedStatus: Option | null;
  _selectedType: Option | null;

  _brandsOptions: Option[] = [];
  _categoriesOptions: Option[] = [];
  _statusOptions: Option[] = [];
  _typeOptions: Option[] = [];
  refresh: () => void;

  get params() {
    return this._params;
  }

  set params(params: TParams) {
    this._params = params;
  }

  get search() {
    return this._search;
  }

  set search(value: string) {
    this._search = value;

    this._params = { ...this.params, search: value };
  }

  get searchBrandValue() {
    return this._searchBrandValue;
  }

  set searchBrandValue(value: string) {
    this._searchBrandValue = value;
  }

  get searchCategoryValue() {
    return this._searchCategoryValue;
  }

  set searchCategoryValue(value: string) {
    this._searchCategoryValue = value;
  }

  get searchStatusValue() {
    return this._searchStatusValue;
  }

  set searchStatusValue(value: string) {
    this._searchStatusValue = value;
  }

  get selectedBrand() {
    return this._selectedBrand;
  }

  set selectedBrand(brand: Option | null) {
    this._selectedBrand = brand;
    this.selectedCategory = null;

    if (brand) {
      this._params = { ...this.params, brandTitle: brand.value };
    }
  }

  get selectedCategory() {
    return this._selectedCategory;
  }

  set selectedCategory(category: Option | null) {
    this._selectedCategory = category;

    if (category) {
      this._params = { ...this.params, categoryTitle: category.value };
    }
  }

  get selectedStatus() {
    return this._selectedStatus;
  }

  set selectedStatus(status: Option | null) {
    this._selectedStatus = status;

    if (status) {
      this._params = { ...this.params, statusTitle: status.value };
    }
  }

  get statusOptions() {
    return this._searchStatusValue
      ? filterBySearchValue(this._statusOptions, this._searchStatusValue)
      : this._statusOptions;
  }

  get page() {
    return this._page;
  }

  set page(page: number) {
    this._page = page;

    this._params = { ...this.params, page: String(page) };
  }

  get pageSize() {
    return this._pageSize;
  }

  set pageSize(size: number) {
    this._pageSize = size;
  }

  get sort() {
    return this._sort;
  }

  set sort(sort: string) {
    this._sort = sort;
  }

  get sortBy() {
    return this._sortBy;
  }

  set sortBy(sortBy: string) {
    this._sortBy = sortBy;
  }

  get brandsOptions() {
    return this._searchBrandValue
      ? filterBySearchValue(this._brandsOptions, this._searchBrandValue)
      : this._brandsOptions;
  }

  get categoriesOptions() {
    if (this._selectedBrand) {
      const categoriesByBrandId = this._categoriesOptions.filter(
        (category) => category.brandId === this._selectedBrand?.id,
      );

      if (this._searchCategoryValue) {
        return filterBySearchValue(
          categoriesByBrandId,
          this._searchCategoryValue,
        );
      } else {
        return categoriesByBrandId;
      }
    } else if (this._searchCategoryValue) {
      return filterBySearchValue(
        this._categoriesOptions,
        this._searchCategoryValue,
      );
    } else {
      return this._categoriesOptions;
    }
  }

  get typeOptions() {
    return this._searchTypeValue
      ? filterBySearchValue(this._typeOptions, this._searchTypeValue)
      : this._typeOptions;
  }

  set searchTypeValue(value: string) {
    this._searchTypeValue = value;
  }

  get searchTypeValue() {
    return this._searchTypeValue;
  }

  get selectedType() {
    return this._selectedType;
  }

  set selectedType(type: Option | null) {
    this._selectedType = type;

    if (type) {
      this._params = { ...this.params, typeTitle: type.value };
    }
  }

  get defaultCurrent() {
    return this.page;
  }

  setSortOptions = (sorter: SorterResult<ProductModel>) => {
    if (sorter.order) {
      if (sorter.order === 'ascend') {
        this.sort = 'ASC';
      } else {
        this.sort = 'DESC';
      }

      this.sortBy = (sorter?.column?.key ?? 'updatedAt') as string;
    } else {
      this.sort = 'DESC';
      this.sortBy = 'updatedAt';
    }
  };

  resetFilters() {
    this._selectedBrand = null;
    this._selectedCategory = null;
    this._selectedStatus = null;
    this._selectedType = null;

    this._search = '';
    this._searchBrandValue = '';
    this._searchCategoryValue = '';
    this._searchTypeValue = '';
    this._searchStatusValue = '';
    this._params = { page: '1' };
    this._page = 1;

    replaceHistoryState('/product', { page: '1' });

    this.refresh();
  }

  getOptions = async () => {
    try {
      this._brandsOptions = await getProductAutocompleteByType(
        ProductAutocompleteTypeEnum.brands,
      );
      this._typeOptions = await getProductAutocompleteByType(
        ProductAutocompleteTypeEnum.types,
      );
      this._categoriesOptions = await getProductAutocompleteByType(
        ProductAutocompleteTypeEnum.subcategories,
      );
    } catch (e) {
      notify(`Error, reason:${e}`, 'error');
    }
  };

  constructor(refreshData?: () => void, params?: TParams) {
    this._params = params || {};

    this._page = Number(params?.page) || 1;

    this._search = params?.search || '';

    this._searchBrandValue = params?.brandTitle || '';
    this._selectedBrand = params?.brandId
      ? { value: params.brandTitle, id: Number(params.brandId) }
      : null;

    this._searchTypeValue = params?.typeTitle || '';
    this._selectedType = params?.typeId
      ? { value: params.typeTitle, id: params.typeId }
      : null;

    this._searchCategoryValue = params?.categoryTitle || '';
    this._selectedCategory = params?.categoryId
      ? { value: params.categoryTitle, id: Number(params.categoryId) }
      : null;

    this._searchStatusValue = params?.statusTitle || '';
    this._selectedStatus = params?.statusId
      ? { value: params.statusTitle, id: Number(params.statusId) }
      : null;

    this.refresh = () => null;

    if (refreshData) {
      this.refresh = refreshData;
    }

    this.getOptions();

    this._statusOptions = variationStatuses.map((status, index) => ({
      value: status,
      id: index + 1,
    }));

    makeAutoObservable(this, {});
  }
}
