import { FC, useEffect, useMemo } from 'react';

import { useCurrentUserStore } from 'common/providers/store-provider';
import { useParams } from 'react-router-dom';

import { BillingPageViewModel } from './billing-page-view-model';
import { CalculationTable } from './CalcultaionTable';
import { InvoiceTable } from './InvoiceTable';
import { PackageTable } from './PackageTable';

import styles from './billing.module.scss';

interface IBillingPageContentProps {
  model: BillingPageViewModel;
}

const BillingPageContent: FC<IBillingPageContentProps> = ({ model }) => (
  <div className={styles.content}>
    <CalculationTable model={model.calculationViewModel} />
    <InvoiceTable model={model.invoicesViewModel} className={styles.invoices} />
    <PackageTable model={model.packagesViewModel} />
  </div>
);

export const BillingPage: FC = () => {
  const { supplierId } = useParams();
  const { currentUser } = useCurrentUserStore();

  const billingPageViewModel = useMemo(
    () => new BillingPageViewModel(Number(supplierId || currentUser?.adminId)),
    [supplierId, currentUser],
  );

  useEffect(() => {
    billingPageViewModel.calculationViewModel.getCalculations();
    billingPageViewModel.invoicesViewModel.getInvoices();
    billingPageViewModel.packagesViewModel.getPackages();
  }, [billingPageViewModel]);

  return <BillingPageContent model={billingPageViewModel} />;
};
