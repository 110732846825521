import { FC } from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { TTablePaginationParams } from 'common/types/common-types';
import { IReview } from 'common/types/review';
import { useModal } from 'common/utils';
import { Table, text, image, customActions } from 'components/Table';
import { observer } from 'mobx-react-lite';

import { ReviewViewModel } from './review-view-model';
import { ReviewModal } from './ReviewModal';

import styles from './styles.module.scss';

interface IReviewProps {
  model: ReviewViewModel;
}

const Review: FC<IReviewProps> = observer(({ model }) => {
  const [isOpen, openModal, closeModal] = useModal(false);

  const handleClickPhoto = (id: number) => {
    const review = model.tableData.find((review) => review.id === id);
    const photos = review?.photos;
    const product = review?.product;

    const { reviewPhoto } = model;

    if (photos) {
      reviewPhoto.selectedPhoto = photos[0];
      reviewPhoto.photos = photos;
    }

    if (product) {
      reviewPhoto.title = product;
    }

    openModal();
  };

  const handleChangeTable = (pagination: TTablePaginationParams) => {
    model.refetchReviews(pagination);
  };

  const columns: ColumnsType<IReview> = [
    image({
      title: 'Photos',
      dataIndex: 'photos',
      key: 'photos',
      onClick: handleClickPhoto,
      loading: model.loading,
    }),
    text({
      width: '30%',
      title: 'Product',
      dataIndex: 'product',
      key: 'product',
      loading: model.loading,
    }),
    text({
      width: '20%',
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      loading: model.loading,
    }),
    text({
      width: '10%',
      title: 'Rating',
      dataIndex: 'rating',
      key: 'rating',
      loading: model.loading,
    }),
    text({
      width: '30%',
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      loading: model.loading,
    }),
    customActions({
      render: (_, review) => (
        <Popconfirm
          title={
            <p className={styles.warningText}>
              Are you sure you want to delete this review?
            </p>
          }
          placement="left"
          onConfirm={() => model.removeReview(review.id)}
        >
          <Button disabled={model.loading} icon={<DeleteOutlined />} />
        </Popconfirm>
      ),
    }),
  ];

  return (
    <>
      <ReviewModal model={model} isOpen={isOpen} closeModal={closeModal} />
      <Table
        columns={columns}
        bordered
        expandable={{
          expandedRowRender: (review) => (
            <p className={styles.description}>{review.reviewText}</p>
          ),
          rowExpandable: (review) => !!review.reviewText,
        }}
        rowKey="id"
        skeleton={model.skeleton}
        data={model.tableData}
        loading={model.loading}
        pagination={{
          total: model.count,
          disabled: model.loading,
        }}
        onChange={handleChangeTable}
      />
    </>
  );
});

export const ReviewPage = () => {
  const model = new ReviewViewModel();

  return <Review model={model} />;
};
