import { FC } from 'react';

import { Modal, Button, Checkbox, Select } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { TOption } from 'common/types';
import { InfoList } from 'components/InfoList';
import { PurchaseModel } from 'store/purchase-model';

import styles from './save-modal.module.scss';

interface ISaveModalProps {
  isOpen: boolean;
  addedPurchases: PurchaseModel[];
  updatedPurchases: PurchaseModel[];
  deletedPurchases: PurchaseModel[];
  isInvoice: boolean;
  isRefund: boolean;
  isRefundValid: boolean;
  responsibleOptions: TOption[];
  reasonOptions: TOption[];
  hasInvoice: boolean;
  hasRefund: boolean;
  closeModal: () => void;
  onSave: () => Promise<void>;
  onInvoiceChange: (refund: boolean) => void;
  onRefundChange: (refund: boolean) => void;
  onResponsibleChange: (responsible: string) => void;
  onReasonChange: (reason: string) => void;
}

export const SaveModal: FC<ISaveModalProps> = ({
  isOpen,
  addedPurchases,
  updatedPurchases,
  deletedPurchases,
  isInvoice,
  isRefund,
  isRefundValid,
  responsibleOptions,
  reasonOptions,
  hasInvoice,
  hasRefund,
  closeModal,
  onSave,
  onInvoiceChange,
  onRefundChange,
  onResponsibleChange,
  onReasonChange,
}) => {
  const showAdded = !!addedPurchases.length;
  const showUpdated = !!updatedPurchases.length;
  const showDeleted = !!deletedPurchases.length;

  const handleCloseModal = () => {
    onRefundChange(false);
    closeModal();
  };

  const handleSaveModal = async () => {
    await onSave();
    closeModal();
  };

  const handleInvoiceChange = (e: CheckboxChangeEvent) => {
    onInvoiceChange(e.target.checked);
  };

  const handleRefundChange = (e: CheckboxChangeEvent) => {
    if (!e.target.checked) {
      onResponsibleChange('');
      onReasonChange('');
    }

    onRefundChange(e.target.checked);
  };

  return (
    <Modal
      open={isOpen}
      onCancel={handleCloseModal}
      closable={false}
      destroyOnClose
      title="Are you sure you want to apply next changes?"
      footer={[
        <Button
          htmlType="submit"
          type="primary"
          disabled={isRefund && !isRefundValid}
          onClick={handleSaveModal}
        >
          Yes
        </Button>,
        <Button onClick={handleCloseModal}>No</Button>,
      ]}
    >
      {showAdded && (
        <InfoList list={addedPurchases} title="Added" type="added" />
      )}
      {showUpdated && (
        <InfoList list={updatedPurchases} title="Updated" type="updated" />
      )}
      {showDeleted && (
        <InfoList list={deletedPurchases} title="Deleted" type="deleted" />
      )}
      {hasInvoice && (
        <Checkbox value={isInvoice} onChange={handleInvoiceChange}>
          Send invoice
        </Checkbox>
      )}
      {hasRefund && (
        <Checkbox value={isRefund} onChange={handleRefundChange}>
          Refund
        </Checkbox>
      )}
      {isRefund && (
        <div className={styles.selectWrapper}>
          <div>
            <p>Responsible</p>
            <Select
              options={responsibleOptions}
              dropdownStyle={{ zIndex: 1001 }}
              className={styles.select}
              onChange={onResponsibleChange}
            />
          </div>
          <div>
            <p>Reason</p>
            <Select
              options={reasonOptions}
              dropdownStyle={{ zIndex: 1001 }}
              className={styles.select}
              onChange={onReasonChange}
            />
          </div>
        </div>
      )}
    </Modal>
  );
};
