import { FC, useState } from 'react';

import { IReturnShipmentItem, TOption } from 'common/types';
import { ReturnShipmentModel } from 'store/return-shipment-model';

import { EditableData } from './EditableData';
import { StaticData } from './StaticData';

interface IInfoSectionProps {
  returnItem: ReturnShipmentModel;
  disabled?: boolean;
  supplierOptions: TOption[];
  responsibleOptions: TOption[];
  handleEditReturn: (
    id: number,
    items: IReturnShipmentItem[],
    costOfGoods: number,
    supplier: string,
    responsible: string,
    reason: string,
  ) => void;
}

export const InfoSection: FC<IInfoSectionProps> = ({
  returnItem,
  disabled,
  supplierOptions,
  responsibleOptions,
  handleEditReturn,
}) => {
  const [isEdit, setEdit] = useState(false);

  const toggleEdit = () => setEdit(!isEdit);

  return (
    <>
      {isEdit ? (
        <EditableData
          returnItem={returnItem}
          handleEdit={handleEditReturn}
          toggleEdit={toggleEdit}
          supplierOptions={supplierOptions}
          responsibleOptions={responsibleOptions}
        />
      ) : (
        <StaticData
          returnItem={returnItem}
          responsibleOptions={responsibleOptions}
          toggleEdit={toggleEdit}
          disabled={disabled}
        />
      )}
    </>
  );
};
