import { FC, Fragment, MouseEvent as ReactMouseEvent, useState } from 'react';

import { Button } from 'antd';
import cn from 'clsx';
import { IField } from 'common/types';
import { getInitialValues } from 'common/utils';
import { Form, FormInput } from 'components/Form';
import { ObjectSchema } from 'yup';

import { StaticField } from './StaticField';

import styles from './editable-section.module.scss';

interface IEditableSection {
  title: string;
  fields: IField[];
  validationSchema: ObjectSchema<any>;
  onEdit: (data: Record<string, string>) => Promise<void>;
  classNames?: { container?: string; field?: string; staticField?: string };
  labelPosition?: 'top' | 'left';
  isLoading?: boolean;
  disabled?: boolean;
  forcedRender?: boolean;
}

export const EditableSection: FC<IEditableSection> = ({
  title,
  fields,
  validationSchema,
  classNames,
  labelPosition = 'top',
  isLoading,
  disabled,
  forcedRender,
  onEdit,
}) => {
  const [isEdit, setEdit] = useState(false);

  const initialValues = getInitialValues(fields);

  const toggleEdit = () => setEdit(!isEdit);

  const handleEdit = (
    e:
      | ReactMouseEvent<HTMLAnchorElement, MouseEvent>
      | ReactMouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    toggleEdit();
  };

  const handleSubmit = async (values: Record<string, string>) => {
    await onEdit(values);

    toggleEdit();
  };

  return (
    <Form
      className={cn(styles.container, classNames?.container)}
      initialValues={initialValues}
      validation={validationSchema}
      onSubmit={handleSubmit}
      key={`${forcedRender && isEdit}`}
    >
      <div className={styles.header}>
        <p className={styles.title}>{title}</p>
        <div className={styles.buttons}>
          {isEdit ? (
            <>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                Save
              </Button>
              <Button onClick={toggleEdit}>Discard</Button>
            </>
          ) : (
            <Button
              type="primary"
              onClick={handleEdit}
              htmlType="button"
              loading={isLoading}
              disabled={disabled}
            >
              Edit
            </Button>
          )}
        </div>
      </div>
      <div className={styles.fields}>
        {fields.map((field) => (
          <Fragment key={field.name}>
            {isEdit ? (
              <FormInput
                name={field.name}
                label={field.label}
                labelPosition={labelPosition}
                placeholder={field.label}
                disabled={field.disabled}
                className={classNames?.field}
                {...(field.disabled && { value: field.value ?? '' })}
              />
            ) : (
              field.value && (
                <StaticField
                  label={field.label}
                  value={field.value}
                  labelPosition={labelPosition}
                  className={classNames?.staticField}
                />
              )
            )}
          </Fragment>
        ))}
      </div>
    </Form>
  );
};
