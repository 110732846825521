import { FC, useEffect, useState } from 'react';

import { Button, Select } from 'antd';
import Title from 'antd/es/typography/Title';
import { NoneEnum } from 'common/types/promocode/enums';
import { observer } from 'mobx-react-lite';

import { MainViewModel } from '../main-view-model';

import styles from './discount-bar.module.scss';

interface IDiscountBarProps {
  model: MainViewModel;
}

export const DiscountBar: FC<IDiscountBarProps> = observer(({ model }) => {
  const [promocode, setPromocode] = useState<number | NoneEnum.None>(
    NoneEnum.None,
  );

  useEffect(() => {
    setPromocode(model.defaultPromocodeCandidate);
  }, [model.defaultPromocodeCandidate]);

  const handleUpdate = () => {
    model.onPromocodeChange(promocode);
  };

  return (
    <div className={styles.discountBarWrapper}>
      <Title level={5} type="secondary">
        Discount bar
      </Title>
      <div className={styles.selectWrapper}>
        <Select
          className={styles.select}
          loading={model.loading}
          disabled={model.loading}
          onChange={setPromocode}
          value={promocode}
          defaultValue={promocode}
          options={model.promocodeCandidatesOptions}
        />
        <Button
          loading={model.loading}
          disabled={model.loading}
          onClick={handleUpdate}
          type="primary"
        >
          Update
        </Button>
      </div>
    </div>
  );
});
