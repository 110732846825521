import { updateVariation } from 'http/variationApi';

import { FC, useState } from 'react';

import { Button, Modal } from 'antd';
import { variationStatuses } from 'common/constants';
import { IServerError } from 'common/types';
import { IVariation } from 'common/types/product';
import { calculateDiscount } from 'common/utils';
import { variationContentSchema } from 'common/validation';
import {
  Form,
  FormInput,
  FormNumberInput,
  FormList,
  FormSelect,
} from 'components/Form';
import { useFormContext, UseFormReturn } from 'react-hook-form';
import { VariationModel } from 'store/variation-model';

import styles from './edit-variation-modal.module.scss';

export interface IEditVariationModalProps {
  isOpen: boolean;
  closeModal: () => void;
  model: VariationModel;
  refetchProduct: () => void;
}

const FormContent: FC = () => {
  const { watch } = useFormContext();

  const displayedPrice = watch(['price', 'discount']);

  return (
    <>
      <div className={styles.separator} />
      {/* at the request of customers, we temporarily hid this information */}
      {/* <FormMarkdown required name="description" label="Description" /> */}
      {/* <div className={styles.separator} /> */}
      <FormSelect
        name="status"
        label="Status"
        options={variationStatuses.map((status) => ({
          label: status,
          value: status,
        }))}
        zIndex={1001}
      />
      <div className={styles.separator} />
      <div className={styles.inputBlock}>
        <FormNumberInput
          className={styles.number}
          precision={2}
          name="price"
          label="Price"
          min={1}
        />
        <FormNumberInput
          className={styles.number}
          name="discount"
          label="Discount"
          precision={2}
          min={0}
          max={99.9}
        />
        <div className={styles.displayedPrice}>
          <div>Displayed price:</div>
          <b>
            {calculateDiscount(displayedPrice[0], displayedPrice[1]).toFixed(2)}
          </b>
        </div>
      </div>
      <div className={styles.separator} />
      <div className={styles.inputBlock}>
        <FormInput className={styles.text} name="sku" label="SKU" />
        <FormInput className={styles.text} name="barcode" label="Barcode" />
      </div>
      <div className={styles.separator} />
      <div className={styles.orderLinkBlock}>
        <FormList name="order_link" label="Order links" />
      </div>
    </>
  );
};

export const EditVariationModal: FC<IEditVariationModalProps> = ({
  isOpen,
  closeModal,
  model,
  refetchProduct,
}) => {
  const [loading, setLoading] = useState(false);

  const onFinish = async (
    values: IVariation['content'],
    form: UseFormReturn<any>,
  ) => {
    setLoading(true);
    try {
      const serializedVariation = model.serializeVariation(values);
      await updateVariation(model.id, serializedVariation);
      refetchProduct();
      closeModal();
    } catch (e) {
      // ToDo: refactor error handling
      form.setError('sku', {
        message: (e as unknown as IServerError).response.data.message[0],
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={isOpen}
      title={
        <div className={styles.editTitle}>Edit {model.variationTitle}</div>
      }
      footer={null}
      onCancel={closeModal}
      destroyOnClose
    >
      <Form
        initialValues={{
          // at the request of clients, we have temporarily hidden this information
          // description: model.variation.content.description,
          price: model.price,
          discount: model.variation.content.discount,
          sku: model.sku,
          barcode: model.variation.content.barcode,
          status: model.status,
          order_link: model.variation.content.order_link,
        }}
        validation={variationContentSchema}
        onSubmit={onFinish}
      >
        <FormContent />
        <div className={styles.buttonsContainer}>
          <Button onClick={closeModal}>Cancel</Button>
          <Button
            disabled={loading}
            loading={loading}
            type="primary"
            htmlType="submit"
          >
            Save
          </Button>
        </div>
      </Form>
    </Modal>
  );
};
