import { IUser } from 'common/types';
import { computed, makeObservable, observable } from 'mobx';

export class UserModel {
  _user: IUser;

  get user() {
    return this._user;
  }

  set user(user: IUser) {
    this._user = user;
  }

  get id() {
    return this._user.id;
  }

  get inactiveSince() {
    return this._user.inactiveSince;
  }

  get firstName() {
    return this._user.firstName;
  }

  get lastName() {
    return this._user.lastName;
  }

  get phone() {
    return this._user.phone;
  }

  get email() {
    return this._user.email;
  }

  get shippingAddress() {
    return this._user.shippingAddress;
  }

  get billingAddress() {
    return this._user.billingAddress;
  }

  get isActive() {
    return !this._user.inactiveSince;
  }

  constructor(user: IUser) {
    this._user = user;

    makeObservable(this, {
      _user: observable,
      user: computed,
      id: computed,
      inactiveSince: computed,
      firstName: computed,
      lastName: computed,
      phone: computed,
      email: computed,
      shippingAddress: computed,
      billingAddress: computed,
      isActive: computed,
    });
  }
}
