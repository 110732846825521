import { makeAutoObservable } from 'mobx';

import { EmployeesViewModel } from './employees-view-model';
import { SuppliersViewModel } from './suppliers-view-model';

export class EmployeesPageViewModel {
  _employeesViewModel = new EmployeesViewModel(this);
  _suppliersViewModel = new SuppliersViewModel(this);

  get employeesViewModel() {
    return this._employeesViewModel;
  }

  get suppliersViewModel() {
    return this._suppliersViewModel;
  }

  constructor() {
    makeAutoObservable(this);
  }
}
