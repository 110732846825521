import { FC, useState } from 'react';

import { PlusCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { promocodeDeleteMessage } from 'common/constants';
import { TTablePaginationParams } from 'common/types/common-types';
import { IPromocode } from 'common/types/promocode';
import { useModal } from 'common/utils';
import { actions, checkbox, Table, text } from 'components/Table';
import { observer } from 'mobx-react-lite';
import { PromocodeModel } from 'store/promocode-model';

import { PromopcodeViewModel } from '../promocode-view-model';

import { PromoCreateModal } from './PromoCreateModal';

import styles from './table-content.module.scss';

interface ITableContentProps {
  model: PromopcodeViewModel;
}

export const TableContent: FC<ITableContentProps> = observer(({ model }) => {
  const [isOpen, openModal, closeModal] = useModal(false);
  const [editablePromo, setEditablePromo] = useState<PromocodeModel | null>(
    null,
  );

  const handleEdit = (id: IPromocode['id']) => {
    const selectedPromocode = model.promocodes.find(
      (promocode) => promocode.id === id,
    );

    if (selectedPromocode) {
      setEditablePromo(selectedPromocode);
    }

    openModal();
  };

  const handleCloseModal = () => {
    closeModal();

    setEditablePromo(null);
  };

  const columns = [
    text({
      width: '30px',
      title: '№',
      dataIndex: 'id',
      loading: model.loading,
    }),
    text({
      width: '150px',
      title: 'Promocode',
      dataIndex: 'promocode',
      loading: model.loading,
    }),
    text({
      width: '150px',
      title: 'Type of promocode',
      dataIndex: 'promoType',
      loading: model.loading,
    }),
    text({
      width: '70px',
      title: 'Discount',
      dataIndex: 'infoDiscountValue',
      loading: model.loading,
    }),
    text({
      width: '80px',
      title: 'Sum to get discount',
      dataIndex: 'purchaseSum',
      loading: model.loading,
    }),
    checkbox({
      width: '50px',
      title: 'Active',
      dataIndex: 'isActive',
      key: 'isActive',
      loading: model.loading,
    }),
    text({
      width: '50px',
      title: 'Uses left',
      dataIndex: 'usesLeft',
      loading: model.loading,
    }),
    actions({
      width: '30px',
      onEdit: handleEdit,
      onDelete: model.removePromocode,
      disabled: model.loading,
      deleteMessage: promocodeDeleteMessage,
    }),
  ];

  const handleChangeTable = (pagination: TTablePaginationParams) => {
    model.refetchPromocodes(pagination);
  };

  return (
    <>
      <div className={styles.header}>
        <Button
          type="primary"
          icon={<PlusCircleOutlined />}
          size="middle"
          onClick={openModal}
        >
          Add Promocode
        </Button>
      </div>
      <Table
        bordered
        loading={model.loading}
        skeleton={model.skeleton}
        data={model.promocodes}
        columns={columns}
        pagination={{
          total: model.count,
          disabled: model.loading,
        }}
        onChange={handleChangeTable}
      />
      <PromoCreateModal
        closeModal={handleCloseModal}
        editablePromo={editablePromo}
        model={model}
        isOpen={isOpen}
      />
    </>
  );
});
