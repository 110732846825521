import { FC, useLayoutEffect, useState } from 'react';

import { LogoutOutlined } from '@ant-design/icons';
import { Button, Menu } from 'antd';
import type { MenuProps } from 'antd';
import Sider from 'antd/es/layout/Sider';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import Logo from 'assets/images/natalex-logo.jpeg';
import { useCurrentUserStore } from 'common/providers/store-provider';
import { sidebarLinks } from 'common/routes';
import { ICurrentUser } from 'common/types';
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';

import styles from './styles.module.scss';

const getMenuItems = (currentUser: ICurrentUser, navigate: NavigateFunction) =>
  sidebarLinks.reduce((acc, curr) => {
    if (curr.permissions[currentUser.role]) {
      const child = {
        key: curr.key,
        label: curr.title,
        children: curr.children.map((child) =>
          child.permissions[currentUser.role] && !child.hidden
            ? {
                key: child.title,
                label: child.title,
                onClick: () => {
                  navigate(child.path);
                },
              }
            : null,
        ),
      };

      acc.push(child);
    }

    return acc;
  }, [] as ItemType[]);

const Sidebar: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentUserStore = useCurrentUserStore();

  const [selectKey, setSelectKey] = useState<string[]>(['Brand']);
  const [openKeys, setOpenKeys] = useState<string[]>(['subProduct']);
  const { currentUser, logout } = currentUserStore;

  useLayoutEffect(() => {
    const firstLevelUrl = location.pathname.split('/')[1];

    const selectedMenuItem = sidebarLinks.find((link) =>
      link.children.find((linkChild) => linkChild.key === firstLevelUrl),
    );

    const selectedSubMenuItem = selectedMenuItem?.children.find(
      (menuItem) => menuItem.key === firstLevelUrl,
    );

    if (setOpenKeys.length === openKeys.length) {
      setOpenKeys([`sub${selectedMenuItem?.title}`]);
    }
    setSelectKey([selectedSubMenuItem?.title ?? 'Brand']);
  }, [location.pathname, openKeys.length]);

  const onOpenChange: MenuProps['onOpenChange'] = (keys) => {
    setOpenKeys(keys);
  };

  return (
    <Sider theme="light" className={styles.container}>
      <div>
        <img src={Logo} alt="Natalex admin" className={styles.logo} />
      </div>
      <Menu
        theme="light"
        mode="inline"
        selectedKeys={selectKey}
        openKeys={openKeys}
        className={styles.menu}
        onOpenChange={onOpenChange}
        items={getMenuItems(currentUser as ICurrentUser, navigate)}
      />
      <div className={styles.footer}>
        <Button onClick={logout} icon={<LogoutOutlined />}>
          Logout
        </Button>
      </div>
    </Sider>
  );
};

export default Sidebar;
