import { defaultDetailedOrderEntity } from 'common/constants';
import { IDetailedOrder } from 'common/types';
import {
  EPaymentCancelledStatuses,
  EDisputeStatuses,
} from 'common/types/order/enums';
import { mockIdKeyGen, parseToShortDate } from 'common/utils';
import { makeAutoObservable } from 'mobx';

export class DetailedOrderModel {
  _order: IDetailedOrder = defaultDetailedOrderEntity;

  set order(order: IDetailedOrder) {
    this._order = order;
  }

  get order() {
    return this._order;
  }

  get key() {
    return !!this.order.id ? this.order.id : mockIdKeyGen();
  }

  get id() {
    return this.order.id;
  }

  get buyerName() {
    return this._order.buyer;
  }

  get cardBrand() {
    return this._order.cardBrand;
  }

  get cardLastDigits() {
    return this._order.cardLast4;
  }

  get createdAt() {
    return parseToShortDate(this._order.createdAt);
  }

  get delivery() {
    return this._order.delivery;
  }

  get appliedPromocode() {
    return this._order.appliedPromocode;
  }

  get promoDiscount() {
    return this._order.promoDiscount;
  }

  get kitDiscount() {
    return this._order.kitDiscount;
  }

  get discount() {
    return this._order.discount;
  }

  get dispute() {
    return this._order.dispute;
  }

  get efficiency() {
    return this._order.efficiency;
  }

  get items() {
    return this._order.items;
  }

  get payment() {
    return this.order.payment;
  }

  get isCancelled() {
    return !!EPaymentCancelledStatuses[
      this.order.payment as EPaymentCancelledStatuses
    ];
  }

  get isOngoingDispute() {
    return (
      this.order.dispute === EDisputeStatuses.DISPUTE_STRIPE_DISPUTE_STARTED ||
      this.order.dispute === EDisputeStatuses.DISPUTE_STRIPE_DISPUTE_REVIEW
    );
  }

  get disabled() {
    return this.isCancelled || this.isOngoingDispute;
  }

  get purchasing() {
    return this.order.purchasing;
  }

  get refund() {
    return this.order.refund;
  }

  get return() {
    return this._order.return;
  }

  get sending() {
    return this._order.sending;
  }

  get shipments() {
    return this._order.shipments;
  }

  get shipping() {
    return this._order.shipping;
  }

  get shippingFinal() {
    return this._order.shippingFinal;
  }

  get todo() {
    return this._order.todo;
  }

  get refunded() {
    return this._order.refunded;
  }

  get total() {
    return this._order.total;
  }

  get totalItem() {
    return this._order.totalItem;
  }

  get totalPrice() {
    return this._order.totalPrice;
  }

  get totalPriceFinal() {
    return this._order.totalPriceFinal;
  }

  get amountPaid() {
    return this._order.amountPaid;
  }

  get user() {
    return this._order.user;
  }

  get userData() {
    return this._order.userData;
  }

  get itemStats() {
    return this._order.itemStats;
  }

  constructor(order: IDetailedOrder) {
    this._order = order;

    makeAutoObservable(this);
  }
}
