import { AxiosResponse } from 'axios';
import { IGetLogByOrderIdResponse } from 'common/types/log';

import { $host } from './index';

export const getLogsByOrderId = async (orderId: number) => {
  const { data } = await $host.get<AxiosResponse<IGetLogByOrderIdResponse>>(
    `admin/logs/${orderId}`,
  );

  return data;
};
