import {
  editVariationOrderLinks,
  syncVariationOrderLink,
} from 'http/purchaseApi';

import { defaultPhoto } from 'common/constants';
import { IOrderItem } from 'common/types/purchase';
import { cents2dollars, getVariationView, notify } from 'common/utils';
import { makeAutoObservable } from 'mobx';

import { IPurchase } from '../types';

import { PurchasesViewModel } from './purchases-view-model';

export type TPurchaseStatus = 'origin' | 'added' | 'updated' | 'removed';

export class PurchaseViewModel {
  _root: PurchasesViewModel | null = null;
  _isLoading = false;
  _isAddLinksModalOpen = false;
  _purchase: IPurchase;
  _status: TPurchaseStatus = 'origin';
  _quantityUpdated = 1;

  get root() {
    return this._root;
  }

  get disabled() {
    return !!this.root?.root?.order?.disabled;
  }

  get isLoading() {
    return this._isLoading;
  }

  set isLoading(isLoading: boolean) {
    this._isLoading = isLoading;
  }

  get isAddLinksModalOpen() {
    return this._isAddLinksModalOpen;
  }

  set isAddLinksModalOpen(isOpen: boolean) {
    this._isAddLinksModalOpen = isOpen;
  }

  get purchase() {
    return this._purchase;
  }

  set purchase(purchase: IPurchase) {
    this._purchase = purchase;
  }

  get status() {
    return this._status;
  }

  set status(status: TPurchaseStatus) {
    this._status = status;
  }

  get quantityUpdated() {
    return this._quantityUpdated;
  }

  set quantityUpdated(quantityUpdated: number) {
    this._quantityUpdated = quantityUpdated;
  }

  get isDecreasedQuantity() {
    return this.quantity > this.quantityUpdated;
  }

  get isIncreasedQuantity() {
    return this.quantity < this.quantityUpdated;
  }

  get id() {
    return this.purchase.id;
  }

  get sku() {
    return this.purchase.sku;
  }

  get productKit() {
    return this.purchase.productKit;
  }

  get quantity() {
    return this.purchase.quantity;
  }

  get title() {
    return this.purchase.title;
  }

  get variation() {
    return this.purchase.variation;
  }

  get photo() {
    return this.purchase.photo;
  }

  get variationView() {
    return getVariationView(this.purchase.variation.content);
  }

  get price() {
    return this.purchase.price;
  }

  get dollarPrice() {
    return cents2dollars(this.purchase.price);
  }

  get orderLinks() {
    return this.purchase.orderLinks;
  }

  getPurchase(orderItem: IOrderItem): IPurchase {
    const { variation, productKit, quantity, adjustedPrice } = orderItem;
    const { content, product } = variation;

    const photo =
      content.photos?.find((photo) => photo.id === content.mainPhotoId) ??
      defaultPhoto;

    return {
      id: orderItem.id,
      sku: content.sku ?? '0',
      quantity: quantity,
      price: adjustedPrice,
      title: product.title,
      variation: variation,
      photo: photo,
      orderLinks: content.order_link,
      productKit: productKit,
    };
  }

  onAddLinksModalOpen = () => {
    this.isAddLinksModalOpen = true;
  };

  onAddLinksModalClose = () => {
    this.isAddLinksModalOpen = false;
  };

  onAddLinksModalSubmit = async (links: { url: string }[]) => {
    const orderId = this.root?.root?.order?.id;

    if (!orderId) {
      return;
    }

    try {
      this.isLoading = true;

      const variationId = this.variation.id;

      const formattedLinks = links
        .filter((el) => el.url.trim().length)
        .map((el) => el.url);

      const params = { variationId: variationId, order_link: formattedLinks };

      const { items } = await editVariationOrderLinks(orderId, params);

      const updatedItem = items.find(
        (item) => item.variation.id === variationId,
      );

      // TODO: make proper formatting for updated purchase
      this.purchase = {
        ...this.purchase,
        orderLinks: updatedItem?.variation?.content?.order_link ?? [],
      };
      this.isAddLinksModalOpen = false;
    } catch (e) {
      const error = e as Error;

      notify(`${error.message}`, 'error');
    } finally {
      this.isLoading = false;
    }
  };

  onOrderLinkSync = async () => {
    try {
      this.isLoading = true;

      const orderId = this.root?.root?.order?.id;

      if (!orderId) {
        return;
      }

      const variationId = this.variation.id;

      const updatedOrder = await syncVariationOrderLink(orderId, variationId);
      const updatedOrderItem = updatedOrder.items.find(
        (item) => item.variation.id === variationId,
      );

      this.purchase = {
        ...this.purchase,
        orderLinks: updatedOrderItem?.variation.content.order_link ?? [],
      };
    } catch (e) {
      const error = e as Error;

      notify(`${error.message}`, 'error');
    } finally {
      this.isLoading = false;
    }
  };

  onQuantityChange = (value: number) => {
    this.quantityUpdated = value;

    if (this.status === 'origin' && this.quantity !== this.quantityUpdated) {
      this.status = 'updated';
    }

    if (this.status === 'updated' && this.quantity === this.quantityUpdated) {
      this.status = 'origin';
    }
  };

  onRemove = () => {
    this.status = 'removed';
  };

  constructor(root: PurchasesViewModel | null, orderItem: IOrderItem) {
    if (root) {
      this._root = root;
    }

    this._purchase = this.getPurchase(orderItem);
    this._quantityUpdated = orderItem.quantity;

    makeAutoObservable(this);
  }
}
