import { AxiosResponse } from 'axios';
import { IOrderItem } from 'common/types/purchase';

import {
  IProduct,
  IVariation,
  IVariationAttributesUpdate,
  IVariationsObject,
} from '../common/types/product';

import { $host } from './index';

const headers = {
  'Content-Type': 'multipart/form-data',
};

export const createVariationByProductId = async (
  productId: number,
  variations: IVariationsObject,
) => {
  const { data } = await $host.post<AxiosResponse<IVariation['content']>>(
    'admin/products/variations',
    {
      productId,
      variations,
    },
  );

  return data.data;
};

export const getVariationBySku = async (sku: number | string) => {
  const { data } = await $host.get<
    AxiosResponse<IOrderItem['variation'] | null>
  >(`admin/products/variations/sku/${sku}`);

  return data;
};

export const deleteById = async (id: IProduct['id']) => {
  const { data } = await $host.delete(`admin/products/variations/${id}`);

  return data;
};

export const updateVariation = async (id: number, variation: FormData) => {
  const {
    data: { data },
  } = await $host.put<AxiosResponse<IVariation['content'][]>>(
    `admin/products/variations/content/${id}`,
    variation,
    { headers },
  );

  return data;
};

export const deleteVariationPhotos = async (
  variationId: number,
  photoIds: number[],
) => {
  const { data } = await $host.delete<AxiosResponse<IVariation>>(
    `/admin/products/${variationId}/photos`,
    {
      data: {
        photoIds,
      },
    },
  );

  return data.data;
};

export const setVariationMainPhoto = async (
  variationId: number,
  photoId: number,
) => {
  const { data } = await $host.patch(
    `/admin/products/${variationId}/main-photo/${photoId}`,
  );

  return data.data;
};

export const editVariation = async (
  productId: number | undefined,
  variations: IVariationAttributesUpdate[],
) => {
  const { data } = await $host.patch(
    `/admin/products/${productId}/variations/attributes`,
    variations,
  );

  return data.data;
};

export const deleteVariationAttribute = async (
  productId: number,
  options: string[],
) => {
  const {
    data: { data },
  } = await $host.delete<AxiosResponse<IVariation>>(
    `/admin/products/${productId}/variations/attributes`,
    { params: { options } },
  );

  return data;
};
