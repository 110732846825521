import { FC, useMemo, useState } from 'react';

import { Button, Divider } from 'antd';
import Title from 'antd/es/typography/Title';
import { destinationDefaultOptions } from 'common/constants';
import { IServerError } from 'common/types';
import { ICreateProduct } from 'common/types/product';
import { useModal } from 'common/utils';
import { productCreateSchema, productUpdateSchema } from 'common/validation';
import { Form, FormSelect } from 'components/Form';
import MetaTagCard from 'components/MetaTagCard';
import { Spinner } from 'components/Spinner';
import VariantsCard from 'components/VariantsCard';
import DoneVariationBar from 'components/VariantsCard/DoneVariationBar';
import { observer } from 'mobx-react-lite';
import { UseFormReturn } from 'react-hook-form';
import { useParams, useNavigate, useLocation } from 'react-router-dom';

import { ChangeAllModal } from './ChangeAllModal';
import { MediaFormContent } from './MediaFormContent';
import { ProductControllerViewModel } from './product-controller-view-model';
import { ProductFormContent } from './ProductFormContent';

import styles from './product-controller.module.scss';

interface IChangeAllBtnProps {
  model: ProductControllerViewModel;
}
interface IProductControllerProps {
  model: ProductControllerViewModel;
}

const ChangeAllBtn: FC<IChangeAllBtnProps> = ({ model }) => {
  const [isOpen, openModal, closeModal] = useModal(false);

  return (
    <>
      <Button type="default" onClick={openModal}>
        Change all
      </Button>
      <ChangeAllModal open={isOpen} onCancel={closeModal} model={model} />
    </>
  );
};

const ProductController: FC<IProductControllerProps> = observer(({ model }) => {
  const [loading, setLoading] = useState(false);
  const { search } = useLocation();
  const navigate = useNavigate();

  const isUpdate = !!model.product.id;
  const eventText = isUpdate ? 'Update' : 'Create';
  const schema = useMemo(() => {
    return !!isUpdate ? productUpdateSchema : productCreateSchema;
  }, [isUpdate]);

  const onSubmit = async (values: ICreateProduct, form: UseFormReturn<any>) => {
    setLoading(true);

    try {
      await model.manageProduct(isUpdate, values);

      navigate({
        pathname: '/product',
        search: search,
      });
    } catch (error) {
      form.setError('sku', {
        message: (error as unknown as IServerError).response.data.message[0],
      });
    } finally {
      setLoading(false);
    }
  };

  const cancel = () =>
    navigate({
      pathname: '/product',
      search: search,
    });

  if (model.loading) {
    return (
      <div className={styles.formLoading}>
        <Spinner type="large" />
      </div>
    );
  }

  return (
    <div className={styles.createProductPage}>
      <Title level={5} type="secondary" className={styles.title}>
        {`${eventText} product`}
      </Title>
      <Form
        validation={schema}
        initialValues={{ ...model.initialFormValues }}
        onSubmit={onSubmit}
        scrollToError
      >
        <div className={styles.product}>
          <ProductFormContent model={model} />
          <MediaFormContent model={model} />
        </div>
        <Divider />
        <Title level={5} type="secondary" className={styles.title}>
          <span>Variants</span>
          <ChangeAllBtn model={model} />
        </Title>
        {isUpdate && <DoneVariationBar model={model} />}
        {!isUpdate && <VariantsCard model={model} />}
        {isUpdate && (
          <>
            <Divider />
            <div className={styles.meta}>
              <Title level={5} type="secondary" className={styles.title}>
                Search engine list
              </Title>
              <MetaTagCard />
            </div>
          </>
        )}
        <Divider />
        <FormSelect
          mode="multiple"
          name="googleMerchantsDestinations"
          label="Google destinations"
          placeholder="Select destinations"
          options={destinationDefaultOptions}
          className={styles.field}
        />
        <Divider />
        <div className={styles.buttons}>
          <Button
            disabled={loading || model.loading}
            onClick={cancel}
            type="default"
          >
            Cancel
          </Button>
          <Button
            loading={loading || model.loading}
            disabled={loading || model.loading}
            type="primary"
            htmlType="submit"
          >
            {eventText}
          </Button>
        </div>
      </Form>
    </div>
  );
});

export const ProductControllerPage = () => {
  const { productId } = useParams();

  const model = new ProductControllerViewModel(Number(productId));

  return <ProductController model={model} />;
};
