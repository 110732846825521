import { FC } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { observer } from 'mobx-react-lite';
import { PurchaseViewModel } from 'pages/NewOrder/view-models/purchase-view-model';

import { EditModalPurchase } from '../EditModalPurchase';

import styles from './edit-purchases-modal.module.scss';

export interface IEditPurchasesModalProps {
  open: boolean;
  purchases: PurchaseViewModel[];
  onOk: () => void;
  onCancel: () => void;
  onAddModalOpen: () => void;
  onKitRemove: (id: number) => void;
}

export const EditPurchasesModal: FC<IEditPurchasesModalProps> = observer(
  ({ open, purchases, onOk, onCancel, onAddModalOpen, onKitRemove }) => {
    const handlePurchaseRemove = (purchase: PurchaseViewModel) => {
      const kitId = purchase.productKit?.id;

      kitId ? onKitRemove(kitId) : purchase.onRemove();
    };

    return (
      <Modal
        open={open}
        title="Edit purchases"
        destroyOnClose
        width="auto"
        onOk={onOk}
        onCancel={onCancel}
      >
        <div>
          <div className={styles.header}>
            <Button type="primary" onClick={onAddModalOpen}>
              <PlusOutlined />
              Add item
            </Button>
          </div>
          <div className={styles.content}>
            {purchases
              .filter((purchase) => purchase.status !== 'removed')
              .map((purchase) => (
                <EditModalPurchase
                  key={purchase.id}
                  photo={purchase.photo}
                  title={purchase.title}
                  variationView={purchase.variationView}
                  quantity={purchase.quantityUpdated}
                  price={purchase.dollarPrice}
                  sku={purchase.sku}
                  kit={purchase.productKit?.id}
                  onQuantityChange={purchase.onQuantityChange}
                  onRemove={() => handlePurchaseRemove(purchase)}
                />
              ))}
          </div>
        </div>
      </Modal>
    );
  },
);
