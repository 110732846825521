import { FC } from 'react';

import { Skeleton } from 'antd';
import { ColumnType } from 'antd/es/table';
import { BrandViewModel } from 'pages/Brand/brand-view-model';

export type TCounterColumn<T> = ColumnType<T> & { loading?: boolean };

interface ICounterProps {
  active: number;
  total: number;
  isLoading?: boolean;
}
export interface IBrandProps {
  model: BrandViewModel;
}

export const Counter: FC<ICounterProps> = ({ active, total, isLoading }) => (
  <>
    {isLoading ? (
      <Skeleton paragraph={{ rows: 1 }} active />
    ) : (
      <span>
        {active}/{total}
      </span>
    )}
  </>
);

export const counter = <T,>({
  dataIndex = '',
  key = '',
  width = '5%',
  loading,
  ...props
}: TCounterColumn<T>): ColumnType<T> => ({
  dataIndex: dataIndex,
  key: key,
  width: width,
  render: (record: { active: number; total: number }) => (
    <Counter active={record.active} total={record.total} isLoading={loading} />
  ),
  ...props,
});
