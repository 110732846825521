import { getOrder } from 'http/orderApi';

import { ILongOrder, IServerError } from 'common/types';
import { notify } from 'common/utils';
import { makeAutoObservable } from 'mobx';

import { LongOrderModel } from '../models/long-order-model';

import { BuyerDetailsViewModel } from './buyer-details-view-model';
import { ControlsViewModel } from './controls-view-model';
import { HistoryViewModel } from './history-view-model';
import { OrderViewModel } from './order-view-model';
import { PerformanceViewModel } from './performance-view-model';
import { PurchasesViewModel } from './purchases-view-model';
import { ReturnsViewModel } from './returns-view-model';
import { ShipmentsViewModel } from './shipments-view-model';
import { StatusesViewModel } from './statuses-view-model';
import { SummaryViewModel } from './summary-view-model';

export class ExpandedOrderViewModel {
  _root: OrderViewModel | null = null;
  _isLoading = false;
  _order: LongOrderModel | null = null;
  _purchasesViewModel: PurchasesViewModel | null = null;
  _shipmentsViewModel: ShipmentsViewModel | null = null;
  _returnsViewModel: ReturnsViewModel | null = null;
  _historyViewmodel: HistoryViewModel | null = null;
  _statusesViewModel: StatusesViewModel | null = null;
  _summaryViewModel: SummaryViewModel | null = null;
  _performanceViewModel: PerformanceViewModel | null = null;
  _controlsViewModel: ControlsViewModel | null = null;
  _buyerDetailsViewModel: BuyerDetailsViewModel | null = null;

  get root() {
    return this._root;
  }

  get isLoading() {
    return this._isLoading;
  }

  set isLoading(isLoading: boolean) {
    this._isLoading = isLoading;
  }

  get order() {
    return this._order;
  }

  set order(order: LongOrderModel | null) {
    this._order = order;
  }

  get purchasesViewModel() {
    return this._purchasesViewModel;
  }

  set purchasesViewModel(purchasesViewModel: PurchasesViewModel | null) {
    this._purchasesViewModel = purchasesViewModel;
  }

  get shipmentsViewModel() {
    return this._shipmentsViewModel;
  }

  set shipmentsViewModel(shipmentsViewModel: ShipmentsViewModel | null) {
    this._shipmentsViewModel = shipmentsViewModel;
  }

  get returnsViewModel() {
    return this._returnsViewModel;
  }

  set returnsViewModel(returnsViewModel: ReturnsViewModel | null) {
    this._returnsViewModel = returnsViewModel;
  }

  get historyViewModel() {
    return this._historyViewmodel;
  }

  set historyViewModel(historyViewModel: HistoryViewModel | null) {
    this._historyViewmodel = historyViewModel;
  }

  get statusesViewModel() {
    return this._statusesViewModel;
  }

  set statusesViewModel(statusesViewModel: StatusesViewModel | null) {
    this._statusesViewModel = statusesViewModel;
  }

  get summaryViewModel() {
    return this._summaryViewModel;
  }

  set summaryViewModel(summaryViewModel: SummaryViewModel | null) {
    this._summaryViewModel = summaryViewModel;
  }

  get performanceViewModel() {
    return this._performanceViewModel;
  }

  set performanceViewModel(performanceViewModel: PerformanceViewModel | null) {
    this._performanceViewModel = performanceViewModel;
  }

  get controlsViewModel() {
    return this._controlsViewModel;
  }

  set controlsViewModel(controlsViewModel: ControlsViewModel | null) {
    this._controlsViewModel = controlsViewModel;
  }

  get buyerDetailsViewModel() {
    return this._buyerDetailsViewModel;
  }

  set buyerDetailsViewModel(
    buyerDetailsViewModel: BuyerDetailsViewModel | null,
  ) {
    this._buyerDetailsViewModel = buyerDetailsViewModel;
  }

  getOrder = async (id: number, handling?: boolean) => {
    try {
      handling && (this.isLoading = true);

      const { data: order } = await getOrder(id);

      this.order = new LongOrderModel(order as ILongOrder);
      this.purchasesViewModel = new PurchasesViewModel(this, order.items);
      this.shipmentsViewModel = new ShipmentsViewModel(this, order.shipments);
      this.returnsViewModel = new ReturnsViewModel(this, order.returnShipments);
      this.historyViewModel = new HistoryViewModel(this, order.id);
      this.statusesViewModel = new StatusesViewModel(this, order as ILongOrder);
      this.summaryViewModel = new SummaryViewModel(this, order as ILongOrder);
      (this.root?.root?.user.isAdmin || this.root?.root?.user.isManager) &&
        (this.performanceViewModel = new PerformanceViewModel(
          this,
          order as ILongOrder,
        ));
      this.controlsViewModel = new ControlsViewModel(this);
      this.buyerDetailsViewModel = new BuyerDetailsViewModel(
        this,
        order.userData,
      );

      return order;
    } catch (e) {
      if (!handling) {
        throw e;
      }

      const error = e as IServerError;

      notify(`Error, reason: ${error.response?.data?.message}`, 'error');
    } finally {
      handling && (this.isLoading = false);
    }
  };

  syncOrderData = async (order: ILongOrder) => {
    this.order?.order && (this.order.order = order);
    this.purchasesViewModel?.syncOrderData(order);
    this.shipmentsViewModel?.syncOrderData(order);
    this.returnsViewModel?.syncOrderData(order);
    this.historyViewModel?.syncOrderData(order);
    this.statusesViewModel?.syncOrderData(order);
    this.summaryViewModel?.syncOrderData(order);
    (this.root?.root?.user.isAdmin || this.root?.root?.user.isManager) &&
      this.performanceViewModel?.syncOrderData(order);
  };

  fetchData = async (id: number) => {
    this.isLoading = true;

    try {
      await Promise.all([this.getOrder(id)]);
    } catch (e) {
      const error = e as IServerError;

      notify(`Error, reason: ${error.response?.data?.message}`, 'error');
    } finally {
      this.isLoading = false;
    }
  };

  constructor(root: OrderViewModel | null, id: number) {
    if (root) {
      this._root = root;
    }

    this.fetchData(id);

    makeAutoObservable(this);
  }
}
