import { FC } from 'react';

import { Spin, Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import { PerformanceViewModel } from 'pages/NewOrder/view-models/performance-view-model';

import { StatItem } from '../StatItem';

import styles from './performance.module.scss';

interface IPerformanceProps {
  model: PerformanceViewModel | null;
}

export const Performance: FC<IPerformanceProps> = observer(({ model }) => (
  <div className={styles.container}>
    <div className={styles.header}>
      <Typography.Title level={4} className={styles.title}>
        Performance
      </Typography.Title>
    </div>
    <div className={styles.content}>
      {!model ? (
        <Spin className={styles.spin} />
      ) : (
        <div className={styles.items}>
          <StatItem label="Shipments" value={model.performance.shipments} />
          <StatItem
            label="Purchase cost"
            value={model.performance.purchaseCost}
          />
          <StatItem
            label="Shipping cost"
            value={model.performance.shippingCost}
          />
          <StatItem label="Fees" value={model.performance.fees} />
          <StatItem label="Margin, $" value={model.performance.margin} />
          <StatItem label="Margin, %" value={model.performance.marginPercent} />
        </div>
      )}
    </div>
  </div>
));
