import { FC } from 'react';

import { Typography } from 'antd';

import { IStaticField, StaticField } from '.';

import styles from './edit-section.module.scss';

export interface ISectionProps {
  title: string;
  staticFields: IStaticField[];
}

export const Section: FC<ISectionProps> = ({ title, staticFields }) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Typography.Text strong>{title}</Typography.Text>
      </div>
      <div>
        {staticFields.map((row, index) => (
          <StaticField key={index} row={row} />
        ))}
      </div>
    </div>
  );
};
