import { useLayoutEffect, useRef, useState } from 'react';

import { Table as AntdTable, TableProps } from 'antd';
import { useWindowSize } from 'common/hook/useWindowSize';

import styles from './table.module.scss';

export interface ITableProps<T extends object> extends TableProps<T> {
  data: T[];
  fixedHeader?: boolean;
  // need for correct fixed header
  headerHeight?: number;
  fixedHeight?: number;
  skeleton?: T[];
  loading?: boolean;
}

// magic number which includes heading with button and search common container paddings and pagination
const HEADING_INITIAL_HEIGHT = 48 + 64;
const HEADING_DEFAULT_HEIGHT = 48;
const TABLE_DEFAULT_HEIGHT = 540;
const PAGINATION_PAGE_SIZE = 50;

export const Table = <T extends object>({
  columns,
  data,
  fixedHeader,
  headerHeight = HEADING_DEFAULT_HEIGHT,
  fixedHeight,
  pagination,
  skeleton,
  loading,
  ...props
}: ITableProps<T>) => {
  const windowSize = useWindowSize();
  const tableRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(TABLE_DEFAULT_HEIGHT);

  const showSkeleton = !!(loading && skeleton);
  const showLoading = !!(loading && !skeleton);

  const scrollProp = {
    x: '100%',
    y: fixedHeader ? fixedHeight || height : '100%',
  };

  const paginationProps = !!pagination && {
    pageSize: PAGINATION_PAGE_SIZE,
    showSizeChanger: false,
    ...pagination,
  };

  useLayoutEffect(() => {
    if (fixedHeader && tableRef?.current) {
      const rect = tableRef.current.getBoundingClientRect();

      setHeight(rect.height - (HEADING_INITIAL_HEIGHT + headerHeight));
    }
  }, [fixedHeader, headerHeight, windowSize.height]);

  return (
    <div ref={tableRef} className={styles.table}>
      <AntdTable
        columns={columns}
        dataSource={showSkeleton ? skeleton : data}
        scroll={scrollProp}
        pagination={paginationProps}
        loading={showLoading}
        {...props}
      />
    </div>
  );
};
