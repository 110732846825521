import { FC } from 'react';

import { Select, SelectProps, Tag, Typography } from 'antd';

import styles from './status.module.scss';

export interface IStatusProps {
  label: string;
  value?: string;
  values?: string[];
  field?:
    | { type: 'single' }
    | { type: 'multiple' }
    | { type: 'select'; props: SelectProps };
}

export const Status: FC<IStatusProps> = ({
  label,
  value,
  values,
  field = { type: 'single' },
}) => (
  <div className={styles.container}>
    <Typography.Text strong>{label}:</Typography.Text>
    {field.type === 'single' && (
      <Tag color="white" className={styles.tag}>
        <Typography.Text>{value}</Typography.Text>
      </Tag>
    )}
    {field.type === 'multiple' && (
      <div className={styles.tags}>
        {values?.map((value) => (
          <Tag key={value} color="white" className={styles.tag}>
            <Typography.Text>{value}</Typography.Text>
          </Tag>
        ))}
      </div>
    )}
    {field.type === 'select' && (
      <Select
        defaultValue={field.props.defaultValue}
        mode={field.props.mode}
        size="small"
        value={field.props.value}
        placeholder={field.props.placeholder}
        options={field.props.options}
        disabled={field.props.disabled}
        loading={field.props.loading}
        className={styles.select}
        onChange={field.props.onChange}
      />
    )}
  </div>
);
