import { FC } from 'react';

import { Typography } from 'antd';
import { observer } from 'mobx-react-lite';

import { ManagedOrderTable } from './ManagedOrderTable';
import { OrderCounterViewModel } from './order-counter-view-model';

interface IOrderPageContentProps {
  model: OrderCounterViewModel;
}

const OrderCounterPageContent: FC<IOrderPageContentProps> = observer(
  ({ model }) => (
    <div>
      <Typography.Title level={1}>Order counter</Typography.Title>
      <ManagedOrderTable model={model} />
    </div>
  ),
);

export const OrderCounterPage = () => {
  const model = new OrderCounterViewModel();

  return <OrderCounterPageContent model={model} />;
};
