import {
  getSupplierShipments,
  getSupplierUnpaidShipments,
} from 'http/supplierApi';
import { paySupplierInvoices } from 'http/supplierInvoiceApi';

import { SorterResult } from 'antd/es/table/interface';
import { TTablePaginationParams } from 'common/types';
import { notify } from 'common/utils';
import { action, computed, makeObservable, observable } from 'mobx';
import { FiltersModel, TFilters } from 'store/filters-model';
import { PackageModel } from 'store/package-model';

import { BillingPageViewModel } from './billing-page-view-model';

export class PackagesViewModel {
  _root: BillingPageViewModel;
  _supplierId: number | undefined;
  _isLoading = false;
  _filters: FiltersModel = new FiltersModel();
  _packages: PackageModel[] = [];

  get supplierId() {
    return this._supplierId;
  }

  get isLoading() {
    return this._isLoading;
  }

  set isLoading(isLoading: boolean) {
    this._isLoading = isLoading;
  }

  get filters() {
    return this._filters;
  }

  set packages(packages: PackageModel[]) {
    this._packages = packages;
  }

  get packages() {
    return this._packages;
  }

  async getPackages() {
    if (!this.supplierId) {
      return;
    }

    try {
      this.isLoading = true;

      const params = {
        page: this.filters.page,
        pageSize: this.filters.pageSize,
        type: this.filters.filters.type?.[0],
        status: this.filters.filters.status?.[0],
      };

      const { shipments, count } = await getSupplierShipments(
        this.supplierId,
        params,
      );

      this.filters.count = count;

      this.packages = shipments.map((shipment) => new PackageModel(shipment));
    } catch (e) {
      notify(`Error, reason:${e}`, 'error');
    } finally {
      this.isLoading = false;
    }

    return null;
  }

  async getUnpaidPackages() {
    if (!this.supplierId) {
      return;
    }

    try {
      const unpaidShipmentData = await getSupplierUnpaidShipments(
        this.supplierId,
      );

      return unpaidShipmentData;
    } catch (e) {
      notify(`Error, reason:${e}`, 'error');
    }
  }

  async payForPackages() {
    if (!this.supplierId) {
      return;
    }

    try {
      await paySupplierInvoices(this.supplierId);

      await this._root.calculationViewModel.getCalculations();
      await this._root.invoicesViewModel.getInvoices();
      await this.getPackages();
    } catch (e) {
      notify(`Error, reason:${e}`, 'error');
    }
  }

  changeFilters = (
    pagination: TTablePaginationParams,
    filters: TFilters,
    _sorter: SorterResult<PackageModel>,
  ) => {
    const { current } = pagination;

    this.filters.filters = filters;
    this._filters.page = current;

    this.getPackages();
  };

  constructor(root: BillingPageViewModel, supplierId?: number) {
    this._root = root;
    this._supplierId = supplierId;

    makeObservable(this, {
      _supplierId: observable,
      _isLoading: observable,
      _filters: observable,
      _packages: observable,
      supplierId: computed,
      isLoading: computed,
      filters: computed,
      packages: computed,
      getPackages: action,
      changeFilters: action,
      payForPackages: action,
    });
  }
}
