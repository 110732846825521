import { FC } from 'react';

import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Tag, Typography } from 'antd';
import { EEmployeeRoles } from 'common/types/employee/enums';
import { useModal } from 'common/utils';
import { Table, text, actions } from 'components/Table';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { EmployeeModel } from 'store/employee-model';

import { EmployeeModal } from '../EmployeeModal';
import { EmployeesViewModel } from '../employees-view-model';
import { IEmployeeFormValues } from '../types';

import styles from './employee-table.module.scss';

interface IEmployeeTableProps {
  model: EmployeesViewModel;
}

interface ILinkCellProps {
  record: EmployeeModel;
}

const getColumns = (
  onEdit: (id: number) => void,
  onDelete: (id: number) => void,
) => [
  text({
    key: 'firstName',
    width: '150px',
    title: 'First name',
    dataIndex: 'firstName',
  }),
  text({
    key: 'lastName',
    width: '150px',
    title: 'Last name',
    dataIndex: 'lastName',
    render: (record?: string) => <span>{record || '-'}</span>,
  }),
  text({
    key: 'role',
    width: '150px',
    title: 'Role',
    dataIndex: 'role',
  }),
  text({
    key: 'email',
    width: '150px',
    title: 'Email',
    dataIndex: 'email',
  }),
  text({
    key: 'calculationLink',
    width: '150px',
    title: 'Calculation',
    dataIndex: 'calculationLink',
    render: (_value, record: EmployeeModel) => <LinkCell record={record} />,
  }),
  actions({
    width: '50px',
    onEdit: onEdit,
    onDelete: onDelete,
    deleteMessage: 'Are you sure you want to delete this employee?',
  }),
];

const LinkCell: FC<ILinkCellProps> = ({ record }) => {
  const navigate = useNavigate();

  return record.role === EEmployeeRoles.SUPPLIER ? (
    <Tag
      onClick={() => navigate(`/billing/${record.id}`)}
      className={styles.link}
    >
      Link
    </Tag>
  ) : (
    <span>-</span>
  );
};

export const EmployeeTable: FC<IEmployeeTableProps> = observer(({ model }) => {
  const [isOpen, openModal, closeModal] = useModal(false);

  const isEdit = !!model.detailedEmployee;

  const openEditModal = async (id: number) => {
    await model.getDetailedEmployee(id);

    openModal();
  };

  const closeEditModal = () => {
    model.detailedEmployee = null;

    closeModal();
  };

  const handleAdd = (values: IEmployeeFormValues) => model.addEmployee(values);

  const handleEdit = (values: IEmployeeFormValues) =>
    model.editEmployee(
      model.detailedEmployee?.id,
      model.detailedEmployee?.supplier?.id,
      values,
    );

  const handleDelete = (id: number) => model.deleteEmployee(id);

  return (
    <div className={styles.container}>
      <Typography.Title level={4} className={styles.title}>
        Employees
      </Typography.Title>
      <Button
        type="primary"
        icon={<PlusCircleOutlined />}
        size="middle"
        disabled={model.isLoading}
        className={styles.button}
        onClick={openModal}
      >
        Add new employee
      </Button>
      <Table
        columns={getColumns(openEditModal, handleDelete)}
        data={model.employees}
        loading={model.isLoading}
        bordered
      />
      <EmployeeModal
        open={isOpen}
        onCancel={isEdit ? closeEditModal : closeModal}
        isEdit={isEdit}
        employee={model.detailedEmployee}
        onSubmit={isEdit ? handleEdit : handleAdd}
      />
    </div>
  );
});
