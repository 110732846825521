import { FC, useEffect, useRef, useState } from 'react';

import { Button, Checkbox, Modal, Divider } from 'antd';
import { defaultImage } from 'common/constants';
import { IOrderItem } from 'common/types/purchase';
import {
  IShipmentItem,
  IShipmentItemWithControlAttrs,
} from 'common/types/shipment';
import { compareFlatObjects, reduceOrderItems } from 'common/utils';
import { observer } from 'mobx-react-lite';

import { OrderExpandedViewModel } from '../../../order-expanded-view-model';
import { ShipmentModalItem } from '../../ShipmentModalItem';

import styles from './edit-shipment-modal.module.scss';

interface IEditShipmentModalProps {
  openEditModal: boolean;
  handleEditModalClose: () => void;
  updateShipment: (orderItems: IShipmentItemWithControlAttrs[]) => void;
  shipmentItems: IShipmentItem[];
  shipmentId: number;
  model: OrderExpandedViewModel;
}

const getFormattedItems = (
  orderItems: IOrderItem[],
  shipmentItems: IShipmentItem[],
): IShipmentItemWithControlAttrs[] =>
  orderItems.reduce((acc, curr) => {
    const relatedShipmentItem = shipmentItems.find(
      (item) => item.orderItem.id === curr.id,
    );

    acc.push({
      id: curr.id,
      productKit: curr.productKit,
      quantity: curr.quantity,
      variation: curr.variation,
      shipmentItems: curr.shipmentItems,
      selected: !!relatedShipmentItem,
      selectedQuantity: relatedShipmentItem?.quantity ?? 1,
    });

    return acc;
  }, [] as IShipmentItemWithControlAttrs[]);

const getReturnShipmentModalItemProps = (
  shipmentItem: IShipmentItemWithControlAttrs,
) => {
  const title = shipmentItem.productKit
    ? `${shipmentItem.variation.product.title} - KIT №${shipmentItem.productKit.id}`
    : shipmentItem.variation.product.title;

  const photo = shipmentItem.variation.content.photos?.find(
    (photo) => photo.id === shipmentItem.variation.content.mainPhotoId,
  ) || {
    id: 0,
    key: 'key',
    url: defaultImage,
  };

  const disabled = { input: !shipmentItem.selected };

  return { title, photo, disabled };
};

export const EditShipmentModal: FC<IEditShipmentModalProps> = observer(
  ({
    openEditModal,
    handleEditModalClose,
    updateShipment,
    shipmentItems,
    shipmentId,
    model,
  }) => {
    const orderItems = model.order.items;

    const [items, setItems] = useState<IShipmentItemWithControlAttrs[]>([]);
    const [isAllSelected, setAllSelected] = useState(true);

    const initialReducedItems = useRef<Record<number, number>>({});
    const reducedItems = reduceOrderItems(items);

    const hasSelected = items.some((item) => item.selected);
    const areItemsEqual = compareFlatObjects(
      initialReducedItems.current,
      reducedItems,
    );

    const canChange = hasSelected && !areItemsEqual;

    const handleToggleAll = () => {
      const updatedItems = items.map((item) => ({
        ...item,
        selected: !isAllSelected,
      }));

      setItems(updatedItems);
    };

    const handleChangeSelected = (
      selectedItem: IShipmentItemWithControlAttrs,
    ) => {
      const updatedItems = items.map((item) => {
        if (item.id === selectedItem.id) {
          return { ...item, selected: !item.selected };
        }

        return item;
      });

      setItems(updatedItems);
    };

    const handleChangeValue = (itemId: number, value: number | null) => {
      const updatedItems = items.map((item) => {
        if (item.id === itemId) {
          return { ...item, selectedQuantity: Number(value) };
        }

        return item;
      });

      setItems(updatedItems);
    };

    const handleChangeItems = () => {
      updateShipment(items);
      handleEditModalClose();
    };

    useEffect(() => {
      const formattedItems = getFormattedItems(orderItems, shipmentItems);

      initialReducedItems.current = reduceOrderItems(formattedItems);
      setItems(formattedItems);
    }, [orderItems, shipmentItems]);

    useEffect(() => {
      const allSelected = !items.some((item) => !item.selected);

      setAllSelected(allSelected);
    }, [items]);

    return (
      <Modal
        open={openEditModal}
        onOk={handleEditModalClose}
        onCancel={handleEditModalClose}
        footer={null}
        destroyOnClose
      >
        <div className={styles.title}>Edit Shipment {shipmentId}</div>
        <div className={styles.selectorWrapper}>
          <div>
            <Checkbox checked={isAllSelected} onClick={handleToggleAll}>
              {isAllSelected ? 'Unselect all' : 'Select all'}
            </Checkbox>
          </div>
          <Divider />
        </div>
        <div className={styles.productList}>
          {items.map((item) => {
            const { title, photo, disabled } =
              getReturnShipmentModalItemProps(item);

            return (
              <ShipmentModalItem
                key={item.id}
                title={title}
                photo={photo}
                selected={!!item.selected}
                min={1}
                max={item.quantity}
                quantity={item.selectedQuantity}
                disabled={disabled}
                onSelect={() => handleChangeSelected(item)}
                onChange={(e) => handleChangeValue(item.id, e)}
              />
            );
          })}
        </div>
        <div className={styles.footer}>
          <div>
            <Button
              type="primary"
              onClick={handleChangeItems}
              disabled={!canChange}
              htmlType="button"
            >
              Change
            </Button>
          </div>
        </div>
      </Modal>
    );
  },
);
