import { FC, Fragment } from 'react';

import { Button, Typography } from 'antd';
import { useModal } from 'common/utils';

import { EditModal } from './EditModal';

import styles from './edit-section.module.scss';

export interface IEditSectionProps {
  id: string;
  title: string;
  initialValues: any;
  validation: any;
  staticFields: IStaticField[];
  dynamicFields: IDynamicField[];
  disabled?: boolean;
  isLoading?: boolean;
  onEdit: (values: any) => Promise<void>;
}

export interface IStaticFieldProps {
  row: IStaticField;
}

export interface IField {
  value: string | number | null | undefined;
}

export interface IStaticField {
  fields: IField[];
  divider?: string;
}

export interface IDynamicField {
  label: string;
  name: string;
}

export const StaticField: FC<IStaticFieldProps> = ({ row }) => (
  <Typography.Text style={{ display: 'block' }}>
    {row.fields.map((entry, index, array) => (
      <Fragment key={index}>
        {entry.value && (
          <span>{`${entry.value}${
            row.divider && array.length !== index + 1 ? row.divider : ''
          }`}</span>
        )}
      </Fragment>
    ))}
  </Typography.Text>
);

export const EditSection: FC<IEditSectionProps> = ({
  id,
  title,
  initialValues,
  validation,
  staticFields,
  dynamicFields,
  disabled,
  isLoading,
  onEdit,
}) => {
  const [open, onOpen, onClose] = useModal();

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Typography.Text strong>{title}</Typography.Text>
        <Button type="primary" disabled={disabled} onClick={onOpen}>
          Edit
        </Button>
      </div>
      <div>
        {staticFields.map((row, index) => (
          <StaticField key={index} row={row} />
        ))}
      </div>
      <EditModal
        id={id}
        open={open}
        initialValues={initialValues}
        validation={validation}
        fields={dynamicFields}
        isLoading={isLoading}
        onOk={onEdit}
        onCancel={onClose}
      />
    </div>
  );
};
