import { AxiosResponse } from 'axios';
import {
  IEditableEmployeeParams,
  IEmployee,
  IResponsible,
  IDetailedEmployeeParams,
  IDetailedEmployee,
  IManagedOrder,
} from 'common/types/employee';
import { INewEmployeeParams } from 'common/types/employee';
import { ISupplier } from 'common/types/supplier';

import { $host } from './index';

export const getResponsibleList = async () => {
  const {
    data: { data },
  } = await $host.get<AxiosResponse<IResponsible[]>>(
    'admin/employees/responsible-list',
  );

  return data;
};

export const getSupplierList = async () => {
  const {
    data: { data },
  } = await $host.get<AxiosResponse<ISupplier[]>>(
    'admin/shipments/suppliers-list',
  );

  return data;
};

export const getEmployees = async () => {
  const {
    data: { data },
  } = await $host.get<AxiosResponse<IEmployee[]>>('admin/employees');

  return data;
};

export const getDetailedEmployee = async (params?: IDetailedEmployeeParams) => {
  const {
    data: { data },
  } = await $host.get<AxiosResponse<IDetailedEmployee>>(
    'admin/employees/single',
    {
      params,
    },
  );

  return data;
};

export const addNewEmployee = async (params: INewEmployeeParams) => {
  const {
    data: { data },
  } = await $host.post<AxiosResponse<IEmployee>>('admin/employees', params);

  return data;
};

export const editEmployee = async (
  id: number,
  params: IEditableEmployeeParams,
) => {
  const {
    data: { data },
  } = await $host.patch<AxiosResponse<IEmployee>>(
    `admin/employees/${id}`,
    params,
  );

  return data;
};

export const deleteEmployee = async (id: number) => {
  const {
    data: { data },
  } = await $host.delete<AxiosResponse<IEmployee>>(`admin/employees/${id}`);

  return data;
};

export const getManagedOrders = async (year: number, month: number) => {
  const {
    data: { data },
  } = await $host.get<AxiosResponse<IManagedOrder[]>>(
    `/admin/employees/managed-orders/${year}/${month}`,
  );

  return data;
};
