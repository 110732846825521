import { AxiosResponse } from 'axios';
import { IPageParams } from 'common/types/common-types';
import {
  IGetPromocodesResponse,
  IPromocode,
  IPromocodeCandidates,
  TPromoParams,
} from 'common/types/promocode';

import { $host } from './index';

export const getAllPromocodes = async (params?: IPageParams) => {
  const {
    data: { data },
  } = await $host.get<AxiosResponse<IGetPromocodesResponse>>('admin/promo', {
    params: params ?? { page: 1, pageSize: 50 },
  });

  return data;
};

export const getPromocodeCandidates = async () => {
  const {
    data: { data },
  } = await $host.get<AxiosResponse<IPromocodeCandidates>>(
    'admin/promo/primary-candidates',
  );

  return data;
};

export const createPromocode = async (params: TPromoParams) => {
  const {
    data: { data },
  } = await $host.post<AxiosResponse<IPromocode>>('admin/promo', params);

  return data;
};

export const editPromocode = async (promoId: number, params: TPromoParams) => {
  const {
    data: { data },
  } = await $host.patch<AxiosResponse<IPromocode>>(
    `admin/promo/${promoId}`,
    params,
  );

  return data;
};

export const deleteById = async (id: IPromocode['id']) => {
  const {
    data: { data },
  } = await $host.delete<AxiosResponse<IPromocode>>(`admin/promo/${id}`);

  return data;
};

export const setPromocodeAsPrimary = async (promoId: number | null) => {
  const {
    data: { data },
  } = await $host.patch<AxiosResponse<IPromocodeCandidates['current']>>(
    `admin/promo/primary/${promoId}`,
  );

  return data;
};
