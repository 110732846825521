import { useMemo, FC, useState, useEffect } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';
import { defaultOptions } from 'common/constants';
import { mockIdKeyGen } from 'common/utils';
import { observer } from 'mobx-react-lite';
import { ProductControllerViewModel } from 'pages/Product/ProductController/product-controller-view-model';
import { useFormContext } from 'react-hook-form';

import Variation, { TVariationItem } from './Variation';

import styles from './styles.module.scss';

interface IVariantsCardProps {
  model: ProductControllerViewModel;
}

const VariantsCard: FC<IVariantsCardProps> = observer(({ model }) => {
  const { clearErrors, resetField } = useFormContext();
  const [variations, setVariations] = useState<TVariationItem[]>([]);

  const variationsOptions = useMemo(() => {
    return defaultOptions.filter(
      (option) => !model.product.variationsObject[option.value]?.length,
    );
  }, [model.product.variationsObject]);

  useEffect(() => {
    setVariations([]);
  }, [variationsOptions]);

  const handleAddVariation = () => {
    setVariations([...variations, { id: mockIdKeyGen(), type: 'none' }]);
  };

  const handleVariationTypeChange = (
    id: string,
    type: TVariationItem['type'],
  ) => {
    const updatedVariations = variations.map((variation) => {
      if (variation.id === id) {
        return {
          ...variation,
          type,
        };
      }

      return variation;
    });

    setVariations([...updatedVariations]);
  };

  const handleDeleteVariationOption = (variationId: string) => {
    const variationToRemove = variations.find(
      (variation) => variation.id === variationId,
    );
    if (variationToRemove) {
      resetField(`variations.${variationToRemove.type}`);
      clearErrors(`variations.${variationToRemove.type}`);
    }
    const updatedVariations = variations.filter(
      (variation) => variation.id !== variationId,
    );
    setVariations([...updatedVariations]);
  };

  const selectedOptions = useMemo(() => {
    return new Set(variations.map((variation) => variation.type));
  }, [variations]);

  return (
    <Space direction="vertical" className={styles.card}>
      {variations.map((variation) => (
        <Variation
          editable
          key={variation.id}
          model={model}
          options={variationsOptions}
          selectedOptions={selectedOptions}
          type={variation.type}
          id={variation.id}
          setVariationType={handleVariationTypeChange}
          deleteVariationOption={handleDeleteVariationOption}
        />
      ))}
      {variations.length < variationsOptions.length && (
        <Button
          type="primary"
          onClick={handleAddVariation}
          icon={<PlusOutlined />}
        >
          Add variation option
        </Button>
      )}
    </Space>
  );
});

export default VariantsCard;
