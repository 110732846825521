import { AxiosResponse } from 'axios';
import { IDetailedOrder } from 'common/types';
import { IPurchase, INewPurchaseParams } from 'common/types/purchase';

import { $host } from './index';

const headers = {
  'Content-Type': 'multipart/form-data',
};

export const deleteById = async (id: IPurchase['id']) => {
  const {
    data: { data },
  } = await $host.delete<AxiosResponse<IPurchase>>(`admin/purchases/${id}`);

  return data;
};

export const createPurchase = async (params: FormData) => {
  const {
    data: { data },
  } = await $host.post<AxiosResponse<IPurchase>>('admin/purchases', params, {
    headers,
  });

  return data;
};

export const editPurchase = async (id: IPurchase['id'], params: FormData) => {
  const {
    data: { data },
  } = await $host.put<AxiosResponse<IPurchase>>(
    `admin/purchases/${id}`,
    params,
    {
      headers,
    },
  );

  return data;
};

export const updatePurchases = async (
  id: IDetailedOrder['id'],
  params: INewPurchaseParams[],
) => {
  const {
    data: { data },
  } = await $host.put<AxiosResponse<IDetailedOrder>>(
    `/admin/orders/${id}/order-items`,
    { orderItems: [...params] },
  );

  return data;
};

interface TOrderLinksParams {
  variationId: number;
  order_link: string[];
}

export const editVariationOrderLinks = async (
  id: IDetailedOrder['id'],
  params: TOrderLinksParams,
) => {
  const {
    data: { data },
  } = await $host.put<AxiosResponse<IDetailedOrder>>(
    `/admin/orders/${id}/order-links`,
    [params],
  );

  return data;
};

export const syncVariationOrderLink = async (
  id: number,
  variationId: number,
) => {
  const {
    data: { data },
  } = await $host.put<AxiosResponse<IDetailedOrder>>(
    `/admin/orders/${id}/order-links/sync/${variationId}`,
  );

  return data;
};
