import { FC } from 'react';

import { Button } from 'antd';
import Title from 'antd/es/typography/Title';
import { destinationDefaultOptions } from 'common/constants';
import { EStatuses } from 'common/types/enums';
import { ICreateSubcategory } from 'common/types/subcategory';
import { subcategorySchema } from 'common/validation';
import {
  Form,
  FormInput,
  FormSelect,
  FormSwitch,
  FormTextArea,
  FormUploader,
} from 'components/Form';
import { Spinner } from 'components/Spinner';
import { observer } from 'mobx-react-lite';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { SubcategoryControllerViewModel } from './subcategory-controller-view-model';

import styles from 'styles/page-controller.module.scss';

export interface ISubcategoryControllerProps {
  model: SubcategoryControllerViewModel;
}

const SubcategoryController: FC<ISubcategoryControllerProps> = observer(
  ({ model }) => {
    const navigate = useNavigate();
    const { search } = useLocation();

    const subcategory = model.subcategory;

    const isUpdate = !!subcategory.id;
    const eventText = isUpdate ? 'Update' : 'Create';

    const onSubmit = async (values: ICreateSubcategory) => {
      const result = await subcategory.manageSubcategory(isUpdate, values);

      if (result) {
        navigate({
          pathname: '/category',
          search: search,
        });
      }
    };

    const cancel = () =>
      navigate({
        pathname: '/category',
        search: search,
      });

    if (model.loading) {
      return (
        <div className={styles.formLoading}>
          <Spinner type="large" />
        </div>
      );
    }

    return (
      <div className={styles.formContainer}>
        <Title level={5} type="secondary" className={styles.title}>
          {`${eventText} subcategory`}
        </Title>
        <Form
          initialValues={{
            title: subcategory.title,
            description: subcategory.description,
            photo: subcategory.photo,
            brandId: subcategory.brandId || undefined,
            isArchive: subcategory.status === EStatuses.ARCHIVE,
            googleMerchantsDestinations:
              subcategory.googleMerchantsDestinations,
            metaTitle: subcategory.metaTitle,
            metaDescription: subcategory.metaDescription,
          }}
          validation={subcategorySchema}
          className={styles.form}
          onSubmit={onSubmit}
        >
          <div className={styles.formContent}>
            <div className={styles.fields}>
              <FormUploader
                name="photo"
                label="Category photo"
                className={styles.imagesContent}
              />
              <FormInput
                required
                name="title"
                label="Title"
                className={styles.field}
              />
              <FormSelect
                showSearch
                name="brandId"
                label="Brand"
                required
                options={model.brandOptions}
                selectClassName={styles.select}
                className={styles.field}
              />
              <FormTextArea
                required
                name="description"
                label="Description"
                autoSize={{ minRows: 6, maxRows: 10 }}
                className={styles.field}
              />
              <FormSwitch required name="isArchive" label="Archive" />
              <FormSelect
                name="googleMerchantsDestinations"
                label="Google destinations"
                placeholder="Select destinations"
                mode="multiple"
                placement="topLeft"
                options={destinationDefaultOptions}
                className={styles.field}
              />
              <FormInput
                name="metaTitle"
                label="Meta title"
                showCount
                className={styles.field}
              />
              <FormTextArea
                name="metaDescription"
                label="Meta description"
                autoSize={{ minRows: 6, maxRows: 10 }}
                showCount
                className={styles.field}
              />
              <div className={styles.buttons}>
                <Button
                  disabled={model.loading}
                  onClick={cancel}
                  type="default"
                >
                  Cancel
                </Button>
                <Button
                  loading={model.loading}
                  disabled={model.loading}
                  type="primary"
                  htmlType="submit"
                >
                  {eventText}
                </Button>
              </div>
            </div>
          </div>
        </Form>
      </div>
    );
  },
);

export const SubcategoryControllerPage = () => {
  const { subcategoryId } = useParams();

  const model = new SubcategoryControllerViewModel(Number(subcategoryId));

  return <SubcategoryController model={model} />;
};
