import { IRoute } from 'common/types';
import { Auth } from 'pages/Auth';
import { BillingPage } from 'pages/Billing';
import { BrandPage } from 'pages/Brand';
import { BrandControllerPage } from 'pages/Brand/BrandController';
import { BulkPriceUpdatePage } from 'pages/BulkPriceUpdate';
import { DashboardPage } from 'pages/Dashboard';
import { EmployeesPage } from 'pages/Employees';
import { KitsPage } from 'pages/Kits';
import { MainPage } from 'pages/Main';
import { OrderPage as NewOrderPage } from 'pages/NewOrder';
import { OrderPage } from 'pages/Order';
import { OrderCounterPage } from 'pages/OrderCounter';
import { ProductPage } from 'pages/Product';
import { ProductControllerPage } from 'pages/Product/ProductController';
import { PromocodePage } from 'pages/Promocode';
import { ReviewPage } from 'pages/Review';
import { SubcategoryPage } from 'pages/Subcategory';
import { SubcategoryControllerPage } from 'pages/Subcategory/SubcategoryController';
import { UserPage } from 'pages/User';
import { UsersPage } from 'pages/Users';

const brandRoutes: IRoute[] = [
  {
    title: 'Brands',
    key: 'brand',
    path: '/brand',
    permissions: { ADMIN: true, MANAGER: true, SUPPLIER: false },
    hidden: false,
    Component: BrandPage,
  },
  {
    key: 'new-brand',
    title: '',
    path: '/brand/new',
    permissions: { ADMIN: true, MANAGER: true, SUPPLIER: false },
    hidden: true,
    Component: BrandControllerPage,
  },
  {
    key: 'brand-id',
    title: '',
    path: '/brand/:brandId',
    permissions: { ADMIN: true, MANAGER: true, SUPPLIER: false },
    hidden: true,
    Component: BrandControllerPage,
  },
];

const categoryRoutes: IRoute[] = [
  {
    key: 'category',
    title: 'Categories',
    path: '/category',
    permissions: { ADMIN: true, MANAGER: true, SUPPLIER: false },
    hidden: false,
    Component: SubcategoryPage,
  },
  {
    key: 'new-category',
    title: '',
    path: '/category/new',
    permissions: { ADMIN: true, MANAGER: true, SUPPLIER: false },
    hidden: true,
    Component: SubcategoryControllerPage,
  },
  {
    key: 'category-id',
    title: '',
    path: '/category/:subcategoryId',
    permissions: { ADMIN: true, MANAGER: true, SUPPLIER: false },
    hidden: true,
    Component: SubcategoryControllerPage,
  },
];

const productsRoutes: IRoute[] = [
  {
    title: 'Products',
    key: 'product',
    path: '/product',
    permissions: { ADMIN: true, MANAGER: true, SUPPLIER: false },
    hidden: false,
    Component: ProductPage,
  },
  {
    key: 'new-product',
    title: '',
    path: '/product/new',
    permissions: { ADMIN: true, MANAGER: true, SUPPLIER: false },
    hidden: true,
    Component: ProductControllerPage,
  },
  {
    key: 'product-id',
    title: '',
    path: '/product/:productId',
    permissions: { ADMIN: true, MANAGER: true, SUPPLIER: false },
    hidden: true,
    Component: ProductControllerPage,
  },
];

const orderRoutes: IRoute[] = [
  {
    title: 'Orders',
    key: 'orders',
    path: '/orders',
    permissions: { ADMIN: true, MANAGER: true, SUPPLIER: true },
    hidden: false,
    Component: NewOrderPage,
  },
  {
    title: 'Order counter',
    key: 'order-counter',
    path: '/order-counter',
    permissions: { ADMIN: true, MANAGER: true, SUPPLIER: false },
    hidden: false,
    Component: OrderCounterPage,
  },
];

const restRoutes: IRoute[] = [
  {
    title: 'Dashboard',
    key: 'dashboard',
    path: '/dashboard',
    permissions: { ADMIN: true, MANAGER: false, SUPPLIER: false },
    hidden: false,
    Component: DashboardPage,
  },
  {
    title: 'Billing',
    key: 'billing',
    path: '/billing',
    permissions: { ADMIN: false, MANAGER: false, SUPPLIER: false },
    hidden: false,
    Component: BillingPage,
  },
  {
    title: '',
    key: 'admin-billing',
    path: '/billing/:supplierId',
    permissions: { ADMIN: true, MANAGER: false, SUPPLIER: false },
    hidden: true,
    Component: BillingPage,
  },
  {
    title: 'Main page',
    key: 'main-page',
    path: '/main-page',
    permissions: { ADMIN: true, MANAGER: true, SUPPLIER: false },
    hidden: false,
    Component: MainPage,
  },
  {
    title: 'Promocodes',
    key: 'promocodes',
    path: '/promocodes',
    permissions: { ADMIN: true, MANAGER: true, SUPPLIER: false },
    hidden: false,
    Component: PromocodePage,
  },
  {
    title: 'Kits',
    key: 'kits',
    path: '/kits',
    permissions: { ADMIN: true, MANAGER: true, SUPPLIER: false },
    hidden: false,
    Component: KitsPage,
  },
  {
    title: 'Reviews',
    key: 'reviews',
    path: '/reviews',
    permissions: { ADMIN: true, MANAGER: true, SUPPLIER: false },
    hidden: false,
    Component: ReviewPage,
  },
  {
    title: 'Employees',
    key: 'employees',
    path: '/employees',
    permissions: { ADMIN: true, MANAGER: false, SUPPLIER: false },
    hidden: false,
    Component: EmployeesPage,
  },
  {
    title: 'Users',
    key: 'users',
    path: '/users',
    permissions: { ADMIN: true, MANAGER: true, SUPPLIER: false },
    hidden: false,
    Component: UsersPage,
  },
  {
    title: 'Bulk price change',
    key: 'bulk-price-change',
    path: '/bulk-price-change',
    permissions: { ADMIN: true, MANAGER: false, SUPPLIER: false },
    hidden: false,
    Component: BulkPriceUpdatePage,
  },
  {
    key: 'user-id',
    title: '',
    path: '/user/:id',
    permissions: { ADMIN: true, MANAGER: true, SUPPLIER: false },
    hidden: true,
    Component: UserPage,
  },
  {
    title: 'Deprecated Orders',
    key: 'deprecated-orders',
    path: '/deprecated-orders',
    permissions: { ADMIN: true, MANAGER: true, SUPPLIER: false },
    hidden: false,
    Component: OrderPage,
  },
];

export const privateRoutes = [
  ...brandRoutes,
  ...productsRoutes,
  ...categoryRoutes,
  ...orderRoutes,
  ...restRoutes,
];

export const publicRoutes = [
  {
    path: '/auth',
    Component: Auth,
  },
];

export const redirectRoutes = {
  ADMIN: '/brand',
  MANAGER: '/brand',
  SUPPLIER: '/orders',
};

export const sidebarLinks = [
  {
    title: 'Product',
    key: 'category-product',
    permissions: { ADMIN: true, MANAGER: true, SUPPLIER: false },
    children: [...brandRoutes, ...categoryRoutes, ...productsRoutes],
  },
  {
    title: 'Order',
    key: 'order-pages',
    permissions: { ADMIN: true, MANAGER: true, SUPPLIER: true },
    children: [...orderRoutes],
  },
  {
    title: 'Pages',
    key: 'category-pages',
    permissions: { ADMIN: true, MANAGER: true, SUPPLIER: false },
    children: [...restRoutes],
  },
];
