// TODO: handle this mess
import { FC, useEffect, useRef, useState } from 'react';

import { Checkbox, Modal, Divider } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { defaultPhoto } from 'common/constants';
import { IOrderItem } from 'common/types/purchase';
import {
  IShipmentItem,
  IShipmentItemWithControlAttrs,
} from 'common/types/shipment';
import { compareFlatObjects, reduceOrderItems } from 'common/utils';
import { observer } from 'mobx-react-lite';

import { PackageModalItem } from '../../PackageModalItem';

import styles from './edit-shipment-modal.module.scss';

interface IEditShipmentModalProps {
  open: boolean;
  shipmentItems: IShipmentItem[];
  shipmentId: number;
  orderItems: IOrderItem[];
  disabled: boolean;
  isLoading?: boolean;
  onOk: (shipments: IShipmentItemWithControlAttrs[]) => Promise<void>;
  onCancel: () => void;
}

const getFormattedItems = (
  orderItems: IOrderItem[],
  shipmentItems: IShipmentItem[],
): IShipmentItemWithControlAttrs[] =>
  orderItems.reduce((acc, curr) => {
    const relatedShipmentItem = shipmentItems.find(
      (item) => item.orderItem.id === curr.id,
    );

    acc.push({
      id: curr.id,
      productKit: curr.productKit,
      quantity: curr.quantity,
      variation: curr.variation,
      shipmentItems: curr.shipmentItems,
      selected: !!relatedShipmentItem,
      selectedQuantity: relatedShipmentItem?.quantity ?? 1,
    });

    return acc;
  }, [] as IShipmentItemWithControlAttrs[]);

const getReturnShipmentModalItemProps = (
  shipmentItem: IShipmentItemWithControlAttrs,
) => {
  const title = shipmentItem.productKit
    ? `${shipmentItem.variation.product.title} - KIT №${shipmentItem.productKit.id}`
    : shipmentItem.variation.product.title;

  const photo =
    shipmentItem.variation.content.photos?.find(
      (photo) => photo.id === shipmentItem.variation.content.mainPhotoId,
    ) || defaultPhoto;

  const disabled = { input: !shipmentItem.selected };

  return { title, photo, disabled };
};

export const EditShipmentModal: FC<IEditShipmentModalProps> = observer(
  ({
    open,
    shipmentItems,
    shipmentId,
    orderItems,
    disabled,
    isLoading,
    onOk,
    onCancel,
  }) => {
    const [items, setItems] = useState<IShipmentItemWithControlAttrs[]>([]);
    const [isAllSelected, setAllSelected] = useState(true);

    const initialReducedItems = useRef<Record<number, number>>({});
    const reducedItems = reduceOrderItems(items);

    const hasSelected = items.some((item) => item.selected);
    const areItemsEqual = compareFlatObjects(
      initialReducedItems.current,
      reducedItems,
    );

    const canChange = hasSelected && !areItemsEqual;

    const handleToggleAll = (e: CheckboxChangeEvent) => {
      const checked = e.target.checked;

      const updatedItems = items.map((item) => ({
        ...item,
        selected: !isAllSelected || checked,
        selectedQuantity: checked ? item.quantity : 1,
      }));

      setItems(updatedItems);
    };

    const handleChangeSelected = (
      selectedItem: IShipmentItemWithControlAttrs,
    ) => {
      const updatedItems = items.map((item) => {
        if (item.id === selectedItem.id) {
          return { ...item, selected: !item.selected };
        }

        return item;
      });

      setItems(updatedItems);
    };

    const handleChangeValue = (itemId: number, value: number | null) => {
      const updatedItems = items.map((item) => {
        if (item.id === itemId) {
          return { ...item, selectedQuantity: Number(value) };
        }

        return item;
      });

      setItems(updatedItems);
    };

    const handleOk = async () => {
      await onOk(items);
    };

    useEffect(() => {
      if (open) {
        const formattedItems = getFormattedItems(orderItems, shipmentItems);

        initialReducedItems.current = reduceOrderItems(formattedItems);
        setItems(formattedItems);
      }
    }, [orderItems, shipmentItems, open]);

    useEffect(() => {
      const allSelected = !items.some((item) => !item.selected);

      setAllSelected(allSelected);
    }, [items]);

    return (
      <Modal
        open={open}
        title={`Edit Shipment ${shipmentId}`}
        okButtonProps={{ disabled: !canChange || disabled, loading: isLoading }}
        destroyOnClose
        onOk={handleOk}
        onCancel={onCancel}
      >
        <div className={styles.selectorWrapper}>
          <div>
            <Checkbox checked={isAllSelected} onChange={handleToggleAll}>
              {isAllSelected ? 'Unselect all' : 'Select all'}
            </Checkbox>
          </div>
          <Divider />
        </div>
        <div className={styles.productList}>
          {items.map((item) => {
            const { title, photo, disabled } =
              getReturnShipmentModalItemProps(item);

            return (
              <PackageModalItem
                key={item.id}
                title={title}
                photo={photo}
                selected={!!item.selected}
                min={1}
                max={item.quantity}
                quantity={item.selectedQuantity}
                disabled={disabled}
                onSelect={() => handleChangeSelected(item)}
                onChange={(e) => handleChangeValue(item.id, e)}
              />
            );
          })}
        </div>
      </Modal>
    );
  },
);
