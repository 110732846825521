import { FC, useEffect, useState } from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import { Button, Modal, AutoComplete } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { IKit, TOption } from 'common/types';
import { compareArraysOfObjects } from 'common/utils';
import { observer } from 'mobx-react-lite';

import { KitsViewModel } from '../kits-page-view-model';

import styles from './kit-modal.module.scss';

interface IKitModalContentProps {
  model: KitsViewModel;
  types: (TOption | undefined)[];
  isEdit: boolean;
  setTypes: (values: (TOption | undefined)[]) => void;
  onCancel: () => void;
  onSubmit: (productTypeOptions: TOption[]) => Promise<void>;
}

interface IKitModalProps {
  isOpen: boolean;
  isEdit: boolean;
  model: KitsViewModel;
  onCancel: () => void;
  onSubmit: (productTypeOptions: TOption[]) => Promise<void>;
}

const getKitTypesOptions = (kit: IKit): TOption[] =>
  Object.keys(kit.productTypes).reduce(
    (acc, curr) => [...acc, { value: curr, label: curr }],
    [],
  );

const getAvailableTypeOptions = (
  kit: (TOption | undefined)[],
  typeOptions: TOption[],
) => {
  const types = kit.map((option) => option?.label);

  return typeOptions.map((option) => {
    if (types.includes(option.value)) {
      return { ...option, disabled: true };
    }

    return option;
  });
};

const KitModalContent: FC<IKitModalContentProps> = observer(
  ({ model, types, isEdit, onCancel, onSubmit, setTypes }) => {
    const [typeOptions, setTypeOptions] = useState<DefaultOptionType[]>([]);
    const [selectedType, setSelectedType] = useState<TOption>();

    useEffect(() => {
      const initialTypeOptions = types.length
        ? getAvailableTypeOptions(types, model.productTypeOptions)
        : model.productTypeOptions;

      setTypeOptions(initialTypeOptions);
    }, [model.productTypeOptions, types]);

    const initialTypes = model.kit ? getKitTypesOptions(model.kit) : [];

    const canAdd = !!selectedType && types.length < 4;
    const canSave = types.length >= 2 && types.length <= 4;

    const disabled =
      !canSave ||
      (isEdit &&
        !!!compareArraysOfObjects(initialTypes, types).length &&
        initialTypes.length === types.length);

    const addType = (type?: TOption) => {
      model.searchProductTypeValue = '';

      setSelectedType(undefined);
      setTypes([...types, type]);
    };

    const removeType = (removedType?: DefaultOptionType) => {
      setTypes(types.filter((type) => type?.value !== removedType?.value));
    };

    const onSave = async () => {
      await onSubmit(types as TOption[]);

      setTypes([]);

      onCancel();
    };

    return (
      <>
        <div>
          <div className={styles.selectContainer}>
            <AutoComplete
              options={typeOptions}
              onSelect={(_value, option) => setSelectedType(option as TOption)}
              allowClear
              value={model.searchProductTypeValue}
              onClear={() => setSelectedType(undefined)}
              onChange={(value) => (model.searchProductTypeValue = value)}
              className={styles.search}
            />
            <Button
              type="primary"
              onClick={() => addType(selectedType)}
              disabled={!canAdd}
            >
              Add
            </Button>
          </div>
          <div className={styles.types}>
            {types.map((type) => (
              <div key={type?.value} className={styles.type}>
                <span className={styles.title}>{type?.label}</span>
                <DeleteOutlined
                  className={styles.delete}
                  onClick={() => removeType(type)}
                />
              </div>
            ))}
          </div>
        </div>
        <div className={styles.buttons}>
          <Button
            type="primary"
            loading={model.isLoading}
            className={styles.button}
            onClick={onSave}
            disabled={disabled}
          >
            Save
          </Button>
          <Button className={styles.button} onClick={onCancel}>
            Cancel
          </Button>
        </div>
      </>
    );
  },
);

export const KitModal: FC<IKitModalProps> = observer(
  ({ isOpen, isEdit, model, onCancel, onSubmit }) => {
    const [types, setTypes] = useState<(TOption | undefined)[]>([]);

    useEffect(() => {
      const initialTypes = model.kit ? getKitTypesOptions(model.kit) : [];

      setTypes(initialTypes);
    }, [model.kit]);

    const handleCancel = () => {
      setTypes([]);

      model.searchProductTypeValue = '';

      model.productTypeOptions = model.productTypeOptions.map((option) => ({
        ...option,
        disabled: false,
      }));

      onCancel();
    };

    return (
      <Modal
        open={isOpen}
        onCancel={handleCancel}
        title={isEdit ? 'Edit kit' : 'Add new kit'}
        footer={null}
        destroyOnClose
      >
        <KitModalContent
          model={model}
          isEdit={isEdit}
          onCancel={handleCancel}
          setTypes={setTypes}
          types={types}
          onSubmit={(productTypeOptions) => onSubmit(productTypeOptions)}
        />
      </Modal>
    );
  },
);
