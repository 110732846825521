import {
  addNewEmployee,
  deleteEmployee,
  editEmployee,
  getEmployees,
  getDetailedEmployee,
} from 'http/employeeApi';
import { editSupplier } from 'http/supplierApi';

import { IServerError } from 'common/types';
import { IDetailedEmployee } from 'common/types/employee';
import { EEmployeeRoles } from 'common/types/employee/enums';
import { notify } from 'common/utils';
import { action, computed, makeObservable, observable } from 'mobx';
import { EmployeeModel } from 'store/employee-model';

import { EmployeesPageViewModel } from './employees-page-view-model';
import { IEmployeeFormValues } from './types';

export class EmployeesViewModel {
  _root: EmployeesPageViewModel;
  _isLoading = false;
  _employees: EmployeeModel[] = [];
  _detailedEmployee: IDetailedEmployee | null = null;

  get isLoading() {
    return this._isLoading;
  }

  set isLoading(isLoading: boolean) {
    this._isLoading = isLoading;
  }

  get employees() {
    return this._employees;
  }

  set employees(employees: EmployeeModel[]) {
    this._employees = employees;
  }

  get detailedEmployee() {
    return this._detailedEmployee;
  }

  set detailedEmployee(detailedEmployee: IDetailedEmployee | null) {
    this._detailedEmployee = detailedEmployee;
  }

  async addEmployee(values: IEmployeeFormValues) {
    try {
      const { email, firstName, lastName, role, password, ...supplierValues } =
        values;

      const params = {
        email,
        firstName,
        lastName,
        role,
        password,
        ...(role === EEmployeeRoles.SUPPLIER && { supplier: supplierValues }),
      };

      const newEmployee = await addNewEmployee(params);
      const newEmployeeModel = new EmployeeModel(newEmployee);

      this.employees = [...this.employees, newEmployeeModel];

      if (role === EEmployeeRoles.SUPPLIER) {
        await this._root.suppliersViewModel.getSuppliers();
      }

      return true;
    } catch (e) {
      const error = e as IServerError;

      notify(error.response.data.message, 'error');

      return false;
    }
  }

  async editEmployee(
    id: number | undefined,
    supplierId: number | undefined,
    values: IEmployeeFormValues,
  ) {
    if (!id) {
      return false;
    }

    try {
      const { email, firstName, lastName, role, password, ...supplierValues } =
        values;

      const employeeParams = {
        email,
        firstName,
        lastName,
        ...(password && { password }),
      };

      const updatedEmployee = await editEmployee(id, employeeParams);

      const updatedEmployeeModel: EmployeeModel = this.employees.find(
        (employee) => employee.id === id,
      ) as EmployeeModel;

      updatedEmployeeModel._employee = updatedEmployee;

      this.employees = [...this.employees];

      if (supplierId) {
        await editSupplier(supplierId, supplierValues);
        await this._root.suppliersViewModel.getSuppliers();
      }

      return true;
    } catch (e) {
      const error = e as IServerError;

      notify(error.response.data.message, 'error');

      return false;
    }
  }

  async deleteEmployee(id: number) {
    try {
      this.isLoading = true;
      await deleteEmployee(id);

      const deletedEmployee = this.employees.find(
        (employee) => employee.id === id,
      );

      this.employees = this.employees.filter((employee) => employee.id !== id);

      if (deletedEmployee?.role === EEmployeeRoles.SUPPLIER) {
        await this._root.suppliersViewModel.getSuppliers();
      }
    } catch (e) {
      notify(`Error, reason:${e}`, 'error');
    } finally {
      this.isLoading = false;
    }
  }

  async getDetailedEmployee(id: number) {
    try {
      const detailedEmployee = await getDetailedEmployee({ id });

      this.detailedEmployee = detailedEmployee;
    } catch (e) {
      notify(`Error, reason:${e}`, 'error');
    }
  }

  async getEmployees() {
    try {
      this.isLoading = true;
      const employees = await getEmployees();

      this.employees = employees.map((employee) => new EmployeeModel(employee));
    } catch (e) {
      notify(`Error, reason:${e}`, 'error');
    } finally {
      this.isLoading = false;
    }
  }

  constructor(root: EmployeesPageViewModel) {
    this._root = root;

    makeObservable(this, {
      _isLoading: observable,
      _employees: observable,
      _detailedEmployee: observable,
      isLoading: computed,
      employees: computed,
      detailedEmployee: computed,
      addEmployee: action,
      editEmployee: action,
      deleteEmployee: action,
      getDetailedEmployee: action,
      getEmployees: action,
    });
  }
}
