import { FC } from 'react';

import { ArrowRightOutlined } from '@ant-design/icons';
import { List } from 'antd';
import { IVariation } from 'common/types';
import { getVariationView } from 'common/utils';

import styles from './info-list.module.scss';

interface IListItem {
  title: string;
  quantity: number;
  quantityUpdated: number;
  sku: string;
  variation: IVariation;
}

interface IInfoListProps {
  list: IListItem[];
  title: string;
  type: 'added' | 'updated' | 'deleted';
}

export const InfoList: FC<IInfoListProps> = ({ list, title, type }) => (
  <List
    key={title}
    bordered
    dataSource={list}
    className={styles.list}
    header={title}
    renderItem={(item, index) => (
      <List.Item>
        <div className={styles.item}>
          <p>{`${index + 1}. ${item.title}`}</p>
          <p>{`variation: ${getVariationView(item.variation.content)}`}</p>
          {type === 'added' && <p>{`quantity: ${item.quantityUpdated}`}</p>}
          {type === 'updated' && (
            <div className={styles.quantityWrapper}>
              <p>
                {`quantity: ${item.quantity} `}
                <ArrowRightOutlined />
              </p>
              <p className={styles.quantity}>{`${item.quantityUpdated}`}</p>
            </div>
          )}
          {type === 'deleted' && <p>{`quantity: ${item.quantity}`}</p>}
          <p>{`sku: ${item.sku}`}</p>
        </div>
      </List.Item>
    )}
  />
);
