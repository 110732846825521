// TODO: Make common component at PackageDetails/EditableData
import { FC } from 'react';

import { Button } from 'antd';
import { useCurrentUserStore } from 'common/providers/store-provider';
import { TOption } from 'common/types';
import { shipmentValidationSchema } from 'common/validation';
import { FormInput, FormSelect } from 'components/Form';
import { Form } from 'components/Form';
import { useFormContext } from 'react-hook-form';

import styles from './editable-data.module.scss';

interface IEditableDataProps {
  supplier: string;
  supplierOptions: TOption[];
  reason: string;
  reasonOptions: TOption[];
  supId: string;
  trackingNumber: string;
  costOfPurchase: number;
  costOfShipping: number;
  comment?: string;
  disabled: boolean;
  isLoading?: boolean;
  onToggleEdit: () => void;
  onEdit: (values: any) => Promise<void>;
}

interface IFormButtons {
  disabled: boolean;
  isLoading?: boolean;
  onDiscard: () => void;
}

const FormButtons: FC<IFormButtons> = ({ disabled, isLoading, onDiscard }) => {
  const {
    formState: { isDirty, isValid },
  } = useFormContext();

  return (
    <div className={styles.buttons}>
      <Button
        htmlType="submit"
        disabled={disabled || !isDirty || !isValid}
        loading={isLoading}
      >
        Save
      </Button>
      <Button htmlType="button" onClick={onDiscard}>
        Discard
      </Button>
    </div>
  );
};

export const EditableData: FC<IEditableDataProps> = ({
  supplier,
  supplierOptions,
  reason,
  reasonOptions,
  supId,
  trackingNumber,
  costOfPurchase,
  costOfShipping,
  comment,
  disabled,
  isLoading,
  onToggleEdit,
  onEdit,
}) => {
  const initialValues = {
    supplier: supplier,
    reason: reason,
    supId: supId,
    trackingNumber: trackingNumber,
    costOfPurchase: costOfPurchase,
    costOfShipping: costOfShipping,
    comment: comment,
  };

  const { isSupplier } = useCurrentUserStore();

  const onSubmit = async (values: any) => {
    onEdit(values);
  };

  return (
    <Form
      initialValues={initialValues}
      validation={shipmentValidationSchema}
      className={styles.container}
      onSubmit={onSubmit}
    >
      <div className={styles.content}>
        <FormSelect
          defaultValue={supplier}
          options={supplierOptions}
          name="supplier"
          disabled={isSupplier}
          label="Supplier:"
          showSearch
        />
        <FormSelect
          defaultValue={reason}
          name="reason"
          label="Reason:"
          disabled={isSupplier}
          options={reasonOptions}
          showSearch
        />
        <FormInput
          min={0}
          label="Supplier id"
          name="supId"
          disabled={isSupplier}
          className={styles.field}
        />
        <FormInput
          min={0}
          label="Tracking number"
          name="trackingNumber"
          className={styles.field}
        />
        <FormInput
          min={0}
          label="Cost of purchase"
          prefix="$"
          disabled={isSupplier}
          name="costOfPurchase"
          className={styles.field}
        />
        <FormInput
          min={0}
          label="Cost of shipping"
          prefix="$"
          disabled={isSupplier}
          name="costOfShipping"
          className={styles.field}
        />
        <FormInput
          min={0}
          label="Comment"
          name="comment"
          disabled={isSupplier}
          className={styles.field}
        />
      </div>
      <FormButtons
        disabled={disabled}
        isLoading={isLoading}
        onDiscard={onToggleEdit}
      />
    </Form>
  );
};
