import { FC } from 'react';

import { Button } from 'antd';
import { TOption } from 'common/types';
import {
  upperUndescoreToRegularCase,
  cents2dollars,
  combineString,
} from 'common/utils';
import { ReturnShipmentModel } from 'store/return-shipment-model';

import styles from './static-data.module.scss';

interface IStaticDataProps {
  returnItem: ReturnShipmentModel;
  responsibleOptions: TOption[];
  disabled?: boolean;
  toggleEdit: () => void;
}

export const StaticData: FC<IStaticDataProps> = ({
  returnItem,
  responsibleOptions,
  disabled,
  toggleEdit,
}) => {
  const supplierName = returnItem.supplier.user
    ? combineString([
        returnItem.supplier.user.firstName,
        returnItem.supplier.user.lastName,
      ])
    : returnItem.supplier.name;

  const responsible =
    responsibleOptions.find((option) => option.value === returnItem.responsible)
      ?.label ?? returnItem.responsible;

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div>
          <span className={styles.title}>Cost of return:&nbsp;</span>
          <span>{cents2dollars(returnItem.costOfReturn)}</span>
        </div>
        <div>
          <span className={styles.title}>Cost of goods:&nbsp;</span>
          <span>{cents2dollars(returnItem.costOfGoods)}</span>
        </div>
        <div>
          <span className={styles.title}>Supplier:&nbsp;</span>
          <span>{supplierName}</span>
        </div>
        <div>
          <span className={styles.title}>Responsible:&nbsp;</span>
          <span>{responsible}</span>
        </div>
        <div>
          <span className={styles.title}>Reason:&nbsp;</span>
          <span>{upperUndescoreToRegularCase(returnItem.reason)}</span>
        </div>
      </div>
      <Button onClick={toggleEdit} htmlType="button" disabled={disabled}>
        Edit
      </Button>
    </div>
  );
};
