import { ChangeEvent, FC, useState } from 'react';

import { SaveOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import { cents2dollars, dollars2cents } from 'common/utils';

import { OrderExpandedViewModel } from '../../../order-expanded-view-model';

import styles from './fees-input.module.scss';

interface IFeesInputProps {
  model: OrderExpandedViewModel;
}

export const FeesInput: FC<IFeesInputProps> = ({ model }) => {
  const { order } = model;

  const [count, setCount] = useState<number | string>(
    cents2dollars(order.efficiency.fees),
  );

  const handleChangeFees = (e: ChangeEvent<HTMLInputElement>) => {
    setCount(e.target.value);
  };

  const handleSubmitFees = () => {
    model.changeFees(
      order.id,
      order.efficiency.fees + dollars2cents(Number(count)),
    );
  };

  return (
    <div className={styles.feesWrapper}>
      <Input
        className={styles.input}
        defaultValue={order.efficiency.fees && 0}
        onChange={handleChangeFees}
        value={count}
        size="small"
        disabled={order.disabled}
      />
      <Button
        onClick={handleSubmitFees}
        disabled={String(Number(count)) === 'NaN' || !count || order.disabled}
        size="small"
        icon={<SaveOutlined />}
      />
    </div>
  );
};
