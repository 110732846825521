import { FC, useEffect } from 'react';

import { useCurrentUserStore } from 'common/providers/store-provider';
import { observer } from 'mobx-react-lite';

import { OrderExpandedViewModel } from '../order-expanded-view-model';
import { OrderTableRowViewModel } from '../order-table-row-view-model';

import { ButtonsSection } from './ButtonsSection';
import CustomerInfo from './CustomerInfo';
import { EfficiencySection } from './EfficiencySection';
import { HistorySection } from './HistorySection';
import { PurchaseSection } from './PurchaseSection';
import { ReturnSection } from './ReturnSection';
import ShipmentSection from './ShipmentSection';

import styles from './goods-table.module.scss';

interface IOrderExpandedContentPageProps {
  model: OrderExpandedViewModel;
}

interface IOrderExpandedContentProps {
  id: number;
  model: OrderTableRowViewModel;
}

const OrderExpandedContentPage: FC<IOrderExpandedContentPageProps> = observer(
  ({ model }) => {
    const currentUserStore = useCurrentUserStore();

    const { isAdmin } = currentUserStore;

    return (
      <div>
        <div className={styles.purchseBilling}>
          <PurchaseSection model={model} />
          {isAdmin && <EfficiencySection model={model} />}
        </div>
        <div className={styles.customer}>
          <ButtonsSection model={model} />
          <CustomerInfo model={model} />
        </div>
        <div className={styles.parcel}>
          <div className={styles.shipments}>
            <ShipmentSection model={model} />
            <ReturnSection model={model} />
          </div>
          <HistorySection model={model} />
        </div>
      </div>
    );
  },
);

export const OrderExpandedContent: FC<IOrderExpandedContentProps> = observer(
  ({ id, model }) => {
    useEffect(() => {
      const orderExpandedViewModel = new OrderExpandedViewModel(model, id);

      model.orderExpandedViewModel = orderExpandedViewModel;
    }, [id, model]);

    return <OrderExpandedContentPage model={model.orderExpandedViewModel} />;
  },
);
