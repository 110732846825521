import axios, { AxiosError, AxiosResponse } from 'axios';
import { notify } from 'common/utils';
import { redirect } from 'react-router-dom';

const $host = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

$host.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;

  return config;
});

const buildAxiosInterceptors = (handlers: { logout: () => void }) => {
  const resInterceptor = (response: AxiosResponse) => response;

  const errInterceptor = (error: AxiosError) => {
    if (error?.response?.status === 401) {
      handlers.logout();

      notify('An unexpected error has occurred, try logging in again', 'error');
      redirect('/auth');
    } else {
      return Promise.reject(error);
    }
  };

  $host.interceptors.response.use(resInterceptor, errInterceptor);
};

export { $host, buildAxiosInterceptors };
