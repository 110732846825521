import { FC } from 'react';

import { Divider, Collapse } from 'antd';
import cn from 'clsx';
import { calculateDiscount } from 'common/utils';
import {
  FormAutocomplete,
  FormInput,
  FormList,
  FormMarkdown,
  FormNumberInput,
} from 'components/Form';
import { useFormContext } from 'react-hook-form';

import { ProductControllerViewModel } from '../product-controller-view-model';

import { AdditionalDescription } from './AdditionalDescription';

import styles from './product-form-content.module.scss';

interface IProductFormContentProps {
  model: ProductControllerViewModel;
}

interface IProductInformation extends IProductFormContentProps {
  price: string;
}

const ProductInformation: FC<IProductInformation> = ({ model, price }) => (
  <div className={styles.info}>
    <FormInput
      className={styles.inputWidth}
      name="placement"
      label="Placement"
    />
    <FormAutocomplete
      className={styles.inputWidth}
      required
      name="material"
      label="Material"
      options={model.materialAutocomplete}
    />
    <FormInput
      className={styles.inputWidth}
      required
      name="color"
      label="Color"
    />
    <FormInput className={styles.inputWidth} name="barcode" label="Barcode" />
    <div className={styles.priceWrapper}>
      <FormNumberInput name="price" label="Price" precision={2} min={1} />
      <FormNumberInput
        name="discount"
        label="Discount"
        precision={2}
        min={0}
        max={99.9}
      />
      <div className={styles.displayedPrice}>
        <div>Displayed price:</div>
        <b>{price}</b>
      </div>
    </div>
  </div>
);

const { Panel } = Collapse;

export const ProductFormContent: FC<IProductFormContentProps> = ({ model }) => {
  const { watch, formState } = useFormContext();

  const isUpdate = !!model.product.id;

  const displayedPrice = watch(['price', 'discount']);

  const discount = calculateDiscount(
    displayedPrice[0],
    displayedPrice[1],
  ).toFixed(2);

  return (
    <div className={styles.formFields}>
      <FormInput
        className={styles.inputWidth}
        required
        name="title"
        label="Title"
      />
      <FormMarkdown required name="description" label="Description" />
      <AdditionalDescription />
      <FormAutocomplete
        className={styles.inputWidth}
        required
        name="type"
        label="Type"
        options={model.typeAutocomplete}
      />
      {isUpdate ? (
        <Collapse
          className={cn({
            [styles.error]:
              !!formState.errors['color'] ||
              !!formState.errors['material'] ||
              !!formState.errors['placement'],
          })}
          accordion
        >
          <Panel header="Other information" key="1">
            <ProductInformation model={model} price={discount} />
          </Panel>
        </Collapse>
      ) : (
        <>
          <FormInput className={styles.inputWidth} name="sku" label="SKU" />
          <ProductInformation model={model} price={discount} />
        </>
      )}
      <Divider />
      <FormList name="order_link" label="Order links" />
    </div>
  );
};
