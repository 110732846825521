import { ColumnType } from 'antd/es/table';

export type TCustomActionColumn<T> = ColumnType<T> &
  Omit<TCustomActionCellProps, 'text'>;

type TVariantOption = { name: string; value: string };
export interface TCustomActionCellProps {
  text: string | TVariantOption[];
  type?: 'variant' | 'default';
  loading?: boolean;
  nested?: boolean;
}

export const customActions = <T,>({
  dataIndex = 'title',
  key = 'title',
  width = '10%',
  loading,
  type = 'default',
  nested = false,
  render,
  ...props
}: TCustomActionColumn<T>): ColumnType<T> => ({
  dataIndex: dataIndex,
  key: key,
  width: width,
  render: render,
  ...props,
});
