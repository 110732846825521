import React, { FC, SyntheticEvent, useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { Button, Input, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { mockCommentsListData } from 'common/constants';
import { Table, text } from 'components/Table';
import { observer } from 'mobx-react-lite';

import { OrderExpandedViewModel } from '../../../order-expanded-view-model';

import styles from './comments-table.module.scss';

interface ICommentsTableProps {
  model: OrderExpandedViewModel;
}

const createTimestamp = () => {
  const date = new Date(Date.now());
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

export const CommentsTable: FC<ICommentsTableProps> = observer(({ model }) => {
  const [data, setData] = useState(mockCommentsListData);
  const [newCommentValue, setNewCommentValue] = useState('');

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    if (!newCommentValue) return;

    const newComment = {
      id: Math.floor(Math.random() * 1000),
      createdAt: createTimestamp(),
      author: 'Admin Natalex',
      comment: newCommentValue,
    };

    setData((prevData) => [...prevData, newComment]);
    setNewCommentValue('');
  };

  const columns: ColumnsType<any> = [
    text({
      title: 'Date',
      dataIndex: 'createdAt',
      width: '15%',
    }),
    text({
      width: '15%',
      title: 'Name',
      dataIndex: 'author',
    }),
    text({
      width: '35%',
      title: 'Comment',
      dataIndex: 'comment',
      render: (comment) => (
        <Typography.Paragraph ellipsis={{ rows: 3, tooltip: comment }}>
          {comment}
        </Typography.Paragraph>
      ),
    }),
  ];
  return (
    <div key={`disabled-${model.order.disabled}`} className={styles.container}>
      <form onSubmit={handleSubmit} className={styles.header}>
        <Input
          value={newCommentValue}
          onChange={(event) => setNewCommentValue(event.target.value)}
          disabled={model.order.disabled}
        />
        <Button
          htmlType="submit"
          type="primary"
          disabled={model.order.disabled}
        >
          <PlusOutlined /> Add comment
        </Button>
      </form>
      <Table bordered columns={columns} data={data} pagination={false} />
    </div>
  );
});
