import { getVariationBySku } from 'http/variationApi';

import { FC, useState } from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import { ValueType } from '@rc-component/mini-decimal';
import { Button, Spin, Tag } from 'antd';
import {
  cents2dollars,
  getVariationView,
  removeStatusPrefix,
  notify,
  upperUndescoreToRegularCase,
  useModal,
} from 'common/utils';
import { customActions, image, Table, text, title } from 'components/Table';
import { useFormContext } from 'react-hook-form';
import { PurchaseModel } from 'store/purchase-model';

import { OrderExpandedViewModel } from '../../../../order-expanded-view-model';
import { Footer } from '../../../TableFooter';

import { DeleteButton, ImageCol, QuantityCol, SkuCol } from './Columns';
import { OrderLinksModal } from './OrderLinksModal';

import styles from './table-content.module.scss';

interface ITableContentProps {
  model: OrderExpandedViewModel;
  isEdit: boolean;
  purchases: PurchaseModel[];
  onUpdate: (purchases: PurchaseModel[]) => void;
  onDelete: (purchase: PurchaseModel) => void;
}

const columns = (
  isEdit: boolean,
  getValues: any,
  onQuantityChange: (purchaseId: number, quantity: ValueType | null) => void,
  findVariationbySku: (purchase: PurchaseModel) => Promise<void>,
  onLinkClick: (purchase: PurchaseModel) => void,
  onDelete: (purchase: PurchaseModel) => void,
) => [
  image({
    width: '60px',
    title: 'Photo',
    dataIndex: 'image',
    render: (image) => <ImageCol image={image} />,
  }),
  title({
    width: '80px',
    title: 'Title',
    dataIndex: 'title',
    getHref: (record: PurchaseModel) =>
      `/product/${record.variation.productId}`,
    target: '_blank',
  }),
  text({
    width: '80px',
    title: 'Shipment',
    dataIndex: 'shipmentReasons',
    render: (record: string[]) =>
      record.map((str) => (
        <Tag className={styles.reason} key={str}>
          {upperUndescoreToRegularCase(removeStatusPrefix(str, 'SHIPMENT_'))}
        </Tag>
      )),
  }),
  customActions({
    width: '60px',
    title: 'Quantity',
    dataIndex: 'quantity',
    render: (_, record: PurchaseModel) => (
      <QuantityCol
        isEditing={isEdit}
        purchase={record}
        quantity={
          getValues(`purchases.${record.id}.quantity`) ?? record.quantityUpdated
        }
        disabled={!!record.productKit}
        onQuantityChange={onQuantityChange}
      />
    ),
  }),
  text({
    width: '80px',
    title: 'Variation',
    dataIndex: 'variation',
    render: ({ content }) => {
      return <span>{getVariationView(content)}</span>;
    },
  }),
  text({
    width: '60px',
    title: 'Price',
    dataIndex: 'adjustedPrice',
    render: (price) => {
      return <span>{cents2dollars(price)}</span>;
    },
  }),
  text({
    width: '80px',
    title: 'SKU',
    dataIndex: 'sku',
    render: (_, record: PurchaseModel) => (
      <SkuCol
        name={`purchases.${record.id}.sku`}
        isEditing={isEdit}
        purchase={record}
        sku={getValues(`purchases.${record.id}.sku`) ?? record.sku}
        onSearch={() => findVariationbySku(record)}
      />
    ),
  }),
  text({
    width: '80px',
    title: 'Purchase Link',
    dataIndex: 'orderLink',
    render: (_, record: PurchaseModel) => (
      <Button
        className={styles.link}
        onClick={() => onLinkClick(record)}
        size="small"
        disabled={record.status === 'empty' || record.status === 'added'}
      >
        Add Link
      </Button>
    ),
  }),
  text({
    width: '60px',
    title: 'KIT №',
    dataIndex: ['productKit', 'id'],
  }),
  customActions({
    width: '50px',
    render: (_, record: PurchaseModel) => {
      const message = record.productKit
        ? `All items from KIT №${record.productKit.id} will be also removed, are you sure to delete?`
        : 'Are you sure to delete?';
      return (
        <DeleteButton
          isEditing={isEdit}
          message={message}
          onDelete={() => onDelete(record)}
        />
      );
    },
  }),
];

export const TableContent: FC<ITableContentProps> = ({
  model,
  purchases,
  isEdit,
  onUpdate,
  onDelete,
}) => {
  const [isOpen, openModal, closeModal] = useModal(false);
  const [orderLinks, setOrderLinks] = useState<string[]>([]);
  const [currentVariationId, setCurrentVariationId] = useState(0);
  const [loading, setLoading] = useState(false);
  const { getValues, setValue } = useFormContext();

  const remainingPurchases = purchases.filter(
    (purchase) => purchase.status !== 'deleted',
  );

  const handleQuantityChange = (
    purchaseId: number,
    quantity: ValueType | null,
  ) => {
    setValue(`purchases.${purchaseId}.quantity`, quantity);
  };

  const handleLinkClick = (purchase: PurchaseModel) => {
    const { orderLink, variationId } = purchase;

    setOrderLinks(orderLink);
    setCurrentVariationId(variationId);
    openModal();
  };

  const findVariationbySku = async (purchase: PurchaseModel) => {
    setLoading(true);

    const emptyPurchaseId = purchase.id;
    const { sku, quantity } = getValues(`purchases.${emptyPurchaseId}`);
    const { data: foundVariation } = await getVariationBySku(sku);

    setLoading(false);

    if (!foundVariation) {
      notify('Variation is not found!', 'error');

      return;
    }

    const updatedFoundVariation = {
      ...foundVariation,
      id: foundVariation.id,
      quantity,
      variation: foundVariation,
      productKit: null,
      adjustedPrice: foundVariation.content.totalPrice,
      shipmentItems: [],
    };

    const formattedPurchase = model.formatifyPurchase(updatedFoundVariation);

    const duplicatedPurchase = purchases.find(
      (purchase) => purchase.sku === formattedPurchase.sku,
    );

    const duplicatedKit =
      duplicatedPurchase?.productKit?.id === formattedPurchase?.productKit?.id;

    const hasDuplicateError = duplicatedPurchase && duplicatedKit;

    if (hasDuplicateError) {
      notify('Variation already exists!', 'error');

      return;
    }

    purchase.purchase = formattedPurchase;
    purchase.status = 'added';
    purchase.quantityUpdated = quantity;

    // we need to mark old value as deleted as it's still in the form
    setValue(`purchases.${emptyPurchaseId}.status`, 'deleted');
    setValue(`purchases.${purchase.id}`, { sku, quantity, status: 'valid' });
    onUpdate([...purchases]);
  };

  const handleDelete = (purchase: PurchaseModel) => {
    onDelete(purchase);
  };

  return (
    <>
      {loading && (
        <Spin
          className={styles.loader}
          indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        />
      )}
      <Table
        data={remainingPurchases}
        columns={columns(
          isEdit,
          getValues,
          handleQuantityChange,
          findVariationbySku,
          handleLinkClick,
          handleDelete,
        )}
        skeleton={model.skeleton}
        footer={() => <Footer model={model} />}
      />
      <OrderLinksModal
        open={isOpen}
        model={model}
        orderLinks={orderLinks}
        variationId={currentVariationId}
        onOk={closeModal}
        onCancel={closeModal}
      />
    </>
  );
};
