import { ChangeEvent, FC, Fragment, useEffect, useState } from 'react';

import { Button, Divider, Input, Modal, Select } from 'antd';
import { defaultPhoto } from 'common/constants';
import { IItemStats, TOption } from 'common/types';
import { EResponsibles } from 'common/types/enums';
import { EReturnReasons } from 'common/types/return/enums';
import { ESuppliers } from 'common/types/shipment/enums';
import { observer } from 'mobx-react-lite';
import { PurchaseViewModel } from 'pages/NewOrder/view-models/purchase-view-model';

import { IReturnWithSelected } from '../../../types';
import { PackageModalItem } from '../../PackageModalItem';

import styles from './add-return-modal.module.scss';

interface IAddReturnModalProps {
  open: boolean;
  purchases: PurchaseViewModel[];
  itemStats: any;
  supplierOptions: TOption[];
  responsibleOptions: TOption[];
  reasonOptions: TOption[];
  isLoading?: boolean;
  onOk: (
    returnItems: IReturnWithSelected[],
    supplier: ESuppliers,
    responsible: EResponsibles,
    reason: EReturnReasons,
    costOfGoods: number,
  ) => Promise<void>;
  onCancel: () => void;
}

const getAvailablePurchaseIds = (itemStats: IItemStats[]) =>
  itemStats.reduce((acc, curr) => {
    if (curr.totalShipped) {
      acc.push(curr.orderItemId);
    }

    return acc;
  }, [] as number[]);

const getFormattedPurchases = (
  purchases: PurchaseViewModel[],
  availableIds: number[],
): IReturnWithSelected[] =>
  purchases.reduce((acc, curr) => {
    if (availableIds.includes(curr.id)) {
      const hasProductKit = !!curr.productKit;

      acc.push({
        ...curr.purchase,
        selected: false,
        selectedQuantity: hasProductKit ? curr.quantity : 1,
      });
    }

    return acc;
  }, [] as IReturnWithSelected[]);

const getReturnShipmentModalItemProps = (returnItem: IReturnWithSelected) => {
  const title = returnItem.productKit
    ? `${returnItem.title} - KIT №${returnItem.productKit.id}`
    : returnItem.title;

  const photo =
    returnItem.variation.content.photos?.find(
      (photo) => photo.id === returnItem.variation.content.mainPhotoId,
    ) || defaultPhoto;

  const disabled = { input: !returnItem.selected || !!returnItem.productKit };

  return { title, photo, disabled };
};

export const AddReturnModal: FC<IAddReturnModalProps> = observer(
  ({
    open,
    purchases,
    itemStats,
    supplierOptions,
    responsibleOptions,
    reasonOptions,
    isLoading,
    onOk,
    onCancel,
  }) => {
    const [supplier, setSupplier] = useState<ESuppliers | null>(null);
    const [responsible, setResponsible] = useState<EResponsibles | null>(null);
    const [reason, setReason] = useState<EReturnReasons | null>(null);
    const [costOfGoods, setCostOfGoods] = useState('');
    const [returnItems, setReturnItems] = useState<IReturnWithSelected[]>([]);
    const [isAllSelected, setAllSelected] = useState(false);

    const hasReturnItems = !!returnItems.length;
    const isCostOfGoodsValid =
      +costOfGoods && !isNaN(+costOfGoods) && +costOfGoods > 0;

    const canAdd =
      returnItems.some((item) => item.selected) &&
      !!supplier &&
      !!responsible &&
      !!reason &&
      isCostOfGoodsValid;

    const onSupplierChange = (supplier: ESuppliers) => setSupplier(supplier);

    const onResponsibleChange = (responsible: EResponsibles) =>
      setResponsible(responsible);

    const onReasonChange = (reason: EReturnReasons) => setReason(reason);

    const onCostOfGoodsChange = (e: ChangeEvent<HTMLInputElement>) =>
      setCostOfGoods(e.target.value);

    const handleToggleAll = () => {
      const updatedItems = returnItems.map((returnItem) => ({
        ...returnItem,
        selected: !isAllSelected,
        selectedQuantity: !isAllSelected ? returnItem.quantity : 1,
      }));

      setReturnItems(updatedItems);
    };

    const handleChangeSelected = (selectedReturnItem: IReturnWithSelected) => {
      const updatedItems = returnItems.map((returnItem) => {
        const shouldChangeSelected =
          returnItem.id === selectedReturnItem.id ||
          (returnItem.productKit &&
            selectedReturnItem.productKit &&
            returnItem.productKit.id === selectedReturnItem.productKit.id);

        if (shouldChangeSelected) {
          return { ...returnItem, selected: !returnItem.selected };
        }

        return returnItem;
      });

      setReturnItems(updatedItems);
    };

    const handleChangeValue = (purchaseId: number, value: number | null) => {
      const updatedItems = returnItems.map((returnItem) => {
        if (returnItem.id === purchaseId) {
          return { ...returnItem, selectedQuantity: Number(value) };
        }

        return returnItem;
      });

      setReturnItems(updatedItems);
    };

    const handleCreate = async () => {
      await onOk(
        returnItems,
        supplier as ESuppliers,
        responsible as EResponsibles,
        reason as EReturnReasons,
        +costOfGoods,
      );
    };

    useEffect(() => {
      if (open) {
        const availablePurchasesIds = getAvailablePurchaseIds(itemStats);
        const formattedPurchases = getFormattedPurchases(
          purchases,
          availablePurchasesIds,
        );

        setSupplier(null);
        setResponsible(null);
        setReason(null);
        setCostOfGoods('');
        setReturnItems(formattedPurchases);
      }
    }, [open, purchases, itemStats]);

    useEffect(() => {
      const allSelected = !returnItems.some(
        (returnItem) => !returnItem.selected,
      );

      setAllSelected(allSelected);
    }, [returnItems]);

    return (
      <Modal
        open={open}
        title="Add new Return"
        okButtonProps={{ disabled: !canAdd, loading: isLoading }}
        destroyOnClose
        onOk={handleCreate}
        onCancel={onCancel}
      >
        <div className={styles.selectorWrapper}>
          <Button onClick={handleToggleAll} disabled={!hasReturnItems}>
            {isAllSelected ? 'Unselect all' : 'Select all'}
          </Button>
          <Select
            placeholder="Supplier"
            options={supplierOptions}
            value={supplier}
            onChange={onSupplierChange}
          />
          <Select
            placeholder="Responsible"
            options={responsibleOptions}
            value={responsible}
            onChange={onResponsibleChange}
          />
          <Select
            placeholder="Reason"
            value={reason}
            options={reasonOptions}
            onChange={onReasonChange}
          />
          <Input
            placeholder="Cost of goods"
            value={costOfGoods}
            prefix="$"
            onChange={onCostOfGoodsChange}
          />
        </div>
        <div className={styles.productList}>
          {hasReturnItems ? (
            returnItems.map((returnItem) => {
              const { title, photo, disabled } =
                getReturnShipmentModalItemProps(returnItem);

              return (
                <Fragment key={returnItem.id}>
                  <PackageModalItem
                    title={title}
                    photo={photo}
                    selected={returnItem.selected}
                    quantity={returnItem.selectedQuantity}
                    min={1}
                    max={returnItem.quantity}
                    disabled={disabled}
                    onSelect={() => handleChangeSelected(returnItem)}
                    onChange={(e) => handleChangeValue(returnItem.id, e)}
                  />
                  <Divider />
                </Fragment>
              );
            })
          ) : (
            <span>No purchases available for return</span>
          )}
        </div>
      </Modal>
    );
  },
);
