import { FC } from 'react';

import { ColumnType } from 'antd/es/table';
import { IManagedOrder } from 'common/types/employee';
import { DatePicker } from 'components/DatePicker';
import { Table, text } from 'components/Table';
import { observer } from 'mobx-react-lite';

import { OrderCounterViewModel } from '../order-counter-view-model';

import styles from './managed-order-table.module.scss';

interface IManagedOrderTableProps {
  model: OrderCounterViewModel;
}

const columns: ColumnType<IManagedOrder>[] = [
  text({
    key: 'name',
    width: '150px',
    title: 'Name',
    dataIndex: 'name',
  }),
  text({
    key: 'amount',
    width: '150px',
    title: 'Amount',
    dataIndex: 'amountOfOrders',
  }),
];

export const ManagedOrderTable: FC<IManagedOrderTableProps> = observer(
  ({ model }) => (
    <>
      <div className={styles.container}>
        <DatePicker
          picker="month"
          value={model.date}
          disabledDate={(current) =>
            current.getFullYear() > model.today.getFullYear()
          }
          allowClear={false}
          className={styles.datePicker}
          onChange={model.onDateChange}
          onOpenChange={model.onPickerOpenChange}
        />
        <Table
          rowKey={(record) => record.email}
          columns={columns}
          data={model.managedOrders}
          loading={model.isLoading}
          bordered
        />
      </div>
    </>
  ),
);
