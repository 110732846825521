import { FC } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useModal } from 'common/utils';
import { observer } from 'mobx-react-lite';

import { OrderExpandedViewModel } from '../../order-expanded-view-model';

import { CreateReturnShipmentModal } from './CreateReturnShipmentModal';
import { ReturnShipment } from './ReturnShipment';
import { ReturnSkeleton } from './ReturnSkeleton';

import styles from './return-section.module.scss';

interface IReturnSectionProps {
  model: OrderExpandedViewModel;
}

export const ReturnSection: FC<IReturnSectionProps> = observer(({ model }) => {
  const [openEditModal, handleEditModalOpen, handleEditModalClose] =
    useModal(false);

  return (
    <div>
      <div className={styles.header}>
        <span className={styles.title}>Return</span>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={handleEditModalOpen}
          disabled={model.loading || model.order.disabled}
        >
          Add new return
        </Button>
      </div>
      <div className={styles.returnItemWrapper}>
        {model.loading && new Array(2).fill(<ReturnSkeleton />)}
        {!model.loading &&
          model.returnShipments.map((el) => (
            <ReturnShipment key={el.id} returnShipment={el} model={model} />
          ))}
      </div>
      <CreateReturnShipmentModal
        openEditModal={openEditModal}
        handleEditModalClose={handleEditModalClose}
        model={model}
      />
    </div>
  );
});
