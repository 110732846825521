import { deleteById, getProductsWithFilters } from 'http/productApi';

import { IProduct } from 'common/types';
import { notify } from 'common/utils';
import { computed, makeAutoObservable, observable } from 'mobx';
import { TParams } from 'store/filters-model';
import { TableProductModel } from 'store/table-product-model';

import { FiltersModel } from './filters-model';

export interface Option {
  value: string;
  id: number;
}
export class ProductViewModel {
  _loading = false;
  _products: TableProductModel[] = [];
  _filters: FiltersModel;
  _count = 0;

  get loading() {
    return this._loading;
  }

  set loading(value: boolean) {
    this._loading = value;
  }

  set products(products: TableProductModel[]) {
    this._products = products;
  }

  get products() {
    return this._products;
  }

  get skeleton() {
    return new Array(5).fill(new TableProductModel());
  }

  get defaultCurrent() {
    return this._filters.defaultCurrent;
  }

  fetchProducts = async () => {
    const {
      search,
      selectedBrand,
      selectedCategory,
      selectedStatus,
      page,
      pageSize,
      sort,
      sortBy,
      selectedType,
    } = this._filters;

    const params = Object.assign(
      {},
      search && { title: search },
      selectedBrand?.id && { brandId: selectedBrand?.id },
      selectedCategory?.id && { subcategoryId: selectedCategory?.id },
      selectedStatus?.value && { status: selectedStatus?.value },
      page && { page: page },
      pageSize && { pageSize: pageSize },
      selectedType && { type: selectedType?.id },
      sort && { sort },
      sortBy && { sortBy },
    );

    this.loading = true;

    try {
      const { count, items, page } = await getProductsWithFilters(params);

      this._products = items.map(
        (product: IProduct) => new TableProductModel(product),
      );

      this._count = count;
      this._filters.page = page;

      return items;
    } catch (e) {
      notify(`Error, reason:${e}`, 'error');
    } finally {
      this.loading = false;
    }
  };

  removeProduct = async (id: number) => {
    const updatedProducts = this._products.filter(
      (product) => product.id !== id,
    );
    const result = await deleteById(id);

    if (result) {
      this.products = updatedProducts;

      notify(
        <div>
          Product <strong>{result.title}</strong> was removed succesfully!
        </div>,
      );
    }
  };

  constructor(params?: TParams) {
    this._filters = new FiltersModel(this.fetchProducts, params);

    this.fetchProducts();

    makeAutoObservable(this, {
      _loading: observable,
      _products: observable,
      _filters: observable,
      _count: observable,

      loading: computed,
    });
  }
}
