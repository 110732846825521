// TODO: make common observer cell
import { FC } from 'react';

import { Skeleton, Typography } from 'antd';
import { ColumnType } from 'antd/es/table';
import cn from 'clsx';
import { getObservableTableCellValue } from 'common/utils';
import { Observer } from 'mobx-react-lite';

import styles from './colored.module.scss';

export interface TColoredCellProps {
  value: string | number;
  formatter?: (status: string | number | null) => string;
  colors?: { [key: string]: [string, string] };
  loading?: boolean;
  observer?: boolean;
}

export type TColoredColumn<T> = ColumnType<T> &
  Omit<TColoredCellProps, 'value'>;

export const ColoredCell: FC<TColoredCellProps> = ({
  value,
  formatter,
  colors,
  loading,
}) => {
  if (loading) {
    return <Skeleton.Input active />;
  }

  const formatted = formatter?.(value) ?? value;
  const color = colors?.[value];

  return (
    <div className={styles.cell} style={{ backgroundColor: color?.[0] }}>
      <Typography.Text
        ellipsis={{ tooltip: formatted }}
        strong
        style={{ color: color?.[1], fontWeight: 500 }}
      >
        {formatted}
      </Typography.Text>
    </div>
  );
};

export const colored = <T,>({
  dataIndex = 'value',
  key = 'value',
  width = '10%',
  loading,
  formatter,
  colors,
  observer,
  ...props
}: TColoredColumn<T>): ColumnType<T> => ({
  dataIndex: dataIndex,
  key: key,
  width: width,
  render: (value: string, record: T) => (
    <Observer>
      {() => {
        const observedValue = getObservableTableCellValue<T>(dataIndex, record);

        return (
          <ColoredCell
            value={observer ? observedValue : value}
            formatter={formatter}
            colors={colors}
            loading={loading}
          />
        );
      }}
    </Observer>
  ),
  className: cn(styles.container, props.className),

  ...props,
});
