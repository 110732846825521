import { TPhoto } from 'common/types';
import { makeAutoObservable, observable } from 'mobx';

export class ReviewPhotoModel {
  _photos: TPhoto[] = [];
  _selectedPhoto: TPhoto = {
    id: -1,
    key: '',
    url: '',
  };
  _title = '';

  get photos() {
    return this._photos;
  }

  set photos(photos: TPhoto[]) {
    this._photos = photos;
  }

  get selectedPhoto() {
    return this._selectedPhoto;
  }

  set selectedPhoto(photo: TPhoto) {
    this._selectedPhoto = photo;
  }

  get title() {
    return this._title;
  }

  set title(title: string) {
    this._title = title;
  }

  constructor() {
    makeAutoObservable(this, {
      _photos: observable,
      _selectedPhoto: observable,
      _title: observable,
    });
  }
}
