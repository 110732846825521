import { FC } from 'react';

import { Button, Typography } from 'antd';

import styles from './static-data.module.scss';

export interface IField {
  label: string;
  value: string | number | null | undefined;
  href?: string;
}

export interface IStaticDataProps {
  fields: IField[];
  disabled: boolean;
  onToggleEdit: () => void;
}

export const StaticData: FC<IStaticDataProps> = ({
  fields,
  disabled,
  onToggleEdit,
}) => (
  <div className={styles.container}>
    <div className={styles.content}>
      {fields.map((field) => (
        <div key={field.label}>
          <Typography.Text strong>{field.label}:&nbsp;</Typography.Text>
          {field.href ? (
            <Typography.Link
              href={`//${field.href}`}
              target="_blank"
              rel="noreferrer"
            >
              {field.value}
            </Typography.Link>
          ) : (
            <Typography.Text>{field.value}</Typography.Text>
          )}
        </div>
      ))}
    </div>
    <Button disabled={disabled} onClick={onToggleEdit}>
      Edit
    </Button>
  </div>
);
