import { getDashboard } from 'http/dashboard';

import { defaultDashboardEntity } from 'common/constants';
import { IServerError } from 'common/types';
import { IDashboard } from 'common/types/dasboard';
import { cents2dollars, notify } from 'common/utils';
import { makeAutoObservable } from 'mobx';

export class DashboardViewModel {
  _isLoading = false;
  today = new Date();
  _startDate = new Date(
    this.today.getFullYear(),
    this.today.getMonth(),
    1,
    0,
    0,
    0,
  );
  _endDate = new Date(
    this.today.getFullYear(),
    this.today.getMonth(),
    this.today.getDate(),
    23,
    59,
    59,
  );
  _dashboard: IDashboard = defaultDashboardEntity;

  get isLoading() {
    return this._isLoading;
  }

  set isLoading(isLoading: boolean) {
    this._isLoading = isLoading;
  }

  get startDate() {
    return this._startDate;
  }

  set startDate(startDate: Date) {
    this._startDate = startDate;
  }

  get endDate() {
    return this._endDate;
  }

  set endDate(endDate: Date) {
    this._endDate = endDate;
  }

  get dashboard() {
    return this._dashboard;
  }

  set dashboard(dashboard: IDashboard) {
    this._dashboard = dashboard;
  }

  get finance() {
    return [
      {
        label: 'Revenue',
        value: cents2dollars(this.dashboard.totalPaid),
      },
      {
        label: 'Total cost of goods',
        value: cents2dollars(this.dashboard.totalCostOfPurchase),
      },
      {
        label: 'Total cost of shipments',
        value: cents2dollars(this.dashboard.totalCostOfShipping),
      },
      {
        label: 'Margin',
        value: cents2dollars(this.dashboard.totalMargin),
      },
      {
        label: 'Fees',
        value: cents2dollars(this.dashboard.totalFees),
      },
    ];
  }

  get orders() {
    return [
      {
        label: 'Total orders',
        value: this.dashboard.amountOfOrders,
      },
      {
        label: 'Total shipments',
        value: this.dashboard.amountOfShipments,
      },
      {
        label: 'Unique buyers',
        value: this.dashboard.amountOfCustomers,
      },
    ];
  }

  onRangeChange = (range: [Date, Date]) => {
    const startDate = new Date(range[0]);
    const endDate = new Date(range[1]);

    startDate.setHours(0, 0, 0);
    endDate.setHours(23, 59, 59);

    this.startDate = startDate;
    this.endDate = endDate;
  };

  onPickerOpenChange = async (isOpen: boolean) => {
    if (isOpen) {
      return;
    }

    await this.getDashboardData(this.startDate, this.endDate);
  };

  getDashboardData = async (startDate: Date, endDate: Date) => {
    try {
      this.isLoading = true;

      const { data: dashboard } = await getDashboard(
        startDate.toISOString(),
        endDate.toISOString(),
      );

      this.dashboard = dashboard;
    } catch (e) {
      const error = e as IServerError;

      notify(`Error, reason:${error.response?.data?.message}`, 'error');
    } finally {
      this.isLoading = false;
    }
  };

  constructor() {
    this.getDashboardData(this.startDate, this.endDate);

    makeAutoObservable(this);
  }
}
