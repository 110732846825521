import { createBrand, editBrand, getBrandById } from 'http/brandApi';

import { defaultBrandEntity } from 'common/constants';
import { IBrand, ICreateBrand } from 'common/types';
import { EStatuses } from 'common/types/enums';
import { notify } from 'common/utils';
import { makeAutoObservable } from 'mobx';

export class BrandModel {
  _loading = false;
  _brand: IBrand = defaultBrandEntity;

  get loading() {
    return this._loading;
  }

  set loading(loading: boolean) {
    this._loading = loading;
  }

  get brand() {
    return this._brand;
  }

  set brand(brand: IBrand) {
    this._brand = brand;
  }

  get id() {
    return this.brand.id;
  }

  get title() {
    return this.brand.title;
  }

  get photo() {
    return this.brand.photo;
  }

  get main() {
    return this.brand.main;
  }

  get displayPriority() {
    return this.brand.displayPriority;
  }

  get description() {
    return this.brand.description;
  }

  get status() {
    return this.brand.status;
  }

  get subcategoriesCount() {
    return this.brand.subcategoriesCount;
  }

  get productsCount() {
    return this.brand.productsCount;
  }

  get metaTitle() {
    return this.brand.metaTitle ?? '';
  }

  get metaDescription() {
    return this.brand.metaDescription ?? '';
  }

  serializeBrand = (values: ICreateBrand) => {
    const fd = new FormData();
    const status = values.isArchive ? EStatuses.ARCHIVE : EStatuses.ACTIVE;

    fd.append('title', values.title);
    fd.append('description', values.description);
    fd.append('status', status);
    fd.append('main', values.main.toString());
    fd.append('displayPriority', values.displayPriority.toString());
    fd.append('metaTitle', values.metaTitle.toString());
    fd.append('metaDescription', values.metaDescription.toString());

    if (values.photo && values.photo.file) {
      fd.append('photo', values.photo.file);
    }

    return fd;
  };

  manageBrand = async (isUpdate: boolean, values: ICreateBrand) => {
    this.loading = true;

    const serializedBrand = this.serializeBrand(values);

    try {
      if (isUpdate) {
        await editBrand(this.id, serializedBrand);
      } else {
        await createBrand(serializedBrand);
      }

      notify(
        <div>
          Brand <strong>{values.title}</strong> was{' '}
          {isUpdate ? 'updated' : 'created'} succesfully!
        </div>,
      );

      return true;
    } catch (e) {
      notify(`Failed to ${isUpdate ? 'update' : 'create'} Brand`, 'error');
    } finally {
      this._loading = false;
    }
  };

  fetchBrand = async (id: number) => {
    this.loading = true;

    try {
      const brand = await getBrandById(id);

      if (brand) {
        this.brand = brand;
      }
    } catch (e) {
      notify('Can`t load brand', 'error');

      setTimeout(() => {
        window.location.href = '/brand/new';
      }, 500);
    } finally {
      this.loading = false;
    }
  };

  constructor(brandId?: number, brand?: IBrand) {
    if (brand) {
      this.brand = brand;
    }

    if (brandId && !brand) {
      this.fetchBrand(brandId);
    }

    makeAutoObservable(this);
  }
}
