import { FC } from 'react';

import { Modal, Button } from 'antd';
import { employeeRoleOptions } from 'common/constants';
import { IDetailedEmployee } from 'common/types/employee';
import { EEmployeeRoles } from 'common/types/employee/enums';
import { getEmployeeValidationSchema } from 'common/validation';
import { Form, FormInput, FormSelect } from 'components/Form';
import { useFormContext } from 'react-hook-form';

import { IEmployeeFormValues } from '../types';
import { getInitialValues } from '../utils';

import styles from './employee-modal.module.scss';

interface IPersonalFieldsProps {
  isEdit: boolean;
}

interface IRequisitesFieldsProps {
  isEdit: boolean;
}

interface IEmployeeModalProps {
  open: boolean;
  onCancel: () => void;
  isEdit: boolean;
  employee: IDetailedEmployee | null | undefined;
  onSubmit: (values: Record<string, string | null>) => Promise<boolean>;
}

const PersonalFields: FC<IPersonalFieldsProps> = ({ isEdit }) => {
  const { watch } = useFormContext();

  const role = watch('role');
  const isLastNameRequired = role !== EEmployeeRoles.SUPPLIER;

  return (
    <div className={styles.fields}>
      <p className={styles.subtitle}>Personal</p>
      <FormInput
        name="firstName"
        label="First name"
        className={styles.input}
        required
      />
      <FormInput
        name="lastName"
        label="Last name"
        className={styles.input}
        required={isLastNameRequired}
      />
      <FormSelect
        name="role"
        label="Role"
        options={employeeRoleOptions}
        zIndex={1000}
        disabled={isEdit}
        className={styles.input}
        required={!isEdit}
      />
      <FormInput name="email" label="Email" className={styles.input} required />
      <FormInput
        type="password"
        name="password"
        label="Password"
        className={styles.input}
        required={!isEdit}
      />
    </div>
  );
};

const RequisitesFields: FC<IRequisitesFieldsProps> = ({ isEdit }) => {
  const { watch } = useFormContext();

  const role = watch('role');
  const isSupplier = role === EEmployeeRoles.SUPPLIER;

  if (!isSupplier) {
    return null;
  }

  return (
    <div className={styles.fields}>
      <p className={styles.subtitle}>Requisites</p>
      <FormInput name="name" label="Naming" className={styles.input} required />
      <FormInput name="address" label="Address" className={styles.input} />
      <FormInput name="phoneNumber" label="Phone" className={styles.input} />
      <FormInput name="bankName" label="Bank name" className={styles.input} />
      <FormInput name="swift" label="Swift" className={styles.input} />
      <FormInput
        name="accountNumber"
        label="Account number"
        className={styles.input}
      />
    </div>
  );
};

export const EmployeeModal: FC<IEmployeeModalProps> = ({
  open,
  onCancel,
  isEdit,
  employee,
  onSubmit,
}) => {
  const initialValues = getInitialValues(isEdit, employee);

  const handleSubmit = async (values: IEmployeeFormValues) => {
    const isValid = await onSubmit(values);

    if (isValid) {
      onCancel();
    }
  };

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      title={isEdit ? 'Edit employee' : 'Add new employee'}
      footer={null}
      destroyOnClose
    >
      <Form
        initialValues={initialValues}
        validation={getEmployeeValidationSchema(isEdit)}
        shouldUnregister
        onSubmit={handleSubmit}
        className={styles.form}
      >
        <PersonalFields isEdit={isEdit} />
        <RequisitesFields isEdit={isEdit} />
        <div className={styles.buttons}>
          <Button type="primary" htmlType="submit" className={styles.button}>
            Save
          </Button>
          <Button
            htmlType="button"
            className={styles.button}
            onClick={onCancel}
          >
            Cancel
          </Button>
        </div>
      </Form>
    </Modal>
  );
};
