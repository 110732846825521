import { ISupplier } from 'common/types/supplier';
import { computed, makeObservable, observable } from 'mobx';

export class SupplierModel {
  _supplier: ISupplier;

  get supplier() {
    return this._supplier;
  }

  set supplier(supplier: ISupplier) {
    this._supplier = supplier;
  }

  get id() {
    return this._supplier.id;
  }

  get name() {
    return this._supplier.name;
  }

  get user() {
    return this._supplier.user;
  }

  constructor(supplier: ISupplier) {
    this._supplier = supplier;

    makeObservable(this, {
      _supplier: observable,
      supplier: computed,
      id: computed,
      name: computed,
    });
  }
}
