import { FC } from 'react';

import { Skeleton, Typography } from 'antd';
import { ColumnType } from 'antd/es/table';
import cn from 'clsx';
import { getNestedProperty } from 'common/utils';

import styles from './title.module.scss';

export type TTitleColumn<T> = ColumnType<T> &
  Omit<TTitleCellProps, 'title'> & {
    getHref?: (record: T) => string;
  };

export interface TTitleCellProps {
  title: string;
  loading?: boolean;
  nested?: boolean;
  className?: string;
  onClick?: (id: number) => void;
  id?: number;
  href?: string;
  target?: string;
}

const TitleCell: FC<TTitleCellProps> = ({
  title,
  loading,
  className,
  onClick,
  id,
  href,
  target,
}) => {
  if (loading) {
    return <Skeleton.Input active />;
  }

  if (href) {
    return (
      <a
        href={href}
        target={target}
        className={cn(styles.clickable, styles.link, className)}
      >
        {title}
      </a>
    );
  }

  return (
    <Typography.Text
      className={cn(className, {
        [styles.clickable]: !!onClick,
      })}
      strong
      onClick={() => id && onClick?.(id)}
    >
      {title}
    </Typography.Text>
  );
};

export const title = <T extends { id: number }>({
  dataIndex = 'title',
  key = 'title',
  width = '10%',
  loading,
  nested = false,
  onClick,
  getHref,
  target,
  className,
  ...props
}: TTitleColumn<T>): ColumnType<T> => ({
  dataIndex: dataIndex,
  key: key,
  width: width,
  render: (title: string, record: T) => {
    const text = nested
      ? getNestedProperty(
          record as Record<string, any>,
          (dataIndex as string).split('.'),
        )
      : title;

    const href = getHref?.(record);

    return (
      <TitleCell
        id={record.id}
        className={className}
        title={text}
        onClick={onClick}
        loading={loading}
        href={href}
        target={target}
      />
    );
  },
  ...props,
});
