import { addMethod, string, object, boolean, number, array, lazy } from 'yup';

import { MAX_PRODUCT_PHOTOS_LIMIT } from './constants';

export const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

addMethod(
  string,
  'onlyLatinChars',
  function onlyLatinChars(errorMessage: string) {
    return this.test(
      'only-latin-chars',
      errorMessage,
      function (value: string) {
        const { path, createError } = this;

        return (
          /^[^ёЁа-яА-Я]*$/giu.test(value ?? '') ||
          createError({
            path,
            message:
              errorMessage ?? 'Field does not accept non-latinic characters',
          })
        );
      },
    );
  },
);

addMethod(array, 'unique', function (message) {
  return this.test(
    'unique',
    message,
    function (list: { id: string; value: string }[]) {
      return list ? list?.length === new Set(list).size : true;
    },
  );
});

addMethod(string, 'email', function email(errorMessage: string) {
  return this.test('email', errorMessage, function (value: string) {
    const { path, createError } = this;

    return (
      emailRegex.test(value ?? '') ||
      createError({
        path,
        message: errorMessage ?? 'Email is invalid',
      })
    );
  });
});

addMethod(string, 'arbitrary', function arbitrary() {
  return this.nullable().transform((curr, orig) => (orig === '' ? null : curr));
});

addMethod(string, 'numeric', function numeric(errorMessage?: string) {
  const message = errorMessage ?? 'Field must be numeric';

  return this.test('numeric', message, function (value: string) {
    const { path, createError } = this;

    if (!value) {
      return true;
    }

    return (
      /^\d+$/.test(value ?? '') ||
      createError({
        path,
        message: errorMessage ?? 'Field must be numeric',
      })
    );
  });
});

addMethod(number, 'arbitrary', function arbitrary() {
  return this.nullable().transform((curr, orig) => (orig === '' ? null : curr));
});

export const photoSchema = object().shape({
  id: number(),
  name: string(),
  url: string(),
});

export const photosSchema = array().of(photoSchema);

export const loginSchema = object().shape({
  email: string().required().onlyLatinChars().email().trim(),
  password: string().required(),
});

export const brandSchema = object().shape({
  title: string().onlyLatinChars().required(),
  main: boolean(),
  displayPriority: number().required(),
  description: string().onlyLatinChars().required(),
  photo: photoSchema.required('Brand photo is required'),
  metaTitle: string(),
  metaDescription: string(),
});

export const subcategorySchema = object().shape({
  title: string().onlyLatinChars().required(),
  brandId: number().required(),
  description: string().onlyLatinChars().required(),
  photo: photoSchema.required('Category photo is required'),
  metaTitle: string(),
  metaDescription: string(),
});

const commonVariationSchema = (variation: string) =>
  array()
    .of(
      string()
        .onlyLatinChars(`${variation} does not accept non-latinic characters`)
        .required('Value is required')
        .trim(),
    )
    .unique('Only unique values allowed');

const addVariationsSchema = object().shape({
  color: commonVariationSchema('color'),
  size: commonVariationSchema('size'),
  style: commonVariationSchema('style'),
  placement: commonVariationSchema('placement'),
  material: commonVariationSchema('material'),
});

export const productCreateSchema = object().shape({
  title: string().onlyLatinChars().required().trim(),
  description: string().onlyLatinChars().required().trim(),
  placement: string().onlyLatinChars().trim().nullable(),
  type: string().onlyLatinChars().required().trim(),
  material: string().onlyLatinChars().required().trim(),
  color: string().onlyLatinChars().required().trim(),
  barcode: string().trim().nullable(),
  order_link: array().of(string().trim()),
  status: string().trim(),
  price: number().typeError('you must specify a number').min(1),
  discount: number().max(99.9).nullable(),
  photos: photosSchema.max(MAX_PRODUCT_PHOTOS_LIMIT),
  brandId: number().required(),
  subcategoryId: number().required(),
  markdownSections: lazy((value) => {
    return Object.keys(value).reduce((schema, key) => {
      return schema.shape({
        [key]: object().shape({
          title: string()
            .onlyLatinChars('Title does not accept non-latinic characters')
            .required('Title is required.')
            .trim(),
          description: string()
            .onlyLatinChars(
              'Description does not accept non-latinic characters',
            )
            .required('Description is required.')
            .trim(),
        }),
      });
    }, object());
  }),
  variations: addVariationsSchema,
});

export const productUpdateSchema = productCreateSchema.shape({
  sku: string()
    .matches(/^[0-9]+$/, 'Must be only digits')
    .min(1, 'Should contain at least one digit'),
  metaTitle: string(),
  metaDescription: string(),
});

export const variationContentSchema = object().shape({
  // at the request of clients, we have temporarily hidden this information
  // description: string().required().onlyLatinChars(),
  price: number().typeError('you must specify a number').min(0),
  discount: number().max(99.9).nullable(),
  sku: string()
    .matches(/^[0-9]+$/, 'Must be only digits')
    .min(1, 'Should contain at least one digit'),
  barcode: string().nullable(),
  status: string(),
  order_link: array().of(string()),
});

export const variationPhotosSchema = object().shape({
  photos: photosSchema,
});

export const mainSliderSchema = object().shape({
  slides: lazy((value) => {
    return Object.keys(value).reduce((schema, key) => {
      return schema.shape({
        [key]: object().shape({
          photo: photoSchema.required('Slide photo is required'),
          title: string().onlyLatinChars().nullable(),
          buttonTitle: string().onlyLatinChars().nullable(),
          url: string().nullable().url('Link must be a valid URL'),
          text: string().onlyLatinChars().nullable(),
        }),
      });
    }, object());
  }),
});

export const purchasesValidationSchema = object().shape({
  purchases: lazy((value) => {
    return Object.keys(value).reduce((schema, key) => {
      return schema.shape({
        [key]: object().shape({
          quantity: number()
            .min(1, 'Quantity must be more than 0')
            .max(30, 'Quantity must be less than or equal to 30')
            .required('Please set quantity'),
          sku: string()
            .when('status', {
              is: 'valid',
              then: (schema) =>
                schema.test('status-valid', 'SKU is valid', () => true),
            })
            .when('status', {
              is: 'invalid',
              then: (schema) =>
                schema.test('status-invalid', 'SKU is invalid', () => false),
            })
            .when('status', {
              is: 'deleted',
              then: (schema) =>
                schema.test('status-deleted', 'SKU is deleted', () => true),
            }),
        }),
      });
    }, object());
  }),
});

export const shipmentValidationSchema = object().shape({
  supplier: string().onlyLatinChars(),
  reason: string().onlyLatinChars(),
  supId: string().onlyLatinChars().nullable(),
  trackingNumber: string().onlyLatinChars().nullable(),
  costOfPurchase: number().typeError('You must specify a number').nullable(),
  costOfShipping: number().typeError('You must specify a number').nullable(),
  comment: string().nullable(),
});

export const returnValidationSchema = object().shape({
  costOfGoods: number()
    .typeError('You must specify a number')
    .min(0, 'Field must not be less than 0'),
  supplier: string().onlyLatinChars(),
  responsible: string().onlyLatinChars(),
  reason: string().onlyLatinChars(),
});

export const returnCommentValidationSchema = object().shape({
  comment: string(),
});

export const promocodeValidationSchema = object().shape({
  promocode: string().onlyLatinChars().required(),
  promoType: string().required('type is a required field'),
  usesLeft: number()
    .min(1, 'count must be greater than or equal to 1')
    .nullable(),
});

export const changeAllVariationSchema = object().shape({
  status: string(),
  price: number()
    .min(1, 'Price must be more than 0')
    .nullable()
    .transform((_, value) => (value === '' ? null : Number(value))),
  discount: number()
    .min(0, 'Discount must be greater than or equal to 0')
    .max(99.9, 'Discount must be less than or equal to 99.9')
    .nullable()
    .transform((_, value) => (value === '' ? null : Number(value))),
});

export const getEmployeeValidationSchema = (isEdit: boolean) =>
  object().shape({
    firstName: string().required().onlyLatinChars().max(1000),
    lastName: string()
      .onlyLatinChars()
      .when('role', {
        is: 'ADMIN',
        then: (schema) => schema.required(),
      })
      .when('role', {
        is: 'MANAGER',
        then: (schema) => schema.required(),
      })
      .when('role', {
        is: 'SUPPLIER',
        then: (schema) => schema.arbitrary(),
      })
      .max(1000),
    role: string().required(),
    email: string().required().onlyLatinChars().email().max(1000).trim(),
    password: isEdit
      ? string().arbitrary().min(6).max(1000)
      : string().required().min(6).max(1000),
    name: string().when('role', {
      is: 'SUPPLIER',
      then: (schema) => schema.onlyLatinChars().required().max(1000),
    }),
    address: string().when('role', {
      is: 'SUPPLIER',
      then: (schema) => schema.arbitrary().max(1000),
    }),
    phoneNumber: string().when('role', {
      is: 'SUPPLIER',
      then: (schema) => schema.arbitrary(),
    }),
    bankName: string().when('role', {
      is: 'SUPPLIER',
      then: (schema) => schema.arbitrary().max(1000),
    }),
    swift: string().when('role', {
      is: 'SUPPLIER',
      then: (schema) => schema.arbitrary().max(1000),
    }),
    accountNumber: string().when('role', {
      is: 'SUPPLIER',
      then: (schema) => schema.numeric().arbitrary().max(1000),
    }),
  });

export const newSupplierSchema = object().shape({
  name: string().required().onlyLatinChars().max(1000),
});

export const personalInformationValidationSchema = object().shape({
  firstName: string().required(),
  lastName: string().required(),
  email: string().email().required(),
  phone: string(),
});

export const orderPersonalInformationValidationSchema = object().shape({
  email: string().email().required(),
});

export const shippingAddressValidationSchema = object().shape({
  firstName: string().required(),
  lastName: string().required(),
  companyName: string().nullable(),
  phone: string().nullable(),
  country: string().required(),
  address: string().required(),
  address2: string().nullable(),
  city: string().required(),
  state: string().required(),
  postalCode: string().required(),
});

export const billingAddressValidationSchema = object().shape({
  firstName: string().required(),
  lastName: string().required(),
  companyName: string().nullable(),
  phone: string().nullable(),
  country: string().required(),
  address: string().required(),
  address2: string().nullable(),
  city: string().required(),
  state: string().required(),
  postalCode: string().required(),
});

export const addPurchaseSchema = object().shape({
  sku: string()
    .min(1, 'SKU should contain at least one digit')
    .matches(/^[0-9]+$/, 'SKU must be numeric string')
    .typeError('SKU must be a number')
    .required(),
  quantity: number()
    .min(1, 'Quantity must be more than 0')
    .max(30, 'Quantity must be less than or equal to 30')
    .typeError('Quantity must be a number')
    .required('Please set quantity'),
});

export const metaInfo = object().shape({
  title: string(),
  description: string(),
});

export const bulkPriceUpdateSchema = object().shape({
  action: string().required(),
  amount: number().min(0.01, 'Amount should be greater than 0').required(),
});
