import { FC, useRef } from 'react';

import { UpOutlined } from '@ant-design/icons';
import cn from 'clsx';
import { TPhoto } from 'common/types';
import { Swiper as SwiperType, Navigation } from 'swiper';
import { SwiperSlide, Swiper } from 'swiper/react';

import styles from './vertical-swiper.module.scss';
import 'swiper/css';

export interface IVerticalSwiperProps {
  selectedPhoto: TPhoto;
  setSelectedPhoto: (photo: TPhoto) => void;
  photos: TPhoto[];
  spaceBetweenImages?: number;
}

export const VerticalSwiper: FC<IVerticalSwiperProps> = ({
  selectedPhoto,
  setSelectedPhoto,
  photos,
  spaceBetweenImages = 16,
}) => {
  const swiperRef = useRef<SwiperType>();

  return (
    <div className={styles.swiperWrapper}>
      <div className={styles.wrapperIcon}>
        <UpOutlined
          className={styles.arrowIcon}
          onClick={() => swiperRef.current?.slidePrev()}
        />
      </div>
      <Swiper
        spaceBetween={spaceBetweenImages}
        direction="vertical"
        modules={[Navigation]}
        slidesPerView="auto"
        onBeforeInit={(swiper) => {
          swiperRef.current = swiper;
        }}
      >
        {photos.map((photo) => (
          <SwiperSlide key={photo.id}>
            <div className={styles.imageWrapper}>
              <img
                src={photo.url}
                alt="no img"
                onClick={() => setSelectedPhoto(photo)}
                className={cn(styles.image, {
                  [styles.selected]: photo.key === selectedPhoto.key,
                })}
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className={styles.wrapperIcon}>
        <UpOutlined
          className={cn(styles.arrowIcon, styles.next)}
          onClick={() => swiperRef.current?.slideNext()}
        />
      </div>
    </div>
  );
};
