import { ICalculation } from 'common/types/supplier';

export class CalculationModel {
  _calculation: ICalculation;

  get correction() {
    return this._calculation.correction;
  }

  get returns() {
    return this._calculation.returns;
  }

  get shipments() {
    return this._calculation.shipments;
  }

  get total() {
    return this._calculation.total;
  }

  constructor(calculation: ICalculation) {
    this._calculation = calculation;
  }
}
