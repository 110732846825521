import { AxiosResponse } from 'axios';
import { ISlider } from 'common/types/slider';

import { $host } from './index';

const headers = {
  'Content-Type': 'multipart/form-data',
};

export const getSlider = async () => {
  const {
    data: { data },
  } = await $host.get<AxiosResponse<ISlider[]>>('admin/slider');

  return data;
};

export const getSlideById = async (id: number) => {
  const {
    data: { data },
  } = await $host.get<AxiosResponse<ISlider>>(`admin/slider/${id}`);

  return data;
};

export const createSlide = async (params: FormData) => {
  const {
    data: { data },
  } = await $host.post<AxiosResponse<ISlider>>('admin/slider', params, {
    headers,
  });

  return data;
};

export const editSlide = async (id: number, params: FormData) => {
  const {
    data: { data },
  } = await $host.put<AxiosResponse<ISlider>>(`admin/slider/${id}`, params, {
    headers,
  });

  return data;
};

export const deleteSlide = async (id: number) => {
  const {
    data: { data },
  } = await $host.delete<AxiosResponse<boolean>>(`admin/slider/${id}`);

  return data;
};
