import { FC, useRef } from 'react';

import cn from 'clsx';

import styles from './zoom-image.module.scss';

export interface IZoomImageProps {
  alt: string;
  src: string;
  className?: string;
  scale?: number;
}

export const ZoomImage: FC<IZoomImageProps> = ({
  alt,
  src,
  className,
  scale = 2,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);

  const onMouseOverHandler = () => {
    if (imgRef.current) {
      imgRef.current.style.transform = `scale(${scale})`;
    }
  };

  const onMouseOutHanlder = () => {
    if (imgRef.current) {
      imgRef.current.style.transform = `scale(1)`;
    }
  };

  const onMouseMoveHandler = (e: MouseEvent) => {
    if (wrapperRef.current && imgRef.current) {
      const x =
        ((e.pageX - wrapperRef.current.offsetLeft) /
          wrapperRef.current.clientWidth) *
        100;

      const y =
        ((e.pageY - wrapperRef.current.offsetTop) /
          wrapperRef.current.clientHeight) *
        100;

      imgRef.current.style.transformOrigin = `${x - 52}% ${y - 24}%`;
    }
  };

  return (
    <div
      className={cn(styles.imageWrapper, className)}
      onMouseOver={onMouseOverHandler}
      onMouseOut={onMouseOutHanlder}
      onMouseMove={(e) => onMouseMoveHandler(e as unknown as MouseEvent)}
      ref={wrapperRef}
    >
      <img src={src} alt={alt} className={styles.image} ref={imgRef} />
    </div>
  );
};
