import { FC } from 'react';

import { useCurrentUserStore } from 'common/providers/store-provider';
import { IPromocode } from 'common/types';
import { PromoTypeEnum } from 'common/types/promocode/enums';
import { cents2dollars, upperUndescoreToRegularCase } from 'common/utils';
import { observer } from 'mobx-react-lite';

import { OrderExpandedViewModel } from '../../order-expanded-view-model';

import { FeesInput } from './FeesInput';

import styles from './table-footer.module.scss';

interface IFooterProps {
  model: OrderExpandedViewModel;
}

const getFormattedPromocodeValues = (
  promocode: IPromocode | null,
  totalDiscount: number,
) => {
  if (!promocode) {
    return { promoDiscount: '-', promoType: '-', totalPromoDiscount: '-' };
  }

  const isPercentage = promocode.promoType === PromoTypeEnum.PERCENTAGE;

  const promoDiscount = isPercentage
    ? `${promocode.discount}%`
    : `${cents2dollars(promocode.discount)}$`;

  const promoType = upperUndescoreToRegularCase(promocode.promoType);
  const totalPromoDiscount = `${cents2dollars(totalDiscount)}$`;

  return { promoDiscount, promoType, totalPromoDiscount };
};

export const Footer: FC<IFooterProps> = observer(({ model }) => {
  const { order } = model;
  const currentUserStore = useCurrentUserStore();

  const { isAdmin } = currentUserStore;

  const summary = order.items.reduce(
    (acc: number, el) => (acc = acc + el.quantity),
    0,
  );

  const { promoDiscount, promoType, totalPromoDiscount } =
    getFormattedPromocodeValues(order.appliedPromocode, order.promoDiscount);

  const kitDiscount = order.kitDiscount
    ? `${cents2dollars(order.kitDiscount)}$`
    : '-';

  return (
    <div className={styles.container}>
      <p className={styles.title}>Summary</p>
      <div className={styles.section}>
        {isAdmin && (
          <div>
            <span className={styles.title}>Fees</span>
            <FeesInput model={model} />
          </div>
        )}
        <div>
          <span className={styles.title}>Items</span>
          <div>{summary}</div>
        </div>
        <div>
          <span className={styles.title}>Shipping</span>
          <div>{cents2dollars(order.shippingFinal)}</div>
        </div>
        <div>
          <span className={styles.title}>Discount</span>
          <div>{cents2dollars(order.discount)}</div>
        </div>
        <div>
          <span className={styles.title}>Refunded</span>
          <div>{cents2dollars(order.refunded)}</div>
        </div>
        <div>
          <span className={styles.title}>Total</span>
          <div>{cents2dollars(order.totalPriceFinal)}</div>
        </div>
        <div>
          <span className={styles.title}>Total paid</span>
          <div>{cents2dollars(order.amountPaid)}</div>
        </div>
      </div>
      <p className={styles.title}>Applied promocode</p>
      <div className={styles.section}>
        <div>
          <span className={styles.title}>Type</span>
          <div>{promoType}</div>
        </div>
        <div>
          <span className={styles.title}>Discount</span>
          <div>{promoDiscount}</div>
        </div>
        <div>
          <span className={styles.title}>Total discount</span>
          <div>{totalPromoDiscount}</div>
        </div>
      </div>
      <p className={styles.title}>Kit</p>
      <div className={styles.section}>
        <div>
          <span className={styles.title}>Discount</span>
          <div>{kitDiscount}</div>
        </div>
      </div>
    </div>
  );
});
