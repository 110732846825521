import { FC } from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import { ValueType } from '@rc-component/mini-decimal';
import { Popconfirm, Tooltip, Typography } from 'antd';
import { defaultImage } from 'common/constants';
import { FormNumberInput } from 'components/Form';
import { FormSearch } from 'components/Form/FormSearch';
import { PurchaseModel } from 'store/purchase-model';

import styles from './columns.module.scss';

interface IImageColProps {
  image: string;
}

interface IQuantityColProps {
  isEditing: boolean;
  purchase: PurchaseModel;
  quantity: string;
  disabled?: boolean;
  onQuantityChange: (recordId: number, value: ValueType | null) => void;
}
interface ISkuColProps {
  isEditing: boolean;
  purchase: PurchaseModel;
  name: string;
  sku: string;
  onSearch: () => Promise<void>;
}

interface IDeleteButtonProps {
  isEditing: boolean;
  message: string;
  onDelete: () => void;
}

export const ImageCol: FC<IImageColProps> = ({ image }) => (
  <div className={styles.imageWrapper}>
    <img
      src={image ? image : defaultImage}
      alt="alt"
      className={styles.image}
    />
  </div>
);

export const QuantityCol: FC<IQuantityColProps> = ({
  isEditing,
  purchase,
  quantity,
  disabled,
  onQuantityChange,
}) => (
  <>
    {isEditing ? (
      <FormNumberInput
        min={1}
        max={30}
        name={`purchases.${purchase.id}.quantity`}
        disabled={disabled}
        onChange={(value) => onQuantityChange(purchase.id, value)}
      />
    ) : (
      <div>{quantity}</div>
    )}
  </>
);

export const SkuCol: FC<ISkuColProps> = ({
  isEditing,
  purchase,
  name,
  sku,
  onSearch,
}) => {
  const Wrapper = true
    ? Tooltip
    : ({ children }: { children: JSX.Element }) => <div>{children}</div>;

  const isNew = purchase.status === 'empty';

  return (
    <div>
      {isEditing && isNew ? (
        <>
          <Wrapper placement="top" title="Please enter SKU">
            <div>
              <FormSearch
                className={styles.search}
                name={name}
                onSearch={onSearch}
              />
            </div>
          </Wrapper>
        </>
      ) : (
        <div>{sku}</div>
      )}
    </div>
  );
};

export const DeleteButton: FC<IDeleteButtonProps> = ({
  isEditing,
  message,
  onDelete,
}) => (
  <div>
    {isEditing && (
      <div className={styles.deleteWrapper}>
        <Typography.Link>
          <Popconfirm title={message} onConfirm={onDelete}>
            <DeleteOutlined />
          </Popconfirm>
        </Typography.Link>
      </div>
    )}
  </div>
);
