import { useLocation } from 'react-router-dom';

export const useQueryParams = () => {
  const { search } = useLocation();

  const defaultParamsKeys = [
    'search',
    'page',
    'brandTitle',
    'brandId',
    'typeTitle',
    'typeId',
    'categoryTitle',
    'categoryId',
    'statusTitle',
    'statusId',
  ];

  const queryParams = new URLSearchParams(search);

  const params = defaultParamsKeys.reduce((acc, param) => {
    if (queryParams.has(param)) {
      return {
        ...acc,
        [param]: queryParams.get(param),
      };
    }

    return acc;
  }, {});

  return params;
};
