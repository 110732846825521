import { makeAutoObservable, computed } from 'mobx';
import { BrandModel } from 'store/brand-model';

export class BrandControllerViewModel {
  _brand: BrandModel;

  get brand() {
    return this._brand;
  }

  constructor(id: number) {
    this._brand = new BrandModel(id);

    makeAutoObservable(this, {
      brand: computed,
    });
  }
}
