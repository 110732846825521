import { FC } from 'react';

import { Modal } from 'antd';
import { addPurchaseSchema } from 'common/validation';
import { Form, FormInput, FormNumberInput } from 'components/Form';
import { observer } from 'mobx-react-lite';

import styles from './add-purchase-modal.module.scss';

export interface IAddPurchaseModalProps {
  open: boolean;
  isLoading?: boolean;
  onOk: (sku: any, quantity: string) => Promise<void>;
  onCancel: () => void;
}

interface IInitialValues {
  sku: string;
  quantity: string;
}

const initialValues = { sku: '', quantity: '' };

export const AddPurchasesModal: FC<IAddPurchaseModalProps> = observer(
  ({ open, isLoading, onOk, onCancel }) => {
    const handleSubmit = async (values: IInitialValues) => {
      await onOk(values.sku, values.quantity);
    };

    return (
      <Modal
        open={open}
        title="Add purchase"
        okButtonProps={{
          htmlType: 'submit',
          form: 'new-purchase',
          loading: isLoading,
        }}
        destroyOnClose
        onCancel={onCancel}
      >
        <Form
          id="new-purchase"
          initialValues={initialValues}
          validation={addPurchaseSchema}
          className={styles.form}
          onSubmit={handleSubmit}
        >
          <FormInput name="sku" placeholder="SKU" className={styles.field} />
          <FormNumberInput
            name="quantity"
            placeholder="Quantity"
            min={1}
            max={30}
            className={styles.field}
          />
        </Form>
      </Modal>
    );
  },
);
