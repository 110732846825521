import { ChangeEventHandler, FC, useState } from 'react';

import { Input } from 'antd';
import cn from 'clsx';
import {
  FieldError,
  Merge,
  FieldErrorsImpl,
  Controller,
  useFormContext,
} from 'react-hook-form';

import styles from './float-input.module.scss';

type TUseFormError =
  | string
  | FieldError
  | Merge<FieldError, FieldErrorsImpl<any>>
  | undefined;

interface IFloatInputProps {
  onChange?: ChangeEventHandler<HTMLInputElement> | undefined;
  label: string;
  defaultValue?: string | number;
  placeholder?: string;
  type?: string;
  required?: boolean;
  onClick?: () => void;
  name: string;
  error?: TUseFormError;
  fullWidth?: boolean;
  disabled?: boolean;
}

const FloatInput: FC<IFloatInputProps> = ({
  label,
  placeholder,
  defaultValue,
  type = 'text',
  name,
  fullWidth,
  disabled = false,
}) => {
  const [focus, setFocus] = useState(false);
  const { control } = useFormContext();

  if (!placeholder) {
    placeholder = label;
  }

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value, name }, fieldState: { error } }) => {
        const isOccupied =
          focus ||
          (defaultValue && `${defaultValue}`.length !== 0) ||
          (value && `${value}`.length !== 0) ||
          value === 0;

        return (
          <div
            className={cn(styles.floatLabel, {
              [styles.fullWidth]: fullWidth,
            })}
            onBlur={() => setFocus(false)}
            onFocus={() => setFocus(true)}
          >
            <Input
              onChange={onChange}
              type={type}
              name={name}
              defaultValue={defaultValue}
              disabled={disabled}
              className={cn({
                [styles.input]: !focus,
                [styles.selectedInput]: focus,
                [styles.asError]: error,
              })}
            />
            {error ? (
              <span
                className={styles.validationError}
              >{`${error.message}`}</span>
            ) : null}
            <label
              className={cn({
                [styles.asLabel]: isOccupied,
                [styles.asPlaceholder]: !isOccupied,
                [styles.validationError]: error,
              })}
              style={focus ? { color: '#00BA1D' } : {}}
            >
              {isOccupied ? label : placeholder}
            </label>
          </div>
        );
      }}
    />
  );
};

export default FloatInput;
