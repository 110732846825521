import { FC, useState } from 'react';

import { ReturnShipmentModel } from 'store/return-shipment-model';

import { EditableData } from './EditableData';
import { StaticData } from './StaticData';

interface ICommentSectionProps {
  returnItem: ReturnShipmentModel;
  disabled?: boolean;
  handleEditComment: (
    id: number,
    values: Record<string, string | number | undefined>,
  ) => void;
}

export const CommentSection: FC<ICommentSectionProps> = ({
  returnItem,
  disabled,
  handleEditComment,
}) => {
  const [isEdit, setEdit] = useState(false);

  const toggleEdit = () => setEdit(!isEdit);

  return (
    <>
      {isEdit ? (
        <EditableData
          returnItem={returnItem}
          handleEdit={handleEditComment}
          toggleEdit={toggleEdit}
        />
      ) : (
        <StaticData
          returnItem={returnItem}
          handleEdit={toggleEdit}
          disabled={disabled}
        />
      )}
    </>
  );
};
