import { Modal, Skeleton } from 'antd';
import { ColumnType } from 'antd/es/table';
import { ReactComponent as NoPhotoSvg } from 'assets/svg/no-photo.svg';
import { TPhoto } from 'common/types';
import { useModal } from 'common/utils';

import styles from './image.module.scss';

export type TImageColumn<T> = ColumnType<T> & Omit<ICellImageProps, 'photo'>;

export interface ICellImageProps {
  loading?: boolean;
  photo: TPhoto;
  onClick?: (id?: number) => void;
  clickOnPlaceholder?: boolean;
}

const buttonProps = { className: styles.hideButton };

export const CellImage = ({
  loading,
  photo,
  onClick,
  clickOnPlaceholder,
}: ICellImageProps) => {
  const [isOpen, openModal, closeModal] = useModal(false);

  const handleImageClick = () => {
    if (onClick) {
      onClick();
      return;
    }

    openModal();
  };

  const handleSkeletonClick = () => {
    if (onClick) {
      onClick();
      return;
    }

    openModal();
  };

  if (loading) {
    return <Skeleton.Image rootClassName={styles.imageSize} active />;
  }

  if (!photo?.url) {
    return (
      <div onClick={clickOnPlaceholder ? handleSkeletonClick : undefined}>
        <NoPhotoSvg />
      </div>
    );
  }

  return (
    <div className={styles.cellSize}>
      <Modal
        open={isOpen}
        onOk={closeModal}
        onCancel={closeModal}
        cancelButtonProps={buttonProps}
        okButtonProps={buttonProps}
        destroyOnClose
      >
        <div className={styles.modalContent}>
          <img
            src={photo.url}
            height="100%"
            width="100%"
            alt={photo.key ?? 'Brand logo'}
          />
        </div>
      </Modal>
      <div className={styles.imageWrapper}>
        <img
          className={styles.image}
          src={photo.url}
          alt={photo.key}
          height="100%"
          width="100%"
          onClick={handleImageClick}
        />
      </div>
    </div>
  );
};

// Extends an object with id because every of our entries has id as parameter
export const image = <T extends { id: number }>({
  dataIndex = 'photo',
  key = 'photo',
  width = '12%',
  loading,
  clickOnPlaceholder,
  onClick,
  ...props
}: TImageColumn<T>): ColumnType<T> => ({
  dataIndex: dataIndex,
  key: key,
  width: width,
  render: (photo, record) => (
    <CellImage
      clickOnPlaceholder={clickOnPlaceholder}
      onClick={onClick ? () => onClick?.(record.id) : undefined}
      loading={loading}
      photo={Array.isArray(photo) ? photo[0] : photo}
    />
  ),
  ...props,
});
