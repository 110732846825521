import {
  FC,
  useState,
  useRef,
  useEffect,
  useCallback,
  ChangeEvent,
} from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import {
  DeleteOutlined,
  PlusOutlined,
  SaveOutlined,
  LinkOutlined,
} from '@ant-design/icons';
import { Button, Divider, Input, Popconfirm, Spin } from 'antd';
import { notify } from 'common/utils';
import { OrderExpandedViewModel } from 'pages/Order/order-expanded-view-model';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useFormContext } from 'react-hook-form';

import styles from './dnd-order-links-list.module.scss';

export interface IDndImagesListProps {
  name: string;
  className?: string;
  orderLinks: string[];
  closeModal: () => void;
  variationId: number;
  disabled?: boolean;
  model: OrderExpandedViewModel;
}

export interface IListItemProps {
  link: TLinkWithId;
  index: number;
  disabled?: boolean;
  moveLink: (dragIndex: number, hoverIndex: number) => void;
  handleDelete: (id: number) => void;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  findСhanges: () => void;
}

type TLinkWithId = {
  id: number;
  url: string;
};

const createId = () => {
  return Math.floor(Math.random() * 420 + 48);
};

const ListItem: FC<IListItemProps> = ({
  link,
  index,
  disabled,
  moveLink,
  handleDelete,
  handleChange,
  findСhanges,
}) => {
  const dropRef = useRef<HTMLDivElement>(null);
  const dragRef = useRef<HTMLDivElement>(null);

  const [, drop] = useDrop({
    accept: 'link',
    hover: (item: { id: number; index: number }) => {
      requestAnimationFrame(() => {
        if (dropRef.current) {
          const dragIndex = item.index;
          const hoverIndex = index;
          if (dragIndex === hoverIndex) {
            return;
          }
          moveLink(dragIndex, hoverIndex);
          item.index = hoverIndex;
        }
      });
    },
  });

  const [, drag, preview] = useDrag({
    type: 'link',
    item: () => {
      return { id: link.id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      opacity: monitor.isDragging() ? 0.6 : 1,
    }),
  });

  preview(drop(dropRef));
  drag(dragRef);

  const handleRedirect = (link: string) => {
    const rule = new RegExp('^(http|https)://', 'i');
    if (!rule.test(link)) {
      return notify(
        'Wrong link format, must begins with http:// or https://',
        'warning',
      );
    }
    window.open(link, '_blank');
  };

  return (
    <div
      ref={dropRef}
      className={styles.orderLinkContainer}
      onDrop={findСhanges}
    >
      <div ref={dragRef} className={styles.dottedIcon} />
      <div className={styles.inputWrapper}>
        <Input
          id={`${link.id}`}
          defaultValue={link.url}
          className={styles.input}
          bordered={false}
          onChange={handleChange}
          disabled={disabled}
        />
        <div className={styles.iconWrapper}>
          <LinkOutlined
            onClick={() => handleRedirect(link.url)}
            className={styles.linkIcon}
          />
        </div>
      </div>
      <div className={styles.iconWrapper}>
        <Popconfirm
          placement="left"
          destroyTooltipOnHide
          rootClassName={styles.actions}
          title={
            <p className={styles.popconfirmMessage}>
              Are you sure you want to delete this link?
            </p>
          }
          onConfirm={() => handleDelete(link.id)}
          disabled={disabled}
        >
          <DeleteOutlined className={styles.deleteIcon} />
        </Popconfirm>
      </div>
    </div>
  );
};

const OrderLinksListContent: FC<IDndImagesListProps> = ({
  name,
  orderLinks,
  closeModal,
  variationId,
  disabled,
  model,
}) => {
  const [links, setLinks] = useState<TLinkWithId[]>([]);
  const [dirty, setDirty] = useState(false);
  const { setValue } = useFormContext();

  const loaderIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const findСhanges = () => {
    setDirty(true);
  };

  const addOrderLink = () => {
    if (orderLinks.length >= 10) {
      return null;
    }
    const newLink = { id: createId(), url: '' };
    setLinks([newLink, ...links]);
    findСhanges();
  };

  const moveLink = useCallback((dragIndex: number, hoverIndex: number) => {
    setLinks((originalLinks) => {
      const clonedCards = [...originalLinks];
      const removedItem = clonedCards.splice(dragIndex, 1)[0];
      clonedCards.splice(hoverIndex, 0, removedItem);
      return clonedCards;
    });
  }, []);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    const updatedLinks = links.map((el) => {
      if (el.id === Number(id)) {
        return { id: el.id, url: value };
      }
      return el;
    });
    setLinks(updatedLinks);
    findСhanges();
  };

  const handleDelete = (id: number) => {
    const updatedLinks = links.filter((el) => el.id !== id);
    setLinks(updatedLinks);
    findСhanges();
  };

  const handleSave = async () => {
    model.editOrderLinks(links, variationId);
    closeModal();
  };

  useEffect(() => {
    if (orderLinks.length !== links.length) {
      const linksWithId = orderLinks.map((el) => {
        return { id: createId(), url: el };
      });
      setLinks(linksWithId);
    }
  }, [orderLinks]);

  useEffect(() => {
    setValue(name, links);
  }, [links]);

  return (
    <div>
      <div className={styles.listWrapper}>
        {links.map((link, index) => (
          <ListItem
            key={link.id}
            link={link}
            moveLink={moveLink}
            index={index}
            handleDelete={handleDelete}
            handleChange={handleChange}
            findСhanges={findСhanges}
            disabled={disabled}
          />
        ))}
      </div>
      <Divider />
      <div className={styles.btnWrapper}>
        <Button
          type={dirty ? 'default' : 'primary'}
          onClick={addOrderLink}
          className={styles.addBtn}
          disabled={links.length >= 10 || model.loading || disabled}
        >
          <PlusOutlined />
          Add new link
        </Button>
        {model.loading && <Spin indicator={loaderIcon} />}
        {dirty && (
          <Button
            type="primary"
            onClick={handleSave}
            className={styles.addBtn}
            disabled={model.loading || disabled}
          >
            <SaveOutlined />
            Save changes
          </Button>
        )}
      </div>
    </div>
  );
};

export const DndOrderLinksList: FC<IDndImagesListProps> = ({
  orderLinks,
  name,
  closeModal,
  className,
  variationId,
  disabled,
  model,
}) => (
  <DndProvider backend={HTML5Backend}>
    <OrderLinksListContent
      orderLinks={orderLinks}
      className={className}
      name={name}
      closeModal={closeModal}
      variationId={variationId}
      disabled={disabled}
      model={model}
    />
  </DndProvider>
);
