import { FC, useMemo } from 'react';

import { IPriceUpdateError } from 'common/types/bulkPriceUpdate';
import { cents2dollars } from 'common/utils';

import styles from './styles.module.scss';

export type TErrorDetailsProps = IPriceUpdateError;

export const ErrorDetails: FC<TErrorDetailsProps> = (props) => {
  const code = useMemo(() => {
    try {
      return JSON.stringify(props.error, null, 2);
    } catch {
      return props.error;
    }
  }, []);

  return (
    <div className={styles.error_details}>
      <div>
        Product (
        <a href={`/product/${props.productId}`}>
          Product Id #{props.productId}
        </a>
        ) price change (from ${cents2dollars(props.oldPrice)} to $
        {cents2dollars(props.newPrice)}) failed
      </div>
      <pre>{code}</pre>
    </div>
  );
};
