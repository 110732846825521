import { getUser, patchUser } from 'http/userApi';

import { IPatchedUserParams } from 'common/types';
import { notify } from 'common/utils';
import { action, computed, makeObservable, observable } from 'mobx';
import { UserModel } from 'store/user-model';

export class UserViewModel {
  _id: string | undefined;
  _isLoading = false;
  _user: UserModel | null = null;

  get id() {
    return this._id;
  }

  get isLoading() {
    return this._isLoading;
  }

  set isLoading(isLoading: boolean) {
    this._isLoading = isLoading;
  }

  get user() {
    return this._user;
  }

  set user(user: UserModel | null) {
    this._user = user;
  }

  async getUser() {
    if (!this._id) {
      return;
    }

    try {
      this.isLoading = true;

      const user = await getUser(this._id);
      const userModel = new UserModel(user);

      this.user = userModel;
    } catch (e) {
      notify(`Error, reason:${e}`, 'error');
    } finally {
      this.isLoading = false;
    }
  }

  async updateUserData(values: IPatchedUserParams) {
    if (!this._id || !this._user) {
      return;
    }

    try {
      this.isLoading = true;

      const shippingAddress = {
        phone: this._user.user.shippingAddress.phone,
        country: this._user.user.shippingAddress.country,
        address: this._user.user.shippingAddress.address,
        address2: this._user.user.shippingAddress.address2,
        state: this._user.user.shippingAddress.state,
        city: this._user.user.shippingAddress.city,
        postalCode: this._user.user.shippingAddress.postalCode,
        firstName: this._user.user.shippingAddress.firstName,
        lastName: this._user.user.shippingAddress.lastName,
      };

      const billingAddress = {
        phone: this._user.user.billingAddress.phone,
        companyName: this._user.user.billingAddress.companyName,
        country: this._user.user.billingAddress.country,
        address: this._user.user.billingAddress.address,
        address2: this._user.user.billingAddress.address2,
        state: this._user.user.billingAddress.state,
        city: this._user.user.billingAddress.city,
        postalCode: this._user.user.billingAddress.postalCode,
        firstName: this._user.user.billingAddress.firstName,
        lastName: this._user.user.billingAddress.lastName,
      };

      const user = {
        firstName: this._user.user.firstName,
        lastName: this._user.user.lastName,
        shippingAddress: shippingAddress,
        billingAddress: billingAddress,
        phone: this._user.user.phone,
      };

      const updatedUser = await patchUser(this._id, {
        ...user,
        ...values,
      });
      const updatedUserModel = new UserModel(updatedUser);

      this.user = updatedUserModel;
    } catch (e) {
      notify(`Error, reason:${e}`, 'error');
    } finally {
      this.isLoading = false;
    }
  }

  constructor(id?: string) {
    this._id = id;

    makeObservable(this, {
      _id: observable,
      _isLoading: observable,
      _user: observable,
      id: computed,
      isLoading: computed,
      user: computed,
      getUser: action,
      updateUserData: action,
    });
  }
}
