import { FormEvent, FC } from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import { AutoComplete, Input, Tooltip } from 'antd';
import Select, { BaseOptionType } from 'antd/es/select';
import cn from 'clsx';
import { TColor } from 'common/types';

import styles from '../list.module.scss';

export type TListItem = { key: string; value: string; unique?: boolean };

export interface IListItemProps {
  id: string;
  onDelete: (id: string) => void;
  onChange: (value: TListItem) => void;
  value?: string;
  unique?: boolean;
  type?: 'default' | 'color' | 'autocomplete';
  options?: BaseOptionType[] | TColor[] | never;
}

export const ListItem: FC<IListItemProps> = ({
  id,
  onDelete,
  onChange,
  value,
  unique = true,
  type = 'default',
  options,
}) => {
  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    onChange({ key: id, value: e.currentTarget.value });
  };

  const handleColorChange = (value: string) => {
    onChange({ key: id, value: value });
  };

  const handleDelete = () => {
    onDelete(id);
  };

  if (type === 'color' && options) {
    return (
      <div className={styles.listItemContainer}>
        <Select
          onChange={handleColorChange}
          value={value}
          className={cn(styles.listItem, styles.colorSelect, {
            [styles.notUnique]: !unique,
          })}
          showSearch
        >
          {options.map((item) => (
            <Select.Option key={item.color} value={item.color}>
              <div className={styles.optionWrapper}>
                <div
                  className={styles.colorBall}
                  style={{ background: item.hex }}
                />
                <div>{item.color}</div>
              </div>
            </Select.Option>
          ))}
        </Select>
        <div className={styles.trash}>
          <Tooltip title="Delete this line">
            <DeleteOutlined
              style={{ color: 'rgba(0,0,0,.45)' }}
              onClick={handleDelete}
            />
          </Tooltip>
        </div>
      </div>
    );
  }

  if (type === 'autocomplete' && options) {
    return (
      <div className={styles.listItemContainer}>
        <AutoComplete
          onChange={handleColorChange}
          value={value}
          options={options as BaseOptionType[]}
          onSelect={handleColorChange}
          className={cn(styles.listItem, styles.colorSelect, {
            [styles.notUnique]: !unique,
          })}
          filterOption={(inputValue, option) =>
            !!(
              option &&
              option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
                -1
            )
          }
        />
        <div className={styles.trash}>
          <Tooltip title="Delete this line">
            <DeleteOutlined
              style={{ color: 'rgba(0,0,0,.45)' }}
              onClick={handleDelete}
            />
          </Tooltip>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.listItemContainer}>
      <Input
        placeholder="Enter value"
        className={cn(styles.listItem, {
          [styles.notUnique]: !unique,
        })}
        onChange={handleChange}
        value={value}
      />
      <div className={styles.trash}>
        <Tooltip title="Delete this line">
          <DeleteOutlined
            style={{ color: 'rgba(0,0,0,.45)' }}
            onClick={handleDelete}
          />
        </Tooltip>
      </div>
    </div>
  );
};
