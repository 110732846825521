import { FC } from 'react';

import { Button } from 'antd';
import Title from 'antd/es/typography/Title';
import { ISlider, TSlideValue, TSliderItems } from 'common/types/slider';
import { getUpdatedSlides } from 'common/utils';
import { mainSliderSchema } from 'common/validation';
import { Form } from 'components/Form';
import { Spinner } from 'components/Spinner';
import { observer } from 'mobx-react-lite';

import { MainViewModel } from '../main-view-model';

import { FormContent } from './FormContent';

import styles from './slider-form.module.scss';

interface ISliderFormProps {
  model: MainViewModel;
}

interface ISubmittedValues {
  slides: TSliderItems;
}

export const SliderForm: FC<ISliderFormProps> = observer(({ model }) => {
  const initialValues = {
    slides: model.slider.reduce((slides, slide) => {
      return {
        ...slides,
        [slide.id]: {
          url: slide.url,
          title: slide.title,
          text: slide.text,
          buttonTitle: slide.buttonTitle,
          photo: slide.photo,
        },
      };
    }, {}),
  };

  const onSubmit = (values: ISubmittedValues) => {
    const submittedSlides = Object.entries(values.slides).map(
      ([key, slide]) => {
        return { ...slide, id: Number(key) };
      },
    );

    const initialSlides = Object.entries(initialValues.slides).map(
      ([key, slide]: [string, ISlider]) => {
        return { ...slide, id: Number(key) };
      },
    );

    const removedSlides = Object.keys(initialValues.slides).filter(
      (el) => !Object.keys(values.slides).includes(el),
    );

    const updatedSlides = getUpdatedSlides(submittedSlides, initialSlides);

    const addedSlides = Object.entries(values.slides).reduce(
      (acc, [id, slide]: [string, TSlideValue]) => {
        if (Number(id) < 0) {
          return [...acc, slide];
        }
        return acc;
      },
      [],
    );

    model.onSliderChange(removedSlides, updatedSlides, addedSlides);
  };

  if (model.loading) {
    return (
      <div className={styles.spinnerWrapper}>
        <Spinner type="large" />
      </div>
    );
  }

  return (
    <div className={styles.sliderForm}>
      <Title level={5} type="secondary">
        Slider
      </Title>
      <Form
        initialValues={initialValues}
        validation={mainSliderSchema}
        onSubmit={onSubmit}
      >
        <FormContent />
        <Button className={styles.button} type="primary" htmlType="submit">
          Save
        </Button>
      </Form>
    </div>
  );
});
