import { AxiosResponse } from 'axios';
import { IPageParams } from 'common/types/common-types';
import { IReview, IGetReviewsResponse } from 'common/types/review';

import { $host } from './index';

export const getReviews = async (params?: IPageParams) => {
  const {
    data: { data },
  } = await $host.get<AxiosResponse<IGetReviewsResponse>>('admin/reviews', {
    params: params ?? { page: 1, pageSize: 50 },
  });

  return data;
};

export const deleteReviewById = async (id: number) => {
  const {
    data: { data },
  } = await $host.delete<AxiosResponse<IReview>>(`admin/reviews/${id}`);

  return data;
};
