import { useState, Fragment, useEffect, useCallback } from 'react';

import { Button, Divider } from 'antd';
import { TFormContent, TSliderItems } from 'common/types/slider';
import { FormUploader, FormInput, FormTextArea } from 'components/Form';
import { useFormContext } from 'react-hook-form';

import styles from './form-content.module.scss';

export const FormContent = () => {
  const [slides, setSlides] = useState<TFormContent[]>([]);

  const {
    formState: { defaultValues },
    setValue,
  } = useFormContext();

  useEffect(() => {
    const sliderItems: TSliderItems = defaultValues?.slides;

    setSlides(
      Object.entries(sliderItems).map(([key, slide]) => ({
        ...slide,
        id: Number(key),
      })),
    );
  }, [defaultValues?.slides]);

  const addNewSlide = () => {
    const newSlide: TFormContent = {
      photo: null,
      title: '',
      text: '',
      url: '',
      buttonTitle: '',
      id: -Date.now(),
    };

    setSlides((oldSlides) => [...oldSlides, newSlide]);
  };

  const removeSlide = useCallback(
    (id: number) => {
      const removedSlides = slides.filter((slide) => slide.id !== id);

      setValue(
        'slides',
        removedSlides.reduce(
          (acc, slide) => ({
            ...acc,
            [slide.id]: {
              ...slide,
            },
          }),
          {},
        ),
      );

      setSlides(removedSlides);
    },
    [slides],
  );

  return (
    <div>
      {slides.map((slide) => (
        <Fragment key={slide.id}>
          <div className={styles.slideContent}>
            <FormUploader
              className={styles.uploader}
              name={`slides[${slide.id}].photo`}
              label="Background Image:"
              required
            />
            <div className={styles.inputWrapper}>
              <FormInput
                name={`slides[${slide.id}].title`}
                label="Slide title:"
              />
              <FormInput
                name={`slides[${slide.id}].buttonTitle`}
                label="Slide button text:"
              />
              <FormInput
                name={`slides[${slide.id}].url`}
                label="Slide button link:"
              />
            </div>
            <FormTextArea
              className={styles.slideDescription}
              name={`slides[${slide.id}].text`}
              label="Slide description:"
              rows={7}
            />
          </div>
          <Button
            className={styles.actionButton}
            type="default"
            htmlType="button"
            onClick={() => removeSlide(Number(slide.id))}
          >
            Remove slide
          </Button>
          <Divider />
        </Fragment>
      ))}
      {slides.length < 3 && (
        <Button
          className={styles.actionButton}
          type="primary"
          htmlType="button"
          onClick={addNewSlide}
        >
          Add new slide
        </Button>
      )}
    </div>
  );
};
