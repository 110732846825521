import { FC } from 'react';

import { Spin, Typography } from 'antd';
import { useCurrentUserStore } from 'common/providers/store-provider';
import {
  billingAddressValidationSchema,
  orderPersonalInformationValidationSchema,
  shippingAddressValidationSchema,
} from 'common/validation';
import { EditSection } from 'components/EditSection';
import { Section } from 'components/EditSection/section';
import { observer } from 'mobx-react-lite';
import { BuyerDetailsViewModel } from 'pages/NewOrder/view-models/buyer-details-view-model';

import styles from './buyer-details.module.scss';

export interface IBuyerDetailsProps {
  model: BuyerDetailsViewModel | null;
}

export const BuyerDetails: FC<IBuyerDetailsProps> = observer(({ model }) => {
  const { isSupplier } = useCurrentUserStore();

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Typography.Title level={4} className={styles.title}>
          Buyer details
        </Typography.Title>
      </div>
      <div className={styles.content}>
        {!model ? (
          <Spin className={styles.spin} />
        ) : (
          <div className={styles.sections}>
            <EditSection
              id="billing-address"
              title="Billing address"
              initialValues={model.billingAddressInitialValues}
              validation={billingAddressValidationSchema}
              staticFields={model.billingAddressStaticFields}
              dynamicFields={model.billingAddressDynamicFields}
              isLoading={model.isLoading}
              disabled={model.root?.order?.disabled || isSupplier}
              onEdit={(values) =>
                model.onUserDataUpdate({ billingAddress: values })
              }
            />
            <EditSection
              id="shipping-address"
              title="Shipping address"
              initialValues={model.shippingAddressInitialValues}
              validation={shippingAddressValidationSchema}
              staticFields={model.shippingAddressStaticFields}
              dynamicFields={model.shippingAddressDynamicFields}
              isLoading={model.isLoading}
              disabled={model.root?.order?.disabled || isSupplier}
              onEdit={(values) =>
                model.onUserDataUpdate({ shippingAddress: values })
              }
            />
            <EditSection
              id="personal-info"
              title="Personal info"
              initialValues={model.personalInfoInitialValues}
              validation={orderPersonalInformationValidationSchema}
              staticFields={model.personalInfoStaticFields}
              dynamicFields={model.personalInfoDynamicFields}
              isLoading={model.isLoading}
              disabled={model.root?.order?.disabled || isSupplier}
              onEdit={(values) =>
                model.onUserDataUpdate({ email: values.email })
              }
            />
            <Section
              title="Payment method"
              staticFields={model.paymentInfoStaticFields}
            />
          </div>
        )}
      </div>
    </div>
  );
});
