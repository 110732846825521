import { FC, ReactElement } from 'react';

import { SelectProps, Tag, Select } from 'antd';
import { useSelectDynamicOptions } from 'common/hook/useSelectDynamicOptions';
import { TCategorizedDynamicOptions } from 'common/types';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';

interface ICategorizedOption {
  category: string;
  value: string;
  label: string;
  color: string;
}
type TCategoryDivider = ':';
type TColorDivider = '~';

interface IColorizedOption {
  value: string;
  label: `${string}${TColorDivider}${string}`;
}

interface ISelectTagProps extends CustomTagProps {
  categoryDivider?: TCategoryDivider;
}

interface IColoredMultiSelectProps extends SelectProps {
  staticOptions?: ICategorizedOption[];
  colors: Record<string, string>;
  categoryDivider?: TCategoryDivider;
  colorDivider?: TColorDivider;
  getOptions?: () => TCategorizedDynamicOptions;
}

const getColoredOptions = (
  options: ICategorizedOption[],
  colorDivider: TColorDivider,
): IColorizedOption[] =>
  options.map((status) => ({
    value: status.value,
    label: `${status.color}${colorDivider}${status.label}`,
  }));

const SelectTag: FC<ISelectTagProps> = ({
  label,
  closable,
  onClose,
  categoryDivider,
}) => {
  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const labelNode = label as ReactElement[];

  const cancelledStatus = labelNode[1].props.children
    .split(categoryDivider)[0]
    .split(' ')[1];

  const labelText = () => {
    if (categoryDivider) {
      if (labelNode[1].props.children.split(categoryDivider)[1]) {
        return cancelledStatus
          ? `${cancelledStatus} ${labelNode[1].props.children
              .split(categoryDivider)[1]
              .toLowerCase()}`
          : labelNode[1].props.children.split(categoryDivider)[1];
      }

      return labelNode[1].props.children.split(categoryDivider)[0];
    }

    return labelNode[1].props.children;
  };

  const color = labelNode[0].props.children;

  return (
    <Tag
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      color={color}
      style={{ marginRight: 3 }}
    >
      {labelText()}
    </Tag>
  );
};

export const ColoredMultiSelect: FC<IColoredMultiSelectProps> = ({
  staticOptions,
  getOptions,
  colors,
  categoryDivider,
  colorDivider = '~',
  ...props
}) => {
  const [isLoading, options] = useSelectDynamicOptions<ICategorizedOption>({
    staticOptions: staticOptions,
    fetchOptions: getOptions,
  });

  const coloredOptions = getColoredOptions(options, colorDivider);

  return (
    <Select
      mode="multiple"
      tagRender={(props) => (
        <SelectTag categoryDivider={categoryDivider} {...props} />
      )}
      loading={isLoading}
      allowClear
      {...props}
    >
      {coloredOptions.map((option) => {
        const [color, label] = option.label.split(colorDivider);

        return (
          <Select.Option key={option.label} value={option.value}>
            <span style={{ display: 'none' }}>{color}</span>
            <span>{label}</span>
          </Select.Option>
        );
      })}
    </Select>
  );
};
