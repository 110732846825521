import { editVariation } from 'http/variationApi';

import { FC, useState } from 'react';

import { Button, Divider } from 'antd';
import { defaultOptions } from 'common/constants';
import { notify } from 'common/utils';
import { ProductControllerViewModel } from 'pages/Product/ProductController/product-controller-view-model';
import { useFormContext } from 'react-hook-form';

import VariantsCard from '../index';
import { TVariationItem } from '../Variation';
import VariationTable from '../VariationTable';

import DoneVariation from './DoneVariation';

import styles from './styles.module.scss';

interface IDoneVariationBarProps {
  model: ProductControllerViewModel;
}

const DoneVariationBar: FC<IDoneVariationBarProps> = ({ model }) => {
  const [loading, setLoading] = useState(false);
  const { watch, trigger, reset, getFieldState } = useFormContext();

  const variations = watch('variations');

  const variationsOptions = defaultOptions.filter(
    (option) => !model.product.variationsObject[option.value]?.length,
  );

  const saveNewVariations = async () => {
    const newValues = variationsOptions.reduce((newVariations, variation) => {
      if (variations[variation.value]) {
        return {
          ...newVariations,
          [variation.value]: variations[variation.value],
        };
      }

      return newVariations;
    }, {});

    await trigger('variations');

    const someValuesInvalid = Object.keys(newValues).some(
      (key) => getFieldState(`variations.${key}`).invalid,
    );

    if (someValuesInvalid) {
      return;
    }

    const valuesMapping = Object.entries(newValues).reduce(
      (mapping, [key, values]: [string, string[]]) => {
        return [...mapping, ...values.map((value) => ({ [key]: value }))];
      },
      [],
    );

    setLoading(true);

    await editVariation(model.product.id, valuesMapping);

    await model.product.fetchProduct(model.product.id, true);

    reset(model.initialFormValues, { keepValues: true });

    notify('Variations list was updated!', 'success');

    setLoading(false);
  };

  const hasNewValues = variationsOptions.some(
    (variation) => variations?.[variation.value]?.length,
  );

  return (
    <div>
      {!!model.product.variationsObject &&
        Object.entries(model.product.variationsObject).map(
          ([key, values]: [TVariationItem['type'], string[]]) =>
            !!values?.length && (
              <DoneVariation
                model={model}
                key={key}
                title={key}
                values={values}
              />
            ),
        )}
      <VariationTable model={model} />
      {!!variationsOptions.length && <Divider />}
      <VariantsCard model={model} />
      {!!hasNewValues && (
        <div className={styles.saveBtnWrapper}>
          <Button
            loading={loading}
            disabled={loading}
            className={styles.saveBtn}
            onClick={saveNewVariations}
          >
            Save
          </Button>
        </div>
      )}
    </div>
  );
};

export default DoneVariationBar;
