import { FC } from 'react';

import { Button } from 'antd';
import { IReturnShipment } from 'common/types/return-shipment';

import styles from './static-data.module.scss';

interface IStaticDataProps {
  returnItem: IReturnShipment;
  disabled?: boolean;
  handleEdit: () => void;
}

export const StaticData: FC<IStaticDataProps> = ({
  returnItem,
  disabled,
  handleEdit,
}) => {
  const hasComment = !!returnItem.comment?.value;

  return (
    <div className={styles.container}>
      <div>
        <span className={styles.title}>Comment:&nbsp;</span>
        <span>{returnItem.comment?.value}</span>
      </div>
      <Button onClick={handleEdit} htmlType="button" disabled={disabled}>
        {hasComment ? 'Edit' : 'Add'}
      </Button>
    </div>
  );
};
