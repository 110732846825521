import { deleteReviewById, getReviews } from 'http/reviewApi';

import { TTablePaginationParams } from 'common/types/common-types';
import { IReview } from 'common/types/review';
import { notify, parseToShortDate, getEllipsisText } from 'common/utils';
import { computed, makeAutoObservable, observable } from 'mobx';
import { FiltersModel } from 'store/filters-model';

import { ReviewPhotoModel } from './review-photo-model';

export class ReviewViewModel {
  _loading = false;
  _reviews: IReview[] = [];
  _filters: FiltersModel = new FiltersModel();
  _reviewPhoto: ReviewPhotoModel = new ReviewPhotoModel();
  _count = 0;

  get loading() {
    return this._loading;
  }

  set loading(value: boolean) {
    this._loading = value;
  }

  get reviews() {
    return this._reviews;
  }

  get skeleton() {
    return new Array(5).fill(new ReviewViewModel());
  }

  get count() {
    return this._count;
  }

  get tableData() {
    return this._reviews.map((review: IReview) => {
      return {
        ...review,
        product: review.product.title,
        description: getEllipsisText(review.reviewText ?? ''),
        createdAt: parseToShortDate(review.createdAt),
      };
    });
  }

  get reviewPhoto() {
    return this._reviewPhoto;
  }

  async fetchReviews() {
    this.loading = true;

    try {
      const { count, reviews } = await getReviews();

      this._reviews = reviews;
      this._count = count;
    } catch (e) {
      notify(`Error, reason:${e}`, 'error');
    } finally {
      this.loading = false;
    }
  }

  async removeReview(id: number) {
    this.loading = true;

    try {
      const result = await deleteReviewById(id);

      if (result) {
        const updatedReviews = this._reviews.filter(
          (review) => review.id !== id,
        );

        this._reviews = updatedReviews;
      }
    } catch (e) {
      notify(`Error, reason:${e}`, 'error');
    } finally {
      this.loading = false;
    }
  }

  async refetchReviews(pagination: TTablePaginationParams) {
    const { current } = pagination;
    const { pageSize } = this._filters;
    const params = {
      page: current ? current : 1,
      pageSize: pageSize,
    };

    this.loading = true;

    try {
      const { count, reviews } = await getReviews(params);

      this._reviews = reviews;
      this._count = count;

      return reviews;
    } catch (e) {
      notify(`Error, reason:${e}`, 'error');
    } finally {
      this.loading = false;
    }
  }

  constructor() {
    this.fetchReviews();

    makeAutoObservable(this, {
      _loading: observable,
      _reviews: observable,
      _count: observable,

      loading: computed,
    });
  }
}
