import {
  deleteVariationPhotos,
  updateVariation,
  setVariationMainPhoto,
} from 'http/variationApi';

import { FC, useState } from 'react';

import { Modal, Button, Alert } from 'antd';
import { MAX_PRODUCT_PHOTOS_LIMIT } from 'common/constants';
import { TPhoto } from 'common/types/common-types';
import { notify } from 'common/utils';
import { variationPhotosSchema } from 'common/validation';
import { Form, FormUploader } from 'components/Form';
import { useFormContext } from 'react-hook-form';
import { VariationModel } from 'store/variation-model';

import { Image } from '../../../Image';

import styles from './edit-images-modal.module.scss';

export interface IEditImagesModalProps {
  isOpen: boolean;
  closeModal: () => void;
  model: VariationModel;
  refetchProduct: () => void;
  uniquePhotos: TPhoto[];
}

export interface IFromContentProps {
  mainPhoto?: TPhoto;
  selectedPhotos: TPhoto[];
  setMainPhoto: (photoId: TPhoto | undefined) => void;
  setSelectedPhotos: (photos: TPhoto[]) => void;
}

const FormContent: FC<IFromContentProps> = ({
  mainPhoto,
  selectedPhotos,
  setMainPhoto,
  setSelectedPhotos,
}) => {
  const { watch } = useFormContext();

  const photos = watch('photos');

  const handleMainPhotoSelect = (id: number) => {
    const mainPhoto = photos.find((photo: TPhoto) => photo.id === id);
    setMainPhoto(mainPhoto);
  };

  const handlePhotoSelection = (id: number) => {
    if (selectedPhotos?.length >= MAX_PRODUCT_PHOTOS_LIMIT) {
      return;
    }
    const photo = photos.find((photo: TPhoto) => photo.id === id);
    if (photo) {
      setSelectedPhotos([...selectedPhotos, photo]);
    }
  };

  const handlePhotoRemove = (id: number) => {
    if (id === mainPhoto?.id) {
      setMainPhoto(undefined);
    }
    const photos = selectedPhotos.filter((photo) => photo.id !== id);
    setSelectedPhotos([...photos]);
  };

  const activePhotosIds = new Set(selectedPhotos?.map((photo) => photo.id));

  return (
    <>
      <FormUploader name="photos" multiple />
      <div className={styles.imagesContainer}>
        {photos.map((image: TPhoto) => (
          <Image
            key={image.id}
            id={image.id}
            main={image.id === mainPhoto?.id}
            selected={activePhotosIds.has(image.id)}
            url={image.url}
            setMain={handleMainPhotoSelect}
            selectImage={handlePhotoSelection}
            unselectImage={handlePhotoRemove}
          />
        ))}
      </div>
    </>
  );
};

export const EditImagesModal: FC<IEditImagesModalProps> = ({
  isOpen,
  closeModal,
  model,
  uniquePhotos,
  refetchProduct,
}) => {
  const [mainPhoto, setMainPhoto] = useState<TPhoto | undefined>(
    model.mainPhoto,
  );
  const [selectedPhotos, setSelectedPhotos] = useState<TPhoto[]>(
    model.variation.content.photos ?? [],
  );
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);

    try {
      const selectedPhotoIdsSet = new Set(selectedPhotos.map(({ id }) => id));
      const recordPhotoIdsSet = new Set(
        model.variation.content.photos?.map(({ id }) => id),
      );

      // Photos which already esists and not attached to variation
      const photoIds = selectedPhotos.reduce(
        (ids, photo) =>
          !photo.file && !recordPhotoIdsSet.has(photo.id)
            ? [...ids, photo.id]
            : ids,
        [],
      );

      // Photos which attached to variation but not selected
      const removePhotoIds = model.variation.content.photos?.reduce(
        (ids, photo) =>
          !selectedPhotoIdsSet.has(photo.id) ? [...ids, photo.id] : ids,
        [],
      );

      // Photos which is new and attached to variation
      const newVariationPhotos = selectedPhotos.filter((photo) => {
        return !!photo.file;
      });

      const newVariationPhotoIds = newVariationPhotos.map((el) => el.id);

      if (removePhotoIds?.length) {
        await deleteVariationPhotos(model.variation.id, removePhotoIds);
      }

      const fd = new FormData();
      // at the request of clients, we have temporarily hidden this information
      // fd.append('description', model.variation.content.description);
      fd.append(
        'discount',
        (model.variation.content?.discount ?? 0).toString(),
      );
      fd.append('order_link', model.variation.content.order_link.toString());
      fd.append('photoIds', photoIds.toString());
      fd.append('newPhotoIds', newVariationPhotoIds.toString());
      fd.append('price', model.variation.content.price.toString());
      newVariationPhotos.forEach((photo) => {
        if (photo.file) {
          fd.append('photos', photo.file);
        }
      });

      const variations = await updateVariation(model.variation.id, fd);

      if (mainPhoto && !mainPhoto?.file) {
        await setVariationMainPhoto(model.variation.id, mainPhoto.id);
      }

      if (mainPhoto && mainPhoto?.file) {
        const variation = variations?.find(
          (variation) => variation.variationId === model.variation.id,
        );

        const newMainPhoto = variation?.photos?.find((photo) => {
          return photo.initialId === mainPhoto.id;
        });

        if (newMainPhoto) {
          await setVariationMainPhoto(model.variation.id, newMainPhoto.id);
        }
      }

      refetchProduct();
      closeModal();
    } catch (e) {
      notify('Failed to update Photos', 'error');
      console.log('e', e);
    }

    setLoading(false);
  };

  const handleMainPhotoSelect = (photo: TPhoto) => {
    setMainPhoto(photo);
  };

  const handlePhotoSelection = (photos: TPhoto[]) => {
    setSelectedPhotos(photos);
  };

  return (
    <Modal
      open={isOpen}
      title={
        <div className={styles.editTitle}>Edit {model.variationTitle}</div>
      }
      footer={null}
      onCancel={closeModal}
      destroyOnClose
    >
      {selectedPhotos?.length >= MAX_PRODUCT_PHOTOS_LIMIT && (
        <Alert
          message={`You reached the limit of ${MAX_PRODUCT_PHOTOS_LIMIT} photos. If you want to select different
          set of photos remove the selection.`}
          type="info"
        />
      )}
      <div className={styles.uploaderContainer}>
        <Form
          initialValues={{
            photos: uniquePhotos,
          }}
          validation={variationPhotosSchema}
          onSubmit={handleSubmit}
        >
          <FormContent
            mainPhoto={mainPhoto}
            selectedPhotos={selectedPhotos}
            setMainPhoto={handleMainPhotoSelect}
            setSelectedPhotos={handlePhotoSelection}
          />
          <div className={styles.buttonsContainer}>
            <Button onClick={closeModal}>Cancel</Button>
            <Button
              disabled={loading}
              loading={loading}
              type="primary"
              htmlType="submit"
            >
              Save
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};
