import { useState, useEffect } from 'react';

import { DefaultOptionType } from 'antd/es/select';

const defaultStaticOptions: unknown[] = [];
const defaultFetchOptions = async () => [];

export const useSelectDynamicOptions = <T = DefaultOptionType>({
  staticOptions = defaultStaticOptions as T[],
  fetchOptions = defaultFetchOptions as () => Promise<T[] | undefined>,
}: {
  staticOptions?: T[];
  fetchOptions?: () => Promise<T[] | undefined>;
}): [boolean, T[]] => {
  const [isLoading, setLoading] = useState(true);
  const [options, setOptions] = useState<T[]>([]);

  useEffect(() => {
    const getOptions = async () => {
      setLoading(true);

      const options = await fetchOptions();

      if (options) {
        setOptions([...staticOptions, ...options]);
      }

      setLoading(false);
    };

    getOptions();
  }, [staticOptions, fetchOptions]);

  return [isLoading, options];
};
