import { useMemo, FC } from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import { TVariationOption } from 'common/types/common-types';
import { FormList } from 'components/Form';
import { ProductControllerViewModel } from 'pages/Product/ProductController/product-controller-view-model';

import styles from './styles.module.scss';

export type TVariationItem = {
  id: string;
  type: 'size' | 'color' | 'material' | 'placement' | 'type' | 'none';
};

type TVariationProps = (
  | {
      editable: false;
      id?: never;
      options?: never;
      setVariationType?: never;
      deleteVariationOption?: never;
    }
  | {
      editable: true;
      id: string;
      options: TVariationOption[];
      setVariationType: (id: string | number, type: string) => void;
      deleteVariationOption: (id: string | number) => void;
    }
) & {
  model: ProductControllerViewModel;
  selectedOptions: Set<string>;
  type: TVariationItem['type'];
  lastRemovable?: boolean;
};

const Variation: FC<TVariationProps> = ({
  model,
  options,
  selectedOptions,
  type,
  id,
  lastRemovable,
  setVariationType,
  deleteVariationOption,
}) => {
  const handleVariationTypeChange = (value: string) => {
    setVariationType?.(id, value);
  };

  const handleDeleteOption = () => {
    deleteVariationOption?.(id);
  };

  const avaliableOptions = useMemo(() => {
    const optionsSet = new Set(Array.from(selectedOptions));
    optionsSet.delete(type);

    return (options || []).filter((option) => !optionsSet.has(option.value));
  }, [options, selectedOptions, type]);

  return (
    <div>
      {!!setVariationType && (
        <>
          <span>Option name</span>
          <div className={styles.inputGroup}>
            <Select
              showSearch
              className={styles.variationSelectType}
              onChange={handleVariationTypeChange}
              options={avaliableOptions}
            />
            <DeleteOutlined onClick={handleDeleteOption} />
          </div>
        </>
      )}
      <div className={styles.valueGroup}>
        {type !== 'none' && (
          <div className={styles.valuesBlock}>
            <div>Option values</div>
            {type === 'material' && (
              <FormList
                unique
                name={`variations.${type}`}
                type="autocomplete"
                options={model.materialAutocomplete}
                lastRemovable={lastRemovable}
              />
            )}
            {type !== 'material' && (
              <FormList
                unique
                name={`variations.${type}`}
                lastRemovable={lastRemovable}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Variation;
