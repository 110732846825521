import { defaultPhoto } from 'common/constants';
import { IReturnShipmentItem } from 'common/types';

export class ReturnItemModel {
  _item: IReturnShipmentItem;

  get item() {
    return this._item;
  }

  set item(item: IReturnShipmentItem) {
    this._item = item;
  }

  get id() {
    return this.item.id;
  }

  get createdAt() {
    return this.item.createdAt;
  }

  get updatedAt() {
    return this.item.createdAt;
  }

  get orderItem() {
    return this.item.orderItem;
  }

  get quantity() {
    return this.item.quantity;
  }

  get variation() {
    return this.item.variation;
  }

  get photo() {
    return (
      this.item.variation.content.photos?.find(
        (photo) => photo.id === this.item.variation.content.mainPhotoId,
      ) || defaultPhoto
    );
  }

  constructor(item: IReturnShipmentItem) {
    this._item = item;
  }
}
