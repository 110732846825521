import { FC, useEffect, useMemo } from 'react';

import { EmployeesPageViewModel } from './employees-page-view-model';
import { EmployeeTable } from './EmployeeTable';
import { SupplierList } from './SupplierList';

import styles from './employees.module.scss';

interface IEmployeesContentProps {
  model: EmployeesPageViewModel;
}

const EmployeesContent: FC<IEmployeesContentProps> = ({ model }) => (
  <div className={styles.content}>
    <EmployeeTable model={model.employeesViewModel} />
    <SupplierList model={model.suppliersViewModel} />
  </div>
);

export const EmployeesPage = () => {
  const employeesPageViewModel = useMemo(
    () => new EmployeesPageViewModel(),
    [],
  );

  useEffect(() => {
    employeesPageViewModel.employeesViewModel.getEmployees();
    employeesPageViewModel.suppliersViewModel.getSuppliers();
  }, [employeesPageViewModel]);

  return <EmployeesContent model={employeesPageViewModel} />;
};
