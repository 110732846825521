import { SetStateAction } from 'react';

import { AxiosResponse } from 'axios';
import { IDataWithPagination } from 'common/types';

import { ISubcategory, ISubcategoryParams } from '../common/types/subcategory';

import { $host } from './index';

const headers = {
  'Content-Type': 'multipart/form-data',
};

export const getAllSubcategories = async (params?: ISubcategoryParams) => {
  const {
    data: { data },
  } = await $host.get<AxiosResponse<IDataWithPagination<ISubcategory>>>(
    'admin/subcategories',
    {
      params: params ?? {
        page: 1,
        pageSize: 50,
        sort: 'DESC',
        sortBy: 'updatedAt',
      },
    },
  );

  return data;
};

export const getSubcategoryById = async (id: ISubcategory['id']) => {
  const {
    data: { data },
  } = await $host.get<AxiosResponse<ISubcategory>>(
    `admin/subcategories/id/${id}`,
  );

  return data;
};

export const getAllSubCategoriesByBrandId = async (
  id: SetStateAction<number | undefined>,
): Promise<ISubcategory[]> => {
  const { data } = await $host.get(`admin/subcategories/${id}`);

  return data.data;
};

export const deleteById = async (id: ISubcategory['id']) => {
  const { data } = await $host.delete<AxiosResponse<ISubcategory>>(
    `admin/subcategories/${id}`,
  );

  return data.data;
};

export const createSubcategory = async (params: FormData) => {
  const { data } = await $host.post<AxiosResponse<ISubcategory>>(
    `admin/subcategories`,
    params,
    { headers },
  );

  return data.data;
};

export const editSubcategory = async (
  id: ISubcategory['id'],
  params: FormData,
) => {
  const { data } = await $host.put<AxiosResponse<ISubcategory>>(
    `admin/subcategories/${id}`,
    params,
    {
      headers,
    },
  );

  return data.data;
};

export const editFeedDestinations = async (id: number, params: any) => {
  const {
    data: { data },
  } = await $host.put<AxiosResponse<any>>(
    `admin/subcategories/${id}/feed-destinations`,
    params,
  );

  return data;
};
