import { getPage, patchPage } from 'http/pagesApi';
import {
  getPromocodeCandidates,
  setPromocodeAsPrimary,
} from 'http/promocodeApi';
import { createSlide, deleteSlide, editSlide, getSlider } from 'http/sliderApi';

import { IPage } from 'common/types/page';
import { EPages } from 'common/types/page/enums';
import {
  IPromocode,
  IPromocodeCandidatesOptions,
} from 'common/types/promocode';
import { NoneEnum } from 'common/types/promocode/enums';
import { ISlider, TSlideValue } from 'common/types/slider';
import { cents2dollars, notify } from 'common/utils';
import { makeAutoObservable } from 'mobx';

export type TDefaultPromocode = number | NoneEnum.None;
export type TBrandsMeta = Omit<IPage, 'page'>;

export class MainViewModel {
  _loading = false;
  _promocodeCandidatesOptions: IPromocodeCandidatesOptions[] = [];
  _defaultPromocodeCandidate: TDefaultPromocode = NoneEnum.None;
  _slider: ISlider[] = [];
  _brandsMeta: TBrandsMeta = { metaTitle: null, metaDescription: null };

  get loading() {
    return this._loading;
  }

  set loading(value: boolean) {
    this._loading = value;
  }

  get promocodeCandidatesOptions() {
    return this._promocodeCandidatesOptions;
  }

  set promocodeCandidatesOptions(value: IPromocodeCandidatesOptions[]) {
    this._promocodeCandidatesOptions = value;
  }

  get defaultPromocodeCandidate() {
    return this._defaultPromocodeCandidate;
  }

  set defaultPromocodeCandidate(value: TDefaultPromocode) {
    this._defaultPromocodeCandidate = value;
  }

  get slider() {
    return this._slider;
  }

  set slider(slider: ISlider[]) {
    this._slider = slider;
  }

  get brandsMeta() {
    return this._brandsMeta;
  }

  set brandsMeta(brandsMeta: TBrandsMeta) {
    this._brandsMeta = brandsMeta;
  }

  getSliderFormData = (values: TSlideValue) => {
    const fd = new FormData();

    fd.append('url', values.url ?? '');
    fd.append('title', values.title ?? '');
    fd.append('text', values.text ?? '');
    fd.append('buttonTitle', values.buttonTitle ?? '');

    values.photo?.file && fd.append('photo', values.photo.file);

    return fd;
  };

  fetchPromocodeCandidates = async () => {
    try {
      const promocodeCandidates = await getPromocodeCandidates();

      const promocodeCandidateOptions = [
        {
          value: NoneEnum.None,
          label: NoneEnum.None,
        },
        ...promocodeCandidates.available.map((promocode: IPromocode) => {
          const discount =
            promocode.units === 'percents'
              ? `${promocode.discount}%`
              : `${cents2dollars(promocode.discount)}$`;

          return {
            value: promocode.id,
            label: `${promocode.promocode} (Purchase sum: ${cents2dollars(
              promocode.purchaseSum,
            )}$/Discount: ${discount})`,
          };
        }),
      ];

      this.promocodeCandidatesOptions = promocodeCandidateOptions;

      this.defaultPromocodeCandidate =
        promocodeCandidates.current?.id ?? NoneEnum.None;
    } catch (e) {
      notify('There was an error loading promocodes data', 'error');
    }
  };

  fetchSlider = async () => {
    try {
      const slider = await getSlider();

      this.slider = slider;
    } catch (e) {
      notify(
        'There was an error loading slider data, please try again',
        'error',
      );
    }
  };

  async fetchBrandsMeta() {
    try {
      const { metaTitle, metaDescription } = await getPage(EPages.BRANDS);

      this.brandsMeta = { metaTitle, metaDescription };
    } catch (e) {
      notify(
        'There was an error loading brands meta data, please try again',
        'error',
      );
    }
  }

  fetchData = async () => {
    this.loading = true;

    try {
      await Promise.all([
        this.fetchPromocodeCandidates(),
        this.fetchSlider(),
        this.fetchBrandsMeta(),
      ]);
    } catch (e) {
      notify('An error occurred while loading the page', 'error');
    } finally {
      this.loading = false;
    }
  };

  onPromocodeChange = async (promoId: number | NoneEnum.None) => {
    this.loading = true;

    try {
      const promocodes = await setPromocodeAsPrimary(
        promoId === NoneEnum.None ? null : promoId,
      );

      this.defaultPromocodeCandidate = promocodes?.id ?? NoneEnum.None;

      notify('Promocode is set!', 'success');
    } catch (e) {
      notify(`Failed to change promocode`, 'error');
    } finally {
      this.loading = false;
    }
  };

  onSliderChange = async (
    removedSlides: string[],
    updatedSlides: TSlideValue[],
    addedSlides: TSlideValue[],
  ) => {
    this.loading = true;

    if (removedSlides.length) {
      try {
        await Promise.all(removedSlides.map((id) => deleteSlide(Number(id))));
      } catch (e) {
        notify('Failed to delete slides', 'error');
      }
    }

    if (updatedSlides.length) {
      const params = updatedSlides.map((slide) => {
        return {
          id: slide.id,
          params: this.getSliderFormData({
            text: slide?.text,
            title: slide?.title,
            buttonTitle: slide?.buttonTitle,
            photo: slide?.photo,
            url: slide?.url,
            id: slide.id,
          }),
        };
      });

      try {
        await Promise.all(
          params.map((value) => editSlide(value.id, value.params)),
        );
      } catch (e) {
        notify('Failed to update slides data', 'error');
      }
    }

    if (addedSlides.length) {
      const params = addedSlides.map((slide) => this.getSliderFormData(slide));

      try {
        await Promise.all(params.map((element) => createSlide(element)));
      } catch (e) {
        notify('Failed to add new slides', 'error');
      }
    }

    await this.fetchSlider();

    this.loading = false;
  };

  onBrandsMetaChange = async (brandsMeta: TBrandsMeta) => {
    try {
      this.loading = true;

      const params = {
        page: EPages.BRANDS,
        ...brandsMeta,
      };

      await patchPage(params);
    } catch (e) {
      notify('Failed to change brands meta', 'error');
    } finally {
      this.loading = false;
    }
  };

  constructor() {
    this.fetchData();

    makeAutoObservable(this);
  }
}
