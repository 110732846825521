import { FC } from 'react';

import { Typography } from 'antd';

import styles from './log.module.scss';

export interface ILogProps {
  date: string;
  action: string;
  author: string;
}

export const Log: FC<ILogProps> = ({ date, action, author }) => (
  <div className={styles.container}>
    <div className={styles.field}>
      <Typography.Text strong>Date</Typography.Text>
      <Typography.Text>{date}</Typography.Text>
    </div>
    <div className={styles.field}>
      <Typography.Text strong>Action</Typography.Text>
      <Typography.Text>{action}</Typography.Text>
    </div>
    <div className={styles.field}>
      <Typography.Text strong>Author</Typography.Text>
      <Typography.Text>{author}</Typography.Text>
    </div>
  </div>
);
